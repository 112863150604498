
/*jslint node: true, sloppy: true, bitwise: true, vars: true, eqeq: true, plusplus: true, nomen: true, es5:true */
/*global angular, atob, btoa, google, sortByColumn, showMessage, dateString */

var app = angular.module('dassUiModule');


// ------------------------------------------------------------------------------------------------------------------------------------
// MODEL CONTROLLERS
// ------------------------------------------------------------------------------------------------------------------------------------

function parseHexPayload(str) {

	if (typeof str !== "string") {
		return null;
	}
	var i, c, s = str.toLowerCase(),
		n, nc = 0,
		cv = 0;
	var res = "";

	for (i = 0; i < s.length; i++) {
		c = s.charCodeAt(i);

		n = -1;
		if (c >= 97 && c <= 102) {
			n = c - 97 + 10;
		} else if (c >= 48 && c <= 57) {
			n = c - 48;
		} else if (c == 32) {

			if (nc % 2 == 0) {
				nc = 0;
			} else if (nc == 1) {
				res += String.fromCharCode(cv);
				nc = 0;
			} else {
				return null;
			}

		} else {
			return null;
		}

		if (n >= 0) {
			if (nc % 2 == 0) {
				cv = n;
			} else {
				cv = cv * 16 + n;
				res += String.fromCharCode(cv);
			}
			nc++;
		}

	}

	if (nc == 1) {
		res += String.fromCharCode(cv);
	} else if (nc % 2 == 1) {
		return null;
	}

	return res;
}




app.directive('hexString', function () {
	return {

		// limit usage to argument only
		restrict: 'A',

		// require NgModelController, i.e. require a controller of ngModel directive
		require: 'ngModel',

		// create linking function and pass in our NgModelController as a 4th argument
		link: function (scope, element, attr, ctrl) {

			function hexStringValidator(str) {
				ctrl.$setValidity('hexStringValidator', parseHexPayload(str) != null);
				return str;
			}

			ctrl.$parsers.push(hexStringValidator);
		}
	};
});



// ------------------------------------------------------------------------------------------------------------------------------------
// MODEL CONTROLLERS
// ------------------------------------------------------------------------------------------------------------------------------------


//
// Modal controller for the Sign In dialog box

app.controller('SignInModalCtrl', ["$scope", "$uibModalInstance", "items", function ($scope, $uibModalInstance, items) {

	$scope.credentials = {
		userid: "",
		password: ""
	};
	$scope.items = items;
	$scope.enable_sign_up = $scope.items[0].enable_sign_up;
	$scope.selected = {
		item: $scope.items[0]
	};
	vm.constants = constants;
	vm.autocomplete = (constants.disable_autocomplete_for_credentials === true) ? "off" : "on";
	$scope.ok = function () {
		$uibModalInstance.close($scope.credentials);
	};

	$scope.cancel = function () {
		$uibModalInstance.dismiss('cancel');
	};
}]);

app.controller('SelectTenantController', ["$scope", "$uibModalInstance", "items", "$filter", "ToastService", "TenantService", function ($scope, $uibModalInstance, items, $filter, ToastService, TenantService) {
	console.log("Select tenant controller");
	const $translate = $filter("translate");
	const vm = $scope;
	vm.loading = false;
	vm.selectedTenant = {
		tenantid: -1
	};

	vm.loadTenants = () => {
		vm.loading = true;
		TenantService.getTenants().then((tenants) => {
			vm.tenants = tenants;
			vm.loading = false;
		}).catch((err) => {
			vm.loading = false;
			ToastService.showMessage($translate("MSG_ERROR_LOADING_TENANTS"), "error");
		});
	}

	// TODO: Fix this hack
	vm.tenantSelected = (tenant) => {
		console.log("tenant selected", tenant);
		vm.selectedTenant = tenant;
	}

	vm.ok = function () {
		console.log("Closing tenant modal with tenant ", vm.selectedTenant);
		$uibModalInstance.close(vm.selectedTenant);
	};

	vm.cancel = function () {
		$uibModalInstance.dismiss('cancel');
	};

	vm.loadTenants();
}]);

app.controller('SendDataModalCtrl', ["$scope", "$uibModalInstance", "items", function ($scope, $uibModalInstance, items) {
	$scope.payload = {
		data: "",
		port: 1,
		fnct: "",
		confirmed: true
	};

	$scope.items = items;

	$scope.getsize = function () {
		var data = parseHexPayload($scope.payload.data);
		return data == null ? 0 : data.length;
	};

	$scope.selected = {
		item: $scope.items[0]
	};

	$scope.isMulticast = items.isMulticast;

	$scope.ok = function () {
		var pldata = parseHexPayload($scope.payload.data);
		if (pldata == null || $scope.payload.port == null) {
			return;
		}

		$scope.payload.data = pldata;
		$uibModalInstance.close($scope.payload);
	};

	$scope.cancel = function () {
		$uibModalInstance.dismiss('cancel');
	};
}]);



app.controller('SelectDeviceModalCtrl', ["$scope", "$uibModalInstance", "$filter", "DeviceService", "items", "$http", function ($scope, $uibModalInstance, $filter, DeviceService, items, $http) {
	var dateString = $filter('dateString');
	var sortByColumn = $filter('sortByColumn');

	$scope.pscope = items;
	$scope.devices = $scope.pscope.devices;
	$scope.formatDeveui = $filter("formatDeveui");
	$scope.sortedDevices = [];
	$scope.filteredDevices = [];
	$scope.deviceSortColumn = "deveui";
	$scope.deviceSortReverse = false;

	$scope.currentPage = 1;
	$scope.itemsPerPage = 10;
	$scope.searchString = "";

	$scope.sortDevices = function () {
		$scope.sortedDevices = sortByColumn($scope.devices, $scope.deviceSortColumn, $scope.deviceSortReverse);
		console.log($scope.sortedDevices);
	};


	$scope.loadDevices = function () {

		DeviceService.getDevices().then(
			function (devices) {
				$scope.devices = devices;

				var i;
				for (i = 0; i < $scope.devices.length; i++) {
					$scope.devices[i].last_reception_str = dateString($scope.devices[i].last_reception);
				}

				$scope.sortDevices();
				$scope.pscope.devices = $scope.devices;
			}
		);
	};



	$scope.select = function (dev) {
		$uibModalInstance.close(dev);
	};

	$scope.cancel = function () {
		$uibModalInstance.dismiss('cancel');
	};
}]);


function validKey(key, length) {

	if (typeof key !== "string") {
		return false;
	}

	if (length == 4) {
		return key.match(/(^(0[x])?[0-9a-f]{8}$)|(^([0-9a-f]{2}-){3}[0-9a-f]{2}$)/i) != null;
	} else if (length == 8) {
		return key.match(/(^(0[x])?[0-9a-f]{16}$)|(^([0-9a-f]{2}-){7}[0-9a-f]{2}$)/i) != null;
	} else if (length == 16) {
		return key.match(/(^(0[x])?[0-9a-f]{32}$)|(^([0-9a-f]{2}-){8}[0-9a-f]{2}$)/i) != null;
	}
	return false;
}

function es(str) {
	console.log(typeof str);
	return typeof str === "string" ? str : "";
}


function toHex32(val) {
	if (typeof val !== "number") {
		return null;
	} else {
		return "00000000".substring(val.toString(16).length) + val.toString(16).toUpperCase();
	}
}


app.controller('AddDeviceModalCtrl', ["$scope", "$uibModalInstance", "items", "$filter", "MessageService", function ($scope, $uibModalInstance, items, $filter, MessageService) {
	var $translate = $filter('translate');
	$scope.items = items;
	$scope.devices = items.devices;
	$scope.batch = items.devices ? true : false;
	$scope.initialDevice = items.device || {};
	$scope.editMode = items.device != undefined;
	$scope.readonly = $scope.editMode || $scope.batch;
	$scope.valid = false;
	$scope.device = {};
	$scope.mod = {};
	$scope.changed = false;
	$scope.edit_rights = items.owner;
	$scope.myModalInstance = $uibModalInstance;
	$scope.deviceTags = items.deviceTags;

	$scope.showStatus = function() {
	};


	/*

	$scope.$on('updateDeviceTagsSecond', function(event, data) {	// when there is a change from the child component
		$scope.deviceTags = data;
		$scope.tagsValidity = $scope.checkTagsValidity($scope.deviceTags) && $scope.newTagNameValidity;
	});

	$scope.$on('updateDeviceNewTagNameSecond', function(event, data) {	// when there is a change from the child component
		let regexTagName = /^[a-zA-Z\d\-_]*$/;
		let result = data.match(regexTagName);
		if (!result) {
			$scope.newTagNameValidity = false;
		}
		else {
			$scope.newTagNameValidity = true;
		}

		$scope.tagsValidity = $scope.checkTagsValidity($scope.deviceTags) && $scope.newTagNameValidity;
    });

	*/

	$scope.tagsValidity = true;
	$scope.newTagNameValidity = true;
	$scope.checkTagsValidity = function (tags) {
		let regexTagName = /^[a-zA-Z\d\-_]*$/;
		for (var tagName in tags) {
			let result = tagName.match(regexTagName);
			if (!result) {
				return false;
			}
		}

		return true;
	}

	$scope.accept = () => {
		if (!$scope.valid) return;
		console.log("Modal accept device", {...$scope.device});
		$uibModalInstance.close($scope.device);
	};

	$scope.cancel = () => {
		$uibModalInstance.dismiss('cancel');
	}
}]);


app.controller('MessageModalCtrl', ["$scope", "$uibModalInstance", "items", function ($scope, $uibModalInstance, items) {

	$scope.title = items.title;
	$scope.body = items.body;

	$scope.ok = function () {
		$uibModalInstance.close('ok');
	};

	$scope.cancel = function () {
		$uibModalInstance.dismiss('cancel');
	};
}]);



app.controller('SelectPositionOnMapModalCtrl', ["$scope", "$uibModalInstance", "items", function ($scope, $uibModalInstance, items) {
	var vm = $scope; // TODO: Figure out how to use vm here instead of scope.
	var origCenter = items;
	console.log('maps market',origCenter);

	vm.center = {
		lat: origCenter.latitude,
		lng: origCenter.longitude,
		alt: origCenter.altitude
	};

	vm.markers = [{
		layer: 'devices',
		lat: origCenter.latitude || 0,
		lng: origCenter.longitude || 0,
		alt: origCenter.altitude,
		latVal: origCenter.latitude,
		lngVal: origCenter.longitude,
		altVal: origCenter.altitude
	}];

	vm.marker = vm.markers[0];

	vm.dragEnd = function (marker) {
		vm.marker = angular.copy(marker);
		vm.marker.latVal = vm.marker.lat;
		vm.marker.lngVal = vm.marker.lng;
		vm.marker.altVal = vm.marker.alt;
		console.log("Map view drag end 2", vm.marker);
	}

	vm.recenter = function () {
		console.log('recenter',vm.marker);
		vm.marker.lat = vm.marker.latVal || 0;
		vm.marker.lng = vm.marker.lngVal || 0;
		vm.marker.alt = vm.marker.altVal;
		vm.center = {
			lat: vm.marker.lat,
			lng: vm.marker.lng,
			alt: vm.marker.alt
		};

		if (!vm.marker.latVal || !vm.marker.lngVal) {
			vm.center.zoom = 1;
		}
		
		vm.markers = [vm.marker];
	}

	vm.ok = function () {
		$uibModalInstance.close(vm.marker);
	};

	vm.cancel = function () {
		$uibModalInstance.dismiss('cancel');
	};
	console.log("Vm", vm);
	console.log(items)
}]);




app.controller('ViewGatewayOnMapModalCtrl', ["$scope", "$uibModalInstance", "items", function ($scope, $uibModalInstance, items) {
	var vm = $scope; // TODO: Figure out how to use vm here instead of scope.
	var origCenter = items;

	vm.latVal = origCenter.latitude || null;
	vm.lngVal = origCenter.longitude || null;
	vm.user      = items.user;
	vm.constants = constants;

	var disableLatLng = false;
	if (items.status == 'NEVER_SEEN' || !vm.user.can_mng_gtw) {
		disableLatLng = true;
	}

	vm.center = {
		lat: origCenter.latitude,
		lng: origCenter.longitude
	};

	vm.markers = [{
		layer: origCenter.layer,
		lat: origCenter.latitude || 0,
		lng: origCenter.longitude || 0,
		name: origCenter.name,
		disableLatLng: disableLatLng,
		disableName: !vm.user.can_mng_gtw && vm.user.can_access_gtw_unfiltered,
	}];

	vm.marker = vm.markers[0];

	vm.dragEnd = function (marker) {
		vm.marker = angular.copy(marker);
		vm.latVal = marker.lat;
		vm.lngVal = marker.lng;
		console.log("Map view drag end 3", vm.marker);
	}

	vm.recenter = function () {
		vm.marker.lat = vm.latVal || 0;
		vm.marker.lng = vm.lngVal || 0;
		vm.center = {
			lat: vm.marker.lat,
			lng: vm.marker.lng
		};

		if (!vm.latVal || !vm.lngVal) {
			vm.center.zoom = 1;
		}

		vm.markers = [vm.marker];
	}

	vm.ok = function () {
		console.log("closing with ", vm.marker)
		$uibModalInstance.close(vm.marker);
	};

	vm.cancel = function () {
		$uibModalInstance.dismiss('cancel');
	};
}]);

app.controller('AddGatewayModalCtrl', ["$scope", "$uibModalInstance", "items", function ($scope, $uibModalInstance, items) {

	$scope.gateway = {
		id: "",
	};
	$scope.items = items;

	$scope.ok = function () {

		var h = parseHexPayload($scope.gateway.id);

		if (h == null || h.length != 8) {
			return;
		}

		$uibModalInstance.close($scope.gateway);
	};

	$scope.cancel = function () {
		$uibModalInstance.dismiss('cancel');
	};
}]);
