import * as React from "react";
import {
    FormControl,
} from "react-bootstrap";
import { strings } from "./../../services/Localization";
export class FilterText extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            value: "",
            filterFieldValidity: "hide-input-tooltip",
            searchCounter: 0,
            inputColor: "",
        };
    }
    public handleChange = (e) => {
        let searchCounterTemp = this.state.searchCounter + 1;

        this.setState({ 
            value: e.target.value,
            searchCounter: searchCounterTemp
        });
    }
    
    public handleKeyPress = (e) => {
        if (this.state.searchCounter > 0 && (e.key === "Enter") &&
            ((this.state.value.length === 0 ) ||
            (this.state.value.length >= ((this.props.customFilterParameters.length) ?
            this.props.customFilterParameters.length : 4 )))) {

            this.setState({ 
                searchCounter: 0
            });

            this.props.FilterHandler(
                this.state.value,
            );
        } 
        else {
            let textMinLength = 4;
            if(this.props.customFilterParameters.length){
                textMinLength = this.props.customFilterParameters.length;
            }

            if(e.target.value.length < textMinLength
                && e.target.value.length != 0){
                
                    this.setState({ 
                        filterFieldValidity: "show-input-tooltip",
                        inputColor: "input-color-error"
                    });
            } else if(e.target.value.length == 0){
                this.setState({ 
                    filterFieldValidity: "hide-input-tooltip",
                    inputColor: "" 
                });
            } else{
                this.setState({ 
                    filterFieldValidity: "hide-input-tooltip",
                    inputColor: "input-color-success" 
                });
            }
        }
    }

    public InputTooltip = (content) => {
        return (
            <span className={`search-tooltip-charachter ${this.state.filterFieldValidity}`}>
                {content}
            </span>
        );
    }

    public handleKeyPressOnFilterField = (e) => {
        let textMinLength = 4;
        if(this.props.customFilterParameters.length) {
            textMinLength = this.props.customFilterParameters.length;
        }

        if(e.target.value.length < textMinLength
            && e.target.value.length !== 0) {

                this.setState({ 
                    filterFieldValidity: "show-input-tooltip",
                    inputColor: "input-color-error"
                });
        } else if(e.target.value.length == 0){
            this.setState({ 
                filterFieldValidity: "hide-input-tooltip",
                inputColor: ""
            });
        } else {
            this.setState({ 
                filterFieldValidity: "hide-input-tooltip",
                inputColor: "input-color-success"
            });
        }
    }

    public render() {
        return (
            <div className="position-relative">

                <FormControl
                    className={`filter text-filter ${this.state.inputColor}`}
                    type="text"
                    value={this.state.value}
                    placeholder= {strings.PROFILES_TABLE_ENTER_PLACEHOLDER_TEXT +
                        " " + strings[this.props.customFilterParameters.placeholder]}
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeyPress}
                    onKeyUp={this.handleKeyPressOnFilterField}
                />

                {this.InputTooltip(
                     ((this.props.customFilterParameters.length) && (this.props.customFilterParameters.length === 16))
                     ? (strings.SEARCH_FIND_DEVICE_TOOLTIP) :
                     ((strings.SEARCH_MIN_LENGTH_TOOLTIP_1 +
                     ((this.props.customFilterParameters.length) ? this.props.customFilterParameters.length : 4) +
                     strings.SEARCH_MIN_LENGTH_TOOLTIP_4 +
                     strings.SEARCH_MIN_LENGTH_TOOLTIP_2)),
                 )}

            </div>
        );
    }
}
