import * as React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { strings } from "../../../services/Localization";

import NoPositionOnMap from "./../../../assets/map-marker-slash.svg";

export default class NetworkMapTable extends React.Component<any,any> {
    constructor(props) {
        super(props);
        this.state = {
            TableData: []
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(prevProps.TableData) !== JSON.stringify(this.props.TableData)) {
            this.setState({
                TableData: this.props.TableData
            });
        }
    }

    render() {
        const { TableData } = this.state;
        return (
            <div className="panel panel-default" id="map-device-list-panel">
                <div className="panel-body panel_body_newstyle">
                    <table className="table table-hover table_newstyle">
                        <tbody>
                            {TableData.map((data, index) => {
                                const type = (data.hasOwnProperty("deveui")) ? "Device" : "Gateway";
                                const id = (data.hasOwnProperty("deveui")) ? data.deveui : data.id;
                                return <tr key={index} className={(!data.longitude || !data.latitude) ? "no-position" : ""}>
                                    <td
                                        onClick={() => this.props.FlyToTheMarker(data)}
                                        onDoubleClick={() => this.props.OpenEditPositionModal(data)}
                                    >
                                        <small>
                                            {type === "Gateway" && 
                                                <><i
                                                    className={
                                                        `fas fa-broadcast-tower ${data.status === "OK" ? "green_icon" : "red_icon"}`
                                                    }
                                                    id="gateway_icon"
                                                ></i> &nbsp;</>
                                            }
                                            {id.match(/.{2}/g).join("-").toUpperCase()}
                                            {(!data.latitude || !data.longitude) && 
                                                <>
                                                    &nbsp;
                                                    <OverlayTrigger
                                                        placement="left"
                                                        overlay={
                                                            <Tooltip id="no_position_tooltip">
                                                                {strings.MAP_NO_POSITION_SET}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <img src={NoPositionOnMap} id="no_position_icon" alt="" />
                                                    </OverlayTrigger>
                                                </>
                                            }
                                            {type === "Device" ? <><br/> {data.comment}</>:
                                                data.name ? <><br/> {data.name}</> : null
                                            }
                                        </small>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
