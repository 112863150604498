((angular) => {
    // TODO: move this
    function b64DecodeUnicode(str) {
        // Going backwards: from bytestream, to percent-encoding, to original string.
        return decodeURIComponent(atob(str).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    }

    function ManageAppModalController($scope, $rootScope, $window, AppService, MessageService, ToastService, $filter, $uibModalInstance, items, $timeout, UserService) {
        let vm = $scope;
        vm.autocomplete = (constants.disable_autocomplete_for_credentials === true) ? "off" : "on";

        // The App-Modal is the top controller for editing the applicaionts. It uses the
        // app-form controller as a sub controller.

        const $translate = $filter('translate');
        vm.defaultApp = {
            accountid: "",
            can_register: false,
            can_access_gtw_info: false,
            can_own_gtw: false,
            can_add_gtw: false,
            can_mng_gtw: false,
            loraloc_enable: false
        };

        $rootScope.readOnlyCustom = true;

        vm.validationData = {
            upper: false,
            lower: false,
            special: false,
            length: false,
            complex: false,
            length_value: 0,
            show_length: false,
            number: false,
            count: 0,
            autocomplete: vm.autocomplete
        }


        vm.resetApp = () => {
            vm.app = vm.defaultApp;
        }

        vm.form = {
            // if want validation by default to be false change valid value to false
            // then do validation to change the validation value
            valid: true,
            passwordConf: ''
        };

        vm.items = items;

        vm.currentUser = items.currentUser;
        vm.passwordConf = "";

        vm.apps = [];
        vm.editMode = vm.items.editMode;
        vm.loading = false;

        vm.selected = [];

        vm.tabs = {
            // the first tab is the rights tab. if there are no rights it is hidden and we set the second tab active.
            activeTab: vm.currentUser.can_register || vm.currentUser.can_access_gtw_info || vm.currentUser.can_own_gtw ||
                       vm.currentUser.can_add_gtw || vm.currentUser.can_mng_gtw ? 0 : 1,
            tabList: ["EDIT_USER_ADMIN_RIGHTS", "HTTP_PUSH_CONFIG_TITLE"]
        };

        vm.resetSubscriptionsListClick = ($event) => {

            return;

            // THIS FOLLOWING CODE IS ONLY COMPATIBLE WITH CHROME!!!!!
            // HOW WAS THIS EVER TESTED!!!

            if ($event.target.className == 'close ui-select-match-close') {
                localStorage.setItem('subsriptionsListCounter', 0);
            }
            else if ($event.target.className != 'dropdown-arrow-click toggle' &&
                !$event.target.className.startsWith('ui-select-search input-xs ng-pristine')) {

                if ($event.path[1] && $event.path[1].className.startsWith('ui-select-match-item')) {

                }
                else if ($event.path[1] && $event.path[1].className.startsWith('ui-select-choices-row')) {
                    localStorage.setItem('subsriptionsListCounter', 1);
                }
                else {
                    localStorage.setItem('subsriptionsListCounter', 0);
                }

                if ($event.path[2] && $event.path[2].id == 'searchAuthTypeArea') {

                }
                else {
                    localStorage.setItem('authTypesListCounter', 0);
                }

                if ($event.path[2] && $event.path[2].id == 'searchDataFormatArea') {

                }
                else {
                    localStorage.setItem('dataFormatListCounter', 0);
                }

                if ($event.path[2] && $event.path[2].id == 'searchStorePayloadArea') {

                }
                else {
                    localStorage.setItem('storePayloadListCounter', 0);
                }
            }
        }

        vm.CheckURLForPort = (OldData, NewData, property, Data) => {
            var DataToSend = Data;
            if (/^(http|mqtt|kafka|https|mqtts|kafkas):[/]{2}([\w.-]*([{]{2}[^}]+[}]{2})*([$][{][\w.-]+[}])*){1,4}(:([0-9]{1,5}))?$/.test(NewData[property])) {
                var host = NewData[property].replace(/(:([0-9]{1,5}))/, "");
                var SplitString = NewData[property].split(":");
                var GetPort = SplitString[SplitString.length - 1];
                var port = (/([0-9]{1,5})/.test(GetPort) ? parseInt(GetPort) : 0);
                if ((!OldData[property] || OldData[property] !== host) || (!OldData["port"] || OldData["port"] !== port)) {
                    if ((!OldData[property] || OldData[property] !== host) && (!OldData["port"] || OldData["port"] !== port)) {
                        DataToSend.host = host, DataToSend.port = port;
                    } else if (!OldData["port"] || OldData["port"] !== port) {
                        DataToSend.port = port;
                    } else if (!OldData[property] || OldData[property] !== host) {
                        DataToSend.host = host;
                    }
                }
                return DataToSend;
            } else {
                DataToSend.host = "";
                DataToSend.port = 0;
                return DataToSend;
            }
        }

        function clearAppObject(json) {

            // the UI uses
            //   username, cleartext_password
            //   no_auth
            //   push_auth_access_token,
            //   custom_auth
            //

            // The follow 3 options exist for authentication to the DASS:
            //
            //   auth_string - custom authentication
            //   push_auth_access_token  - access token authentication
            //   push_auth_password, push_auth_username - Basic authentication
            //
            // The fields of these three are mutual exclusive.

            json.retry_policy = 0;

            delete json.push_auth_username;
            delete json.push_auth_password;
            delete json.auth_string;

            delete app['_valid'];
            delete app['app_uuid'];
            delete app.validationResult;

            if (json.username || json.cleartext_password) {
                json.push_auth_username = json.username;
                json.push_auth_password = json.cleartext_password;
                delete json.push_auth_access_token;

            } else if (json.hasOwnProperty("no_auth")) {
                json.auth_string = "";
                delete json.push_auth_access_token;

            } else if (json.hasOwnProperty("push_auth_access_token")) {
                // ok

            } else if (json.hasOwnProperty("custom_auth")) {
                json.auth_string = json.custom_auth;
                delete json.push_auth_access_token;
            }

            delete json.username;
            delete json.cleartext_password;
            delete json.no_auth;
            delete json.custom_auth;

            return json
        }


        vm.ok = () => {

            const app = clearAppObject({...vm.app});

            if (app.password === ""){
                delete app.password;
            }

            if (vm.editMode) {
                var DataToSend = {
                    accountid: app.accountid
                };

                Promise.all(Object.keys(app).map(async (property) => {
                    if (property === "host") {
                        DataToSend = await vm.CheckURLForPort(vm.OldAppData, app, property, DataToSend);
                    } else if (vm.OldAppData[property] !== app[property]) {
                        return DataToSend[property] = app[property];
                    }
                })).then(() => {

                    AppService.updateApp(DataToSend).then((response) => {
                        ToastService.showMessage($translate('MSG_APPLICATION_EDIT_SUCCESS_BODY', app), "success");
                        $uibModalInstance.close(app);

                    }).catch(async (error) => {
                        var message,
                        data = {data: error.data.charAt(0).toUpperCase() + error.data.slice(1)};
                        switch (error.status) {
                        case 412: message = $translate('PASSWORD_FAILED'); break;
                        case 401: message = $translate('UNAUTHORIZED_ERROR'); break;
                        case 404: message = $translate('UNKNOWN_APP_ID_ERROR'); break;
                        default:  message = $translate('MSG_APPLICATION_EDIT_FAIL_BODY', data); break;
                        }
                        ToastService.showMessage(message, "error");
                    });
                });
            }
            else {
                var DataToAdd = app;
                Promise.all(Object.keys(app).map(async (property) => {
                    if (property === "host") {
                        DataToAdd = await vm.CheckURLForPort({}, app, property, DataToAdd);
                    }
                })).then(() => {
                    AppService.addApp(DataToAdd).then((response) => {
                        ToastService.showMessage($translate('MSG_APPLICATION_ADDED_SUCCESS_BODY', app), "success");
                        $uibModalInstance.close(app);

                    }).catch((error) => {
                        console.log("Error Add App data: ", app);
                        var message,
                        data = {data: error.data.charAt(0).toUpperCase() + error.data.slice(1)};
                        switch (error.status) {
                        case 401: message = $translate('UNAUTHORIZED_ERROR'); break;
                        case 403: message = $translate('APP_FORBIDDEN_ERROR'); break;
                        case 409: message = $translate('MSG_APPLICATION_ADDED_FAIL_EXISTS', app); break;
                        default:  message = $translate('MSG_APPLICATION_ADDED_FAIL_BODY', data); break;
                        }
                        ToastService.showMessage(message, "error");
                    });
                });
            }
        };

        vm.close = () => {
            $uibModalInstance.dismiss('cancel');
        };

        vm.cancelForm = () => {
            vm.resetGroup();
        }

        vm.edit = (app) => {
            vm.resetGroup();
            vm.app = app;
        }

        vm.addNew = () => {
            vm.editMode = false;
            vm.addMode = true;
            vm.resetGroup();
        }

        vm.updateSelected = () => {
            vm.selected = vm.apps.filter(app => app._marked);
        }

        if (vm.items.app) {


            vm.OldAppData = JSON.parse(JSON.stringify(vm.items.app));
            vm.app = vm.items.app;

        } else {
            vm.resetApp();
        }

        vm.checkPasswordPolicyApp = (password) => {
            if (password === undefined) {
                password = null;
            }

            const objToSend = {
                password: password,
                type: 'app',
            };

            UserService.validatePasswordPolicy(objToSend).then(validationResult => {
                angular.extend(vm.validationData, validationResult.data);
                vm.app.validationResult = {};
                angular.extend(vm.app.validationResult, validationResult.data)
            }).catch(err => {
                MessageService.showMessage({
                    title: $translate("ERROR"),
                    body: $translate(err.data)
                });
            });
        }

        const owner = vm.currentUser;
        vm.profiles = {};
        vm.profiles.selectedProfileType = "";
        vm.profiles.device_profiles = owner.can_list_device_profile || owner.can_inspect_device_profile || owner.can_create_device_profile || owner.can_link_device_profile; 
        vm.profiles.service_profiles = owner.can_list_service_profile || owner.can_inspect_service_profile || owner.can_create_service_profile || owner.can_link_service_profile || owner.can_restrict_service_profile;
        vm.profiles.connectivity_profiles = owner.can_list_connectivity_profile || owner.can_inspect_connectivity_profile || owner.can_create_connectivity_profile || owner.can_link_connectivity_profile;
        vm.profiles.roaming_profiles = owner.can_list_roaming_profile || owner.can_inspect_roaming_profile ||    owner.can_create_roaming_profile || owner.can_link_roaming_profile;
        vm.profiles.channel_profiles = owner.can_list_channel_profile || owner.can_inspect_channel_profile ||    owner.can_create_channel_profile || owner.can_link_channel_profile
        vm.profiles.qos_profiles = owner.can_list_qos_profile || owner.can_inspect_qos_profile || owner.can_create_qos_profile || owner.can_link_qos_profile;

        vm.DefaultProfilesValues = (selectedProfileType) => {
            const Rights = ["create", "list", "inspect", "restrict"];
            const owner = vm.currentUser;
            Rights.map((right) => {
                if (owner[`can_${right}_${selectedProfileType}_profile`] &&
                    (vm.app[`can_${right}_${selectedProfileType}_profile`] === undefined)) {
                    vm.app[`can_${right}_${selectedProfileType}_profile`] = false;
                }
            });
        }

        vm.selectedProfileType = (profileType) => {
            vm.profiles.selectedProfileType = profileType;
            if ((vm.items.editMode === false) || (vm.items.editMode === undefined)) {
                vm.DefaultProfilesValues(profileType)
            }
        }

        // this is helper to be able to revert to previous tag name/desc when the user clicks on the cancel btn
        vm.oldTagName = '';
        vm.oldTagDesc = '';

        vm.editTagNameValidity = true;
        vm.sameTaqNameValidity = true;
        vm.editTag = function(index) {
            if (!vm.editTagNameValidity || !vm.validTagName) {
                // if some of the tags have incorrent tag name, forbid to edit some other tag

                return;
            }
    
            var counter = 0;
            for (var tagKey in vm.app.tags) {
                if (counter == index) {
                    vm.oldTagName = tagKey;
                    vm.oldTagDesc = vm.app.tags[tagKey];
    
                    break;
                }
    
                counter++;
            }
    
            if (vm.editTagNameValidity) {
                vm.hideAllTagsInput();
            }
    
            // show corresponding buttons depending of which row is currently being edited
            var tagKey = document.querySelector('.tags-table .table-body .key-' + index);
            var tagDesc = document.querySelector('.tags-table .table-body .val-' + index);
            var tagEditBtns = document.querySelector('.tags-table .table-body .current-tag-edit-btns-' + index);
            var tagActionsBtns = document.querySelector('.tags-table .table-body .current-tag-action-btns-' + index);
            if (tagKey) {
                tagKey.setAttribute("tags", "show");
            }
            if (tagDesc) {
                tagDesc.setAttribute("tags", "show");
            }
            if (tagActionsBtns) {
                tagActionsBtns.setAttribute("tags", "show");
            }
            if (tagEditBtns) {
                tagEditBtns.setAttribute("tags", "show");
            }
            
            $timeout(function() {   // we need timeout for immediate effect, otherwise the focus wont fork
                try {
                    document.querySelector('.tags-table .table-body .key-' + index + ' .tag-input-container').focus();
                }
                catch (e) {
                    
                }
            }, 0);
        }

        vm.hideAllTagsInput = function() {
            var counter = 0;
            for (var tagKey in vm.app.tags) {
                var tagKey = document.querySelector('.tags-table .table-body .key-' + counter);
                var tagDesc = document.querySelector('.tags-table .table-body .val-' + counter);
                var tagEditBtns = document.querySelector('.tags-table .table-body .current-tag-edit-btns-' + counter);
                var tagActionsBtns = document.querySelector('.tags-table .table-body .current-tag-action-btns-' + counter);

                if (tagKey) {
                    tagKey.setAttribute("tags", "hide");
                }

                if (tagDesc) {
                    tagDesc.setAttribute("tags", "hide");
                }

                if (tagActionsBtns) {
                    tagActionsBtns.setAttribute("tags", "hide");
                }

                if (tagEditBtns) {
                    tagEditBtns.setAttribute("tags", "hide");
                }

                counter++;
            }
        }

        vm.deleteTag = function(index) {
            var counter = 0;
            for (var tagKey in vm.app.tags) {
                if (counter == index) {
                    delete vm.app.tags[tagKey];
                    break;
                }

                counter++;
            }
        }

        vm.newTagKeyVal = '';
        vm.editTagKey = function(newTagKey, index) {
            var appTags = {};
            var counter = 0;
            for (var tagKey in vm.app.tags) {
                if (counter == index) {
                    var bFlag = false;
                    var secondCounter = 0;
                    for (var currentTagKey in vm.app.tags) {
                        if (currentTagKey == newTagKey && counter != secondCounter) {
                            bFlag = true;

                            break;
                        }

                        secondCounter++;
                    }

                    if (bFlag) {
                        appTags[tagKey] = vm.app.tags[tagKey];
                        vm.newTagKeyVal = newTagKey;
                        document.querySelector('.same-tag-name-' + counter).setAttribute("tags", "show");

                        vm.sameTaqNameValidity = false;
                    }
                    else {
                        appTags[newTagKey] = vm.app.tags[tagKey];
                        vm.newTagKeyVal = '';
                        document.querySelector('.same-tag-name-' + counter).setAttribute("tags", "hide");

                        vm.sameTaqNameValidity = true;
                    }

                    var patt = /^[a-zA-Z\d\-_]*$/;
                    var result = newTagKey.match(patt);
                    if (!result) {
                        vm.editTagNameValidity = false;
                        document.querySelector('.popover-tag-' + counter).setAttribute("tags", "show");
                    }
                    else {
                        vm.editTagNameValidity = true;
                        document.querySelector('.popover-tag-' + counter).setAttribute("tags", "hide");
                    }
                }
                else {
                    appTags[tagKey] = vm.app.tags[tagKey];
                }

                counter++;
            }

            vm.app.tags = appTags;
        }

        vm.editTagVal = function(newTagVal, index) {
            var counter = 0;
            for (var tagKey in vm.app.tags) {
                if (counter == index) {
                    vm.app.tags[tagKey] = newTagVal;
                    break;
                }

                counter++;
            }
        }

        vm.sameTagName = false;
        $rootScope.newAppTagName = "";
        $rootScope.newAppTagDesc = "";
        vm.insertTag = function() {
            var bFlag = false;
            for (var tagKey in vm.app.tags) {
                if (tagKey == $rootScope.newAppTagName) {
                    bFlag = true;

                    break;
                }
            }

            vm.sameTagName = bFlag;
            if (bFlag) {
                return;
            }

            if ($rootScope.newAppTagName != '' && $rootScope.newAppTagDesc != '' && vm.validTagName) {
                if (vm.app && vm.app.tags) {
                    vm.app.tags[$rootScope.newAppTagName] = $rootScope.newAppTagDesc;
                }
                else {
                    vm.app.tags = {};
                    vm.app.tags[$rootScope.newAppTagName] = $rootScope.newAppTagDesc;
                }

                $rootScope.newAppTagName = "";
                $rootScope.newAppTagDesc = "";

                document.querySelector('.new-tag.name').focus();
            }
            else if (!vm.validTagName && $rootScope.newAppTagDesc != '') {
                document.querySelector('.new-tag.name').focus();
            }
            else if ($rootScope.newAppTagDesc == '') {
                document.querySelector('.new-tag.desc').focus();
            }
            else if ($rootScope.newAppTagName == '' && $rootScope.newAppTagDesc == '') {
                document.querySelector('.new-tag.name').focus();
            }
        }

        vm.clearTagsInput = function() {
            $rootScope.newAppTagName = "";
            $rootScope.newAppTagDesc = "";
            vm.validTagName = true;
            vm.sameTagName = false;
        }

        vm.validTagName = true;
        vm.enterOnNewTag = function(event, inputToFocus) {
            $timeout(function() {   // // hack with timeout to immediately display/hide the tooltip if there are same tag names

                var bFlag = false;
                for (var tagKey in vm.app.tags) {
                    if (tagKey == $rootScope.newAppTagName) {
                        bFlag = true;

                        break;
                    }
                }

                vm.sameTagName = bFlag;

            }, 0);

            if(event.keyCode == 13) {   // '13' is the key code for enter
                event.preventDefault();

                if ($rootScope.newAppTagName != '' && $rootScope.newAppTagDesc != '') {
                    vm.insertTag();
                }
                else if ($rootScope.newAppTagName != '' && inputToFocus == 'newTagDesc') {     // move the focus to tag description input
                    document.querySelector('.new-tag.desc').focus();
                }
                else if ($rootScope.newAppTagDesc != '' && inputToFocus == 'newTagName') {     // move the focus to tag name input
                    document.querySelector('.new-tag.name').focus();
                }

                $timeout(function() {
                    document.querySelector('.tags-table').click();
                }, 0);
            }
            else if (inputToFocus == 'newTagDesc') {
                $timeout(function() {   // hack with timeout to immediately display/hide the tooltip if there is and error in the tag name
                    var patt = /^[a-zA-Z\d\-_]*$/;
                    var result = $rootScope.newAppTagName.match(patt);
                    if (!result) {
                        vm.validTagName = false;
                    }
                    else {
                        vm.validTagName = true;
                    }

                }, 0);
            }
        }

        vm.enterOnTagName = function(event, tagPositionNumber) {
            if(event.keyCode == 13) {   // '13' is the key code for enter
                event.preventDefault();

                if (vm.newTagKeyVal != '') {    // if the edited tag name already exist, don`t hide the input box

                    vm.sameTaqNameValidity = false;
                    return;
                }
                else {
                    vm.sameTaqNameValidity = true;
                }

                if (vm.editTagNameValidity) {
                    vm.hideAllTagsInput();
                }
            }
            else if (event.keyCode != 9 && tagPositionNumber >= 0) {
                vm.editTagNameValidity = true;
                document.querySelector('.popover-tag-' + tagPositionNumber).setAttribute("tags", "hide");
            }
        }

        vm.saveTag = function() {
            if (vm.newTagKeyVal != '') {    // if the edited tag name already exist, don`t hide the input box

                vm.sameTaqNameValidity = false;
                return;
            }
            else {
                vm.sameTaqNameValidity = true;
            }

            if (vm.editTagNameValidity) {
                vm.hideAllTagsInput();
            }
        }

        vm.clearCurrentTagInput = function(index) {
            var newTags = {};
            var counter = 0;
            for (var tagKey in vm.app.tags) {
                if (counter == index) {
                    newTags[vm.oldTagName] = vm.oldTagDesc;

                    vm.editTagNameValidity = true;
                    vm.sameTaqNameValidity = true;
                    vm.newTagKeyVal = '';
                    document.querySelector('.popover-tag-' + counter).setAttribute("tags", "hide");
                    document.querySelector('.same-tag-name-' + counter).setAttribute("tags", "hide");
                }
                else {
                    newTags[tagKey] = vm.app.tags[tagKey];
                }

                counter++;
            }

            vm.app.tags = newTags;
        }

        vm.checkTagsValidity = function () {
            let regexTagName = /^[a-zA-Z\d\-_]*$/;
            const tags = vm.app.tags;
            for (var tagName in tags) {
                let result = tagName.match(regexTagName);
                if (!result) {
                    return false;
                }
            }

            if ($rootScope.newAppTagName) {
                let result = $rootScope.newAppTagName.match(regexTagName);
                if (!result) {
                    return false;
                }
            }

            if (!vm.sameTaqNameValidity) {
                return false;
            }

            return true;
        }
    };

    function SelectAppsModalCtrl($scope, AppService, MessageService, ToastService, $filter, $uibModalInstance, items) {
        let vm = $scope;
        const $translate = $filter('translate');
        const sortByColumn = $filter('sortByColumn');

        vm.sortColumn = 'accountid';
        vm.sortInverse = false;

        vm.sort = () => {
            vm.apps = sortByColumn(vm.apps, vm.sortColumn, vm.sortInverse);
        }

        vm.apps = [];
        vm.loading = false;

        vm.doSelectAll = false;

        vm.selectAll = () => {
            vm.apps.forEach((app) => {
                app._marked = vm.doSelectAll;
            });

            vm.updateSelected();
        }

        vm.selected = [];

        vm.appFilter = {
            accountid: ""
        }

        vm.itemsPerPage = 10;
        vm.currentPage = 1;

        vm.ok = (action) => {
            $uibModalInstance.close({ apps: vm.selectedApps, action });
        };

        vm.close = () => {
            $uibModalInstance.dismiss('cancel');
        };

        vm.saveMode = items.saveMode;
        vm.deleteMode = items.deleteMode;

        vm.loadApps = () => {
            vm.loading = true;
            AppService.getApps().then((apps) => {
                vm.apps = apps;
                console.log("Apps loaded");
                vm.loading = false;
            }).catch(() => {
                console.log("Error loading apps");
                vm.loading = false;
            });
        };

        vm.tableSelectionChanged = (app) => {
            console.log("Selection changed ", app);

            vm.selectedApps = app;
        };

        vm.loadApps();
        vm.dataType = '/uiapi/rest/applications';
        vm.initDataTable = () => {
            vm.actions = [];
            vm.bulkActions = [];
            vm.columns = [{
                key: "accountid",
                type: "text",
                title: "APP_ID",
                filterable: true,
                filterType: 'text',
                filterField: 'search_id',
                filterSearchMinLength: 1,
                filterParams: {
                    mapper: (x) => x || undefined
                }
            }];
            // vm.reloadData();
        };
        vm.initDataTable();
        Promise.all([vm.loadApps()]).then(vm.initDataTable);
        vm.reloadData = () => {
            console.log("This should get overwritten");
        };
    };

    function PayloadsDateRangeCtrl($scope, ToastService, $filter, $uibModalInstance, items) {
        let vm = $scope;
        const $translate = $filter('translate');
        const sortByColumn = $filter('sortByColumn');

        vm.dateChanged = false;

        vm.payloadsExportDate = {
            startDate: moment().startOf('day'),
            endDate:  moment().endOf('day')
        };

        vm.payloadsDateRangeOptions = {
            eventHandlers: {
                'apply.daterangepicker': function (ev, picker) {
                    vm.dateChanged = true;
                }
            }
        }

        vm.ok = () => {
            var queryStr = items.exportQueryStr;

            if (vm.dateChanged) {
                queryStr += '&payload_from_date=';
                queryStr += vm.payloadsExportDate.startDate.toISOString();

                queryStr += '&payload_to_date=';
                queryStr += vm.payloadsExportDate.endDate.toISOString();
            }

            var url = window.location.protocol;
            var tz = "&tz=" + (new Date()).getTimezoneOffset();
            url += '//';
            url += window.location.host;
            window.location.assign(url + '/uiapi/rest/export/payloads?download_filename=payloads.csv' + tz + queryStr);

            $uibModalInstance.close({ apps: vm.selectedApps });
        };

        vm.close = () => {
            $uibModalInstance.dismiss('cancel');
        };

        vm.reloadData = () => {
            console.log("This should get overwritten");
        };
    };

    var app = angular.module('dassUiModule');

    app.controller('ManageAppModal', ['$scope', '$rootScope', '$window', 'AppService', 'MessageService', 'ToastService', '$filter', '$uibModalInstance', 'items', '$timeout', "UserService", ManageAppModalController]);

    app.controller('SelectAppsModalCtrl', ['$scope', 'AppService', 'MessageService', 'ToastService', '$filter', '$uibModalInstance', 'items', SelectAppsModalCtrl]);

    app.controller('PayloadsDateRangeCtrl', ['$scope', 'ToastService', '$filter', '$uibModalInstance', 'items', PayloadsDateRangeCtrl]);
})(angular);
