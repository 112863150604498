import * as React from "react";
import { Suspense, lazy } from "react";

import "react-toastify/dist/ReactToastify.css";

//import "swagger-ui-themes/themes/3.x/theme-newspaper.css";
import "swagger-ui-react/swagger-ui.css"

const SwaggerUI = lazy(() => import("swagger-ui-react"));

export class SwaggerPage extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    public render() {
        return (
            <Suspense fallback={<div>Loading...</div>}>
                <SwaggerUI url="/uiapi/rest/openapi-schema?dassui=true"></SwaggerUI>
            </Suspense>
        );
    }
}
