(function (angular) {
    var app = angular.module('dassUiModule');
    app.factory('GatewayService', GatewayServiceFn);

    const headersJson = { headers: headerContentTypeJson }
    const headersNoJson = { headers: headerNoContentType }

    GatewayServiceFn.$inject = ['$http', "$q", "$filter"];

    function GatewayServiceFn($http, $q, $filter) {
        var sortByColumn = $filter("sortByColumn");

        // List all gateways
        function getGatewaysImpl() {
            var defer = $q.defer();
            $http.get('/uiapi/rest/gateways', headersNoJson).then(
                function (response) {
                    var gateways = response.data;
                    defer.resolve(gateways);
                },
                function (response) {
                    defer.reject(response);
                }
            );
            return defer.promise;
        }

        // Get individual gateway data
        function getGatewayImpl(gtw_id) {
            return $http.get('/uiapi/rest/gateways/' + gtw_id, headersNoJson);
        }

        // Sort a list of gateways
        function sortGatewaysImpl(gateways, column = 'gtw_id', reverse = false) {
            return sortByColumn(gateways, column, reverse);
        }

        // Delete a gateway
        function deleteGatewayImpl(gtw_id) {
            return $http.delete('/uiapi/rest/gateways/' + gtw_id, headersNoJson)
        }

        //Delete gateways
        function deleteGatewaysImpl(gateways) {
            var promises = gateways.map(gateway => deleteGatewayImpl(gateway.id));
            return Promise.all(promises);
        }

        // Add a gateway
        function addGatewayImpl(gateway) {
            const { id, ...rest } = gateway;
            return $http.post('/uiapi/rest/gateways/' + id, rest, headersJson);
        }

        // Update a gateway
        function updateGatewayImpl(gateway) {
            const {id, ...rest} = gateway;
            if (typeof rest.longitude !== "number") { delete rest.longitude; }
            if (typeof rest.latitude !== "number")  { delete rest.latitude; }
            return $http.put('/uiapi/rest/gateways/' + id, rest, headersJson);
        }

        return {
            getGateways: getGatewaysImpl,
            getGateway: getGatewayImpl,
            addGateway: addGatewayImpl,
            updateGateway: updateGatewayImpl,
            deleteGateway: deleteGatewayImpl,
            deleteGateways: deleteGatewaysImpl,
            sortGateways: sortGatewaysImpl
        }
    }
})(angular);
