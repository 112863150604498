import DatetimeRangePicker from "@hanabyan/react-bootstrap-datetimerangepicker";
import * as moment from "moment";
import * as React from "react";
import { FormControl } from "react-bootstrap";
import { LocaleDate } from "./../../controllers/GlobalController";

export default class DateTimeRangePicker extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            endDate: moment(),
            ranges: {
                "Today": [moment().startOf("day"), moment().endOf("day")],
                "Yesterday": [moment().subtract(1, "days").startOf("day"), moment().subtract(1, "days").endOf("day")],
                // tslint:disable-next-line:object-literal-sort-keys
                "Last 7 Days": [moment().subtract(6, "days"), moment()],
                "Last 30 Days": [moment().subtract(29, "days"), moment()],
                "This Month": [moment().startOf("month"), moment().endOf("month")],
                "Last Month": [moment().subtract(1, "month").startOf("month"),
                    moment().subtract(1, "month").endOf("month")],
            },
            startDate: moment(),
        };
    }

    public handleApply = (event, picker) => {
        this.setState({
            endDate: picker.endDate,
            startDate: picker.startDate,
        });
        this.props.RangeHandler({
            callback: {
                endDate: moment(picker.endDate).toISOString(),
                startDate: moment(picker.startDate).toISOString(),
            },
        });
    }

    public render() {
        const { startDate, endDate, ranges } = this.state;
        const label = moment(startDate).format(LocaleDate().DateFormat) + " - " +
        moment(endDate).format(LocaleDate().DateFormat);
        return (
            <DatetimeRangePicker
                timePicker={true}
                startDate={startDate}
                endDate={endDate}
                onApply={this.handleApply}
                autoUpdateInput={true}
                ranges={ranges}
                timePicker24Hour={LocaleDate().Time24Hour}
                maxDate={moment()}
            >
                <FormControl
                    type="text"
                    id="DateTimeRangePicker"
                    className="DateTimeRangePicker"
                    value={label}
                    onChange={() => { return; }}
                    disabled={false}
                />
            </DatetimeRangePicker>
        );
    }

}
