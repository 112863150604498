((angular) => {
    function AssignGroupsModalCtrl($scope, DeviceService, GroupService, MessageService, ToastService, $filter, $uibModalInstance, items) {
        let vm = $scope;
        const $translate = $filter('translate');
        const sortByColumn = $filter('sortByColumn');
        vm.deveui = items.device.deveui;
        vm.items = items;
        vm.groups = [];
        vm.loading = 0;
        vm.deviceGroups = [];
        vm.groupsToAssign = [];
        vm.selected = [];
        console.log(vm);

        vm.sortColumn = 'groupid';
        vm.sortInverse = false;

        vm.sort = () => {
            vm.groups = sortByColumn(vm.groups, vm.sortColumn, vm.sortInverse);
        };

        // Reference: http://dotansimha.github.io/angularjs-dropdown-multiselect/docs/#/main
        vm.groupSelectSettings = {
            displayProp: "title",
            searchField: "title",
            dynamicTitle: true,
            enableSearch: true,
            scrollableHeight: '300px',
            scrollable: true
        };

        vm.groupsTranslations = {
            dynamicButtonTextSuffix: $translate("GROUPS_SELECTED_SUFFIX"),
            buttonDefaultText: $translate("GROUPS_SELECT_ASSIGN_BUTTON"),
            checkAll: $translate("GROUPS_SELECT_ALL"),
            uncheckAll: $translate("GROUPS_SELECT_NONE"),
        };



        vm.ok = () => {
            $uibModalInstance.close(vm.group);
        };

        vm.close = () => {
            $uibModalInstance.dismiss('cancel');
        };

        vm.addGroups = () => {
            if (vm.groupsToAssign.length === 0) {
                ToastService.showMessage($translate('MSG_ADD_GROUPS_NONE_SELECTED'));
                return;
            }

            DeviceService.addGroups(vm.device, vm.groupsToAssign).then(() => {
                ToastService.showMessage($translate('MSG_ASSIGN_DEVICES_SUCCESS_BODY'), "success", "ok");

                vm.reloadData();
            }).catch(() => {
                ToastService.showMessage($translate('MSG_ASSIGN_DEVICES_FAIL_BODY', "error", "remove"));
            });

            vm.groupsToAssign = [];
        };

        vm.removeGroup = (group) => {
            DeviceService.removeGroup(vm.device, group).then(() => {
                ToastService.showMessage($translate('MSG_REMOVE_GROUP_SUCCESS_BODY'), "success", "ok");
                vm.reloadData();
            }).catch(() => {
                ToastService.showMessage($translate('MSG_REMOVE_GROUP_FAIL_BODY', "error", "remove"));
            });
        };

        vm.removeSelected = () => {
            const selected = vm.deviceGroups.filter(group => group._marked);

            if (selected.length === 0) {
                ToastService.showMessage($translate('MSG_REMOVE_GROUPS_NONE_SELECTED'));
                return;
            }

            DeviceService.removeGroups(vm.device, selected).then(() => {
                ToastService.showMessage($translate('MSG_REMOVE_GROUPS_SUCCESS_BODY'), "success", "ok");
                vm.reloadData();
            }).catch(() => {
                ToastService.showMessage($translate('MSG_REMOVE_GROUPS_FAIL_BODY', "error", "remove"));
            });
        };

        vm.loadGroups = () => {
            vm.loading += 1;
            GroupService.getGroups().then((groups) => {
                vm.groups = groups;
                vm.loading -= 1;
            }).catch(() => {
                console.log("Error loading groups");
                vm.loading -= 1;
            });
        };

        vm.loadDevice = () => {
            vm.loading += 1;
            DeviceService.getDevice(vm.deveui).then(device => {
                vm.device = device;
                if (!device.groups) {
                    vm.deviceGroups = [];
                } else {
                    vm.deviceGroups = device.groups.split(",").map(id => { return { groupid: id } });
                }
                vm.loading -= 1;
            }).catch((response) => {
                console.log(response);
                console.log("Error loading device data");
                vm.loading -= 1;
            });
        };

        vm.reloadData = () => {
            vm.loadGroups();
            vm.loadDevice();
        };

        vm.reloadData();
    };

    function AddGroupModalCtrl($scope, GroupService, MessageService, ToastService, $filter, $uibModalInstance, items, $uibModal, $window) {
        let vm = $scope;
        const $translate = $filter('translate');

        vm.dataType = '/uiapi/rest/groups';

        vm.resetGroup = function () {
            vm.group = {
                groupid: "",
                title: "",
                comment: ""
            };
        };

        vm.tableSelectionChanged = (group) => {
            console.log("Selection changed ", group);

            vm.selected = group;
        };

        vm.items = items;
        vm.groups = [];
        vm.editMode = items.editMode;
        vm.addMode = items.addMode;
        // vm.loading = false;
        vm.group = items.group;
        vm.selected = items.group;
        $(".modal").animate({ scrollTop: 0 }, "slow");
        vm.assignMode = items.assignMode;

        vm.selected = [];
        vm.ok = (action) => {
            $uibModalInstance.close({ action, groups: vm.selected });
        };

        vm.close = () => {
            $uibModalInstance.dismiss('cancel');
        };

        vm.cancelForm = () => {
            vm.editMode = vm.addMode = false;
            vm.resetGroup();
        };

        vm.edit = (group) => {
            vm.resetGroup();
            vm.editMode = true;
            vm.addMode = false;
            vm.group = group;

            // TODO: Figure out a better way to do this ?
            $(".modal").animate({ scrollTop: 0 }, "slow");
        };

        vm.addNew = () => {
            vm.editMode = false;
            vm.addMode = true;
            vm.resetGroup();
        };

        vm.updateSelected = () => {
            vm.selected = vm.groups.filter(group => group._marked);
        };

        vm.selectAll = () => {
            vm.groups.forEach((group) => {
                group._marked = vm.doSelectAll;
            });

            vm.updateSelected();
        };

        vm.delete = (group) => {
            MessageService.showMessage({
                title: $translate('MSG_DELETE_GROUPS_TITLE'),
                body: $translate('MSG_DELETE_GROUPS_BODY', {
                    count: 1
                })
            }).then(() => {
                GroupService.deleteGroup(group.groupid).then(() => {
                    ToastService.showMessage($translate('MSG_DELETE_GROUP_SUCCESS', group), "success")
                })
                    .then(vm.loadGroups)
                    .catch(() => {
                        ToastService.showMessage($translate('MSG_DELETE_GROUP_FAIL', group), "error")
                    });
                vm.cancelForm();
            });
        };

        vm.deleteSelected = () => {
            MessageService.showMessage({
                title: $translate('MSG_DELETE_GROUPS_TITLE'),
                body: $translate('MSG_DELETE_GROUPS_BODY', {
                    count: vm.selected.length
                })
            }).then(() => {
                GroupService.deleteGroups(vm.selected).then(() => {
                    ToastService.showMessage($translate('MSG_DELETE_GROUPS_SUCCESS', vm.selected))
                })
                    .then(vm.loadGroups)
                    .catch(() => {
                        ToastService.showMessage($translate('MSG_DELETE_GROUPS_FAIL', vm.selected))
                    });
                vm.cancelForm();
            });
        };

        vm.updateGroup = () => {
            GroupService.updateGroup(vm.group).then(() => {
                ToastService.showMessage($translate('MSG_UPDATE_GROUP_SUCCESS', vm.group), "success", "ok")
            })
                .then(vm.loadGroups)
                .catch(() => {
                    ToastService.showMessage($translate('MSG_UPDATE_GROUP_FAIL', vm.group), "error", "remove")
                });
            vm.cancelForm();
        };

        vm.saveGroup = () => {
            if (vm.group.appskey == "") {
                delete vm.group.appskey;
            }
            GroupService.addGroup(vm.group).then(() => {
                ToastService.showMessage($translate('MSG_CREATE_GROUP_SUCCESS', vm.group), "success", "ok")
            })
                .then(vm.loadGroups)
                .catch((response) => {
                    ToastService.showMessage($translate('MSG_CREATE_GROUP_FAIL', vm.group), "error", "remove");
                    ToastService.showMessage($translate(response.data, vm.group), "error", "remove")
                });
            vm.cancelForm();
        };

        vm.showSendDataDialog = (group) => {
            console.log($uibModal, $uibModalInstance);
            const modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'sendDataModalContent.html',
                controller: 'SendDataModalCtrl',
                size: "",
                resolve: {
                    items: () => {
                        return {
                            isMulticast: true
                        };
                    }
                }
            });

            modalInstance.result.then((payload) => {
                console.log("Sending payload to group ", group, payload);
                GroupService.sendMulticast(group, payload.data, payload.port, payload.fcnt).then((result) => {
                    ToastService.showMessage($translate('MSG_MULTICAST_SUCCESS', vm.group), "success", "send")
                }).catch((err) => {
                    ToastService.showMessage($translate('MSG_MULTICAST_FAIL', vm.group), "error", "remove");
                    console.log("Error sending multicast", err);
                });
            });
        };

        vm.loadGroups = () => {
            if (!vm.reloadData) return;
            var reloadPromise = vm.reloadData();
            if (reloadPromise) {
                vm.workingCount += 1;
                reloadPromise.then(() => {
                    vm.type = type;
                    vm.workingCount -= 1;
                }).catch(err => {
                    vm.workingCount -= 1;
                });
            }
        };
        vm.initDataTable = () => {


            vm.columns = [{
                key: "groupid",
                type: "text",
                title: "GROUP_ID",
                filterable: true,
                filterField: 'search_id',
                filterType: 'text',
                filterSearchMinLength: 1,
                filterParams: {
                    mapper: (x) => x || undefined
                }
            }, {
                key: "title",
                title: "GROUP_TITLE",
                type: "text",
                filterable: true,
                filterField: 'search_title',
                filterType: 'text',
                filterSearchMinLength: 1,
                filterParams: {
                    mapper: (x) => x || undefined
                }
            }, {
                key: "comment",
                title: "COMMENT",
                type: "text",
            }];
        };
        vm.initDataTable();
        Promise.all([vm.loadGroups()]).then(vm.initDataTable);
    };

    var app = angular.module('dassUiModule');
    app.controller('AssignGroupsModalCtrl', ['$scope', 'DeviceService', 'GroupService', 'MessageService', 'ToastService', '$filter', '$uibModalInstance', 'items', AssignGroupsModalCtrl]);
    app.controller('AddGroupModalCtrl', ['$scope', 'GroupService', 'MessageService', 'ToastService', '$filter', '$uibModalInstance', 'items', '$uibModal', '$window', AddGroupModalCtrl])
})(angular);