import { toast as t, ToastContent, ToastOptions } from "react-toastify";

class Toaster {
    /**
     * Shorthand to display toast of type 'success'.
     */
    public static success(content: ToastContent, opts?: ToastOptions) {
        const options: ToastOptions = {
            autoClose: 5000,
            bodyClassName: "toast-content",
            closeButton: true,
            closeOnClick: true,
            hideProgressBar: true,
            pauseOnHover: false,
            position: "bottom-right",
            ...opts,
        };
        return t.success(content, options);
    }

    /**
     * Shorthand to display toast of type 'error'.
     */
    public static error(content: ToastContent, opts?: ToastOptions) {
        const options: ToastOptions = {
            autoClose: false,
            bodyClassName: "toast-content",
            closeButton: true,
            closeOnClick: true,
            hideProgressBar: true,
            pauseOnHover: false,
            position: "bottom-right",
            ...opts,
        };

        return t.error(content, options);
    }
}

export const toast = Toaster;
