import * as React from "react";
import { Button, Modal } from "react-bootstrap";
import { strings } from "./../../services/Localization";

interface IProps {
    ConfirmBtnText?: string | string[];
    CancelBtnText?: string | string[];
    CloseOnEsc?: boolean;
    CloseWhenClickOutside?: boolean;
    ShowCloseButton?: boolean;
    ShowModal: boolean;
    ModalTitle?: string | string[];
    ModalBody?: string | string[] | React.ReactElement;
    ModalSize?: "sm" | "lg" | undefined;
    ConfirmBtn?: boolean;
    CancelBtn?: boolean;
    CloseModal(): any;
    OnConfirm?(): any;
    OnCancel?(): any;
}

export class AlertModal extends React.Component <IProps, any> {
    constructor(props) {
        super(props);
    }

    public render() {
        return(
            <Modal
                show={this.props.ShowModal || false}
                onHide={() => {
                    if (this.props.CloseModal) {
                        this.props.CloseModal();
                    }
                }}
                bsSize={this.props.ModalSize || "sm"}
                className="ReactModal ModalAlert"
                keyboard={this.props.CloseOnEsc || false}
                backdrop={this.props.CloseWhenClickOutside || false}
            >
                <Modal.Header closeButton={this.props.ShowCloseButton || false}>
                    <Modal.Title>
                        {this.props.ModalTitle || strings.ALERT_MODAL_TITLE_TEXT}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.props.ModalBody || ""}
                </Modal.Body>
                <Modal.Footer>
                    {this.props.ConfirmBtn && (
                        <Button
                            onClick={() => {
                                if (this.props.OnConfirm) {
                                    this.props.OnConfirm();
                                } else {
                                    this.props.CloseModal();
                                }
                            }}
                            className="React_new_style_btn_ok"
                        >
                            {this.props.ConfirmBtnText || strings.OK}
                        </Button>
                    )}
                    {this.props.CancelBtn && (
                        <Button
                            onClick={() => {
                                if (this.props.OnCancel) {
                                    this.props.OnCancel();
                                } else {
                                    this.props.CloseModal();
                                }
                            }}
                            className="React_new_style_btn_cancel"
                        >
                            {this.props.CancelBtnText || strings.CANCEL}
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        );
    }
}
