// tslint:disable:max-line-length
import * as React from "react";
import {
    Button,
    Col,
    Modal,
    Row,
    Panel,
    FormGroup,
    ControlLabel,
    FormControl,
    PanelGroup,
    HelpBlock
} from "react-bootstrap";
import Carousel from "react-multi-carousel";
import { toast } from "../../utils/Toaster";

import { GetGatewaysModels, InstallGateway } from "../../services/Gateways";
import { strings } from "./../../services/Localization";

import "react-toastify/dist/ReactToastify.css";
import "./EnterpriseAddGateway.css";
import "./MultiCarousel.css";
declare const constants;
declare const headerContentTypeJson;

import { IDassGatewayModel, IInstallGateway, IInstallGatewayResponse } from "../../dassTypes";

interface IDassGatewayModelInt extends IDassGatewayModel {
    id: number;
}

const validMacAddrRegexp = /^[a-f0-9]{2}:[a-f0-9]{2}:[a-f0-9]{2}:[a-f0-9]{2}:[a-f0-9]{2}:[a-f0-9]{2}$/i;

interface IAddGatewayStates {
    InstallData: IInstallGatewayResponse | null;
    numSx130x: number;

    LoadingModels: boolean;
    ShowModal: boolean;
    gtwModels: IDassGatewayModelInt[],
    gtwName: string,

    selectedGtwModel: string | null;
    selectedGtwModelId: number;

    gtwMacAddress: string;

    activeAccordionKey: string;
    collapseIcon: string;

    oldConfigFile: string;
    modelDefaultsShow: boolean;
    disableButton: boolean;

    modelDefaults: any;
    errors: any;
}


const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 3,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2,
    },
};

export class EnterpriseAddGateway extends React.Component<any, IAddGatewayStates> {

    public bulletNumber = 0;

    constructor(props) {
        super(props);
        this.state = {
            InstallData: null,
            LoadingModels: true,
            ShowModal: true,
            numSx130x: 1,
            gtwModels: [],
            gtwMacAddress: "",
            gtwName: "",
            selectedGtwModel: null,
            selectedGtwModelId: -1,
            activeAccordionKey: "0",
            collapseIcon: "glyphicon-plus",
            modelDefaults: {
                antenna_gain: 0,
                cable_loss: 0,
                classb_tx_power: 0,
                rx1_tx_power: 0,
                rx2_tx_power: 0
            },
            oldConfigFile: "",
            modelDefaultsShow: false,
            disableButton: false,
            errors: {
                antenna_gain: {
                    hasError: false,
                    messageError: "",
                },
                cable_loss: {
                    hasError: false,
                    messageError: "",
                },
                classb_tx_power: {
                    hasError: false,
                    messageError: "",
                },
                rx1_tx_power: {
                    hasError: false,
                    messageError: "",
                },
                rx2_tx_power: {
                    hasError: false,
                    messageError: "",
                },
            },
        };
    }

    public componentDidMount() {
        this.GetGatewaysModelsFunc();
    }

    public GetGatewaysModelsFunc = async () => {
        const response: any = await GetGatewaysModels();

        if (response.status === 200) {
            const data = await response.json();
            let gtwModels: IDassGatewayModelInt[] = [];
            if (data && data.hasOwnProperty("gateway_models") && (data.gateway_models.constructor === Array)) {
                gtwModels = data.gateway_models;
            }

            if (gtwModels.length > 0) {
                const gtwModelsArray = await gtwModels.map(async (value, index) => {
                    const modelObj = value;
                    modelObj.id = index;
                    modelObj.image = "/uiapi/rest/gateways/models/images/" + value.image;

                    return modelObj;
                });
                Promise.all(gtwModelsArray)
                .then(async (models) => {
                    this.setState({
                        gtwModels: (models) ? models : [],
                    });
                })
                .catch((error) => {
                    console.log("Get gateway models error => ", error);
                })
                .then(() => {
                    this.setState({
                        LoadingModels: false,
                    });
                });
            } else {
                this.setState({
                    LoadingModels: false,
                    gtwModels,
                });
            }
        } else {
            this.setState({
                LoadingModels: false,
                gtwModels: [],
            });
        }
    }



    // Collect all settings and call install API on DASS to get the gateway configuration data
    // that can be copied by the user
    // For all Non-BSP gateway, the generated string is automatically copied to the clipboard
    // but for BSP gateway, each field must be copied separately

    public InstallGatewayFunc = async () => {
        try {
            const model = this.getActiveModel();

            console.log(model);


            if (model == null)  { return; }

            let data: IInstallGateway = {
                model: model.model,
                name: this.state.gtwName,
            };

            if (model.model === "generic_pktfwd") {
                if (!validMacAddrRegexp.test(this.state.gtwMacAddress.trim())) { return; }

                if (this.state.oldConfigFile) { data.old_config_file = this.state.oldConfigFile; }

                const mac = this.state.gtwMacAddress.trim().toLowerCase().replace(/:/g, "");
                data.gtwid = mac.substring(0, 6) + "fffe" + mac.substring(6);
            }
            if (model.model === "generic_basic_station") {
                data.num_sx130x = this.state.numSx130x;
            }

            const {modelDefaults} = this.state;
            if((modelDefaults.constructor === Object) && (Object.keys(modelDefaults).length > 0)){
                Object.keys(modelDefaults).map((key) => {
                    data[key] = typeof modelDefaults[key] === "string" ? parseFloat(modelDefaults[key]) : modelDefaults[key];
                });
            }

            console.log(data);

            const response = await InstallGateway(data);
            if (response.status === 200) {
                const installResponseData: IInstallGatewayResponse = await response.json();

                this.setState({ InstallData: installResponseData }, () => {

                    if (model.model !== "generic_basic_station") {
                        setTimeout(() => {
                            this.CopyToClipboard("clipboard-text-holder", true);
                        }, 300);
                    }
                });

            } else {
                let message = "";
                switch (response.status) {
                    case 400:
                        message = strings.ADD_GATEWAY_CONFIG_ERROR_400;
                        break;
                    default:
                        message = await response.text();
                        break;
                }
                toast.error(message);
            }
        } catch (error) {
            toast.error(strings.ADD_GATEWAY_ERROR);
        }
    }


    // Extract the data from an input/textarea component and copy the entire text to the 
    // clipboard
    //
    public CopyToClipboard = async (id: string, failQuiet = false) => {

        const textarea: any = document.getElementById(id);
        if (textarea) {
            textarea.disabled = false;
            textarea.select();
            const copy = await document.execCommand("copy");
            textarea.disabled = true;
            if (copy) {
                toast.success(strings.ADD_GATEWAY_SUCCESS);
            } else if (!failQuiet) {
                toast.error(strings.ADD_GATEWAY_BROWSER_NOT_SUPPORTED_COPY_TO_CLIPBOARD);
            }
        }
    }

    public selectGtwModel = (gtwId) => {
        let gtwModel = "";
        let modelDefaults = {
            antenna_gain: 0,
            cable_loss: 0,
            classb_tx_power: 0,
            rx1_tx_power: 0,
            rx2_tx_power: 0
        };
        let model: IDassGatewayModelInt | null = null;

        for (const key in this.state.gtwModels) {
            if (this.state.gtwModels[key].id === gtwId) {
                model = this.state.gtwModels[key];
                gtwModel = model.model;
                if (this.state.gtwModels[key].hasOwnProperty("defaults")) {
                    modelDefaults = this.state.gtwModels[key].defaults;
                }
                break;
            }
        }

        this.setState({
            InstallData: null,
            selectedGtwModel: gtwModel,
            selectedGtwModelId: gtwId,
            modelDefaultsShow: true,
            modelDefaults,
            numSx130x: model && model.num_sx130x_options && model.num_sx130x_options[0] || 1,
        });
    }

    public isGtwModelSelected = (gtwModel) => {
        let gtwModelClass = "";
        if (gtwModel.id === this.state.selectedGtwModelId) {
            gtwModelClass = "selected";
        }

        return gtwModelClass;
    }

    public getActiveModel() {
        if (this.state.selectedGtwModelId >= 0) {
            return this.state.gtwModels[this.state.selectedGtwModelId];
        } else {
            return null;
        }
    }


    public isGtwModelChecked = (gtwModel) => {
        let isChecked = false;
        if (gtwModel.id === this.state.selectedGtwModelId) {
            isChecked = true;
        }

        return isChecked;
    }

    public closeModal = () => {
        this.setState({ ShowModal: false });
    }

    public gtwNameChange = (event) => {
        this.setState({
            InstallData: null,
            gtwName: event.target.value,
        });
    }

    public gtwMacChange = (event) => {
        this.setState({
            InstallData: null,
            gtwMacAddress: event.target.value,
        });
    }



    public oldConfigFileChange = (event) => {
        this.setState({
            oldConfigFile: event.target.value,
        });
    }


    public handleAccordionSelect = (activeAccordionKey) => {
        let newCollapseIcon = "glyphicon-plus";
        if (this.state.collapseIcon === "glyphicon-plus") {
            newCollapseIcon = "glyphicon-remove";
        }

        this.setState({
            activeAccordionKey,
            collapseIcon: newCollapseIcon,
        });
    }

    handleChange(e: any, name: string) {

        const {errors} = this.state;
        const valueInt = parseInt(e.target.value);
        const value = e.target.value;

        console.log(name, value);

        let disableButton = false;
        if(value.match(/\d/)){
            errors[name].hasError = false;
            errors[name].messageError = "";
            switch (name) {
                case "antenna_gain":
                    if((valueInt < -5) || (valueInt > 10)) {
                        errors[name].hasError = true;
                        errors[name].messageError = strings.formatString(strings.ADD_GATEWAY_NUMBER_RANGE_ERROR, {
                            min: "-5",
                            max: "10"
                        });
                    }
                    break;
                case "cable_loss":
                    if((valueInt < 0) || (valueInt > 10)){
                        errors[name].hasError = true;
                        errors[name].messageError = strings.formatString(strings.ADD_GATEWAY_NUMBER_RANGE_ERROR, {
                            min: "0",
                            max: "10"
                        });
                    }
                    break;
                default:    // all tx powers
                    if((valueInt < 0) || (valueInt > 30)){
                        errors[name].hasError = true;
                        errors[name].messageError = strings.formatString(strings.ADD_GATEWAY_NUMBER_RANGE_ERROR, {
                            min: "0",
                            max: "30"
                        });
                    }
                    break;
            }
        } else {
            errors[name].hasError = true;
            errors[name].messageError = strings.ADD_GATEWAY_NUMBER_ONLY_ERROR;
        }

        Object.keys(errors).map((key) => {
            if(errors[key].hasError){
                disableButton = true;
            }
        });

        this.setState({
            InstallData: null,
            modelDefaults: {...this.state.modelDefaults, [name]: value},
            errors,
            disableButton,
        });
    }


    public renderNumSx130x() {

        const model = this.getActiveModel();
        const numTable: any[] = [];

        for (const i of model!.num_sx130x_options!) {
            numTable.push(<option value={i} key={"num130x_" + i}>{i}</option>);
        }

        return (
            <>
            <Row>
                <Col sm={12} className="form-horizontal">
                    <div className="desc">
                        <p>{this.bullet("{{#}}) Select number of SX130x that the gateways has (most common is 1)")}</p>
                    </div>
                </Col>
            </Row>
            <div className="custom-background">
                <Row>
                    <Col sm={12}>
                        <FormGroup controlId="sx130x">
                            <FormControl
                                componentClass="select"
                                placeholder="select"
                                value={this.state.numSx130x}
                                onChange={(e) => {
                                    this.setState({
                                        InstallData: null,
                                        numSx130x: parseInt((e as any).target.value, 10),
                                    });
                                }}
                            >
                                {numTable}
                            </FormControl>
                        </FormGroup>
                    </Col>
                </Row>
            </div>
            </>
        );
    }


    // Render panel with TX options
    public renderTxOptions() {

        const {
            modelDefaults,
            errors,
        } = this.state;

        // Generate TX steps. TODO: find valid range. Depends on regions and gateway capabilities
        const rx1TxPower: any[] = [];
        const rx2TxPower: any[] = [];
        const classBTxPower: any[] = [];

        for (let i = 0; i < 30; i++) {
            rx1TxPower.push(<option value={i} key={"rx1_" + i}>{i}</option>);
        }
        for (let i = 0; i < 30; i++) {
            rx2TxPower.push(<option value={i} key={"rx2_" + i}>{i}</option>);
        }
        for (let i = 0; i < 30; i++) {
            classBTxPower.push(<option value={i} key={"classb_" + i}>{i}</option>);
        }


        return  (<Row>
            <Col sm={12}>
                <PanelGroup
                    accordion
                    id="wrapper-accordion-settings"
                    activeKey={this.state.activeAccordionKey}
                    onSelect={this.handleAccordionSelect}
                >
                    <Panel eventKey="1" id="collapsible-panel">
                        <Panel.Heading>
                            <Panel.Title toggle>
                                <div className="row wrapper-accordion">
                                    <div className="title-modify-settings">
                                        <strong className="auth-methods">
                                            {strings.E_GTW_MODIFY_SETTINGS}
                                        </strong>
                                    </div>

                                    <div className="collapse-container-sign">
                                        <button type="button" className="btn btn-default btn-sm">
                                            <span className={`glyphicon ${this.state.collapseIcon}`}></span>
                                        </button>
                                    </div>
                                </div>
                            </Panel.Title>
                        </Panel.Heading>

                        <Panel.Body collapsible>
                            <form className="form-wrapper">
                                <Row>
                                    <Col sm={6}>
                                        <FormGroup controlId="antenna-gain" validationState={errors.antenna_gain.hasError ? "error" : null}>
                                            <ControlLabel>{strings.ADD_GATEWAY_ANTENNA_GAIN}</ControlLabel>
                                            <FormControl
                                                type="number"
                                                min="-5"
                                                max="10"
                                                step="0.1"
                                                value={modelDefaults.antenna_gain}
                                                onChange={(e) => this.handleChange(e,"antenna_gain")}
                                            />
                                            <HelpBlock>{errors.antenna_gain.messageError}</HelpBlock>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6}>
                                        <FormGroup controlId="cable-loss" validationState={errors.cable_loss.hasError ? "error" : null}>
                                            <ControlLabel>{strings.ADD_GATEWAY_CABLE_LOSS}</ControlLabel>
                                            <FormControl
                                                type="number"
                                                min="0"
                                                max="10"
                                                step="0.1"
                                                value={modelDefaults.cable_loss}
                                                onChange={(e) => this.handleChange(e,"cable_loss")}
                                            />
                                            <HelpBlock>{errors.cable_loss.messageError}</HelpBlock>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={4}>
                                        <FormGroup controlId="rx1-tx-power">
                                            <ControlLabel>
                                                {strings.ADD_GATEWAY_RX1_TX_POWER} <br/> {strings.ADD_GATEWAY_DBM}
                                            </ControlLabel>
                                            <FormControl
                                                componentClass="select"
                                                placeholder="select"
                                                value={modelDefaults.rx1_tx_power}
                                                onChange={(e) => this.handleChange(e,"rx1_tx_power")}>
                                                {rx1TxPower}
                                            </FormControl>
                                        </FormGroup>
                                    </Col>

                                    <Col sm={4}>
                                    <FormGroup controlId="rx2-tx-power">
                                            <ControlLabel>
                                                {strings.ADD_GATEWAY_RX2_TX_POWER} <br/> {strings.ADD_GATEWAY_DBM}
                                            </ControlLabel>
                                            <FormControl
                                                componentClass="select"
                                                placeholder="select"
                                                value={modelDefaults.rx2_tx_power}
                                                onChange={(e) => this.handleChange(e,"rx2_tx_power")}>
                                                {rx2TxPower}
                                            </FormControl>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={4}>
                                    <FormGroup controlId="class-b-tx-power">
                                            <ControlLabel>
                                                {strings.ADD_GATEWAY_CLASSB_TX_POWER} <br/> {strings.ADD_GATEWAY_DBM}
                                            </ControlLabel>
                                            <FormControl
                                                componentClass="select"
                                                placeholder="select"
                                                value={modelDefaults.classb_tx_power}
                                                onChange={(e) => this.handleChange(e,"classb_tx_power")}>
                                                {classBTxPower}
                                            </FormControl>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </form>
                        </Panel.Body>
                    </Panel>
                </PanelGroup>
            </Col>
        </Row>);
    }


    public dlcc = (filename: string, value: string) => {

        async function onClick() {
            const response = await fetch("/download_storage", {
                credentials: "same-origin",
                headers: headerContentTypeJson,
                method: "POST",
                body: JSON.stringify({ filename, value }),
            });
            if (response.status === 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            if (response.status === 200) {
                window.location.href = await response.text();
            }
        }

        return <a onClick={onClick} title="click here to download as file">
            <i className="fas fa-download" aria-hidden="true"></i> download
        </a>
    }


    public ctcc = (id: string) => {
        return <a onClick={() => this.CopyToClipboard(id)}  title="click here to copy to clipboard">
            <i className="far fa-copy" aria-hidden="true"></i> copy
        </a>
    }

    public renderBspConfigData() {
        const { InstallData } = this.state;
        const ctcc = this.ctcc;
        const dlcc = this.dlcc;

        if (InstallData == null || InstallData.install_params == null) { return <div></div>; }


        return (
            <React.Fragment>
                <Row>
                    <Col sm={8}>
                        <p className="install dark-anchor">Server URI ({ctcc("lns_uri")})</p>
                    </Col>
                    <Col sm={4}>
                        <p className="install dark-anchor">Port ({ctcc("lns_port")})</p>
                    </Col>
                </Row>

                <Row className="clipboard-btn-container">
                    <Col sm={8}>
                        <FormControl id="lns_uri" componentClass="input"
                            rows={8} disabled={true}
                            value={InstallData.install_params.lns_uri}
                        />

                    </Col>
                    <Col sm={4}>
                        <FormControl id="lns_port" componentClass="input"
                            rows={4} disabled={true}
                            value={InstallData.install_params.lns_port}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>
                        <p className="install dark-anchor">Server certificate ({ctcc("server_cert")}, {dlcc("server_cert.pem", InstallData.install_params.server_cert)})</p>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <FormControl id="server_cert" componentClass="textarea"
                            rows={6} disabled={true}
                            value={InstallData.install_params.server_cert}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>
                        <p className="install dark-anchor">Gateway certificate ({ctcc("gtw_cert")}, {dlcc("gtw_cert.pem", InstallData.install_params.gtw_cert)})</p>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <FormControl id="gtw_cert" componentClass="textarea"
                            rows={6} disabled={true}
                            value={InstallData.install_params.gtw_cert}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>
                        <p className="install dark-anchor">Gateway private key ({ctcc("gtw_key")}, {dlcc("gtw_key.pem", InstallData.install_params.gtw_key)})</p>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <FormControl id="gtw_key" componentClass="textarea"
                            rows={6} disabled={true}
                            value={InstallData.install_params.gtw_key}
                        />
                    </Col>
                </Row>

            </React.Fragment>);

    }

    public bullet(txt: string) {
        while (txt.match(/\{\{\#\}\}/)) {
            txt = txt.replace(/\{\{\#\}\}/, this.bulletNumber++ + "");
        }
        return txt;
    }


    public render() {
        const {
            gtwModels,
            InstallData,
            LoadingModels,
        } = this.state;

        const model = this.getActiveModel();
        const ctcc = this.ctcc;
        const dlcc = this.dlcc;
        this.bulletNumber = 1;

        return (
            <div>
                <Modal
                    show={this.state.ShowModal}
                    onHide={this.closeModal}
                    bsSize="large"
                    className="ReactModal default-push-config enterprise-add-gateway"
                    backdrop="static"
                >
                    <Modal.Header closeButton={true}>
                        <Modal.Title>
                            {strings.ADD_GATEWAY}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="padding_0_all">
                        <Row>
                            <Col sm={12} className="form-horizontal">
                                <div className="desc">
                                    <p>{this.bullet("{{#}}) Select your gateway")}</p>

                                    {constants.enterpriseModeGtwInfoLink && (<p>
                                        {strings.E_GTW_INFO_MSG_7}
                                        <a href={constants.enterpriseModeGtwInfoLink} target="_blank"> {strings.E_GTW_INFO_MSG_8}</a>
                                    </p>)}
                                </div>
                            </Col>
                        </Row>

                        <div className="custom-background">

                            <Row className="models-container">
                                <Col sm={12} className="carousel-container">
                                    {(LoadingModels) ? (
                                        <div className="loading_models">
                                            <i className="fas fa-spinner fa-spin fa-4x"></i>
                                        </div>
                                    ) : (
                                        (gtwModels.length > 0) ? (
                                            <Carousel
                                                swipeable={true}
                                                draggable={false}
                                                showDots={false}
                                                responsive={responsive}
                                                ssr={false}
                                                infinite={true}
                                                keyBoardControl={false}
                                            >
                                                {
                                                    gtwModels.map((gtwModel, i) => {
                                                        const modelName = gtwModel.full_model_names.map((fullModelName, index) => {
                                                            return (
                                                                <li key={index}>
                                                                    {fullModelName}
                                                                </li>
                                                            );
                                                        });
                                                        return (
                                                            <div className="model-parent-container" key={i} onClick={() => this.selectGtwModel(gtwModel.id)}>
                                                                <div className={`${this.isGtwModelSelected(gtwModel)} model-container`} key={gtwModel.id}>
                                                                    <div className="row name-container">
                                                                        <div className="col-md-12">
                                                                            <h4 className="gtw-name">
                                                                                { gtwModel.manufacturer }
                                                                            </h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row image-container">
                                                                        <div className="col-md-12">
                                                                            {(gtwModel.image) && <img src={gtwModel.image}/>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className="line">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-12 names-container">
                                                                            <ul className="full-names">
                                                                                { modelName }
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row check-box-container">
                                                                    <div className="col-md-12">
                                                                        <input
                                                                            checked={this.isGtwModelChecked(gtwModel)}
                                                                            readOnly={true}
                                                                            type="checkbox"
                                                                            name="gtw-model"
                                                                            value={gtwModel.model}
                                                                            onClick={() => this.selectGtwModel(gtwModel.id)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </Carousel>
                                        ) : (
                                            <div className="no_gtw_models">{strings.E_GTW_NO_MODELS}</div>
                                        )
                                    )}
                                </Col>
                            </Row>

                        </div>


                        {this.state.modelDefaultsShow && <Row>
                            <Col sm={12} className="form-horizontal">
                                <div className="desc">
                                    <p>{this.bullet("{{#}}) Give the gateway a name or description (optional):")}</p>
                                </div>
                            </Col>
                        </Row>}


                        {this.state.modelDefaultsShow && <div className="custom-background">
                            <Row className="name-input-container">
                                <Col sm={12} className="form-horizontal">
                                    <input type="text"
                                        className="form-control new_style_focus"
                                        value={this.state.gtwName}
                                        id="testId2"
                                        placeholder="Enter gateway name..."
                                        onChange={this.gtwNameChange} />
                                </Col>
                            </Row>
                        </div>}

                        {model && model.model === "generic_basic_station" && model.num_sx130x_options && model.num_sx130x_options.length > 1 && this.renderNumSx130x()}

                        {model && model.model !== "generic_basic_station" && (
                            <>
                            <Row>
                                <Col sm={12} className="form-horizontal">
                                    <div className="desc">
                                        <p>{this.bullet("{{#}}) Modify gateway TX power paremeters (optional). Generally the defaults are correct, so if your not sure, just skip this step.")}</p>
                                    </div>
                                </Col>
                            </Row>
                            <div className="custom-background">
                                {this.renderTxOptions()}
                            </div>
                            </>
                        )}


                        {model && model.model === "generic_pktfwd" && <Row>
                            <Col sm={12} className="form-horizontal">
                                <div className="desc">
                                    <p>{this.bullet("{{#}}) Enter MAC address of gateway:")}</p>
                                </div>
                            </Col>
                        </Row>}

                        {model && model.model === "generic_pktfwd" && <div className="custom-background">
                            <Row className="name-input-container">
                                <Col sm={12} className={"form-horizontal" +
                                            (validMacAddrRegexp.test(this.state.gtwMacAddress.trim()) ? "" : " has-error")}>
                                    <input type="text"
                                        className="form-control new_style_focus"
                                        value={this.state.gtwMacAddress}
                                        id="testId3"
                                        spellCheck={false}
                                        placeholder="Gateway MAC address, e.g. aa:bb:cc:00:11:22"
                                        onChange={this.gtwMacChange} />
                                </Col>
                            </Row>
                        </div>}






                        {model && model.help_html && <Row>
                            <Col sm={12} className="form-horizontal">
                                <div className="desc">
                                    <div dangerouslySetInnerHTML={{ __html: this.bullet(model.help_html) }} />
                                </div>
                            </Col>
                        </Row>}


                        <div className="custom-background">

                            {model && model.model === "generic_pktfwd" && <Row className="old-config-file-input-container">
                                <Col sm={12} className="form-horizontal">
                                    <FormControl
                                        id="old-config-file-text-holder"
                                        placeholder="Paste existing config file here..."
                                        componentClass="textarea"
                                        value={this.state.oldConfigFile}
                                        onChange={this.oldConfigFileChange}
                                        rows={8}
                                    />
                               </Col>
                            </Row>}

                            {InstallData && InstallData.install_params && this.renderBspConfigData()}

                            {(InstallData && InstallData.install_cmd) && (
                                <React.Fragment>
                                    <Row>
                                        <Col sm={12}>
                                            <p className="install dark-anchor">
                                                {strings.E_GTW_INSTALLATION} ({ctcc("clipboard-text-holder")},
                                                    {dlcc(model && model.model === "generic_pktfwd" ? "config.json" : "install_cmd.txt", InstallData.install_cmd)})
                                            </p>
                                        </Col>
                                    </Row>

                                    <Row className="clipboard-btn-container">
                                        <Col sm={12}>
                                            <FormControl
                                                id="clipboard-text-holder"
                                                componentClass="textarea"
                                                value={InstallData.install_cmd}
                                                onChange={(e) => e}
                                                rows={12}
                                                disabled={true}
                                            />
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            )}


                            {!InstallData && model && !this.state.disableButton && (
                                <Button
                                    onClick={this.InstallGatewayFunc}
                                    className="clipboard-btn"
                                >
                                    <i className="fas fa-cog" aria-hidden="true"></i><span> </span>
                                    Generate gateway config
                                </Button>
                            )}

                        </div>
                    </Modal.Body>

                    <div className="modal-footer">
                        <div className="modal_footer_btns">
                            <Button
                                onClick={this.closeModal}
                                className="React_new_style_btn_ok"
                            >
                                {strings.CLOSE}
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}
