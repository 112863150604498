
(function (angular) {
    var app = angular.module('dassUiModule');
    app.factory('CommonService', CommonServiceFn);

    CommonServiceFn.$inject = ['$http', "$q"];

    function CommonServiceFn($http, $q) {
        function parseQueryStringImpl( queryString ) {
            var params = {}, queries, temp, i, l;
            // Split into key/value pairs
            queries = queryString.split("&");
            // Convert the array of strings into an object
            for ( i = 0, l = queries.length; i < l; i++ ) {
                temp = queries[i].split('=');
                params[temp[0]] = temp[1];
            }
            return params;
        };

        return {
            parseQueryString: parseQueryStringImpl
        }
    }
})(angular);