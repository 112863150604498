import * as React from "react";
import {
    ButtonToolbar,
    Col,
    ControlLabel,
    Form,
    FormGroup,
    HelpBlock,
    ToggleButton,
    ToggleButtonGroup,
} from "react-bootstrap";
import { strings } from "./../../services/Localization";

export default class ProfilesRights extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            DisableButton: false,
            Rights: {},
        };
    }

    public HandleChange = async (value, right) => {
        const Profile = this.props.ActiveTab;
        let { DisableButton } = this.state;
        if (right === "list") {
            if (value === "false") {
                const ProfileRights = ["inspect", "create", "link"];
                if (Profile === "service") {
                    ProfileRights.push("restrict");
                }
                const Rights = this.state.Rights;
                await ProfileRights.map((ProfileRight) => {
                    if (this.props.User[`can_${ProfileRight}_${Profile}_profile`]) {
                        Rights[`can_${ProfileRight}_${Profile}_profile`] = "false";
                    }
                });
                DisableButton = true;
            } else {
                DisableButton = false;
            }
            this.setState({
                DisableButton,
            });

        }
        this.setState({
            Rights: {...this.state.Rights, [`can_${right}_${Profile}_profile`]: value},
        });
    }

    public DisableButton = (profile) => {
        const { ForwardUser } = this.props;
        let DisableButton = this.state.DisableButton;
        if (ForwardUser[`can_list_${profile}_profile`]) {
            DisableButton = false;
        } else {
            DisableButton = true;
        }
        this.setState({
            DisableButton,
        });
    }

    public componentDidMount() {
        this.DisableButton(this.props.ActiveTab);
    }

    public componentDidUpdate(prevProps) {
        if (this.props.ActiveTab !== prevProps.ActiveTab) {
            this.setState({
                DisableButton: this.props.DisableButton,
            });
        }
    }

    public render() {
        const ProfileRights = ["list", "inspect", "create", "link", "restrict"];
        const { ActiveTab, ForwardUser, User } = this.props;
        return (
            <Form horizontal className="profiles-div">
                {(ActiveTab !== undefined) && (
                    ProfileRights.map((Right, index) => {
                        const ForwardUserRights = ForwardUser[`can_${Right}_${ActiveTab}_profile`];
                        const ProfileRightValue = User[`can_${Right}_${ActiveTab}_profile`];
                        const ProfileRight = `can_${Right}_${ActiveTab}_profile`;
                        if (ProfileRightValue && ((ActiveTab !== "qos" && ActiveTab !== "channel") ||
                            Right !== "link")) {
                            return <FormGroup controlId="ProfilesRights" key={index}>
                                <Col
                                    componentClass={ControlLabel}
                                    sm={5}
                                >
                                    <strong className="new_style_font_weight">
                                    {strings.formatString(
                                        strings[`PROFILES_CAN_${Right.toUpperCase()}_NAME`], {
                                            name: strings[`PROFILES_${ActiveTab.toUpperCase()}_PROFILE`],
                                    })}
                                    </strong>
                                </Col>
                                <Col sm={7}>
                                    <ButtonToolbar>
                                        <ToggleButtonGroup
                                            name="ProfileRightsButtons"
                                            type="radio"
                                            defaultValue="false"
                                            value={
                                                this.state.Rights[ProfileRight] ?
                                                    `${this.state.Rights[ProfileRight]}` : `${ForwardUserRights}`
                                            }
                                            onChange={(event) => {
                                                this.props.HandleRightChange(
                                                    event, ProfileRight, Right,
                                                );
                                                this.HandleChange(event, Right);
                                            }}
                                        >
                                            <ToggleButton
                                                value="true"
                                                disabled={(Right !== "list") ? (this.state.DisableButton || this.props.readOnly) : (false || this.props.readOnly)  }
                                            >
                                                {strings.YES}
                                            </ToggleButton>
                                            <ToggleButton
                                                value="false"
                                                disabled={(Right !== "list") ? (this.state.DisableButton ||this.props.readOnly) : (false || this.props.readOnly) }
                                            >
                                                {strings.NO}
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </ButtonToolbar>
                                    <HelpBlock>
                                        <small
                                        className="new_style_help_block_color"
                                        >
                                    {strings.formatString(
                                        strings[`PROFILES_CAN_${Right.toUpperCase()}_HELP`], {
                                            name: strings[`PROFILES_${ActiveTab.toUpperCase()}_PROFILE`].toLowerCase(),
                                        },
                                    )}
                                        </small>
                                    </HelpBlock>
                                </Col>
                            </FormGroup>;
                        } else {
                            return;
                        }
                    })
                )}
            </Form>
        );
    }
}
