import * as React from "react";
import * as ReactDOM from "react-dom";

import { DeviceModal } from "../components/Device/DeviceModal";
import EditPositionModal from "./../components/Device/NetworkMap/EditPositionModal";
import { toast } from "./../utils/Toaster";
import { strings } from "./../services/Localization";
import { UpdateDevice } from "./../services/Devices/NetworkMap";

declare const angular;

export class DeviceModals {

  public showAddModal() {
    const addDeviceModal = document.getElementById("add-device-button") as HTMLAnchorElement;
    const controller = angular.element(addDeviceModal).controller();
    const modalRoot = document.getElementById("reactModalRoot") as HTMLElement;
    ReactDOM.unmountComponentAtNode(modalRoot);
    ReactDOM.render(
      <DeviceModal Device={controller} />,
      modalRoot,
    );
  }
  public showEditModal(device) {
    const addDeviceModal = document.getElementById("add-device-button") as HTMLAnchorElement;
    const controller = angular.element(addDeviceModal).controller();
    const modalRoot = document.getElementById("reactModalRoot") as HTMLElement;
    ReactDOM.unmountComponentAtNode(modalRoot);
    ReactDOM.render(
      <DeviceModal editMode={true} EditDevice={device} Device={controller} />,
      modalRoot,
    );
  }

  public UpdateDevicePosition = async (marker) => {
    const DeviceEl = document.querySelector(".my_Device_style") as HTMLAnchorElement;
    const controller = angular.element(DeviceEl).controller();
    const modalRoot = document.getElementById("reactModalRoot") as HTMLElement;
    if (marker && marker.position && marker.data) {
      const dataToSend = {
        "latitude": marker.position.latitude,
        "longitude": marker.position.longitude
      };
      if (marker.position.altitude) {
        dataToSend["altitude"] = marker.position.altitude || 0;
      }
      if (marker.data.hasOwnProperty("name")) {
        dataToSend["comment"] = marker.data.name
      }
      const res:any = await UpdateDevice(
        marker.data.id,
        dataToSend
      );
      const data = await res.text();
      if (res.status === 200) {
        controller.loadDevices();
        toast.success(strings.formatString(
          strings.MSG_DEVICE_UPDATE_SUCCESS_BODY,
          { deveui: marker.data.id.match(/.{2}/g).join("-").toUpperCase() },
        ));
      } else {
        toast.error(strings.formatString(
          strings.MSG_DEVICE_UPDATE_FAIL_BODY,
          { data: "" },
        ));
        console.log(data);
      }
    }
    ReactDOM.unmountComponentAtNode(modalRoot);
  }

  public showPositionOnMap(device) {
    const modalRoot = document.getElementById("reactModalRoot") as HTMLElement;
    ReactDOM.unmountComponentAtNode(modalRoot);
    ReactDOM.render(
      <EditPositionModal
        Data={device}
        ShowModal={true}
        OnConfirm={this.UpdateDevicePosition}
        CloseModal={() => {
          ReactDOM.unmountComponentAtNode(modalRoot);
        }}
      />,
      modalRoot,
    );
  }

}
