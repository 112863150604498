import * as React from "react";
import {
    Checkbox,
    FormGroup,
    FormControl,
    InputGroup,
    Button,
} from "react-bootstrap";

import { strings } from "./../../services/Localization";
import { toast } from "./../../utils/Toaster";
import NetworkMapTable from "../../components/Device/NetworkMap/DevicesTable";
import Map from "../../components/Device/NetworkMap/Map/Map";
import EditPositionModal from "./../../components/Device/NetworkMap/EditPositionModal";
import { UpdateUserSettings } from "./../../services/UserSettings";

import {
    LoadAllGateways,
    LoadAllDevices,
    UpdateGateway,
    UpdateDevice,
    FilterDevices,
} from "./../../services/Devices/NetworkMap";

import { Whoami } from "./../../services/Login";

export default class NetworkMap extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            Gateways: [],
            Devices: [],
            Draggable: false,
            ShowDevices: true,
            ShowGateways: true,
            FlyTo: null,
            OpenEditPositionModal: false,
            EditPositionModalData: null,
            TableFilterValue: "",
            TableData: [],
            LoggedUser: null,
            mapCenterLatitude: null,
            mapCenterLongitude: null,
            mapZoom: null,
        }
    }

    onRecenter = (latitude: number, longitude: number, zoom: number) => {
        this.setState({ mapCenterLatitude: latitude, mapCenterLongitude: longitude, mapZoom: zoom });
    }

    async componentDidMount() {
        this.setState({ LoggedUser: (await Whoami() as any).user });
        Promise.all([this.LoadAllDevices(), this.LoadAllGateways()])
            .then((data:any) => {
                this.setState({
                    Devices: data[0].Devices,
                    Gateways: data[1].Gateways,
                    TableData: data[0].Devices.concat(data[1].Gateways),
                });
            });
    }

    LoadAllGateways = async () => {
        const res:any = await LoadAllGateways();
        let Gateways = [];
        if (res.status == 200) {
            Gateways = await res.json();
        }
        return { "Gateways": Gateways };
    }

    LoadAllDevices = async () => {
        const res:any = await LoadAllDevices();
        let Devices:string[] = [];
        if (res.status == 200) {
            Devices = await res.json();
        }
        return { "Devices": Devices };
    }

    FlyToTheMarker = (obj) => {
        if (obj && obj.latitude && obj.longitude) {
            const type = obj.hasOwnProperty("deveui") ? "Devices" : "Gateways";
            const { ShowDevices, ShowGateways } = this.state;
            if (((type === "Devices") && ShowDevices) || ((type === "Gateways") && ShowGateways)) {
                this.setState({
                    FlyTo: {
                        coordinates: [obj.latitude, obj.longitude],
                        id: obj.hasOwnProperty("deveui") ? obj.deveui : obj.id
                    }
                });
            }
        } else {
            this.OpenEditPositionModal(obj);
        }
    }

    OpenEditPositionModal = (obj) => {
        const { LoggedUser } = this.state;
        if (obj.hasOwnProperty("deveui") || (LoggedUser && LoggedUser.can_mng_gtw)) {
            this.setState({
                OpenEditPositionModal: true,
                EditPositionModalData: obj
            });
        }
    }

    UpdateMarker = async (marker) => {
        if (marker && marker.position && marker.data) {
            const dataToSend = {
                "latitude": marker.position.latitude,
                "longitude": marker.position.longitude
            };
            if (marker.position.altitude) {
                dataToSend["altitude"] = marker.position.altitude || 0;
            } if (marker.data.hasOwnProperty("name")) {
                if (marker.data.type === "Devices") {
                    dataToSend["comment"] = marker.data.name
                } else {
                    dataToSend["name"] = marker.data.name
                }
            }
            if (marker.data.type === "Gateways") {
                const NewGateways = JSON.parse(JSON.stringify(this.state.Gateways));
                const res:any = await UpdateGateway(
                    marker.data.id,
                    dataToSend
                );
                const data = await res.text();
                if (res.status === 200) {
                    const index = NewGateways.findIndex(g => g.id === marker.data.id);
                    if (marker.data.hasOwnProperty("name")) {
                        NewGateways[index].name = marker.data.name;
                    }
                    if (marker.position.altitude) {
                        NewGateways[index].altitude = marker.position.altitude;
                    }
                    NewGateways[index].latitude = marker.position.latitude;
                    NewGateways[index].longitude = marker.position.longitude;
                    this.setState({
                        Gateways: NewGateways,
                        TableData: this.state.Devices.concat(NewGateways)
                    });
                    toast.success(strings.MSG_GATEWAY_POS_UPDATE_SUCCESS);
                } else {
                    toast.error(strings.MSG_GATEWAY_POS_UPDATE_FAIL);
                    console.log(data);
                }
            } else if (marker.data.type === "Devices") {
                const NewDevices = JSON.parse(JSON.stringify(this.state.Devices));
                const res:any = await UpdateDevice(
                    marker.data.id,
                    dataToSend
                );
                const data = res.text();
                if (res.status === 200) {
                    const index = NewDevices.findIndex(g => g.deveui === marker.data.id);
                    if (marker.data.hasOwnProperty("name")) {
                        NewDevices[index].comment = marker.data.name;
                    }
                    if (marker.position.altitude) {
                        NewDevices[index].altitude = marker.position.altitude;
                    }
                    NewDevices[index].latitude = marker.position.latitude;
                    NewDevices[index].longitude = marker.position.longitude;
                    this.setState({
                        Devices: NewDevices,
                        TableData: NewDevices.concat(this.state.Gateways)
                    });
                    toast.success(strings.formatString(
                        strings.MSG_DEVICE_UPDATE_SUCCESS_BODY,
                        { deveui: marker.data.id.match(/.{2}/g).join("-").toUpperCase() },
                    ));
                } else {
                    toast.error(strings.formatString(
                        strings.MSG_DEVICE_UPDATE_FAIL_BODY,
                        { data: "" },
                    ));
                    console.log(data);
                }
            }
        }
    }

    TableFilterValue = (e) => {
        if (e.keyCode === 13) {
            this.FilterTable();
        } else {
            this.setState({
                TableFilterValue: e.target.value
            });
        }
    }

    FilterTable = () => {
        const { TableFilterValue } = this.state;
        if (TableFilterValue.length > 0) {
            Promise.all([
                this.GetFilterTableData("search_comment", TableFilterValue),
                this.GetFilterTableData("search_deveui", TableFilterValue.replace(/-|:/g, ""))
            ]).then((Data) => {
                this.setState({
                    TableData: Data[0].concat(Data[1])
                });
            }).catch((e) => {
                console.log(e);
            });
        } else {
            Promise.all([this.LoadAllDevices(), this.LoadAllGateways()])
            .then((data:any) => {
                this.setState({
                    TableData: data[0].Devices.concat(data[1].Gateways)
                });
            });
        }
    }

    GetFilterTableData = async (filterBy, filterValue) => {
        const res:any = await FilterDevices(filterBy, filterValue);
        let Data = [];
        if (res.status == 200) {
            Data = await res.json();
        }
        return Data;
    }


    setDefPosition = () => {
        if (this.state.LoggedUser) {
            const ui_settings = {
                ...this.state.LoggedUser.ui_settings,
                map_center_latitude: this.state.mapCenterLatitude,
                map_center_longitude: this.state.mapCenterLongitude,
                map_zoom: this.state.mapZoom,
            };
            UpdateUserSettings(this.state.LoggedUser.userid, { ui_settings });
        }
    }


    render() {
        const {
            Gateways,
            Devices,
            Draggable,
            OpenEditPositionModal,
            EditPositionModalData,
            FlyTo,
            TableData,
            ShowDevices,
            ShowGateways,
            TableFilterValue,
            LoggedUser
        } = this.state;
        return (
            <div id="NetworkMap">
                <div className="search_input_wrapper">
                    <FormGroup
                        validationState={(TableFilterValue.length > 0) ? "success" : null}
                    >
                        <InputGroup>
                            <InputGroup.Addon>
                                <i className="fas fa-search"></i>
                            </InputGroup.Addon>
                            <FormControl
                                type="text"
                                onKeyUp={this.TableFilterValue}
                                placeholder={strings.MAP_FILTER_DEVICES}
                            />
                        </InputGroup>
                    </FormGroup>
                </div>
                <NetworkMapTable
                    TableData={TableData}
                    FlyToTheMarker={this.FlyToTheMarker}
                    OpenEditPositionModal={this.OpenEditPositionModal}
                />
                <Map
                    Markers={{
                        "Gateways": Gateways,
                        "Devices": Devices
                    }}
                    Draggable={Draggable}
                    ShowGateways={ShowGateways}
                    ShowDevices={ShowDevices}
                    onDragEnd={this.UpdateMarker}
                    GoToMarker={FlyTo}
                    LoggedUser={LoggedUser}
                    onRecenter={this.onRecenter}
                />
                <div className="custom_control">
                    <Checkbox
                        onChange={(e:any) => {
                            this.setState({
                                Draggable: e.target.checked
                            });
                        }}
                    >
                        {strings.MAP_MOVABLE}
                    </Checkbox>
                    <Checkbox
                        checked={ShowGateways}
                        onChange={(e:any) => {
                            this.setState({
                                ShowGateways: e.target.checked
                            });
                        }}
                    >
                        {strings.MAP_SHOW_GATEWAYS}
                    </Checkbox>
                    <Checkbox
                        checked={ShowDevices}
                        onChange={(e:any) => {
                            this.setState({
                                ShowDevices: e.target.checked
                            });
                        }}
                    >
                        {strings.MAP_SHOW_DEVICES}
                    </Checkbox>
                </div>

                <div>
                    <Button
                        title={strings.MAP_SAVE_MAP_TOOLTIP}
                        className="saveButton fas fa-thumbtack"
                        onClick={this.setDefPosition}
                    />
                </div>


                {OpenEditPositionModal && (
                    <EditPositionModal
                        ShowModal={OpenEditPositionModal}
                        CloseModal={() => {
                            this.setState({
                                OpenEditPositionModal: false
                            });
                        }}
                        Data={EditPositionModalData}
                        OnConfirm={(marker) => {
                            this.UpdateMarker(marker);
                            this.setState({
                                OpenEditPositionModal: false
                            });
                        }}
                    />
                )}
            </div>
        );
    }
}
