import * as React from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import { strings } from "./../../services/Localization";

interface ISelectDropdownProps {
    Profiles: any[];
    DefaultValue: any;
    ProfileName: string;
    SelectedProfile: ((profile) => void) | null;
    ShowProfileName?: (state: boolean, name: string) => void;
    Required?: boolean;
    Remote?: boolean;
    Clearable?: boolean;
    Disabled: boolean;
    Error?: boolean;
    Creatable?: boolean;

    RemoteFilter?: any;
    CustomPlaceholder?: string;
    MenuPosition?: any;
    OpenMenuOnClick?: boolean;
}

export class ProfileSelectDropdown extends React.Component<ISelectDropdownProps, any> {
    constructor(props) {
        super(props);
        this.state = {
            NewProfiles: [{ label: "", value: "" }],
            Profiles: this.props.Profiles || [],
            SelectedProfile: null,
        };
    }

    public componentDidMount() {
        if (this.props.DefaultValue) {
            const DefaultValues: any = {};
            DefaultValues.label = this.props.DefaultValue.profile_name || "";
/*
            DefaultValues.label = <div>
                    {this.props.DefaultValue.profile_name || ""}
                    <small> {this.props.DefaultValue.profile_uuid || ""}</small>
                </div>;
*/
            DefaultValues.value = this.props.DefaultValue.profile_uuid || "";
            this.setState({
                SelectedProfile: DefaultValues,
            });
        }
    }

    public componentWillReceiveProps(nextProps) {
        if (nextProps.Profiles !== this.state.Profiles) {
            this.setState({
                Profiles: nextProps.Profiles,
            });
        }
    }

    public DropdownProfiles = (props) => {
        return <div className="Dropdown" {...props.innerProps}>
            <p>{props.data.label}</p>
            <div>
                <p>{props.data.value}</p>
                <p>{props.data.description}</p>
            </div>
        </div>;
    }

    public handleProfile = (Profile) => {
        const SelectedProfile: any = {};
        SelectedProfile.label = Profile ? Profile.label : "";
        SelectedProfile.value = Profile ? Profile.value : "";
        SelectedProfile.ProfileName = this.props.ProfileName;
        this.setState({
            SelectedProfile,
        });
        if (this.props.SelectedProfile) { this.props.SelectedProfile(SelectedProfile) };
        if (this.props.ShowProfileName) {
            if ((Profile === null) || (Profile.length === 0)) {
                this.setState({
                    SelectedProfile: null,
                });
                this.props.ShowProfileName(false, this.props.ProfileName);
            }
        } else {
            if ((Profile === null) || (Profile.length === 0)) {
                this.setState({
                    SelectedProfile: null,
                });
                if (this.props.SelectedProfile) {
                    this.props.SelectedProfile({value: "", ProfileName: this.props.ProfileName});
                }
            }
        }

    }

    public getProfiles = () => {
        const Profiles = new Array();
        this.state.Profiles.forEach((element) => {
            const profile = {
                description: "",
                label: "",
                value: "",
            };
            profile.label = element.profile_name;
            profile.value = element.profile_uuid;
            profile.description = element.description;
            Profiles.push(profile);
        });
        this.setState({
            NewProfiles: Profiles,
        });
    }

    public render() {
        if (this.props.Remote) {
            if (this.props.Creatable) {
                return (
                    <AsyncCreatableSelect
                        className={`SelectDropdownReact
                            ${(this.props.Required) ?
                                (this.state.SelectedProfile === null) ?
                                    "has-error" : (this.props.Error) ? "has-error" : "success"
                            : (this.props.Error) ? "has-error" : ""}
                        `}
                        loadOptions={this.props.RemoteFilter}
                        cacheOptions={false}
                        defaultOptions={this.state.NewProfiles}
                        components={{ Option: this.DropdownProfiles }}
                        placeholder={this.props.CustomPlaceholder ?
                            this.props.CustomPlaceholder : strings.SELECT_PROFILE_PLACEHOLDER
                        }
                        classNamePrefix="ReactSelect"
                        menuPlacement={this.props.MenuPosition ? this.props.MenuPosition : "bottom"}
                        openMenuOnClick={this.props.OpenMenuOnClick || false}
                        isDisabled={this.props.Disabled || false}
                        value={this.state.SelectedProfile}
                        onChange={this.handleProfile}
                        onMenuOpen={this.getProfiles}
                        formatCreateLabel={(inputValue) => (`${strings.CREATABLE_SELECT_LABEL} "${inputValue}"`)}
                        isClearable={this.props.Clearable ? this.props.Clearable : false}
                    />
                );
            } else {
                return (
                    <AsyncSelect
                        className={
                            (this.props.Required) ?
                                (this.state.SelectedProfile === null) ?
                                    "SelectDropdownReact has-error" : "SelectDropdownReact success"
                            : "SelectDropdownReact"
                        }
                        loadOptions={this.props.RemoteFilter}
                        cacheOptions={false}
                        defaultOptions={this.state.NewProfiles}
                        components={{ Option: this.DropdownProfiles }}
                        placeholder={this.props.CustomPlaceholder ?
                            this.props.CustomPlaceholder : strings.SELECT_PROFILE_PLACEHOLDER
                        }
                        classNamePrefix="ReactSelect"
                        menuPlacement={this.props.MenuPosition ? this.props.MenuPosition : "bottom"}
                        openMenuOnClick={false}
                        isDisabled={this.props.Disabled || false}
                        value={this.state.SelectedProfile}
                        onChange={this.handleProfile}
                        onMenuOpen={this.getProfiles}
                    />
                );
            }
        } else {
            return (
                <Select
                    className="SelectDropdownReact"
                    components={{ Option: this.DropdownProfiles }}
                    value={this.state.SelectedProfile}
                    options={this.state.NewProfiles}
                    onMenuOpen={this.getProfiles}
                    isDisabled={this.props.Disabled || false}
                    onChange={this.handleProfile}
                    openMenuOnClick={false}
                    placeholder={this.props.CustomPlaceholder ?
                        this.props.CustomPlaceholder : strings.SELECT_PROFILE_PLACEHOLDER
                    }
                    classNamePrefix="ReactSelect"
                    menuPlacement={this.props.MenuPosition ? this.props.MenuPosition : "bottom"}
                />
            );
        }
    }

}
