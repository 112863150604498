import * as React from "react";
import * as L from "leaflet";
import "leaflet.markercluster";
import "leaflet.markercluster.layersupport";

import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "./style.css";

import MarkerIconDefault from "./marker-icon-2x.png";

interface IPosition {
    latitude: number;
    longitude: number;
    altitude: number;
}

interface IMarker {
    coordinates: [number, number];
    id: string;
}


interface IMapProps {
    Markers: {
        Gateways: any;
        Devices: any;
    };
    Draggable: boolean;
    ShowGateways: boolean;
    ShowDevices: boolean;
    onDragEnd: (mark: { position: IPosition, data: any }) => void;
    GoToMarker: IMarker;
    LoggedUser: any;
    onRecenter?: (latitude: number, longitude: number, zoom: number) => void;
}

export default class Map extends React.Component<IMapProps, any> {
    map;
    GatewaysLayerGroup;
    DevicesLayerGroup;
    MarkerCluster;
    LayersControl;
    Gateways: string[] = [];
    Devices: string[] = [];
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    DefaultMarkerIcon = L.icon({
        iconUrl: MarkerIconDefault,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -41],
        className: "default_icon"
    });

    GatewayMarkerIcon = L.divIcon({
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -41],
        className: "gateway_icon",
        html: '<div class="marker_pin"><i class="fas fa-broadcast-tower"></i></div>'
    });

    DevicesMarkerIcon = L.divIcon({
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -41],
        className: "device_icon",
        html: '<div class="marker_pin"><i class="fas fa-cog"></i></div>'
    });

    onRecenter = () => {
        if (this.props.onRecenter) {
            const center = this.map.getCenter();
            this.props.onRecenter(center.lat, center.lng, this.map.getZoom());
        }
    }

    addMap() {
        const { LoggedUser } = this.props;

        let center = [47, 49];
        if (LoggedUser.ui_settings.map_center_latitude != null && LoggedUser.ui_settings.map_center_longitude != null) {
            center = [LoggedUser.ui_settings.map_center_latitude, LoggedUser.ui_settings.map_center_longitude];
        }
        const zoom = LoggedUser.ui_settings.map_zoom || 2;

        this.map = L.map("leaflet-map", {
            center,
            tap: false,
            zoom,
            maxZoom: 18,
            zoomControl: false,
            layers: [
                L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
                    attribution: "&amp;copy <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
                })
            ]
        });

        // Zoom Control
        L.control.zoom({ position: "topright" }).addTo(this.map);

        // Markers clustering
        this.MarkerCluster = L.markerClusterGroup.layerSupport({
            animate: false,
            spiderfyDistanceMultiplier: 1.5,
            maxClusterRadius: 28,
            spiderfyOnMaxZoom: true,
        }).addTo(this.map);

        this.map.on("zoomend", this.onRecenter);
        this.map.on("moveend", this.onRecenter);

        this.onRecenter();
    }


    componentDidMount() {
        // We can't add the map until the user is populated. So it is either done here, ror in componentDidUpdate()
        const { LoggedUser } = this.props;
        if (LoggedUser && this.map == null) {
            this.addMap();
        }
    }


    componentDidUpdate(prevProps, prevState) {
        const { ShowGateways, ShowDevices, Draggable, LoggedUser } = this.props;
        const { Gateways, Devices } = this.props.Markers;

        if (LoggedUser && this.map == null) {
            this.addMap();
        }

        if (JSON.stringify(prevProps.Markers) !== JSON.stringify(this.props.Markers)) {
            if (JSON.stringify(prevProps.Markers.Gateways) !== JSON.stringify(Gateways)) {
                this.CreateMarkers({"data": Gateways, "type": "Gateways"}, Draggable);
            }
            if (JSON.stringify(prevProps.Markers.Devices) !== JSON.stringify(Devices)) {
                this.CreateMarkers({"data": Devices, "type": "Devices"}, Draggable);
            }
        }
        if (prevProps.ShowDevices !== ShowDevices) {
            this.CreateMarkers({"data": ShowDevices ? Devices : [], "type": "Devices"}, Draggable);
        }
        if (prevProps.ShowGateways !== ShowGateways) {
            this.CreateMarkers({"data": ShowGateways ? Gateways : [], "type": "Gateways"}, Draggable);
        }
        if (this.props.Markers && (prevProps.Draggable !== this.props.Draggable)) {
            this.MarkerCluster.clearLayers();
            this.CreateMarkers({"data": ShowGateways ? Gateways : [], "type": "Gateways"}, Draggable);
            this.CreateMarkers({"data": ShowDevices ? Devices : [], "type": "Devices"}, Draggable);
        }
        if (this.props.GoToMarker && (prevProps.GoToMarker !== this.props.GoToMarker)) {
            this.map.flyToBounds([this.props.GoToMarker.coordinates], {
                paddingTopLeft: [200, 0],
                animate: false,
                duration: 0,
            });
            this.openMarkerPopup(this.props.GoToMarker.id);
        }
    }

    openMarkerPopup = (id) => {
        const markers = this.Devices.concat(this.Gateways);
        const index = markers.findIndex(m => m["options"].data.id === id);
        if (index > -1) {
            const marker:any = markers[index];
            if (!marker._icon) marker.__parent.spiderfy();
            marker.openPopup();
        }
    }

    CreateMarkers = (Markers, draggable = false) => {
        const { LoggedUser } = this.props;
        if (Markers && (Markers.data.length > 0) && Markers.type) {
            this[Markers.type] = [];
            Markers.data.map((marker) => {
                if (marker.latitude && marker.longitude) {
                    const markerId = (Markers.type === "Devices") ? marker.deveui : marker.id;
                    const markerName = (Markers.type === "Devices") ? marker.comment : marker.name;
                    const newMarker:any = L.marker([marker.latitude, marker.longitude], {
                        icon: (Markers.type === "Gateways") ? this.GatewayMarkerIcon : (Markers.type === "Devices") ? this.DevicesMarkerIcon : this.DefaultMarkerIcon,
                        draggable: draggable,
                        data: {
                            id: markerId,
                            type: Markers.type,
                        }
                    });
                    if (draggable && ((Markers.type === "Devices") || (LoggedUser && LoggedUser.can_mng_gtw))) {
                        newMarker.on("dragend", (e) => {
                            const pos = e.target.getLatLng();
                            const opt = e.target.options;
                            this.props.onDragEnd({
                                "position": {
                                    altitude: marker.altitude || 0,
                                    latitude: pos.lat,
                                    longitude: pos.lng
                                },
                                "data": opt.data
                            });
                        });
                    }
                    let markerPopup = markerId.match(/.{2}/g).join("-").toUpperCase();
                    if (markerName) {
                        markerPopup += `<br/><div style="text-align:center;">${markerName}</div>`;
                    }
                    newMarker.bindPopup(markerPopup);
                    this[Markers.type].push(newMarker);
                    this.MarkerCluster.addLayer(newMarker);
                }
            });
            if (Markers.type === "Devices") {
                if (this.DevicesLayerGroup) {
                    this.map.removeLayer(this.DevicesLayerGroup);
                }
                this.DevicesLayerGroup = L.layerGroup(this[Markers.type]);
                this.DevicesLayerGroup.addTo(this.map);
            } else if (Markers.type === "Gateways") {
                if (this.GatewaysLayerGroup) {
                    this.map.removeLayer(this.GatewaysLayerGroup);
                }
                this.GatewaysLayerGroup = L.layerGroup(this[Markers.type]);
                this.GatewaysLayerGroup.addTo(this.map);
            }
            this.MarkerCluster.checkIn(L.layerGroup(this[Markers.type]));
        } else if (Markers && Markers.type && (Markers.data.length === 0)) {
            if ((Markers.type === "Devices") && this.DevicesLayerGroup) {
                    this.map.removeLayer(this.DevicesLayerGroup);
            } else if ((Markers.type === "Gateways") && this.GatewaysLayerGroup) {
                    this.map.removeLayer(this.GatewaysLayerGroup);
            }
        }
    }

    render() {
        return (
            <div id="map-container-div">
                <div id="leaflet-map"></div>
            </div>
        );
    }
}
