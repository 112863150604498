(function (angular) {
    var app = angular.module('dassUiModule');
    app.factory('UserService', UserServiceFn);

    UserServiceFn.$inject = ['$http', "$q"];

    const typeToUrlMap = {
        customer: "/uiapi/rest/customers",
        organization: "/uiapi/rest/organisations",
        user: "/uiapi/rest/users",
    }

    const headersJson = { headers: headerContentTypeJson }
    const headersNoJson = { headers: headerNoContentType }


    function UserServiceFn($http, $q) {
        // Get individual gateway data
        function getUserDataImpl() {
            var defer = $q.defer();
            $http.get('/whoami/', {headers:{'Cache-Control': 'no-cache'}}).then(function (response) {

                const allowed = [
                    "/forgot_password.html", "/forgot_username.html", "/email_username.html",
                    "/password_reset_instructions.html",
                    "/reset_password.html", "/password_successfully_changed.html",
                    "/new_password.html", "/welcome.html", "/initial_password.html"
                ];

                if (!response.data.user &&
                    (window.location.pathname == '/admin_users.html' ||
                    window.location.pathname == '/data_list.html' ||
                    window.location.pathname == '/mygateways_list.html' ||
                    window.location.pathname == '/multicast_activity.html' ||
                    window.location.pathname == '/mydevices_register.html' ||
                    window.location.pathname == '/admin_devices.html' ||
                    window.location.pathname == '/users_list.html' ||
                    window.location.pathname == '/mydevices_list.html')) {

                    window.location.href = '/app/signin';
                }
                else if (!response.data.user && allowed.indexOf(window.location.pathname) < 0) {
                    window.location.href = 'welcome.html';
                }

                defer.resolve(response.data);
            }, function (response) {
                defer.reject(response);
            });
            return defer.promise;
        }

        function getUserImpl(id, usertype) {
            var defer = $q.defer();
            url = `${typeToUrlMap[usertype]}/${id}?all=true&_license=true`;

            $http.get(url, headersNoJson).then(function (response) {
                defer.resolve(response.data);
            }, function (response) {
                defer.reject(response);
            });

            return defer.promise;
        }
        function getUsersImpl(type) {
            var defer = $q.defer();
            $http.get(typeToUrlMap[type], headersNoJson).then(function (response) {
                defer.resolve(response.data);
            }, function (response) {
                defer.reject(response);
            });
            return defer.promise;
        }
        function getUserDefaultsImpl(type) {
            var defer = $q.defer();
            $http.get(typeToUrlMap[type] + "?get_defaults=true", headersNoJson).then(function (response) {
                defer.resolve(response.data);
            }, function (response) {
                defer.reject(response);
            });
            return defer.promise;
        }


        function setCurrentDeviceImpl(deveui, comment = "") {
            return $http.put('/setcurdev?deveui=' + deveui + '&comment=' + comment, null, headersNoJson);
        }

        function deleteUserImpl(userid, user_type) {
            return $http.delete(typeToUrlMap[user_type] + "/" + userid, headersNoJson);
        }

        function deleteUsersImpl(users, user_type) {
            var promises = users.map(user => deleteUserImpl(user.userid, user_type));
            return Promise.all(promises);
        }

        function addUserOnDassImpl(user, user_type) {
            return $http.post(typeToUrlMap[user_type], user, headersJson);
        }

        function updateUserImpl(userid, obj) {
            return $http.put('/uiapi/rest/users/' + userid, obj, headersJson);
        }

        function signInForwardImpl(userid) {
            return $http.put('/signinforward' + (userid ? '?userid=' + userid : ''), null, headersNoJson);
        }

        function validatePasswordPolicyImpl(user){
            return $http.put('/validate_policy', user, headersJson);
        }

        function changePasswordImpl(user) {
            const {userid, ...rest} = user;
            return $http.put('/uiapi/rest/users/' + userid, rest, headersJson);
        }

        function getAllUserDataImpl(userId) {
            return $http.get("/uiapi/rest/customers/" + userId, headersNoJson);
        }

        return {
            getAllUserData: getAllUserDataImpl,
            getUserData: getUserDataImpl,
            getUser: getUserImpl,
            getUserDefaults: getUserDefaultsImpl,
            getUsers: getUsersImpl,
            setCurrentDevice: setCurrentDeviceImpl,
            deleteUser: deleteUserImpl,
            deleteUsers: deleteUsersImpl,
            signInForward: signInForwardImpl,
            addUserOnDass: addUserOnDassImpl,
            updateUser: updateUserImpl,
            changePassword: changePasswordImpl,
            validatePasswordPolicy: validatePasswordPolicyImpl
        }
    }
})(angular);
