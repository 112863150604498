import { Component, lazy, Suspense } from "react";
import * as React from "react";

import { TimelineOptions, DataGroup } from "vis-timeline/standalone";
import { Button, Col, Modal, Row, Panel, Table, Tabs, Tab, Nav, NavItem, ProgressBar } from "react-bootstrap";

import { strings } from "./../../services/Localization";
import { GetGatewayAlarms, GetGatewayConfig, GetGatewayScannerStatus, IGatewayInfo, StartGatewayScanner, StopGatewayScanner } from "./../../services/Gateways";
import "./EnterpriseGatewayConfig.css";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "../../utils/Toaster";
import * as moment from "moment";
import { LocaleDate } from "./../../controllers/GlobalController";
import { IGatewayAlarms, IScannerStatus, IUser } from "../../dassTypes";

const VisTimeline = lazy(() => import("./VisTimeline"));

interface IGatewayConfigStates {
    ShowModal: boolean;
    Gateway: IGatewayInfo;
    activeTab: "Scanner" | "Info" | "Statistics" | "Trace" | "Alarm" | "GtwLogs" | "GtwTerm";
    scannerStatus: IScannerStatus;
    abortAll: boolean;

    items: any[];
    groups: any[];
}

interface IGatewayConfigProps {
    GatewayId: string;
    User: IUser;
}
export class EnterpriseGatewayConfig extends Component<IGatewayConfigProps, IGatewayConfigStates> {

    public statusTimer: any = null;
    public updatingStatus = false;

    constructor(props: IGatewayConfigProps) {
        super(props);

        console.log("In constructor");

        this.state = {
            ShowModal: true,
            Gateway: {} as any,
            activeTab: "Info",
            abortAll: false,
            items: [],
            groups: [],

            scannerStatus: {
                scanner_state: "SCANNER_STOPPED",
                progress_cnt: 0,
                finish_cnt: 0,
            }
        };
    }

    componentDidMount() {
        this.GetGatewayConfigFunc();
    }


    componentWillUnmount() {
        clearTimeout(this.statusTimer);
        this.setState({ abortAll: true });
    }


    public LocaleDateFunc = (date) => {
        if(moment(date).isValid()){
            return moment(date).format(LocaleDate().DateTimeFormat);
        } else {
            return date;
        }
    }

    public GetGatewayConfigFunc = async () => {
        try {
            const Gateway = await GetGatewayConfig(this.props.GatewayId);
            this.setState({ Gateway });
        } catch (e) {
            console.log(e);
            toast.error(e.errorTxt || strings.GATEWAY_ERROR_CONFIG);
        }
    }


    public async updateScannerStatus(quiet: boolean) {

        if (this.updatingStatus === false) {

            clearTimeout(this.statusTimer);
            this.updatingStatus = true;

            try {
                const scannerStatus = await GetGatewayScannerStatus(this.props.GatewayId);
                this.setState({ scannerStatus });
            } catch (e) {
                console.log(e);
                if (!quiet) {
                    toast.error(strings.GATEWAY_ERROR_CONFIG);
                }
            }

            this.updatingStatus = false;

            if (this.state.activeTab === "Scanner" && !this.state.abortAll) {
                this.statusTimer = setTimeout(() => this.updateScannerStatus(true), 60 * 1000);
            }
        }
    }


    public async startScanner() {
        try {
            await StartGatewayScanner(this.props.GatewayId, 10);
            toast.success("Scanner started");
        } catch (e) {
            console.log(e);
            toast.error("Can't start scanner");
        }
        this.updateScannerStatus(false);
    }

    public async stopScanner() {
        try {
            await StopGatewayScanner(this.props.GatewayId);
            toast.success("Scanner stopped");
        } catch (e) {
            console.log(e);
            toast.error("Can't stop scanner");
        }
        this.updateScannerStatus(false);
    }


    public closeModal = () => {
        this.setState({ ShowModal: false });
    }


    public infoPanel() {

        const { Gateway } = this.state;

        return (
            <Row>
                <Col sm={12} className="form-horizontal">
                <Panel>
                    <Panel.Heading>{strings.GATEWAY_VERSIONS}</Panel.Heading>
                    <Panel.Body>
                        <Row>
                            <Col md={6}>{strings.GATEWAY_TYPE}</Col>
                            <Col md={6}>{Gateway.gateway_type}</Col>
                        </Row>
                        <Row>
                            <Col md={6}>{strings.GATEWAY_FIRMWARE_VERSION}</Col>
                            <Col md={6}>{Gateway.gateway_firmware_version}</Col>
                        </Row>
                        <Row>
                            <Col md={6}>{strings.GATEWAY_SOFTWARE_VERSION}</Col>
                            <Col md={6}>{Gateway.gateway_software_version}</Col>
                        </Row>
                    </Panel.Body>
                </Panel>

                {Gateway.active_alarms && Gateway.active_alarms.length > 0 && <Panel>

                    <Panel.Heading>Active Alarms</Panel.Heading>
                    <Panel.Body>
                        {Gateway.active_alarms.map((alrm) =>
                            (<Row>
                                <Col md={8}>{alrm.description}</Col>
                                <Col md={8}>{alrm.start_time && new Date(alrm.start_time).toLocaleString()}</Col>
                            </Row>)
                        )}
                    </Panel.Body>
                </Panel>}

                <Panel>
                <Panel.Heading>{strings.GATEWAY_TX_POWER_AND_ANTENNA_GAIN}</Panel.Heading>
                    <Panel.Body>
                        <Row>
                            <Col md={6}>{strings.GATEWAY_RX1_POWER} (dBm)</Col>
                            <Col md={6}>{Gateway.rx1_tx_power}</Col>
                        </Row>
                        <Row>
                            <Col md={6}>{strings.GATEWAY_RX2_POWER} (dBm)</Col>
                            <Col md={6}>{Gateway.rx2_tx_power}</Col>
                        </Row>
                        <Row>
                            <Col md={6}>{strings.GATEWAY_CLASSB_TX_POWER} (dBm)</Col>
                            <Col md={6}>{Gateway.classb_tx_power}</Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={6}>{strings.GATEWAY_ANTENNA_GAIN} (dB)</Col>
                            <Col md={6}>{Gateway.antenna_gain}</Col>
                        </Row>
                        <Row>
                            <Col md={6}>{strings.GATEWAY_CABLE_LOSS} (dB)</Col>
                            <Col md={6}>{Gateway.cable_loss}</Col>
                        </Row>
                    </Panel.Body>
                </Panel>

                <Panel>
                    <Panel.Heading>{strings.GATEWAY_BEACON_CONFIGURATION}</Panel.Heading>
                    <Panel.Body>
                        <Row>
                            <Col md={6}>{strings.GATEWAY_BEACON_FREQUENCY} (MHz)</Col>
                            <Col md={6}>{Gateway.beacon_frequency}</Col>
                        </Row>
                        <Row>
                            <Col md={6}>{strings.GATEWAY_BEACON_ENABLE}</Col>
                            <Col md={6}>{`${Gateway.beacon_enabled}`}</Col>
                        </Row>
                    </Panel.Body>
                </Panel>

                <Panel>
                    <Panel.Heading>{strings.GATEWAY_FREQUENCY_CONFIGURATION}</Panel.Heading>
                    <Panel.Body>
                        <Table bordered condensed>
                            <thead>
                                <tr>
                                    <th>{strings.GATEWAY_FREQUENCY} (MHz)</th>
                                    <th>{strings.GATEWAY_MINIMUM_DATA_RATE}</th>
                                    <th>{strings.GATEWAY_MAXIMUM_DATA_RATE}</th>
                                </tr>
                            </thead>
                            <tbody className="table-body">
                                {(Gateway.hasOwnProperty("channels") && (Gateway.channels.constructor === Array) && Gateway.channels.length > 0) ?
                                        Gateway.channels.map((channel, index) => (
                                <tr key={index}>
                                    <td>{channel.frequency}</td>
                                    <td>{channel.min_dr}</td>
                                    <td>{channel.max_dr}</td>
                                </tr>
                                )) :
                                (<tr>
                                    <td colSpan={3} className="text-center">{strings.GATEWAY_NO_CHANNELS}</td>
                                    </tr>)
                                }
                            </tbody>
                        </Table>
                    </Panel.Body>
                </Panel>

                <Panel>
                    <Panel.Heading>{strings.GATEWAY_CONNECTION_STATUS}</Panel.Heading>
                    <Panel.Body>
                        <Row>
                            <Col md={6}>{strings.GATEWAY_LAST_REST_KA}</Col>
                            <Col md={6}>{Gateway.last_ns_udp_keepalive_date ? this.LocaleDateFunc(Gateway.last_ns_udp_keepalive_date) : ""}</Col>
                        </Row>
                        <Row>
                            <Col md={6}>{strings.GATEWAY_LAST_OMC_UDP_KA}</Col>
                            <Col md={6}>{Gateway.last_omc_udp_keepalive_date ? this.LocaleDateFunc(Gateway.last_omc_udp_keepalive_date) : ""}</Col>
                        </Row>
                        <Row>
                            <Col md={6}>{strings.GATEWAY_LAST_ANSWERED_PING}</Col>
                            <Col md={6}>{Gateway.last_rest_udp_keepalive_date ? this.LocaleDateFunc(Gateway.last_rest_udp_keepalive_date) : ""}</Col>
                        </Row>
                    </Panel.Body>
                </Panel>
            </Col>
            </Row>);
    }


    public alarmTimelinePanel() {

        const options: TimelineOptions = {
            align: "left",
            maxHeight: "400px",
            minHeight: "400px",
            stack: false,
        };

        return (
        <Row>
            <Col sm={12} className="form-horizontal">
                <Panel>
                    <Panel.Heading>Alarm Timeline</Panel.Heading>
                    <Panel.Body>
                        <Row>
                            <Button
                                onClick={() => this.updateAlarmTimeline()}
                                className="React_new_style_btn_cancel"
                            >
                                Refresh
                            </Button>

                        </Row>
                        <br/>
                        <Row>
                         <Suspense fallback={<div>Loading...</div>}>
                            <VisTimeline options={options} items={this.state.items} groups={this.state.groups}></VisTimeline>
                        </Suspense>
                            
                        </Row>
                    </Panel.Body>
                </Panel>
            </Col>
        </Row>
        );
    }


    public scannerPanel() {

        const state = this.state.scannerStatus.scanner_state;
        const progress = this.state.scannerStatus.progress_cnt + "/" + this.state.scannerStatus.finish_cnt;

        return (
            <>
            <Row>
                <Col sm={12} className="form-horizontal">
                    <Panel>
                        <Panel.Heading>{strings.GATEWAY_VERSIONS}</Panel.Heading>
                        <Panel.Body>
                            <Row>

                                <p>{state + " " + progress}</p>

                                <ProgressBar now={this.state.scannerStatus.progress_cnt} max={this.state.scannerStatus.finish_cnt}
                                label={state + " " + progress} />

                                <Button
                                    onClick={() => this.startScanner()}
                                    className="React_new_style_btn_cancel"
                                >
                                    Start scanner
                                </Button>

                                <Button
                                    onClick={() => this.stopScanner()}
                                    className="React_new_style_btn_cancel"
                                >
                                    Stop scanner
                                </Button>

                            </Row>
                        </Panel.Body>
                    </Panel>
                </Col>
            </Row>

            <Row>
                <iframe src={`/rssiscan/rssiscanview.html?gtwid=${this.props.GatewayId}`} width="900px" height="800px" />
            </Row>
            </>
        );
    }


    // read the gateway alarms from DASS and then render the timeline view
    public async updateAlarmTimeline() {
        const gatewayAlarms: IGatewayAlarms = await GetGatewayAlarms(this.props.GatewayId);

        const dataset: any[] = [];
        const usedGroups: { [index: string]: boolean; } = {};
        const severityMap: { [index: string]: number; } = {};

        for (const grp of gatewayAlarms.alarm_types) {
            severityMap[grp.alarm_type] = grp.severity;
        }

        for (const alarm of gatewayAlarms.alarms) {
            const severity = severityMap[alarm.alarm_type];
            const style = severity >= 3 ? "background-color: red;" :
                          (severity >= 2 ? "background-color: yellow;" : undefined);

            dataset.push({
                className: "timeline-event",
                content: "",
                id: alarm.id,
                group: alarm.alarm_type,
                start: alarm.start_time,
                end: alarm.end_time || new Date(),
                style,
            });
            usedGroups[alarm.alarm_type] = true;
        }
        const items = dataset;
        /*
        const items = new DataSet(dataset);
        const options: TimelineOptions = {
            align: "left",
            maxHeight: "400px",
            minHeight: "400px",
            stack: false,
        };
        */

        let groups: DataGroup[] = [];

        for (const grp of gatewayAlarms.alarm_types) {
            if (usedGroups[grp.alarm_type]) {
                groups.push({
                    className: "timeline-group",
                    id: grp.alarm_type,
                    content: grp.description,
                });
            }
        }
        groups = groups.sort((a, b) => a.id === b.id ? 0 : (a.id < b.id ? -1 : 1));

        this.setState({ items, groups });

/*

        if (this.myvis.current) {

            if (this.myvis.current.firstChild && this.timeline) {
                this.timeline.setData({ items, groups });
            } else {
                if (this.timeline) {
                    console.log("destroying old timeline");
                    this.timeline.destroy();
                }
                this.timeline = new Timeline(this.myvis.current, items, groups, options);
            }

        } else {
            console.log("timeline div not ready");
        }
        */
    }



    public handleTabs = (event) => {
        this.setState({ activeTab: event.target.title }, async () => {
            clearTimeout(this.statusTimer);
            if (this.state.activeTab === "Scanner") {
                this.updateScannerStatus(false);
            }
            if (this.state.activeTab === "Alarm") {
                this.updateAlarmTimeline();
            }
        });
    }


    public render() {
        const {
            ShowModal,
        } = this.state;

        const from = encodeURIComponent(new Date(Date.now() - 24 * 60 * 60 * 1000).toJSON());
        const to = encodeURIComponent(new Date(Date.now() + 24 * 60 * 60 * 1000).toJSON());
        const tz = new Date().getTimezoneOffset();

        return (
            <div>
                <Modal
                    show={ShowModal}
                    onHide={this.closeModal}
                    bsSize="sm"
                    className="ReactModal enterprise-gateway-config"
                    backdrop="static"
                >
                <Modal.Header closeButton={true}>
                    <Modal.Title>
                        {strings.formatString(strings.GATEWAY_CONFIGURATION_TITLE, {
                            gatewayId: this.props.GatewayId
                        })}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="padding_0_all">


                    {/* For users that has trace access, we will show several tabs, so we instantiate the tabs component  */}
                    {this.props.User.can_access_gtw_trace &&  <Tabs id="DeviceModalTab">
                    <Row className="clearfix ">
                        <Col className="tabs_style">
                            <Nav >
                                <NavItem className={this.state.activeTab === "Info" ? "nav-items" : ""}     onClick={this.handleTabs} title="Info">Overview</NavItem>
                                <NavItem className={this.state.activeTab === "Statistics" ? "nav-items" : ""}  onClick={this.handleTabs} title="Statistics">Statistics</NavItem>
                                <NavItem className={this.state.activeTab === "Trace"   ? "nav-items" : ""}  onClick={this.handleTabs} title="Trace">Trace</NavItem>
                                <NavItem className={this.state.activeTab === "Scanner" ? "nav-items" : ""}  onClick={this.handleTabs} title="Scanner">Scanner</NavItem>
                                <NavItem className={this.state.activeTab === "Alarm" ? "nav-items" : ""}    onClick={this.handleTabs} title="Alarm">Alarms</NavItem>
                                <NavItem className={this.state.activeTab === "GtwLogs" ? "nav-items" : ""}  onClick={this.handleTabs} title="GtwLogs">Logs</NavItem>
                                <NavItem className={this.state.activeTab === "GtwTerm" ? "nav-items" : ""}  onClick={this.handleTabs} title="GtwTerm">Terminal</NavItem>
                            </Nav>
                        </Col>
                        <Col className="style_tabs">
                            <Tab.Content animation>

                                {this.state.activeTab === "Info" && <Tab.Pane >
                                    {this.infoPanel()}
                                </Tab.Pane>}
                                {this.state.activeTab === "Statistics" && <Tab.Pane >

                                    <div>
                                    <iframe src={`/tfw1/report?embed&pltspan=w&gw=${this.props.GatewayId}&from=${from}&to=${to}&tz=${tz}`} width="900px" height="800px" />
                                    </div>

                                </Tab.Pane>}

                                {this.state.activeTab === "Trace" && <Tab.Pane >
                                    <div>
                                        <iframe src={`/tfw1/trace?embed&gw=${this.props.GatewayId}&from=${from}&to=${to}&tz=${tz}`} width="900px" height="800px" />
                                    </div>
                                </Tab.Pane>}

                                {this.state.activeTab === "GtwLogs" && <Tab.Pane >
                                    <div>
                                        <iframe src={`/gtwlogs/gtwlogs.html?gtwid=${this.props.GatewayId}`} width="900px" height="800px" />
                                    </div>
                                </Tab.Pane>}

                                {this.state.activeTab === "GtwTerm" && <Tab.Pane >
                                    <div>
                                        <iframe src={`/gtwterm/gtwterm.html?gtwid=${this.props.GatewayId}`} width="900px" height="800px" />
                                    </div>
                                </Tab.Pane>}


                                {this.state.activeTab === "Scanner" && <Tab.Pane >
                                    {this.scannerPanel()}
                                </Tab.Pane>}

                                {this.state.activeTab === "Alarm" && <Tab.Pane >
                                    {this.alarmTimelinePanel()}
                                </Tab.Pane>}

                            </Tab.Content>
                        </Col>
                    </Row>
                </Tabs>}

                {/* For users without trace access, we only show the config panel without any tabs */}
                {!this.props.User.can_access_gtw_trace && this.infoPanel()}

            </Modal.Body>

            <Modal.Footer className="new_style_modal_footer">
                <Button
                    onClick={this.closeModal}
                    className="React_new_style_btn_cancel"
                >
                    {strings.CLOSE}
                </Button>
            </Modal.Footer>
            </Modal>
            </div>
        );
    }
}
