((angular) => {
    function GroupAddModalController($scope, GroupService, MessageService, ToastService, $filter, $uibModalInstance, items, $uibModal, $window) {
        let vm = $scope;
        const $translate = $filter('translate');

        vm.dataType = '/uiapi/rest/groups';

        vm.resetGroup = function () {
            vm.group = {
                groupid: "",
                title: "",
                comment: ""
            };
        };

        vm.tableSelectionChanged = (group) => {
            console.log("Selection changed ", group);

            vm.selected = group;
        };
        
        vm.items = items;
        vm.groups = [];

        vm.loading = false;

        vm.editMode = items.editMode;
        vm.addMode = items.addMode;
        vm.group = items.group || {};
        vm.selected = items.group;
        vm.readOnly = true;
        $(".modal").animate({ scrollTop: 0 }, "slow");

        vm.unlockInput = () => {
            vm.readOnly = false;
        }
        
        console.log('vm.group items',items);
        vm.assignMode = items.assignMode;

        vm.selected = [];

        vm.ok = (action) => {
            $uibModalInstance.close({ action, groups: vm.selected });
        };

        vm.close = () => {
            $uibModalInstance.dismiss('cancel');
        };

        vm.cancelForm = () => {
            vm.editMode = vm.addMode = false;
            vm.resetGroup();
            vm.close();
        };


        vm.updateGroup = () => {
            if (vm.group.appskey == null) {
                vm.group.appskey = "";
            } if(vm.group.devaddr == null){
                vm.group.devaddr = "";
            } if (vm.group.nwkskey == null){
                vm.group.nwkskey = "";
            }
            if(vm.group.ping_slot_periodicity){
                vm.group.ping_slot_periodicity = parseInt(vm.group.ping_slot_periodicity, 10);
            }
            else{
                vm.group.ping_slot_periodicity = null;
            }
            GroupService.updateGroup(vm.group).then(() => {
                ToastService.showMessage($translate('MSG_UPDATE_GROUP_SUCCESS', vm.group), "success", "ok")
            })
                .then(vm.loadGroups)
                .catch(() => {
                    ToastService.showMessage($translate('MSG_UPDATE_GROUP_FAIL', vm.group), "error", "remove")
                });
            vm.cancelForm();
        };

        vm.saveGroup = () => {
            if((!vm.group.title)||(!vm.group.title == null)){
                vm.group.title = "";
            }
            if (vm.group.appskey == "") {
                delete vm.group.appskey;
            } if(vm.group.devaddr == ""){
                delete vm.group.devaddr;
            } if (vm.group.nwkskey == ""){
                delete vm.group.nwkskey;
            }
            vm.group.ping_slot_periodicity = parseInt(vm.group.ping_slot_periodicity, 10);
            GroupService.addGroup(vm.group).then(() => {
                ToastService.showMessage($translate('MSG_CREATE_GROUP_SUCCESS', vm.group), "success", "ok")
            })
                .then(vm.loadGroups)
                .catch((response) => {
                    if(response.status === 409){
                        ToastService.showMessage($translate(response.data, vm.group), "error", "remove")
                    }
                    else{
                        ToastService.showMessage($translate('MSG_CREATE_GROUP_FAIL', vm.group), "error", "remove");
                    }
                });
            vm.cancelForm();
        };

        vm.showSendDataDialog = (group) => {
            console.log($uibModal, $uibModalInstance);
            const modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'sendDataModalContent.html',
                controller: 'SendDataModalCtrl',
                size: "",
                resolve: {
                    items: () => {
                        return {
                            isMulticast: true
                        };
                    }
                }
            });

            modalInstance.result.then((payload) => {
                console.log("Sending payload to group ", group, payload);
                GroupService.sendMulticast(group, payload.data, payload.port, payload.fcnt).then((result) => {
                    ToastService.showMessage($translate('MSG_MULTICAST_SUCCESS', vm.group), "success", "send")
                }).catch((err) => {
                    ToastService.showMessage($translate('MSG_MULTICAST_FAIL', vm.group), "error", "remove");
                    console.log("Error sending multicast", err);
                });
            });
        };

        vm.loadGroups = () => {
            if (!vm.reloadData) return;
            var reloadPromise = vm.reloadData();
            if (reloadPromise) {
                vm.workingCount += 1;
                reloadPromise.then(() => {
                    vm.type = type;
                    vm.workingCount -= 1;
                }).catch(err => {
                    vm.workingCount -= 1;
                });
            }
        };

        $scope.$watch(function(scope) {
            return {
                appskey: scope.addgroup.hasOwnProperty("appskey") ? scope.addgroup.appskey.$viewValue : "",
                nwkskey: scope.addgroup.hasOwnProperty("nwks_key") ? scope.addgroup.nwks_key.$viewValue : ""
            }
        }, function(object) {
            Object.keys(object).map(function(prop) {
                var pattern = /^([0-9A-Fa-f]{2}[:-]){15}([0-9A-Fa-f]{2})$/;
                var pattern1 = /^([0-9A-Fa-f]{4}[:]){7}([0-9A-Fa-f]{4})$/;
                if (object[prop]) {
                    var value = object[prop];
                    if (value.match(pattern) || value.match(pattern1)) {
                        // Remove ":" or "-" from the value
                        if (prop === "nwks_key") {
                            vm.group.nwkskey = value.replace(/[:|-]/g, "");
                        } else {
                            vm.group[prop] = value.replace(/[:|-]/g, "");
                        }
                    }
                }
            });
        }, true);

        Promise.all([vm.loadGroups()]).then(vm.initDataTable);
    };

    var app = angular.module('dassUiModule');
    app.controller('GroupAddModalController', ['$scope', 'GroupService', 'MessageService', 'ToastService', '$filter', '$uibModalInstance', 'items', '$uibModal', '$window', GroupAddModalController])
})(angular);
