import * as React from "react";
import AceEditor from "react-ace-cdn";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { strings } from "./../../services/Localization";

import {
    GetXProfile,
    PayloadDecoderTemplate,
    UpdateXProfile,
} from "./../../services/Profiles/Profiles";
import { toast } from "./../../utils/Toaster";

interface ICodeEditorModalProps {
    ShowModal: boolean;
    HideModal: () => void;
    ProfileUUID: string;
    ReadOnly: boolean;
}

export default class CodeEditorModal extends React.Component<ICodeEditorModalProps, any> {
    constructor(props: ICodeEditorModalProps) {
        super(props);
        this.state = {
            CodeEditorData: "",
            NewCodeEditorData: "",
            ProfileUUID: this.props.ProfileUUID || "",
        };
    }

    public componentDidMount() {
        this.GetDeviceProfile(this.state.ProfileUUID);
    }

    public componentWillReceiveProps(nextProps) {
        const { ProfileUUID } = this.state;
        if (nextProps.ProfileUUID !== ProfileUUID) {
            this.setState({
                ProfileUUID: nextProps.ProfileUUID || ProfileUUID,
            });
        }
    }

    public GetDeviceProfile = async (profileUUID) => {
        if (profileUUID !== "") {
            try {
                const response: any = await GetXProfile("device-profiles", profileUUID);
                const data = await response.json();
                if (response.status === 200) {
                    if (data && data.payload_decoder_js != null) {
                        this.setState({
                            CodeEditorData: data.payload_decoder_js,
                            NewCodeEditorData: data.payload_decoder_js,
                        });
                    } else {
                        this.GetPayloadDecoderTemplate();
                    }
                } else {
                    toast.error(data);
                }
            } catch (error) {
                toast.error(error);
            }
        }
    }

    public onChange = (newValue) => {
        this.setState({
            NewCodeEditorData: newValue,
        });
    }

    public GetPayloadDecoderTemplate = async () => {
        try {
            const response = await PayloadDecoderTemplate();
            const data = await response.text();
            if (response.status === 200) {
                this.setState({
                    NewCodeEditorData: data || "",
                });
            } else {
                toast.error(data);
            }
        } catch (error) {
            toast.error(error);
        }
    }

    public ResetCode = () => {
        const { CodeEditorData } = this.state;
        this.setState({
            NewCodeEditorData: CodeEditorData,
        });
    }

    public SaveNewCode = async () => {
        const { NewCodeEditorData, ProfileUUID } = this.state;
        try {
            const response: any = await UpdateXProfile("device-profiles", ProfileUUID, {
                    payload_decoder_js: NewCodeEditorData || "",
                },
            );
            const data = await response.json();
            if (response.status === 200) {
                toast.success(strings.PAYLOAD_DECODER_UPDATED_SUCCESS);
                this.setState({
                    CodeEditorData: "",
                    NewCodeEditorData: "",
                    ProfileUUID: "",
                });
                this.props.HideModal();
            } else {
                toast.error(data);
            }
        } catch (error) {
            toast.error(error);
        }
    }

    public render() {
        const { NewCodeEditorData } = this.state;
        return (
            <Modal
                show={this.props.ShowModal}
                onHide={this.props.HideModal}
                bsSize="lg"
                className="ReactModal PayloadCodeEditor"
                backdrop="static"
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>
                        {strings.DEVICE_PROFILES_EDIT_PAYLOAD_DECODER}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="body_padding">
                    <Row>
                        <Col sm={12}>
                            {!this.props.ReadOnly && (
                            <Button
                                className="React_new_style_btn_reset"
                                onClick={this.ResetCode}
                            >
                                {strings.UNDO}
                            </Button>)}

                            <span> </span>

                            {!this.props.ReadOnly && (
                            <Button
                                className="React_new_style_btn_reset"
                                onClick={this.GetPayloadDecoderTemplate}
                            >
                                {strings.DEVICE_PROFILES_TABLE_ACTION_EDIT_PAYLOAD_DECODER_TEMPLATE}
                            </Button>)}


                        </Col>
                        <Col sm={12}>
                            <AceEditor
                                mode="javascript"
                                theme="textmate"
                                name="code"
                                wrapEnabled={true}
                                width={"100%"}
                                height={"calc(70vh - 60px)"}
                                value={NewCodeEditorData}
                                onChange={this.onChange}
                                editorProps={{
                                    $blockScrolling: Infinity,
                                }}
                                readOnly={this.props.ReadOnly}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <div className="pull-right">
                        {!this.props.ReadOnly && (
                        <Button
                            className="React_new_style_btn_ok"
                            onClick={this.SaveNewCode}
                        >
                            {strings.SAVE}
                        </Button>)}
                        <Button
                            onClick={this.props.HideModal}
                            className="React_new_style_btn_cancel"
                        >
                            {this.props.ReadOnly ? strings.CLOSE : strings.CANCEL}
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}
