(function (angular) {
    var app = angular.module('dassUiModule');
    app.factory('GroupService', GroupServiceFn);

    GroupServiceFn.$inject = ['$http', "$q", "$filter"];

    const headersJson = { headers: headerContentTypeJson }
    const headersNoJson = { headers: headerNoContentType }


    function GroupServiceFn($http, $q, $filter) {
        const toHex32 = $filter('toHex32');

        function processGroup(group) {
            group.devaddr = toHex32(group.devaddr);
            return group;
        }

        // List all groups
        function getGroupsImpl() {
            var defer = $q.defer();
            $http.get('/uiapi/rest/groups?all=true', headersNoJson).then(
                function (response) {
                    var groups = response.data.map(processGroup);
                    defer.resolve(groups);
                },
                function (response) {
                    defer.reject(response);
                }
            );
            return defer.promise;
        }

        // Get individual group data
        function getGroupImpl(groupid) {
            var defer = $q.defer();
            $http.get('/uiapi/rest/groups/' + groupid, headersNoJson).then(
                function (response) {
                    defer.resolve(processGroup(response.data));
                },
                function (response) {
                    defer.reject(response);
                }
            );
            return defer.promise;
        }

        // Sort a list of groups
        function sortGroupsImpl(groups, column = 'groupid', reverse = false) {
            return sortByColumn(groups, column, reverse);
        }

        // Delete a group
        function deleteGroupImpl(groupid) {
            return $http.delete('/uiapi/rest/groups/' + groupid, headersNoJson)
        }

        // Delete multiple groups
        function deleteGroupsImpl(groups) {
            var promises = groups.map(group => deleteGroupImpl(group.groupid));
            return Promise.all(promises);
        }

        // Add a group
        function addGroupImpl(group) {
            return $http.post('/uiapi/rest/groups', group, headersJson);
        }

        // Update a group
        function updateGroupImpl(group) {
            return $http.put(`/uiapi/rest/groups/${group.groupid}`, group, headersJson);
        }

        // Send payload data to a group of devices
        function sendMulticastImpl(group, data, port, fcnt) {
            let url = `/uiapi/rest/multicast/${group.groupid}?port=${port}&data_format=base64`

            if (fcnt != null) {
                url += `&fcnt=${fcnt}`;
            }
            return $http.post(url, btoa(data), headersNoJson);
        }

        function removeAppImpl(group, application) {
            return updateGroupImpl({
                groupid: group.groupid,
                applications: `-${application.accountid}`
            });
        }

        function addAppImpl(group, application) {
            return updateGroupImpl({
                groupid: group.groupid,
                applications: `+${application.accountid}`
            });
        }

        function removeAppsImpl(group, applications) {
            const appString = applications.map(app => "-" + app.accountid).join(",");
            return updateGroupImpl({
                groupid: group.groupid,
                applications: appString
            });
        }

        function addAppsImpl(group, applications) {
            const appString = applications.map(app => "+" + app.accountid).join(",");
            return updateGroupImpl({
                groupid: group.groupid,
                applications: appString
            });
        }

        function addGroupsToAppsImpl(groups, apps) {
            var individualPromises = groups.map(
                group => addAppsImpl(group, apps)
            );

            return Promise.all(individualPromises);
        }

        function removeGroupsFromAppsImpl(groups, apps) {
            var individualPromises = groups.map(
                group => removeAppsImpl(group, apps)
            );

            return Promise.all(individualPromises);
        }

        return {
            getGroups: getGroupsImpl,
            getGroup: getGroupImpl,
            addGroup: addGroupImpl,
            sendMulticast: sendMulticastImpl,
            updateGroup: updateGroupImpl,
            deleteGroup: deleteGroupImpl,
            deleteGroups: deleteGroupsImpl,
            sortGroups: sortGroupsImpl,
            addApp: addAppImpl,
            removeApp: removeAppImpl,
            addApps: addAppsImpl,
            removeApps: removeAppsImpl,
            addGroupsToApps: addGroupsToAppsImpl,
            removeGroupsFromApps:removeGroupsFromAppsImpl
        }
    }
})(angular);
