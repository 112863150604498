import './dassui_app';
import './services';
import './controllers';
import './localization';
import './components';
import './filters';
import './app_react/src/';

import { getDassInfo } from "./app_react/src/services/BasicDassQueries";
declare const constants;

let DassInfoInterval: any = null;
let IntervalValue = 60 * 1000;
let StoredInfo;
let DassInfo;

if (typeof(Storage) !== "undefined") {
    StoredInfo = sessionStorage.getItem("dass_info");
    DassInfo = StoredInfo ? JSON.parse(StoredInfo) : null;
    if (StoredInfo) {
        if (constants) {
            constants.instance_title = DassInfo.instance_name || constants.instance_title;
        }
        const InstanceHolder: any = document.querySelector(".instance_holder");
        if (InstanceHolder && DassInfo.hasOwnProperty("instance_name")) {
            InstanceHolder.innerText = DassInfo.instance_name;
        }
        ChangeStyle(DassInfo.variant);
        IntervalValue = 5 * 60 * 1000;
        SetDassInfoInterval();
    } else {
        DassInfoAPI();
    }
}

function ChangeStyle(variant) {
    const HaveNewStyle: any = document.querySelector("link#dassui-enterprise");
    if (variant) {
        if (!HaveNewStyle && (variant === 1)) {
            SetNewStyle();
        } else if (HaveNewStyle && variant !== 1) {
            HaveNewStyle.remove();
        }
    } else if (HaveNewStyle) {
        HaveNewStyle.remove();
    }
}

async function DassInfoAPI() {
    try {
        StoredInfo = sessionStorage.getItem("dass_info");
        DassInfo = StoredInfo ? JSON.parse(StoredInfo) : null;
        const data = (await getDassInfo()).data;
        const InstanceHolder: any = document.querySelector(".instance_holder");
        IntervalValue = 5 * 60 * 1000;
        if (!DassInfoInterval) {
            if (!StoredInfo && data.variant && (data.variant === 1)) {
                SetNewStyle();
            }
            SetDassInfoInterval();
            constants.instance_title = data.instance_name || constants.instance_title;
            if (InstanceHolder) {
                InstanceHolder.innerText = data.instance_name || constants.instance_title;
            }
        } else if (DassInfoInterval && StoredInfo && (IntervalValue !== 10 * 1000)) {
            constants.instance_title = data.instance_name || constants.instance_title;
            if (InstanceHolder) {
                InstanceHolder.innerText = data.instance_name || constants.instance_title;
            }
            if (data.hasOwnProperty("variant") && (data.variant !== DassInfo.variant)) {
                ChangeStyle(data.variant);
            }
        } else if (IntervalValue === 10 * 1000) {
            SetDassInfoInterval();
        }
        sessionStorage.setItem("dass_info", JSON.stringify({
            instance_name: data.instance_name || constants.instance_title,
            variant: data.variant || null,
        }));
    } catch (error) {
        console.log(error);
        if (!DassInfoInterval) {
            SetDassInfoInterval();
        }
    }
}

function SetNewStyle() {
    const greenStyle = "/theme/styles/dassui-enterprise.css";
    if (greenStyle) {
        const head = document.getElementsByTagName('head')[0];
        const link = document.createElement('link');
        link.id = "dassui-enterprise";
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = greenStyle;
        head.appendChild(link);
    }
}

function SetDassInfoInterval() {
    if (DassInfoInterval) {
        clearInterval(DassInfoInterval);
    }
    DassInfoInterval = setInterval(() => {
        DassInfoAPI();
    }, IntervalValue);
}
