var app = angular.module('dassUiModule');

function GroupsListController(DeviceService, AppService, UserService, GroupService, MessageService, ToastService, PayloadService, DataService, $filter, $uibModal, $scope, $timeout, MulticastService) {
    const $translate = $filter("translate");
    const formatDeveui = $filter("formatDeveui");
    const statusSymbol = $filter("statusSymbol");
    const statusTooltip = $filter("statusTooltip");
    const elipsis = $filter("elipsis");
    const dateString = $filter('dateString');

    let vm = this;
    vm.devices = [];
    vm.apps = [];
    vm.scanning = 0;
    vm.numberOfGroups = 0;
    vm.loading = true;
    vm.loadingData = true;

    vm.constants = constants;
    vm.workingCount = 0;
    vm.editMode = true;

    vm.textFilterTimeout = null;
    vm.ping_slot_periodicity = null;

    vm.currentPage = 1;
    vm.itemsPerPage = 25;
    vm.user = null;
    vm.deviceSortReverse = true;
    vm.deviceSortColumn = "last_reception";
    vm.currentDevice = null;

    vm.dataType = '/uiapi/rest/groups';
    vm.editMode = true;
    vm.addMode = true;
    vm.resetGroup = function () {
        vm.group = {
            groupid: "",
            title: "",
            comment: ""
        };
    }

	vm.progressCb = (ctrl, done) => {
        $scope.child       = ctrl;
        vm.loadingData     = !done;
        vm.scanning        = done ? 0 : ctrl.scanned;
        vm.numberOfGroups = ctrl.pageData && ctrl.pageData.total || 0;
	}

    vm.edit = (group) => {
        vm.resetGroup();
        vm.editMode = true;
        vm.addMode = true;
        vm.group = group;

        // TODO: Figure out a better way to do this ?
        $(".modal").animate({ scrollTop: 0 }, "slow");
    }

    vm.delete = (group) => {
        MessageService.showMessage({
            title: $translate('MSG_DELETE_GROUPS_TITLE'),
            body: $translate('MSG_DELETE_GROUPS_BODY_SINGLE', {
                groupid: group.groupid
            })
        }).then(() => {
            GroupService.deleteGroup(group.groupid).then(() => {
                ToastService.showMessage($translate('MSG_DELETE_GROUP_SUCCESS', group), "success")
            })
                .catch(() => {
                    ToastService.showMessage($translate('MSG_DELETE_GROUP_FAIL', group), "error")
                });
            vm.refreshGroups();
        });
        vm.refreshGroups();
    }

    vm.showAddGroupDialog = () => {
        vm.editMode = false;
        vm.addMode = true;
        console.log("showAddGroupDialog");
        
        const modalInstance = $uibModal.open({
            backdrop: "static",
            animation: true,
            templateUrl: 'addGroupModal.html',
            controller: 'GroupAddModalController',
            size: "lg",
            resolve: {
                items: () => {
                    return {
                        constants: vm.constants,
                        owner: vm.user,
                        editMode: vm.editMode,
                        addMode: vm.addMode
                    }
                }
            }
        });

        modalInstance.result.then(() => {
            vm.refreshGroups();
        }, () => {
            vm.refreshGroups();
        });
    };

    vm.showEditGroupDialog = (group) => {
        vm.editMode = true;
        vm.addMode = false;
        GroupService.getGroup(group.groupid).then((gro) => {
            vm.ping_slot_periodicity = gro.ping_slot_periodicity;
            vm.resetGroup();
            
            group.ping_slot_periodicity = vm.ping_slot_periodicity;
            if(!group.ping_slot_periodicity){
                if(group.ping_slot_periodicity != 0){
                    group.ping_slot_periodicity = '';
                    console.log('group.ping_slot_periodicity 21',group.ping_slot_periodicity);
                }
                group.ping_slot_periodicity = group.ping_slot_periodicity.toString();
            }
            else{
                group.ping_slot_periodicity = group.ping_slot_periodicity.toString();
            }
            if(group.devaddr){
                group.devaddr = group.devaddr.toString(16);
            } if (!group.devaddr){
                group.devaddr = "";
            } if (!group.nwkskey){
                group.nwkskey = "";
            }
            const modalInstance = $uibModal.open({
                backdrop: "static",
                animation: true,
                templateUrl: 'addGroupModal.html',
                controller: 'GroupAddModalController',
                size: "lg",
                resolve: {
                    items: () => {
                        return {
                            constants: vm.constants,
                            owner: vm.user,
                            group: group,
                            editMode: vm.editMode,
                            addMode: vm.addMode
                        }
                    }
                }
            });

            modalInstance.result.then((group) => {
                vm.refreshGroups();
            }, () => {
                vm.refreshGroups();
            });
        })
        .catch(() => {
                console.log('error');
            });
        };

    vm.deleteSelected = () => {
        MessageService.showMessage({
            title: $translate('MSG_DELETE_GROUPS_TITLE'),
            body: $translate('MSG_DELETE_GROUPS_BODY', {
                count: vm.selected.length
            })
        }).then(() => {
            GroupService.deleteGroups(vm.selected).then(() => {
                ToastService.showMessage($translate('MSG_DELETE_GROUPS_SUCCESS', vm.selected), "success")
            })
                .catch(() => {
                    ToastService.showMessage($translate('MSG_DELETE_GROUPS_FAIL', vm.selected), "error")
                });
            vm.refreshGroups();
        });
    };


    vm.showSendDataDialog = (group) => {
        console.log($uibModal, group);
        const modalInstance = $uibModal.open({
            backdrop: "static",
            animation: true,
            templateUrl: 'sendDataModalContent.html',
            controller: 'SendDataModalCtrl',
            size: "",
            resolve: {
                items: () => {
                    return {
                        isMulticast: true
                    };
                }
            }
        });

        modalInstance.result.then((payload) => {
            console.log("Sending payload to group ", group, payload);
            GroupService.sendMulticast(group, payload.data, payload.port, payload.fcnt).then((result) => {
                ToastService.showMessage($translate('MSG_MULTICAST_SUCCESS', vm.group), "success", "send")
            }).catch((err) => {
                ToastService.showMessage($translate(err.data), "error", "remove");
                console.log("Error sending multicast", err);
            });
        });
    };


    vm.refreshGroups = () => {
        if (!vm.reloadData) return;
        var reloadPromise = vm.reloadData();
        if (reloadPromise) {
            vm.workingCount += 1;
            reloadPromise.then(() => {
                vm.workingCount -= 1;
            }).catch(err => {
                vm.workingCount -= 1;
            });
        }
    };

    vm.loadApps = () => {
        vm.workingCount += 1;

        return AppService.getApps().then(
            (apps) => {

                const ids = [];
                for (const app of apps) {  ids.push( app.accountid); }
                const sortedIds = ids.sort();
                for (const aid of sortedIds) { vm.apps.push( aid ); }

                vm.workingCount -= 1;
            },
            (err) => {
                console.log("Error loading apps", err);
                vm.workingCount -= 1;
            }
        );
    };

    vm.showSelectAppDialog = (group) => {
        let groupsToChange = vm.selected;
        if (group) {
            groupsToChange = [group];
        }

        const modalInstance = $uibModal.open({
            backdrop: "static",
            animation: true,
            templateUrl: 'selectAppsModal.html',
            controller: 'SelectAppsModalCtrl',
            resolve: {
                items: () => {
                    return {}
                }
            },
            size: "lg"
        });

        modalInstance.result.then((data) => {
            const selectedApps = data.apps;
            const deleteMode = data.action === "remove";
            if (deleteMode) {
                GroupService.removeGroupsFromApps(groupsToChange, selectedApps).then(() => {
                    ToastService.showMessage($translate('MSG_REMOVE_APPS_SUCCESS_BODY'), "success", "ok");
                    vm.refreshGroups();
                }).then(vm.deselectAll).catch((err) => {
                    ToastService.showMessage($translate('MSG_REMOVE_APPS_FAIL_BODY', "error", "remove"));
                });
            } else {
                GroupService.addGroupsToApps(groupsToChange, selectedApps).then(() => {
                    ToastService.showMessage($translate('MSG_ASSIGN_APPS_SUCCESS_BODY'), "success", "ok");
                    vm.refreshGroups();
                }).then(vm.deselectAll).catch((err) => {
                    ToastService.showMessage($translate('MSG_ASSIGN_APPS_FAIL_BODY', "error", "remove"));
                });
            }

        }).catch((result) => {
            console.log(result);
        });
    };

    vm.initDataTable = () => {
        vm.actions = [
            {
                type: "action",
                text: "SEND_MULTICAST",
                icon: "far fa-paper-plane fa-fw",
                action: (group) => vm.showSendDataDialog(group)
            }, 
            {
                type: "separator"
            }, 
            {
                type: "action",
                text: "EDIT_GROUP",
                icon: "far fa-edit fa-fw",
                action: (group) => vm.showEditGroupDialog(group)
            }, 
            {
                type: "action",
                text: "MY_DEVICES_ACTION_DATA",
                icon: "fas fa-database fa-fw",
                action: (group) => vm.setCurrentGroup(group.groupid, '/multicast_activity.html')
            },
            {
                type: "action",
                text: "APP_ASSIGNMENT",
                icon: "far fa-edit fa-fw",
                action: vm.showSelectAppDialog
            }, 
            {
                type: "action",
                text: "DELETE_GROUP",
                icon: "far fa-trash-alt fa-fw",
                action: (group) => vm.delete(group)
            }
        ];


        vm.bulkActions = [{
            type: "action",
            text: "DELETE_SELECTED_GROUPS",
            icon: "far fa-trash-alt fa-fw",
            action: vm.deleteSelected
        }, {
            type: "action",
            text: "APP_ASSIGNMENT",
            icon: "far fa-edit fa-fw",
            action: vm.showSelectAppDialog
        }];


        vm.columns = [{
            key: "groupid",
            type: "text",
            title: "GROUP_ID",
            filterable: true,
            filterField: 'search_id',
            filterType: 'text',
            filterSearchMinLength: 1,
            filterParams: {
                mapper: (x) => x || undefined
            }
        }, {
            key: "title",
            title: "GROUP_TITLE",
            type: "text",
            filterable: true,
            filterField: 'search_title',
            filterType: 'text',
            filterSearchMinLength: 1,
            filterParams: {
                mapper: (x) => x || undefined
            }
        }, {
            key: "comment",
            title: "COMMENT",
            type: "text",
        },{
            key: "applications",
            title: "MY_DEVICES_APPLICATIONS",
            type: "text_with_tooltip",
            tooltip_props: {
                tooltipClass: "increase-popover-width",
                appendToBody: true,
                trigger: "mouseenter"
            },
            render_tooltip: x => x.applications.replace(/,/g, ', '),
            render: x => elipsis(x.applications, 20),
            filterable: true,
            filterField: "application",
            filterType: "multiselect",
            filterParams: {
                data: vm.apps,
                mapper: (x) => x,
                selectSettings: {
//                    displayProp: "accountid",
//                    searchField: "accountid",
                    dynamicTitle: false,
                    enableSearch: true,
                    selectionLimit: 1,
                    scrollableHeight: '300px',
                    scrollable: true,
                    template: '{{option}}',
                    smartButtonTextConverter: (skip, option) => { return option; },
                },
                translations: {
                    dynamicButtonTextSuffix: $translate("APPS_SELECTED_SUFFIX"),
                    buttonDefaultText: $translate("APPS_SELECT_BUTTON"),
                    checkAll: $translate("APPS_SELECT_ALL"),
                    uncheckAll: $translate("APPS_SELECT_NONE"),
                }
            }
        }];
//        vm.reloadData();

        // this is needed for: filtering by applications
        Promise.all([vm.loadApps()]).then(() => {
            // vm.refreshGroups();
        });
    };

    vm.setCurrentGroup = (groupId, url) => {
        if (url != null) {
            window.location.href = url + '#' + groupId;
        }
    };

    vm.tableSelectionChanged = (dev) => {
        console.log("Selection changed ", dev);

        vm.selectedDevices = dev;
        vm.selected = dev;
    };

    vm.reloadData = () => {
        console.log("This should get overwritten");
    };

    vm.$onInit = () => {
        vm.initDataTable();
    }

};

app.controller("GroupsListController", ["DeviceService", "AppService", "UserService", "GroupService", "MessageService", "ToastService", "PayloadService", "DataService", "$filter", "$uibModal", "$scope", "$timeout", "MulticastService", GroupsListController]);
