(function (angular) {
    const app = angular.module('dassUiModule');
    app.factory('DataService', DataServiceFn);

    DataServiceFn.$inject = ['$http', "$httpParamSerializer", "$q", "$filter"];

    function DataServiceFn($http, $httpParamSerializer, $q, $filter) {

        function getDataImpl(sourceType, params, oboeCallback) {

            const args  = $httpParamSerializer(params);
            const defer = $q.defer();
            let statusCode;
            let savedHeaders = {};

            const oboeObj = oboe({
                url: sourceType + (args ? (sourceType.indexOf("?") < 0 ? "?" : "&") + args : ""),
                method: "GET"
            });
            oboeObj.on("start", (status, headers) => {
                statusCode = status;
                savedHeaders = headers;
            });
            oboeObj.on("done", (data) => {
                defer.resolve(data);
            });
            oboeObj.on("fail", (fail) => {
                if (fail.statusCode != null) {
                    defer.reject(Object.assign({ headers: savedHeaders }, fail));
                }
            });
            if (oboeCallback) {
                oboeCallback(oboeObj);
            }
            return defer.promise;
        }

        return {
            getData: getDataImpl,
        }
    }
})(angular);