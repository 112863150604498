import * as React from "react";
import * as ReactDOM from "react-dom";
import { IUser } from "../dassTypes";
import { EnterpriseGatewayConfig } from "../components/Gateways/EnterpriseGatewayConfig";

export class EnterpriseGatewayConfigModal {

  public showEnterpriseConfigModal(id: string, user: IUser) {
    const modalRoot = document.getElementById("reactModalRootEGateway") as HTMLElement;
    ReactDOM.unmountComponentAtNode(modalRoot);
    ReactDOM.render(
      <EnterpriseGatewayConfig GatewayId={id} User={user} />,
      modalRoot,
    );
  }

}
