import * as React from "react";
import {
    Modal,
    Button,
    Row,
    Col,
    FormGroup,
    FormControl
} from "react-bootstrap";
import * as moment from "moment";
import { toast } from "./../../utils/Toaster";
import { strings } from "./../../services/Localization";

// Services
import { GetLicense, UpdateLicense } from "./../../services/UserSettings";

// Controllers
import { LocaleDate } from "./../../controllers/GlobalController";

export default class LicenseModal extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            License: {
                license: "",
                expiry_date: "",
                licensee: ""
            }
        }
    }

    componentDidMount() {
        this.GetLicense();
    }

    GetLicense = async () => {
        try {
            const response: any = await GetLicense();
            if (response.status === 200) {
                const data = await response.json();
                this.setState({
                    License: data
                });
            }
        } catch (err) {
            console.log(err);
        }
    }

    SubmitNewLicense = async () => {
        try {
            const response: any = await UpdateLicense({license: this.state.License.license});
            const data = await response.text();
            if (response.status === 200) {
                toast.success(strings.PRODUCT_LICENSE_SUCCESS_UPDATE);
                this.props.ToggleModal();
            } else {
                toast.error(data);
            }
        } catch (error) {
            console.log(error);
            toast.error(strings.PRODUCT_LICENSE_ERROR_WHILE_UPDATE);
        }
    }

    LicenseInputHandler = (e) => {
        this.setState({
            License: {...this.state.License, license: e.target.value}
        });
    }

    render() {
        const {
            ShowModal,
            ToggleModal
        } = this.props;
        const { License } = this.state;
        return (
            <Modal
                show={ShowModal}
                onHide={ToggleModal}
                className="ReactModal product_license"
                backdrop="static"
                bsSize="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{strings.PRODUCT_LICENSE_TITLE}</Modal.Title>
                </Modal.Header>
                <Modal.Body
                    className="all_padding_0"
                >
                    <Row className="licensed_to">
                        <Col sm={2} xs={4}>
                            {strings.PRODUCT_LICENSE_LICENSED_TO}
                        </Col>
                        <Col sm={10} xs={8}>
                            {License.licensee}
                        </Col>
                    </Row>
                    <Row className="expires_on">
                        <Col sm={2} xs={4}>
                            {strings.PRODUCT_LICENSE_EXPIRES_ON}
                        </Col>
                        <Col sm={10} xs={6}>
                            {moment(License.expiry_date).isValid() ?
                                moment(License.expiry_date).format(LocaleDate().DateFormat) :
                                License.expiry_date}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <FormGroup controlId="ProductLicense">
                                <FormControl
                                    componentClass="textarea"
                                    value={License.license}
                                    onChange={this.LicenseInputHandler}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer
                    className="new_style_modal_footer"
                >
                    <Button
                        className="new_style_btn_ok"
                        onClick={this.SubmitNewLicense}
                    >
                        {strings.UPDATE}
                    </Button>
                    <Button
                        className="new_style_btn_cancel"
                        onClick={ToggleModal}
                    >
                        {strings.CANCEL}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
