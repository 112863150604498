
declare const headerContentTypeJson;
declare const headerNoContentType;

export const LoadAllGateways = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch("/uiapi/rest/gateways", {
                credentials: "same-origin",
                headers: headerNoContentType,
                method: "GET",
            });

            if (response.status == 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const LoadAllDevices = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch("/uiapi/rest/nodes", {
                credentials: "same-origin",
                headers: headerNoContentType,
                method: "GET",
            });

            if (response.status == 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const UpdateGateway = (id, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch(`/uiapi/rest/gateways/${id}`, {
                body: JSON.stringify(data),
                credentials: "same-origin",
                headers: headerContentTypeJson,
                method: "PUT",
            });

            if (response.status == 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const UpdateDevice = (id, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch(`/uiapi/rest/nodes/${id}`, {
                body: JSON.stringify(data),
                credentials: "same-origin",
                headers: headerContentTypeJson,
                method: "PUT",
            });

            if (response.status == 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const FilterDevices = (filterBy, filterValue) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch(`/uiapi/rest/nodes?${filterBy}=${filterValue}`, {
                credentials: "same-origin",
                headers: headerNoContentType,
                method: "GET"
            });

            if (response.status == 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
}
