(function (angular) {
    var app = angular.module('dassUiModule');
    app.factory('MessageService', MessageServiceFn);

    MessageServiceFn.$inject = ['$log', "$q", "$uibModal"];

    function MessageServiceFn($log, $q, $uibModal) {
        function showMessageImpl(msg, callback) {
            var defer = $q.defer();

            var modalInstance = $uibModal.open({
                backdrop: "static",
                animation: true,
                templateUrl: 'messageModalContent.html',
                controller: 'MessageModalCtrl',
                size: "sm",
                msg: msg,
                resolve: {
                    items: function () {
                        return msg;
                    }
                }
            });

            modalInstance.result.then(function (ok) {
                defer.resolve(ok);
            }, function () {
                $log.info('Modal dismissed at: ' + new Date());
            });

            return defer.promise;
        }

        return {
            showMessage: showMessageImpl
        }
    }
})(angular);
