
declare const headerContentTypeJson;
declare const headerNoContentType;

export const ActiveConnections = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch("/uiapi/rest/pushmode/connections", {
                credentials: "same-origin",
                headers: headerNoContentType,
                method: "GET",
            });

            if (response.status == 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};
