import * as React from "react";

// Note we use XRegExp here instead of the native javascript regular expression
// That is because some browers does not correctly handle unicode characters, particularely
// the \p{L} general letter (which include unicodes). Using this \p{L} characther in the
// regular expressions is so convienient that it is worth to include the external XRegExp
// library just for that.
import * as XRegExp from "xregexp";
import * as base from "xregexp/lib/addons/unicode-base";
import * as categories from "xregexp/lib/addons/unicode-categories";

base(XRegExp);
categories(XRegExp);

import {
    FormControl,
} from "react-bootstrap";
import { strings } from "./../services/Localization";

interface ITableFilterRegexProps {
    FilterHandler: any;
    customFilterParameters: {
        length: number;
        placeholder: string;
        regex: RegExp | string;
        replaceSpace: string;
    };
};

interface ITableFilterRegexState {
    value: string;
    filterFieldValidity: string;
    searchCounter: number;
    inputColor: string;
}


export class TableFilterRegex extends React.Component<ITableFilterRegexProps, ITableFilterRegexState> {

	constructor(props) {
        super(props);
        this.state = {
            value: "",
            filterFieldValidity: "hide-input-tooltip",
            searchCounter: 0,
            inputColor: "",
        };
    }

	public handleChange = (e) => {
        let searchCounterTemp = this.state.searchCounter + 1;

        this.setState({
            value: e.target.value,
            searchCounter: searchCounterTemp
        });
    }

    public handleKeyPress = (e) => {
        if (this.state.searchCounter > 0 &&  e.key === "Enter" &&
            ((this.state.value.length === 0) ||
             (this.state.value.length > 0 &&
                XRegExp(this.props.customFilterParameters.regex as string).test(this.state.value)))) {

            this.setState({
                searchCounter: 0
            });

            // Do the replace space if needed. This is done to avoid that the somehow builtin (where is this done?)
            // trim() feature of react will remove spaces at the beginning and end of the filter.
            // In some cases we need to keep it, so we can replace space with another values and the
            // in the calling class replace it back to sapce.
            this.props.FilterHandler(this.props.customFilterParameters.replaceSpace ?
                        this.state.value.replace(/ /g, this.props.customFilterParameters.replaceSpace ) :
                        this.state.value);
		}
    }

    public InputTooltip = (content) => {
        return (
            <span className={`search-tooltip-charachter ${this.state.filterFieldValidity}`}>
                {content}
            </span>
        );
    }

    public handleKeyPressOnFilterField = (e) => {

        const value: string = e.target.value;
        if (e.target.value === "") {
            this.setState({
                filterFieldValidity: "hide-input-tooltip",
                inputColor: ""
            });
        } else {

            if (this.props.customFilterParameters.regex) {
                if (XRegExp(this.props.customFilterParameters.regex as string).test(value)) {

                    this.setState({
                        filterFieldValidity: "hide-input-tooltip",
                        inputColor: "input-color-success"
                    });

                } else {

                    this.setState({
                        filterFieldValidity: "show-input-tooltip",
                        inputColor: "input-color-error"
                    });

                }
            }

        }
    }

    public render() {
        return (
            <div className="position-relative">

                <FormControl
                    className={`filter text-filter ${this.state.inputColor}`}
                    type="text"
                    value={this.state.value}
                    placeholder= {this.props.customFilterParameters.placeholder}
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeyPress}
                    onKeyUp={this.handleKeyPressOnFilterField}
                />

                {this.props.customFilterParameters.length > 1 ? this.InputTooltip(
					(strings.SEARCH_MIN_LENGTH_TOOLTIP_1 + this.props.customFilterParameters.length +
                     strings.SEARCH_MIN_LENGTH_TOOLTIP_4 + strings.SEARCH_MIN_LENGTH_TOOLTIP_2)) : null}

            </div>
        );
    }
}
