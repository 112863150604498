(function (angular) {
    var app = angular.module('dassUiModule');
    app.factory('BatchRegDataService', BatchRegServiceFn);

    const headersJson = { headers: headerContentTypeJson }
    const headersNoJson = { headers: headerNoContentType }


    BatchRegServiceFn.$inject = ['$http', "$q"];

    function BatchRegServiceFn($http, $q) {
        // Get individual gateway data
        // function getUserDataImpl() {
        //     var defer = $q.defer();
        //     $http.get('/whoami/').then(function (response) {
        //         defer.resolve(response.data);
        //     }, function (response) {
        //         defer.reject(response);
        //     });
        //     return defer.promise;
        // }

        // function getUserImpl() {
        //     var defer = $q.defer();

        //     getUserDataImpl().then(function (data) {
        //         defer.resolve(data.user)
        //     }).catch(function (err) {
        //         defer.reject(err);
        //     });

        //     return defer.promise;
        // }


        function loadRegDataImpl() {
            var defer = $q.defer();

            $http.get('/registration_file.json', headersNoJson).then(function (response) {
                defer.resolve(response.data);
            }, function (response) {
                defer.reject(response);
            });
            return defer.promise;
        }

        function refreshDevicesImpl(data) {
            return $http.put('/getstatus_devices', data, headersJson)
        }

        function registerDevicesImpl(data) {
            return $http.put('/register_devices', data, headersJson);
        }

        function updateDevicesImpl (data) {
            return $http.put('/update_devices', data, headersJson);
        }

        function deleteDevicesImpl (data) {
            return $http.put('/delete_devices', data, headersJson);
        }

        function clearDevicesImpl () {
            return $http.put('/clear_devices', null, headersJson);
        }

        return {
            loadRegData: loadRegDataImpl,
            refreshDevices: refreshDevicesImpl,
            registerDevices: registerDevicesImpl,
            updateDevices: updateDevicesImpl,
            deleteDevices: deleteDevicesImpl,
            clearDevices: clearDevicesImpl
        }
    }
})(angular);