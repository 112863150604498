var app = angular.module('dassUiModule');

function DataSelectController(DeviceService, UserService, ToastService, GroupService, AppService, $filter, $uibModal, $scope, items, $timeout, $uibModalInstance) {

    const $translate = $filter("translate");
    const formatDeveui = $filter("formatDeveui");
    const statusSymbol = $filter("statusSymbol");
    const statusTooltip = $filter("statusTooltip");
    const elipsis = $filter("elipsis");
    const dateString = $filter('dateString');

    let vm = $scope;
    vm.devices = [];
    vm.apps = [];
    vm.groups = [];
    vm.user = null;
    vm.constants = constants;
    vm.workingCount = 0;    

    vm.tableSelectionChanged = (items) => {
        console.log("Selection changed ", items);

        vm.selectedItems = items;
    };

    vm.submit = () => {
        $uibModalInstance.close(vm.selectedItems);
    };

    vm.cancel = () => {
        $uibModalInstance.dismiss('cancel');
    };


    vm.loadUsers = (type) => {
        vm.type = type;
        console.log("Loading users of type", type);
        UserService.getUsers(vm.type).then(
            (user) => {
                vm.users = user;
              
            }
        ).catch(err => {
            ToastService.showMessage($translate(err.data), "error")
            ToastService.showMessage($translate('MSG_LOGIN_FORWARD_FAILED'), "error")
        });
    };

    vm.reloadData = () => {
	    console.log("This function should be overwritten by the component before it gets called");
    };
    
    vm.initDataTable = () => {
        vm.title = items.title;
        vm.columns = items.columns;
        vm.actions = items.actions;
        vm.bulkActions = items.bulkActions;
        vm.dataType = items.dataType;
        vm.reloadData();
    };

    vm.initDataTable();
}

app.controller("DataSelectController", ["DeviceService", "UserService", "ToastService", "GroupService", "AppService", "$filter", "$uibModal", "$scope", "items", "$timeout", "$uibModalInstance", DataSelectController]);