import { IGatewayAlarms, IScannerStatus } from "../../src/dassTypes";

declare const headerContentTypeJson;
declare const headerNoContentType;

export async function InstallGateway(Data: any) {
    const response = await fetch("/uiapi/rest/gateways/install", {
        body: JSON.stringify(Data),
        credentials: "same-origin",
        headers: headerContentTypeJson,
        method: "POST",
    });

    if (response.status === 401) { // the status should be changed!!!
        window.location.href = '/app/signout?resignin';
    }

    return response;
}


export async function GetGatewaysModels() {
    const response = await fetch("/uiapi/rest/gateways/models", {
        credentials: "same-origin",
        headers: headerNoContentType,
        method: "GET",
    });

    if (response.status === 401) { // the status should be changed!!!
        window.location.href = '/app/signout?resignin';
    }

    return(response);
}



interface IGatewayChannel {
    frequency: number;
    min_dr: string;
    max_dr: string;
}
export interface IGatewayInfo {

    address: {
        address?: string;
        city?: string;
        zipcode?: string;
        country?: string;
    };
    altitude: number;
    antenna_gain: number;
    backhaul_cell_operator: string;
    backhaul_cell_rssi: number;
    backhaul_type: string;
    cable_loss: number;
    id: string;
    latitude: number;
    longitude: number;
    name: string;
    position_valid: boolean;
    status: string;


    // Extended info
    channels: IGatewayChannel[];
    active_alarms: Array<{
        alarm_type: number;
        description: string;
        severity?: number;  // 1-3
        start_time?: string;
    }>;

    classb_tx_power: number;
    rx1_tx_power: number;
    rx2_tx_power: number;

    last_ns_udp_keepalive_date: string;
    last_omc_udp_keepalive_date: string;
    last_rest_udp_keepalive_date: string;

    beacon_enabled: boolean;
    beacon_frequency: number;

    gateway_type: string;
    gateway_firmware_version: string;
    gateway_software_version: string;
}


export async function GetGatewayConfig(gatewayId: string): Promise<IGatewayInfo> {
    const response = await fetch("/uiapi/rest/gateways/" + gatewayId, {
        credentials: "same-origin",
        headers: headerNoContentType,
        method: "GET",
    });

    if (response.status === 401) { // the status should be changed!!!
        window.location.href = '/app/signout?resignin';
    }

    if (response.status === 200) {
        return await response.json();
    } else {
        const errorTxt = await response.text();
        throw { status: response.status, errorTxt };
    }
}



export async function GetGatewayScannerStatus(gatewayId: string): Promise<IScannerStatus> {
    const response = await fetch("/uiapi/rest/gateways/" + gatewayId + "/scanner/status", {
        credentials: "same-origin",
        headers: headerNoContentType,
        method: "GET",
    });
    if (response.status === 401) { // the status should be changed!!!
        window.location.href = '/app/signout?resignin';
    }
    if (response.status === 200) {
        return await response.json();
    } else {
        const errorTxt = await response.text();
        throw { status: response.status, errorTxt };
    }
}

export async function GetGatewayAlarms(gatewayId: string): Promise<IGatewayAlarms> {
    const response = await fetch("/uiapi/rest/gateways/" + gatewayId + "/alarms", {
        credentials: "same-origin",
        headers: headerNoContentType,
        method: "GET",
    });
    if (response.status === 401) { // the status should be changed!!!
        window.location.href = '/app/signout?resignin';
    }
    if (response.status === 200) {
        return await response.json();
    } else {
        const errorTxt = await response.text();
        throw { status: response.status, errorTxt };
    }
}



export async function StartGatewayScanner(gatewayId: string, numSweeps: number) {
    const response = await fetch("/uiapi/rest/gateways/" + gatewayId + "/scanner/start", {
        credentials: "same-origin",
        headers: headerContentTypeJson,
        body: JSON.stringify({ num_sweeps: numSweeps }),
        method: "PUT",
    });

    if (response.status === 401) { // the status should be changed!!!
        window.location.href = '/app/signout?resignin';
    }
    if (response.status === 200) {
        return await response.text();
    } else {
        const errorTxt = await response.text();
        throw { status: response.status, errorTxt };
    }
}

export async function StopGatewayScanner(gatewayId: string) {
    const response = await fetch("/uiapi/rest/gateways/" + gatewayId + "/scanner/stop", {
        credentials: "same-origin",
        headers: headerNoContentType,
        method: "PUT",
    });

    if (response.status === 401) { // the status should be changed!!!
        window.location.href = '/app/signout?resignin';
    }
    if (response.status === 200) {
        return await response.text();
    } else {
        const errorTxt = await response.text();
        throw { status: response.status, errorTxt };
    }
}
