
export interface IOboeFail {
    statusCode: number;
    body: string;
    thrown: Error;
    jsonBody: any;
}

declare const headerContentTypeJson;
declare const headerNoContentType;

// TODO: we should import this in a proper way, however as it is loaded globally to serve
// the angular stuff, it is already globally loaded, so it is just declared here.
declare function oboe(options: any);

export const GetTableRequest = (
                url: string,
                options: any,
                oboeCallback?: (oboeObj: any) => void) => {

    let query = "";
    for (const key of Object.keys(options)) {
        if (options[key] != null) {
            query += (query === "" ? "?" : "&") + key + "=" + encodeURIComponent(options[key]);
        }
    }

    const response = new Promise<any>((resolve, reject) => {
        let statusCode: number;
        let savedHeaders = {};
        const oboeObj = oboe({
            url: url + query,
            method: "GET"
        });
        oboeObj.on("done", (data) => {
            // console.log("oboe done");
            resolve({ status: statusCode, json: data });
        });
        oboeObj.on("start", (status: number, headers: any) => {
            // console.log("oboe start", status, headers);
            statusCode = status;
            savedHeaders = headers;
        });
        oboeObj.on("fail", (fail: IOboeFail) => {
            // console.log("oboe fail", fail);
            if (fail.statusCode != null) {
                reject(Object.assign({ headers: savedHeaders }, fail));
            }
        });
        if (oboeCallback) {
            oboeCallback(oboeObj);
        }
    });

    return response;
};



export const SetLogLevel = (LogLevel, TimeDuration) => {
    return new Promise(async (resolve, reject) => {
        try {

            const query = "log_level=" + LogLevel + (LogLevel !== "error" ?
                             "&duration_in_hours=" + TimeDuration : "");

            const response = await fetch("/uiapi/rest/pushmode/loglevel?" + query, {
                body: null,
                credentials: "same-origin",
                headers: headerContentTypeJson,
                method: "PUT",
            });

            if (response.status == 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const GetLogLevel = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch("/uiapi/rest/pushmode/loglevel?log_level=info", {
                credentials: "same-origin",
                headers: headerNoContentType,
                method: "GET",
            });

            if (response.status == 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};
