import * as React from "react";
import * as ReactDOM from "react-dom";
import { DefaultPushConfigModal } from "../components/Applications/DefaultPushConfigModal";
declare const angular;

export class AppDefaultPushConfigModal {

  public showDefaultPushConfigModal() {
    const addDefaultPushConfigModal = document.getElementById("addAppPushConfModalTrigger") as HTMLAnchorElement;
    const controller = angular.element(addDefaultPushConfigModal).controller();
    const modalRoot = document.getElementById("reactModalRoot") as HTMLElement;
    ReactDOM.unmountComponentAtNode(modalRoot);
    ReactDOM.render(
      <DefaultPushConfigModal Device={controller} />,
      modalRoot,
    );
  }

}
