var app = angular.module('dassUiModule');

function AppController(AppService, UserService, ToastService, MessageService, GroupService, $timeout, $filter, $uibModal, $log, $scope) {
    const $translate = $filter("translate");
    const formatDeveui = $filter("formatDeveui");
    const elipsis = $filter("elipsis");

    let vm = this;
    vm.sortedApps = [];
    vm.selectedApps = [];
    vm.selectAll = false;
    vm.globalAppValid = false;
    vm.numberOfApps = 0;
    vm.globalPushConfig = {
        host: '',
        path_prefix: '',
        username: '',
        cleartext_password: '',
        enabled: false
    };

    vm.workingCount = 0;
    vm.editGlobalPushConfig = false;
    vm.autocomplete = (constants.disable_autocomplete_for_credentials === true) ? "off" : "on";
    vm.globalPushSave = () => {
        console.log("Save");
        vm.editGlobalPushConfig = false;

        AppService.saveGlobalPushConfig(vm.globalPushConfig).then(r => {
            ToastService.showMessage($translate('SAVE_GLOBAL_PUSH_CFG_SUCCESS'), 'success');
            vm.loadGlobalPushConfig();
        }).catch(err => {
            console.log("Error saving global push config", err);
            ToastService.showMessage($translate('SAVE_GLOBAL_PUSH_CFG_FAIL'), 'error');
        });
    }

    vm.globalPushStart = () => {
        AppService.getGlobalPush().then(pushConfig => {
            vm.globalPushConfig = pushConfig;
            
            AppService.startGlobalPush(vm.globalPushConfig).then(r => {
                ToastService.showMessage($translate('START_GLOBAL_PUSH_SUCCESS'), 'success');
                vm.loadGlobalPushConfig();
            }).catch(err => {
                console.log("Error starting global push", err);
                ToastService.showMessage(err.data, 'error');
            });

        }).catch(err => {
            console.log("Error loading global push config", err);
            vm.workingCount -= 1;
        });
    }

    vm.globalPushStop = () => {
        AppService.stopGlobalPush().then(r => {
            ToastService.showMessage($translate('STOP_GLOBAL_PUSH_SUCCESS'), 'success');
            vm.loadGlobalPushConfig();
        }).catch(err => {
            console.log("Error stopping global push", err);
            ToastService.showMessage($translate('STOP_GLOBAL_PUSH_FAIL'), 'error');
        });
    }

    vm.globalPushCancel = () => {
        console.log("Cancel");
        vm.editGlobalPushConfig = false;
        vm.loadGlobalPushConfig();
    }

    vm.toggleAppsSelected = () => {
        vm.setAllAppsSelected(vm.selectAll);
        vm.appSelected();
    }

    vm.setAllAppsSelected = (selected) => {
        vm.sortedApps.forEach((app, i) => {
            app._marked = vm.selectAll;
        });
    }

    vm.appSelected = (app) => {
        vm.selectedApps = vm.sortedApps.filter(app => app._marked);
    }

    vm.loadApps = () => {
        vm.loadGlobalPushConfig();
        if (vm.reloadData) {
            vm.reloadData();
        }
    };

    $scope.$on("tablePaginationLoaded", function(event, totalNumberOfItems) {
        vm.numberOfApps = totalNumberOfItems;
    });

    vm.loadGlobalPushConfig = () => {
        vm.workingCount += 1;
        AppService.getGlobalPush().then(pushConfig => {
            vm.globalPushConfig = pushConfig;
            vm.workingCount -= 1;
            console.log("Global push ocnfig", vm.globalPushConfig)
        }).catch(err => {
            console.log("Error loading global push config", err);
            vm.workingCount -= 1;
        });
    }

    vm.addApp = () => {
        vm.loadUserData()
        .then(() => {
            vm.addAppModal();
        })
        .catch((error) => {
            console.log(error);
            vm.addAppModal();
        });
    };

    vm.addAppModal = () => {
        const modalInstance = $uibModal.open({
            backdrop: "static",
            animation: vm.animationsEnabled,
            templateUrl: 'manageAppModal.html',
            controller: 'ManageAppModal',
            size: "",
            resolve: {
                items: () => {
                    return {
                        currentUser: vm.user
                    };
                }
            }
        });

        modalInstance.result.then((app) => {
            console.log("Creating app ", app);
            vm.loadApps();
        }, () => {
            $log.info('Modal dismissed at: ' + new Date());
        });
    }

    vm.editApp = (application) => {
        AppService.getApp(application.accountid)
        .then((app) => {
            if (app.status === 200) {
                app = app.data;
            } else {
                app = application;
            }
            vm.editAppModal(app);
        })
        .catch((error) => {
            console.log(error);
            vm.editAppModal(application);
        });
    };

    vm.editAppModal = (app) => {
        const modalInstance = $uibModal.open({
            backdrop: "static",
            animation: vm.animationsEnabled,
            templateUrl: 'manageAppModal.html',
            controller: 'ManageAppModal',
            size: "",
            resolve: {
                items: () => {
                    return {
                        currentUser: vm.user,
                        app: app,
                        editMode: true
                    };
                }
            }
        });

        modalInstance.result.then((app) => {
            console.log("editing app ", app);
            vm.loadApps();
        }, () => {
            $log.info('Modal dismissed at: ' + new Date());
        });
    }

    vm.deleteApp = (app) => {
        MessageService.showMessage({
            title: $translate('MSG_DELETE_APP_TITLE'),
            body: $translate('MSG_DELETE_APP_BODY', app)
        }).then((ok) => {
            if (ok == "ok") {
                console.log("sending delete app", app);
                AppService.deleteApp(app.accountid).then((response) => {
                    ToastService.showMessage($translate('MSG_DELETE_APP_SUCCESS_BODY'), "success");
                    vm.loadApps();
                }).catch((response) => {
                    ToastService.showMessage($translate('MSG_DELETE_APP_FAIL_BODY'), "error");
                });
            }
        });
    };

    vm.startPush = (app) => {
        console.log("start")
        AppService.pushModeStart(app).then((response) => {
            ToastService.showMessage($translate('PUSH_MODE_START_SUCCESS', app), "success");
            vm.loadApps();
        }).catch((response) => {
            let message = "";
            switch (response.status) {
                case 400:
                    if (response.data.match(/^Can't start push, no valid host set$/)) {
                        message = "PUSH_MODE_START_FAIL_INVALID_HOST";
                    } else if (response.data.match(/^Can't start push, no valid port set$/)) {
                        message = "PUSH_MODE_START_FAIL_INVALID_PORT";
                    } else {
                        message = response.data;
                    }
                    break;
            
                default:
                    message = "PUSH_MODE_START_FAIL";
                    break;
            }
            ToastService.showMessage($translate(message, app), "error");
        });
    }

    vm.openDefaultPushConfigModal = () => {

        // calling the modal functionality written in React
        window.appDefaultPushConfig.showDefaultPushConfigModal({});
    }

    vm.stopPush = (app) => {
        console.log("stop")
        AppService.pushModeStop(app).then((response) => {
            ToastService.showMessage($translate('PUSH_MODE_STOP_SUCCESS', app), "success");
            vm.loadApps();
        }).catch((response) => {
            ToastService.showMessage($translate('PUSH_MODE_STOP_FAIL', app), "error");
        });
    } 
    
    vm.batchStartPush = () => {
        console.log("start")
        AppService.batchStartPush(vm.selectedApps).then((response) => {
            ToastService.showMessage($translate('BATCH_PUSH_MODE_START_SUCCESS', vm.selectedApps), "success");
            vm.loadApps();
        }).catch((response) => {
            ToastService.showMessage($translate('BATCH_PUSH_MODE_START_FAIL', vm.selectedApps), "error");
        });
    }

    vm.batchStopPush = () => {
        console.log("stop")
        AppService.batchStopPush(vm.selectedApps).then((response) => {
            ToastService.showMessage($translate('BATCH_PUSH_MODE_STOP_SUCCESS', vm.selectedApps), "success");
            vm.loadApps();
        }).catch((response) => {
            ToastService.showMessage($translate('BATCH_PUSH_MODE_STOP_FAIL', vm.selectedApps), "error");
        });
    }

    vm.batchDelete = () => {
        console.log("delete")
		MessageService.showMessage({
			title: $translate('MSG_DELETE_APPS_TITLE'),
			body: $translate('MSG_DELETE_APPS_BODY', {
                count: vm.selectedApps.length
            })
		}).then((ok) => {
            AppService.deleteApps(vm.selectedApps).then((response) => {
                ToastService.showMessage($translate('MSG_DELETE_APPS_SUCCESS_BODY', vm.selectedApps), "success");
                vm.loadApps();
            }).catch((response) => {
                console.log("Failed deleting apps", response);
                ToastService.showMessage($translate('MSG_DELETE_APPS_FAIL_BODY', vm.selectedApps), "error");
            });
        }).catch(() => {});
    }

    vm.tableSelectionChanged = (app) => {
        console.log("Selection changed ", app);

        vm.selectedApps = app;
    };

    vm.loadUserData = async () => {
        vm.workingCount += 1;
        try {
            const userData = await UserService.getUserData();
            vm.user = userData.user;
            vm.currentDevice = userData.currentDevice;
            vm.workingCount -= 1;
        } catch (response) {
            console.log("error getting user data:" + response.status);
            vm.workingCount -= 1;
        }
    }

    // vm.loadApps();
    vm.dataType= '/uiapi/rest/applications?all=true';
    vm.initDataTable = () => {
		vm.actions= [{
			type: "action",
            text: "MY_APPS_ACTION_EDIT",
            icon: "far fa-edit fa-fw",
            action: (app) => {
                vm.loadUserData()
                .then(() => {
                    vm.editApp(app);
                }).catch((error) => {
                    console.log(error);
                    vm.editApp(app);
                });
            }
		},{
			type: "action",
            text: "MY_APPS_ACTION_DELETE",
            icon: "far fa-trash-alt fa-fw",
            action: (app) => vm.deleteApp(app)	
		},{
            type: "separator"
        },{
			type: "action",
            text: "HTTP_PUSH_START",
            icon: "glyphicon glyphicon-play fa-fw",
            action: (app) => vm.startPush(app)	
		},{
			type: "action",
            text: "HTTP_PUSH_STOP",
            icon: "glyphicon glyphicon-stop fa-fw",
            action: (app) => vm.stopPush(app)	
        }];
        
		vm.bulkActions= [{
            type: "action",
            text: "MY_APPS_ACTION_BULK_DELETE",
            icon: "far fa-trash-alt fa-fw",
            action: vm.batchDelete
        },{
            type: "divider"
        },{
            type: "action",
            text: "HTTP_PUSH_START",
            icon: "glyphicon glyphicon-play fa-fw",
            action: vm.batchStartPush
        },{
            type: "action",
            text: "HTTP_PUSH_STOP",
            icon: "glyphicon glyphicon-stop fa-fw",
            action: vm.batchStopPush
        }];

		vm.columns= [{
			key: "accountid",
			type: "text",
            title: "APP_ID",
            filterable: true,
            filterType: 'text',
            filterSearchMinLength: 1,
            filterField: 'search_id',
            filterParams: {
                mapper: (x) => x || undefined
            }
		},{
			key: "app_uuid",
			type: "text",
            title: "UUID",
            style: {
                minWidth: "260px"
            },
		}, {
			key: "status",
			title: "STATUS",
            type: "icon_with_tooltip",
            render_icon: (obj) => (obj.enabled ? 'glyphicon glyphicon-play' : 'glyphicon  glyphicon-stop'), 
            render_tooltip: (obj) => (obj.enabled ? 'started' : 'stopped'), 
		}]
        vm.reloadData();
    };
    vm.reloadData = () => {
        console.log("This should get overwritten");
    };
    
	Promise.all([vm.loadApps()]).then(vm.initDataTable);
};

app.controller("AppController", ["AppService", "UserService", "ToastService", "MessageService", "GroupService", "$timeout", "$filter", "$uibModal", "$log", "$scope", AppController])
