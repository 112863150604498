const app = angular.module('dassUiModule');

function DataTableController($filter, $scope, $timeout, $element, $attrs, DataService, ToastService) {
    const ctrl = this;
    const $translate = $filter("translate");

    ctrl.pageData = {
        total: 0,
        per_page: 0,
        pages: []
    };

    ctrl.filter = {
        limit: 50,
        ...ctrl.customFilter
    };

    ctrl.CustomTableLimits = {
        modalTableLimit: 3,
        paginationSize: 5,
        modalPaginationSize: 3,
    }

    ctrl.multiselectFilters = {};

    ctrl.selectedItem = undefined;

    ctrl.sortColumn = {};
    ctrl.sortDirection = 0;

    ctrl.workingCount = 0;
    ctrl.loadingPages = 0;
    ctrl.numSelected = 0;

    ctrl.selectedItems = [];
    ctrl.unmappedFilter = {};
    ctrl.unmappedFilterPrevios = {};
    ctrl.dateRangeSettings = {};

    ctrl.validationStatus = {};

    ctrl.columns = [{
        key: "deveui",
        type: "text",
        filterable: false,
        filterField: "",
        render: (x) => "No columns provided"
    }];

    const sortCmd = [undefined, "asc", "desc"];

    ctrl.setSortBy = (column) => {
        if (!column.sortable) return;

        if (ctrl.sortColumn.sortKey === column.sortKey) {
            ctrl.sortDirection = (ctrl.sortDirection + 1) % 3;
        } else {
            ctrl.sortColumn = column;
            ctrl.sortDirection = 1;
        }

        if (ctrl.sortColumn.validSortDirections) {
            while (!ctrl.sortColumn.validSortDirections[ctrl.sortDirection]) {
                ctrl.sortDirection = (ctrl.sortDirection + 1) % 3;
            }
        }

        ctrl.columns.forEach(column => {
            if (column.sortable) {
                ctrl.filter[column.sortKey] = column.sortKey === ctrl.sortColumn.sortKey ? sortCmd[ctrl.sortDirection] : undefined;
            }
        });
        ctrl.loadData();
    };

    $scope.$watch("$ctrl.unmappedFilter", function(newValue, oldValue) {
        if(newValue != oldValue) {
            setTimeout(() => {
                var bFlag = false;
                for (var newKey in newValue) {
                    var keyPresent = true;
                    for (var oldKey in oldValue) {
                        if (newKey == oldKey) {
                            keyPresent = false;
                            if ((typeof newValue[newKey] === 'string' || newValue[newKey] instanceof String) 
                                && newValue[newKey] != oldValue[oldKey]) {

                                ctrl.unmappedFilterPrevios[newKey] = oldValue[oldKey];
                                bFlag = true;

                                break;
                            }
                        }
                    }

                    // we get here only when we enter the number of character which is the same as the minimum length
                    if (keyPresent) {
                        ctrl.unmappedFilterPrevios[newKey] = newValue[newKey] + ' ';
                    }

                    if (bFlag) {
                        break;
                    }
                }
            }, 0);
        }
    }, true);

    ctrl.filterFieldUpdated = (event, filterFieldId, filterParams) => {
        setTimeout(() => {
            var searchFieldValue = ctrl.unmappedFilter[filterFieldId];
            if(filterFieldId == "search_deveui" && ctrl.unmappedFilter[filterFieldId] != undefined){
                var valueSearchDevUi = ctrl.unmappedFilter[filterFieldId];
                searchFieldValue = valueSearchDevUi.replace(/-|:/g, "");
            }

            if (event.keyCode == 13) {
                // It was a RETURN, we must activate the filter.

                // only do anything if the value has changed
                if (searchFieldValue != ctrl.unmappedFilterPrevios[filterFieldId] &&
                    searchFieldValue != undefined) {

                    ctrl.unmappedFilterPrevios[filterFieldId] = searchFieldValue;

                    if(filterFieldId == "search_deveui"){

                        if(!new RegExp(/[^0-9"AaBbCcDdEeFf\-\:"]/g).test(ctrl.unmappedFilter[filterFieldId])
                                || ctrl.unmappedFilter[filterFieldId].length == 0){
                            ctrl.updateField(filterFieldId, 'text', filterParams);
                        }
                    }
                    else {
                        ctrl.updateField(filterFieldId, 'text', filterParams);
                    }
                }
            }
        }, 100);
    };

    ctrl.updateField = (field, fieldType, filterParams, updateOnly) => {
        let newval = null;
        const mapper = filterParams && filterParams.mapper;
        const validator = filterParams && filterParams.validation;
        if (fieldType === 'daterange') {
            let start = null;
            let end = null;
            let startField = filterParams.startField || `start_${field}`;
            let endField = filterParams.endField || `end_${field}`;

            if (mapper) {
                start = mapper(ctrl.unmappedFilter[field].startDate);
                end = mapper(ctrl.unmappedFilter[field].endDate);

            } else {
                start = ctrl.unmappedFilter[field].startDate;
                end = ctrl.unmappedFilter[field].endDate;
            }

            ctrl.filter[startField] = start;
            ctrl.filter[endField] = end;
            ctrl.filter['date_changed'] = true;

            // if we click on the clear option, after sending the start and end date to the server (with nos start and end date set), we need to assign the current date back so the today filter can work properly 
            if (!ctrl.unmappedFilter[field].startDate && !ctrl.unmappedFilter[field].endDate) {
                ctrl.unmappedFilter[field].startDate = moment(new Date());
                ctrl.unmappedFilter[field].endDate = moment(new Date());
            }

        } else {
            if (mapper) {
                newval = mapper(ctrl.unmappedFilter[field]);
            }
            else {
                newval = ctrl.unmappedFilter[field];
            }
            ctrl.filter[field] = newval;
        }

        if (!updateOnly) {
            ctrl.loadData();
        }
    };

    ctrl.updateFilter = (field, mapper) => {
        const fields = Object.keys(ctrl.unmappedFilter);
        fields.forEach(field => {
        });
    };

    ctrl.pageChanged = (updateOnly = false) => {
        ctrl.deselectAll();
        let page_data = ctrl.pageData.pages[ctrl.currentPage - 1];
        if (page_data) {
            ctrl.filter.page_state = page_data.page_state;
        } else {
            ctrl.filter.page_state = undefined;
        }
        if (updateOnly) return;

        ctrl.loadDataNoPagination();
    };

    ctrl.data = [];

    ctrl.currentPage = 1;
    ctrl.allSelected = false;

    ctrl.toggleItemsSelected = () => {
        if (!ctrl.allSelected) ctrl.deselectAll();
        else ctrl.selectAll();
    };

    ctrl.notifySelectionChanged = () => {
        const notificationCallback = ctrl.selectionChanged();
        if (notificationCallback) {
            notificationCallback(ctrl.selectedItems);
        }
    };

    ctrl.selectAll = (device) => {
        ctrl.numSelected = ctrl.data.length;
        ctrl.selectedItems = ctrl.data;
        ctrl.data.forEach((item) => {
            item._marked = true;
        });

        ctrl.notifySelectionChanged();
    };

    ctrl.deselectAll = (item) => {
        ctrl.numSelected = 0;
        ctrl.selectedItems = [];
        ctrl.data.forEach((item) => {
            item._marked = false;
        });

        ctrl.allSelected = false;
        ctrl.notifySelectionChanged();
    };


    ctrl.itemSelected = (item, index, selected) => {
        ctrl.numSelected = 0;
        ctrl.selectedItems = [];
        if (ctrl.multiselect) {
            ctrl.data.forEach((item) => {
                if (item._marked) {
                    ctrl.numSelected++;
                    ctrl.selectedItems.push(item);
                }
            });
        } else {
            ctrl.selectedItem = selected;
            ctrl.selectedItems = [ctrl.data[index]];
        }

        ctrl.notifySelectionChanged();
    };

    ctrl.initialized = false;


    // loadData() will load page-states and the first page using stream loading.
    // Data is streamed from the DASS->DASS-UI-BE and parsed through OBOE JSON parser
    // and data is being parsed on the fly as it arrive.
    // When the process is running loadingPages is 1 and workingCount is 1 while the first
    // page is being loaded
    //
    ctrl.loadData = () => {

        // console.log("loadData()", ctrl.loadingPages, ctrl.workingCount);

        if (ctrl.selectedItem !== undefined) {
            ctrl.selectedItem = undefined;
        }
        if (!ctrl.source) return;
        if (!ctrl.initialized) ctrl.init(null, true);
        ctrl.filter = {
            ...ctrl.filter,
            ...ctrl.customFilter
        };
        let devFilterNoAfter = _.clone(ctrl.filter);
        delete devFilterNoAfter["page_state"];

        if (ctrl.abortLoadingPage)       { ctrl.abortLoadingPage(); }
        if (ctrl.abortLoadingPageStates) { ctrl.abortLoadingPageStates(); }

        const pages  = [];
        const rows   = [];
        const mapper = ctrl.mapper();

        clearTimeout(ctrl.applyTimer);
        clearTimeout(ctrl.progressTimer);

        ctrl.workingCount   += 1;
        ctrl.loadingPages    = 1;
        ctrl.scanned         = 0;
        ctrl.pageData        = { total: 0, per_page: ctrl.filter.limit, pages };
        ctrl.data            = mapper && rows.map(mapper) || rows;
        ctrl.applyTimer      = null;
        ctrl.progressTimer   = null;
        $scope.$parent.child = ctrl;    // Some pages need the chipd field.


        function oboeCallback (oboeObj) {

            // console.log("setting up oboe");

            ctrl.abortLoadingPageStates = () => {
                console.log("stopping ongoing load pages transfer");
                try { oboeObj.abort(); } catch(e) { console.log(e); }
                ctrl.abortLoadingPageStates = null;
                ctrl.loadingPages           = 0;
                ctrl.workingCount           = 0;
            }

            // Register to receive the pages array elemets on the fly
            oboeObj.node("pages.*", (pagesNode, path, ancestor) => {

                // console.log("OBOE-callback", pagesNode, path, ancestor);

                if (pagesNode) {
                    if (pagesNode._ign === undefined) {
                        pages.push(pagesNode);
                        // When we get the first page state we must have finished loading the page, and we can stop the workingCount.
                        if (pages.length === 1) {
                            ctrl.workingCount = 0;
                        }
                    }
                    if (pagesNode._ign && pagesNode.row) {
                        rows.push(pagesNode.row);

                        if (!ctrl.applyTimer) {
                            ctrl.applyTimer = setTimeout(() => {
                                // execute inside $timeout
                                $timeout(() => {
                                    ctrl.applyTimer = null;
                                    ctrl.data       = mapper && rows.map(mapper) || rows;
                                });
                            }, 300);
                        }
                    }
                    if (pagesNode._scan) {
                        ctrl.pageData.total = Math.max(pagesNode._cnt, ctrl.data.length);
                        ctrl.scanned = pagesNode._scan;
                        const now = Date.now();

                        if (ctrl.progressCallback && !ctrl.progressTimer) {
                            ctrl.progressTimer = setTimeout(() => {
                                $timeout(() => {
                                    ctrl.progressCallback(ctrl, false);
                                    ctrl.progressTimer = null;
                                });
                            }, 300);
                        }
                    }
                }
            });
        }

        // trigger immediate render of empty table
        $timeout(() => {
            if (typeof $scope.$parent.loadTableProgress === "number") {
                $scope.$parent.loadTableProgress++;
            }
        });

        return DataService.getData(ctrl.source, {
            get_pages: true,
            stream: "progress",
            ...devFilterNoAfter,

        }, oboeCallback).then((pageData) => {

            // We execute inside the $timeout to avoid using $apply()
            $timeout(() => {
                console.log("done getting pages", Date.now());
                clearTimeout(ctrl.applyTimer);
                clearTimeout(ctrl.progressTimer);

                ctrl.applyTimer    = null;
                ctrl.progressTimer = null;
                ctrl.abortLoadingPageStates = null;

                // store the pageData with the filtered pages table
                pageData.pages = pages;
                ctrl.pageData  = pageData;

                $scope.$emit("tablePaginationLoaded", ctrl.pageData.total);
                ctrl.pageChanged(true);


                ctrl.data          = mapper && rows.map(mapper) || rows;
                ctrl.loadingPages  = 0;
                ctrl.workingCount -= pages.length > 0 ? 0 : 1;

                if (typeof $scope.$parent.loadTableProgress === "number") {
                    $scope.$parent.loadTableProgress++;
                }

                // In the case that the streaming protocol is not working, we can check if we didn't get
                // any rows, but there are elements in the paging element. In this case we trigger a manual
                // load of the page.
                if (rows.length === 0 && ctrl.pageData.total > 0) {
                    ctrl.loadDataNoPagination();
                    if (ctrl.progressCallback) { ctrl.progressCallback(ctrl, false); }

                } else {
                    if (ctrl.progressCallback) { ctrl.progressCallback(ctrl, true); }
                }

                // console.log("Finished getting pages", ctrl.loadingPages, ctrl.workingCount);
            });

        }).catch((err) => {
            $timeout(() => {

                if (err.statusCode === 401) {
                    window.location.href = '/app/signout?resignin';
                }

                console.log("catch error", JSON.stringify(err));
                ctrl.workingCount -= 1;
                if (ctrl.workingCount <= 0) {
                    if (err.statusCode == 400) {
                        ToastService.showMessage($translate('MSG_ERROR_INVALID_FIELD'), "error");
                    }
                    else if ((ctrl.source == '/uiapi/rest/customers' || ctrl.source == '/uiapi/rest/users') && err.statusCode == 403) {
                        // just don`t display the toaster for the given page
                    }
                    else {
                        ToastService.showMessage($translate('MSG_ERROR_LOADING_DATA'), "error");
                    }
                }

                if (ctrl.progressCallback) { ctrl.progressCallback(ctrl, true); }
            });
        });
    };




    ctrl.loadDataNoPagination = () => {

        // console.log("loadDataNoPagination()", ctrl.loadingPages, ctrl.workingCount);

        if (!ctrl.source) return;
        if (!ctrl.initialized) ctrl.init(null, true);
        ctrl.filter = {
            ...ctrl.filter,
            ...ctrl.customFilter
        };
        let devFilterNoAfter = _.clone(ctrl.filter);

        if (ctrl.abortLoadingPage) { ctrl.abortLoadingPage(); }

        ctrl.workingCount = 1;
        const rows = [];
        const mapper = ctrl.mapper();

        clearTimeout(ctrl.apply2Timer);
        ctrl.apply2Timer = null;


        function oboeCallback (oboeObj) {

            ctrl.abortLoadingPage = () => {
                console.log("stopping ongoing load page transfer");
                try { oboeObj.abort(); } catch(e) { console.log(e); }
                ctrl.abortLoadingPage = null;
                ctrl.workingCount     = 0;
            }

            oboeObj.node("results.*", (pagesNode, path, ancestor) => {

                if (pagesNode && pagesNode._ign == undefined) {
                    rows.push(pagesNode);

                    if (!ctrl.apply2Timer) {
                        ctrl.apply2Timer = setTimeout(() => {
                            $timeout(() => {
                                ctrl.data        = mapper && rows.map(mapper) || rows;
                                ctrl.apply2Timer = null;
                                if (ctrl.progressCallback) { ctrl.progressCallback(ctrl, false); }
                            });
                        }, 300);
                    }
                }

            });
        }

        return DataService.getData(ctrl.source, {
            paged_results: true,
            ...devFilterNoAfter,
        }, oboeCallback).then((data) => {

            clearTimeout(ctrl.apply2Timer);
            ctrl.apply2Timer = null;

            ctrl.apply2Timer      = null;
            ctrl.abortLoadingPage = null;
            ctrl.data             = mapper && rows.map(mapper) || rows;
            ctrl.workingCount     = 0;

            if (ctrl.progressCallback) { ctrl.progressCallback(ctrl, true); }

        }).catch((err) => {

            if (err.statusCode === 401) {
                window.location.href = '/app/signout?resignin';
            }

            if (ctrl.progressCallback) { ctrl.progressCallback(ctrl, true); }
            ctrl.workingCount = 0;

            if(err.statusCode == 410){
                ctrl.loadData();
            } else {
                throw err;
            }
        });
    };


    ctrl.dateRangeChanged = (field, fieldType, filterParams) => {
        ctrl.updateField(field, fieldType, filterParams);
    };

    ctrl.clearDateRange = (field, fieldType, filterParams) => {
        ctrl.unmappedFilter[field].startDate = undefined;
        ctrl.unmappedFilter[field].endDate = undefined;
        ctrl.updateField(field, fieldType, filterParams);
    };

    ctrl.init = (param1, dontLoad) => {
        ctrl.filter.limit = (ctrl.isTableInModal === true) ? ctrl.CustomTableLimits.modalTableLimit : ctrl.filter.limit;
        ctrl.CustomTableLimits.paginationSize = (ctrl.isTableInModal === true) ? ctrl.CustomTableLimits.modalPaginationSize : ctrl.CustomTableLimits.paginationSize;
        ctrl.reloadData = ctrl.loadData;

        if (ctrl.initialized) return;

        if (!ctrl.columns) {
            return;
        }

        ctrl.actions = ctrl.actions || [];
        var offset = moment().utcOffset() / 60;
        var dateFormat = 'DD/MM/YYYY';
        if (offset <= -4 && offset >= -10){
            var dateFormat = 'MM/DD/YYYY';
        }
        ctrl.columns.forEach(column => {
            if (column.filterable) {
                if (column.filterType === 'daterange') {
                    ctrl.unmappedFilter[column.filterField] = {
                        startDate: moment().startOf('day'),
                        endDate:  moment().endOf('day')
                    };
                    ctrl.dateRangeSettings[column.filterField] = {
                        timePicker: true,
                        autoApply: true,
                        locale: {
                            format: dateFormat,
                            separator: " - ",
                            cancelLabel: 'Clear'
                        },
                        opens: 'left',
                        eventHandlers: {
                            'apply.daterangepicker': () => ctrl.dateRangeChanged(column.filterField, column.filterType, column.filterParams),
                            'cancel.daterangepicker': () => ctrl.clearDateRange(column.filterField, column.filterType, column.filterParams)
                        },
                        ranges: {'Today': [moment().startOf('day'), moment().endOf('day')]}
                    };
                }
                if (column.filterType === 'multiselect') {
                    ctrl.unmappedFilter[column.filterField] = column.filterType === "multiselect" ? [] : "";
                    ctrl.multiselectFilters[column.filterField] = {
                        search_string: "",
                        valid: true
                    };

                    column.filterParams.events = {
                        onItemSelect: (item) => {
                        },
                        onClose: () => {},
                        onMaxSelectionReached: () => {},
                        onDeselectAll: () => {},
                        onItemDeselect: () => {},
                        onSelectionChanged: () => {
                            ctrl.updateField(column.filterField, column.filterType, column.filterParams);
                        },
                        onSearchChanged: (search, items) => {
                            ctrl.multiselectFilters[column.filterField].search_string = search;
                            ctrl.multiselectFilters[column.filterField].valid = items.length > 0;

                            console.log(`${column.filterField} = ${search}`);
                        },
                        onInitDone: () => {},
                    };
                }
                if (column.initialSorting) {
                    ctrl.sortColumn = column;
                    ctrl.sortDirection = column.initialSorting;
                    ctrl.filter[column.sortKey] = sortCmd[ctrl.sortDirection];
                }
            }

            ctrl.validationStatus[column.filterField] = true;
        });

        if (!dontLoad) { ctrl.loadData();}
        ctrl.initialized = true;
    };

    ctrl.actionMenuOpened = (entry) => {
        const callback = ctrl.onActionMenuOpened();
        if (callback) {
            callback(entry);
        }
    }

    ctrl.$onInit = ctrl.init;
    // ctrl.$onChanges = ctrl.init;
}

app.component('dassDataTable', {
    templateUrl: "data_table.html",
    controller: ['$filter', '$scope', '$timeout', '$element', '$attrs', 'DataService', 'ToastService', DataTableController],
    bindings: {
        source: '<',
        mapper: '&?',
        onActionMenuOpened: "&",
        selectionChanged: '&?',
        customFilter: '<',
        itemsPerPage: '<',
        multiselect: '<',
        columns: '<',
        actions: '<',
        bulkActions: '<',
        reloadData: '=',
        isTableInModal: '<',
        progressCallback: '<',
    }
});
