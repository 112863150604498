import * as moment from "moment";
import * as React from "react";
import { Button, Col, Glyphicon, Grid, Row } from "react-bootstrap";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import { toast } from "../../utils/Toaster";
import DateTimeRangePicker from "./../../components/DateTimeRangePicker/DateTimeRangePicker";
import { AlertModal } from "./../../components/Modals/AlertModal";
import { LocaleDate } from "./../../controllers/GlobalController";
import { FilterText } from "./../../controllers/Profiles/ProfileTableController";
import { ActiveConnections as ActiveConn } from "./../../services/Applications/ActiveConnections";
import { strings } from "./../../services/Localization";
import { Whoami } from "./../../services/Login";

export default class ActiveConnections extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            Connections: [],
            CountClicks: 0,
            LoadingData: false,
            LoadingTableData: true,
            LoggedUser: null,
            sortName: [],
            sortOrder: [],
        };
    }

    public componentDidMount() {
        this.LoggedIn();
    }

    public LoggedIn = async () => {
        try {
            const response: any = await Whoami();
            if (response.user) {
                // tslint:disable-next-line:no-bitwise
                if (response.user._license && ((response.user._license & 0x8) === 0)) {
                    this.setState({
                        ShowAlertModal: true,
                    });
                } else {
                    this.setState({
                        LoggedUser: response.user,
                    });
                    this.GetActiveConnections();
                }
            } else {
                this.setState({
                    LoggedUser: null,
                });
                window.location.href = "/app/signin";
            }
        } catch (error) {
            console.log(`Error: ${error}`);
            this.setState({
                LoggedUser: null,
            });
            window.location.href = "/app/signin";
        }
    }

    public LoadingData = () => {
        this.setState({
            LoadingData: true,
            LoadingTableData: true,
        });
        this.GetActiveConnections();
    }

    public remoteAddressOrUrl = (Connections) => {
        const jsonData: any = [];
        Connections.map((connection) => {
            if (!connection.remote_address && connection.url) {
                connection.remote_address = connection.url;
            }
            jsonData.push(connection);
        });
        this.setState({
            Connections: jsonData,
        });
    }

    public GetActiveConnections = async () => {
        try {
            const response: any = await ActiveConn();
            if (response.status === 200) {
                const Connections = await response.json();
                this.remoteAddressOrUrl(Connections);
                this.setState({
                    Connections,
                    LoadingData: false,
                    LoadingTableData: false,
                });
            } else {
                let message;
                switch (response.status) {
                    case 401:
                        message = strings.ACTIVE_CONNECTIONS_RES_401;
                        break;
                    default:
                        message = await response.json();
                        break;
                }
                this.setState({
                    Connections: [],
                    LoadingData: false,
                    LoadingTableData: false,
                });
                toast.error(message);
            }
        } catch (error) {
            console.log(`Active connections => ${error}`);
            toast.error(strings.ACTIVE_CONNECTIONS_CANT_GET_DATA);
            this.setState({
                Connections: [],
                LoadingData: false,
                LoadingTableData: false,
            });
        }
    }

    public onSortChange = (sortName, sortOrder) => {
        let CountClicks = this.state.CountClicks;
        if (this.state.sortName[0] === sortName) {
            CountClicks = CountClicks + 1;
        } else {
            CountClicks = 0;
        }

        this.setState({
            CountClicks,
            sortName: [sortName],
            sortOrder: [sortOrder],
        });

        if (CountClicks === 2) {
            this.setState({
                CountClicks: 0,
                sortName: [],
                sortOrder: [],
            });
        }
    }

    public DateFormat = (date) => {
        if (date) {
            return moment(date).format(LocaleDate().DateTimeFormat);
        } else {
            return "";
        }
    }

    public CustomRangePicker = (filterHandler) => {
        let values: any = "";
        function Filter(FilterValues) {
            values = FilterValues.callback;
            filterHandler({callback: isFiltered});
        }
        function isFiltered(targetValue) {
            targetValue = moment(targetValue).toISOString();
            if (targetValue && values.startDate && values.endDate) {
                return (targetValue >= values.startDate) && (targetValue <= values.endDate);
            } else {
                return;
            }
        }
        return <DateTimeRangePicker RangeHandler={Filter} />;
    }

    public SubscriptionFix = (subscriptions) => {
            return subscriptions.split(",").join(", ");
    }
    public customTextFilter = (filterHandler, customFilterParameters) => {
        return <FilterText FilterHandler = {filterHandler} customFilterParameters = {customFilterParameters}/>;
    }
    public render() {
        const { Connections, LoadingData, LoadingTableData, LoggedUser, ShowAlertModal } = this.state;
        if (LoggedUser === null) {
            return (
                <AlertModal
                    ShowModal={ShowAlertModal}
                    CloseModal={async () => {
                        await this.setState({
                            ShowAlertModal: true,
                        });
                        window.location.href = "/app";
                    }}
                    ModalBody={strings.NO_RIGHTS_TO_SEE_THIS_PAGE}
                    ConfirmBtn={true}
                    OnConfirm={async () => {
                        await this.setState({
                            ShowAlertModal: false,
                        });
                        window.location.href = "/app";
                    }}
                />
            );
        } else {
            const options = {
                noDataText: (LoadingTableData ?
                    <div>
                        <i className="fas fa-spinner fa-spin"></i>&nbsp;{strings.LOADING}...
                    </div> :
                    strings.NO_ITEMS_MATCHING
                ),
                onSortChange: this.onSortChange,
                sortIndicator: false,
                sortName: this.state.sortName,
                sortOrder: this.state.sortOrder,
            };
            const CustomFilter: any = "CustomFilter";
            const CustomPlaceHolderTableColType: any = {
                length: 3,
                placeholder: "ACTIVE_CONNECTIONS_TABLE_COL_TYPE",
            };
            const CustomPlaceHolderTableColApplication: any = {placeholder: "ACTIVE_CONNECTIONS_TABLE_COL_APPLICATION"};
            return(
                <Grid fluid={true} className="ActiveConnections">
                    <h2>{strings.ACTIVE_CONNECTIONS_PAGE_TITLE}</h2>
                    <Row className="show-grid ReactPages">
                        <Col sm={5} className="positionRight position_ab">
                            <Button className="black_b_btn" onClick={this.LoadingData}>
                                <Glyphicon
                                    glyph="refresh"
                                    className={LoadingData ? "spinning" : ""}
                                /> {strings.REFRESH_LIST}
                            </Button>
                        </Col>
                    </Row>
                    <Row className="show-grid ConnectionsTableContainer">
                        <Col sm={12}>
                            <BootstrapTable
                                multiColumnSort={1}
                                ref="table"
                                data={(LoadingTableData ? [] : Connections)}
                                striped={true}
                                hover={true}
                                options={options}
                                tableContainerClass="ReactPages new_style_dark react_new_style_dark ConnectionsTable"
                            >
                                <TableHeaderColumn
                                    dataField="type"
                                    className="type_table"
                                    columnClassName="type_table"
                                    isKey
                                    filter={{
                                        customFilterParameters: CustomPlaceHolderTableColType,
                                        getElement: this.customTextFilter,
                                        type: CustomFilter,
                                    }}
                                >
                                    {strings.ACTIVE_CONNECTIONS_TABLE_COL_TYPE}
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="status"
                                    className="status"
                                    columnClassName="status"
                                >
                                    {strings.ACTIVE_CONNECTIONS_TABLE_COL_STATUS}
                                </TableHeaderColumn>


                                <TableHeaderColumn
                                    dataField="connection_time"
                                    className="conection_time"
                                    columnClassName="conection_time"
                                    dataSort={true}
                                    dataFormat={this.DateFormat}
                                    filter={{
                                        getElement: this.CustomRangePicker,
                                        type: CustomFilter,
                                    }}
                                >
                                    {strings.ACTIVE_CONNECTIONS_TABLE_COL_CONN_TIME}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="appid"
                                    className="application_table"
                                    columnClassName="application_table"
                                    filter={{
                                        customFilterParameters: CustomPlaceHolderTableColApplication,
                                        getElement: this.customTextFilter,
                                        type: CustomFilter,
                                    }}
                                    dataSort={true}
                                >
                                    {strings.ACTIVE_CONNECTIONS_TABLE_COL_APPLICATION}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="remote_address"
                                    className="remote_address"
                                    columnClassName="remote_address"
                                >
                                    {strings.ACTIVE_CONNECTIONS_TABLE_COL_REMOTE_ADDRESS}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="subscription"
                                    className="subscription"
                                    columnClassName="subscription"
                                    dataFormat={this.SubscriptionFix}
                                >
                                    {strings.ACTIVE_CONNECTIONS_TABLE_COL_SUBSCRIPTIONS}
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="error"
                                    className="error"
                                    columnClassName="error"
                                >
                                    {strings.ACTIVE_CONNECTIONS_TABLE_COL_ERRORS}
                                </TableHeaderColumn>

                            </BootstrapTable>
                        </Col>
                    </Row>
                </Grid>
            );
        }
    }
}
