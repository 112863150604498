import * as React from "react";
import {
    Modal,
    Button,
    Row,
    Col
} from "react-bootstrap";
import { strings } from "./../../services/Localization";

// Services
import { GetSoftwareInfo } from "./../../services/UserSettings";

export default class SoftwareInfoModal extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            SoftwareInfo: ""
        }
    }

    componentDidMount() {
        this.GetSoftwareInfo();
    }

    GetSoftwareInfo = async () => {
        try {
            const response = await GetSoftwareInfo();
            let resData = strings.SOFTWARE_INFO_ERROR_MESSAGE;
            if (response.status === 200) {
                resData = await response.text();
            }
            this.setState({
                SoftwareInfo: resData
            });
        } catch (err) {
            console.log(err);
            this.setState({
                SoftwareInfo: strings.SOFTWARE_INFO_ERROR_MESSAGE
            });
        }
    }

    render() {
        const {
            ShowModal,
            ToggleModal
        } = this.props;
        const { SoftwareInfo } = this.state;
        return (
            <Modal
                show={ShowModal}
                onHide={ToggleModal}
                className="ReactModal software_info"
                backdrop="static"
                bsSize="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{strings.SOFTWARE_INFO_TITLE}</Modal.Title>
                </Modal.Header>
                <Modal.Body
                    className="software_info_body"
                >
                    <Row>
                        <Col xs={12}>
                            <div dangerouslySetInnerHTML={{ __html: SoftwareInfo }} />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer
                    className="new_style_modal_footer"
                >
                    <Button
                        className="new_style_btn_cancel"
                        onClick={ToggleModal}
                    >
                        {strings.OK}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
