
declare const headerContentTypeJson;
declare const headerNoContentType;

export const UpdateDefaultPushConfiguration = (Data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch("/uiapi/rest/pushmode/start?update_only=true", {
                body: JSON.stringify(Data),
                credentials: "same-origin",
                headers: headerContentTypeJson,
                method: "PUT",
            });

            if (response.status == 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const GetDefaultPushConfiguration = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch("/uiapi/rest/pushmode/status", {
                credentials: "same-origin",
                headers: headerNoContentType,
                method: "GET",
            });

            if (response.status == 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};
