var app = angular.module('dassUiModule');

function PayloadListController(DeviceService, UserService, ToastService, MessageService, PayloadService, $filter, $uibModal, $log, $scope) {
	const $translate = $filter("translate");
	const formatDeveui = $filter("formatDeveui");
	const formatPayload = $filter("formatPayload");
	const sortByColumn = $filter('sortByColumn');
	const dateString = $filter('dateString');

	let vm = this;

	const hexRegex = /^[0-9a-fA-F]+$/;
	vm.workingCount = 0;
	vm.currentDevice = null;
	vm.currentDeviceComment = null;
	vm.device_status = undefined;
	vm.activation = undefined;
	vm.payloads = [];

	const dlstates = [0, 1, 2, 3, 4, 5, 'SENT'].map(s => $translate(`PACKET_STATUS_${s}`));// ["Enqueued", "Sent, waiting Ack", "Ack Ok", "Ack Fail", "Error", "Cancelled"];

	vm.customFilter = {
		mac_msg: false
	};

	vm.can_export = false;
	vm.can_view_mac_msg = false;
	vm.show_mac_msg = false;
	vm.show_decoded_payload = false;
	vm.show_data = true;
	vm.show_position_estimates = false;

	// NOTE: here is a structural problem. We need to know the region and mac version BEFORE
	// starting to use the MAC payload decoder. As page loader will automatically start to
	// decode the payloads while loading this means that we may have the wrongly decoded MAC messages.
	// However, as it normally load without first, and then changing the setting will cause it
	// to load again, it is most likely ok since on the reload the parameters will have been loaded.
	// Note, the problem is for profile devices as these does not provide the region and mac version.
	//
	vm.RFRegion = "";
	vm.lorawanVersion = undefined;
	vm.RegParamsRevision = "";

	vm.DevicePayloads = 0;
	vm.devicePayloadsMessage = $translate('DEVICE_PAYLOADS_MESSAGE', {
		number: vm.DevicePayloads,
		scanning: 0,
	});


	vm.progressCb = (ctrl, done) => {
		vm.devicePayloadsMessage = $translate('DEVICE_PAYLOADS_MESSAGE', {
			number:   (ctrl.pageData.total) || 0,
			scanning: (done ? 0 : ctrl.scanned) || 0,
		});
	}

	vm.changeVisibility = (show_mac_msg) => {
		vm.show_mac_msg = !show_mac_msg;
		vm.customFilter.mac_msg = !show_mac_msg;
		vm.initDataTable();
	}

	vm.changeDecodedPayload = (show_decoded_payload) => {
		vm.show_decoded_payload = !show_decoded_payload
		vm.initDataTable();
	}

	vm.changeData = (show_data) => {
		vm.show_data = !show_data
		vm.initDataTable();
	}

	vm.changePpositionEstimates = (show_position_estimates) => {
		vm.show_position_estimates = !show_position_estimates
		vm.customFilter.locations = !show_position_estimates;
		vm.initDataTable();
	}

	vm.updateDataType = () => {
		if (vm.currentDevice == null) {
			return;
		}
		vm.dataType = `/uiapi/rest/nodes/${vm.currentDevice}/payloads/all`;
	}

	vm.getDeviceStatus = () => {
		DeviceService.getDevice(vm.currentDevice).then(response => {
			vm.device_status = response.device_status;
			vm.activation = response.activation;

			// Note see comment at top of file regarding Region
			vm.lorawanVersion = response.MACVersion;
			vm.RFRegion = response.RFRegion;
			vm.RegParamsRevision = response.RegParamsRevision;
		}).catch(err => {
			console.log("Error " + err);
		})
	}


	vm.sf2txt = (sf) => {
		return sf != null ? ("SF" + (sf & 255) + (["", "BW250", "BW500"][sf >> 8])) : "";
	};

	vm.exportPayloads = () => {
		var queryStr = '';
		if (vm.currentDevice) {
			queryStr += '&deveuis=';
			queryStr += vm.currentDevice;
			queryStr += "&mac_msg=" + (vm.show_mac_msg ? "true" : "false");

			if ($scope.child.filter.date_changed) {
				let deviceStartDate = $scope.child.unmappedFilter.date.startDate;
				let deviceEndDate   = $scope.child.unmappedFilter.date.endDate;

				queryStr += '&payload_from_date=';
				queryStr += deviceStartDate.toISOString();

				queryStr += '&payload_to_date=';
                queryStr += deviceEndDate.toISOString();
			}
		}

		var url = window.location.protocol;
		var tz = "&tz=" + (new Date()).getTimezoneOffset();
		url += '//';
		url += window.location.host;
		window.location.assign(url + '/uiapi/rest/export/payloads?download_filename=payloads.csv' + tz + queryStr);
	}

	vm.loadPayloads = () => {

		if (!vm.reloadData) return;
		var reloadPromise = vm.reloadData();
		vm.updateDataType();
		if (reloadPromise) {
			vm.workingCount += 1;
			reloadPromise.then(() => {
				vm.DevicePayloads = $scope.child.pageData.total;
				vm.devicePayloadsMessage = $translate('DEVICE_PAYLOADS_MESSAGE', {
					number: vm.DevicePayloads,
					scanning: 0
				});
				vm.getDeviceStatus();
				vm.workingCount -= 1;
			}).catch(err => {
				vm.workingCount -= 1;
			});
		}
	};

	vm.tableSelectionChanged = (payload) => {
		console.log("Selection changed ", payload);
		vm.selectedPayloads = payload;
	};

	vm.deletePayload = (deveui, packet) => {
		MessageService.showMessage({
			title: $translate('MSG_DELETE_PACKET_TITLE'),
			body: $translate('MSG_DELETE_PACKET_BODY', {
				deveui: formatDeveui(deveui)
			})
		}).then((ok) => {
			if (ok == "ok") {
				console.log("sending delete request dir = " + packet.dir);
				PayloadService.deletePayload(deveui, packet).then(
					(response) => {
						ToastService.showMessage($translate('MSG_DELETE_PACKET_SUCCESS_BODY'), "success");

						vm.loadPayloads();
					},
					(response) => {
						ToastService.showMessage($translate('MSG_DELETE_PACKET_FAIL_BODY'), "error");
					}
				);
			}
		});
	};

	vm.deletePayloads = () => {
		MessageService.showMessage({
			title: $translate('MSG_DELETE_PACKETS_TITLE'),
			body: $translate('MSG_DELETE_PACKETS_BODY', {
				deveui: formatDeveui(vm.currentDevice),
				count: vm.selectedPayloads.length
			})
		}).then((ok) => {
			PayloadService.deletePayloads(vm.currentDevice, vm.selectedPayloads).then(
				(response) => {
					ToastService.showMessage($translate('MSG_DELETE_PACKETS_SUCCESS_BODY', {
						deveui: formatDeveui(vm.currentDevice),
						count: vm.selectedPayloads.length
					}), "success");
					vm.loadPayloads();
				},
				(response) => {
					ToastService.showMessage($translate('MSG_DELETE_PACKETS_FAIL_BODY', {
						deveui: formatDeveui(vm.currentDevice),
						count: vm.selectedPayloads.length
					}), "error");
				}
			);
		});
	};

	const sendPayloadToDass = (deveui, payload) => {
		const sendobj = {
			deveui: deveui,
			data: btoa(payload.data),
			port: payload.port,
			fcnt: payload.fcnt == null ? undefined : payload.fcnt,
			confirmed: payload.confirmed == null ? undefined : payload.confirmed
		};

		PayloadService.sendDlPayload(sendobj).then(
			(response) => {
				ToastService.showMessage($translate('MSG_SEND_DL_PACKET_SUCCESS_BODY', {
					deveui: formatDeveui(deveui),
					transaction_id: response.data.id
				}), "success");

				vm.loadPayloads();
				console.log(response.data);
			},
			(response) => {
				let message = response.data;
				switch (response.status) {
					case 409:
						if (response.data.match(/^downlink queue full,\W? [0-9]+ (payload|payloads)\W? already enqueued$/)) {
							message = $translate('MSG_SEND_DL_PACKET_FULL_DOWNLINK_QUEUE_BODY', {
								payloads: response.data.match(/\d+/)[0],
							});
						} else {
							message = $translate('MSG_SEND_DL_PACKET_FAIL_BODY');
						}
						break;

					default:
						message = response.data;
						break;
				}
				ToastService.showMessage(message, "error");

				console.log(response.data);
			}
		);
	}

	vm.sendData = (deveui) => {
		if (deveui == null && vm.currentDevice == "") {
			MessageService.showMessage({
				title: $translate('MSG_TITLE_OOPS'),
				body: $translate('MSG_SELECT_DEVICE_BODY')
			});
			return;
		}
		if (deveui == null) {
			deveui = vm.currentDevice;
		}

		const modalInstance = $uibModal.open({
			backdrop: "static",
			animation: vm.animationsEnabled,
			templateUrl: 'sendDataModalContent.html',
			controller: 'SendDataModalCtrl',
			size: "",
			resolve: {
				items: () => {
					return [];
				}
			}
		});
		modalInstance.result.then((payload) => {
			sendPayloadToDass(deveui, payload);
		}, () => {
			$log.info('Modal dismissed at: ' + new Date());
		});
	};


	vm.selectDevice = () => {
		const modalInstance = $uibModal.open({
			backdrop: "static",
			animation: true,
			templateUrl: 'showUsers.html',
			controller: 'DataSelectController',
			size: "lg",
			resolve: {
				items: () => {
					return {
						dataType: '/uiapi/rest/nodes',
						title: "NAV_DEVICES_ITEM",
						actions: [],
						bulkActions: [],
						columns: [{
							key: "deveui",
							type: "text",
							title: "DEVEUI",
							filterable: true,
							filterField: "search_deveui",
							filterType: "text",
							filterSearchMinLength: 1,
							filterParams: {
								mapper: x => x.replace(new RegExp("-", 'g'), "")
							},
							sortable: true,
							sortKey: "sort_by_deveui",
							render: (x) => formatDeveui(x.deveui)
						}, {
							key: "comment",
							title: "COMMENT",
							type: "text",
							filterable: true,
							filterField: "search_comment",
							filterSearchMinLength: 1,
							filterType: "text",
							sortable: true,
							sortKey: "sort_by_comment"
						}, {
							key: "last_reception",
							title: "LAST_SEEN",
							type: "text",
							filterable: true,
							filterField: "date",
							filterType: 'daterange',
							filterParams: {
								startField: "from_date",
								endField: "to_date",
								mapper: (x) => x && x.format()
							},
							sortable: true,
							sortKey: "sort_by_date",
							render: (x) => dateString(x.last_reception)
						}],
					}
				}
			}
		});

		modalInstance.result.then((selectedDevices) => {
			console.log("Outside modal: ", selectedDevices);
			if (selectedDevices.length > 0) {
				const dev = selectedDevices[0];
					vm.currentDevice = dev.deveui;
					vm.currentDeviceComment = dev.comment;
					vm.updateDataType();
					UserService.setCurrentDevice(vm.currentDevice, vm.currentDeviceComment).then((res) => {
						console.log("Set current device successfully ", res);
						vm.loadPayloads();
					}).catch((err) => {
						console.log("Error setting current device ", err);
					});
			}
		}, () => {
			// vm.loadDevices()
			console.log("Dismissed");
		});
	};


	vm.loadUserData = () => {
		vm.workingCount += 1;

		return UserService.getUserData().then(
			(userData) => {
				vm.user = userData.user;
				vm.can_view_mac_msg = vm.user.can_view_mac_msg;
				vm.can_export = (vm.user._license & 0x80) == 0x80;
				if (userData.currentDevice !== null) {
					DeviceService.getDevice(userData.currentDevice).then(response => {
						vm.currentDevice = userData.currentDevice;
						vm.currentDeviceComment = userData.currentDeviceComment;
						vm.workingCount -= 1;
						vm.loadPayloads();
						vm.device_status = response.device_status;
						vm.activation = response.activation;

						// Note, see comment at top of file
						vm.RFRegion = response.RFRegion;
						vm.lorawanVersion = response.MACVersion;
						vm.RegParamsRevision = response.RegParamsRevision;
					}).catch(err => {
						console.log("Error " + err);
						vm.workingCount = 0;
						vm.currentDevice = "";
					})
				} else {
					vm.workingCount = 0;
				}
			},
			(response) => {
				console.log("error getting user data:" + response.status);
				vm.workingCount -= 1;
			}
		);
	}

	vm.rePushPayloadUL = (device, payload) => {
		PayloadService.rePushDevicePayloadUL(device, payload)
		.then(async (response) => {
			if (response.status === 200) {
				ToastService.showMessage($translate('RE_PUSH_PAYLOAD_SUCCESS'), "success");
			} else {
				ToastService.showMessage($translate('RE_PUSH_PAYLOAD_ERROR'), "error");
			}
		})
		.catch((e) => {
			console.log("RePush payload error => ", e);
		});
	}

	vm.initDataTable = () => {
		vm.actions = [
			{
				type: "action",
				text: "DATA_DELETE_PACKET",
				icon: "far fa-trash-alt fa-fw",
				action: (packet) => vm.deletePayload(vm.currentDevice, packet)
			}, {
				type: "action",
				text: "RE_PUSH_PAYLOAD",
				icon: "far fa-paper-plane",
				action: (x) => vm.rePushPayloadUL(vm.currentDevice, x.id),
				visible: (entry, payload) => payload.rssi
			}
		];

		vm.bulkActions = [{
			type: "action",
			text: "DATA_DELETE_PACKETS",
			icon: "far fa-trash-alt fa-fw",
			action: vm.deletePayloads
		}];

		vm.columns = [{
			key: "direction",
			title: "DIRECTION",
			type: "text",
			filterField: 'payload_type',
			render: (x) => x.rssi ? $translate("DIRECTION_UP") : $translate("DIRECTION_DOWN"),
			filterable: true,
			filterType: 'downlink_uplink',
		},  {
			key: "timestamp",
			title: "TIME",
			type: "text",
			render: (x) => dateString(x.timestamp),
			filterable: true,
			filterField: "date",
			filterType: 'daterange',
			filterParams: {
				startField: "from_date",
				endField: "to_date",
				mapper: (x) => x && x.format()
			},
			sortable: true,
			sortKey: "sort_by_timestamp",
			initialSorting: 2,							// default descending sort
			validSortDirections: [false, true, true],	// always sort
		}, {
			key: "fcnt",
			title: "FCNT",
			type: "text",
			filterable: false,
            filterType: 'text',
            filterField: 'fcnt',
            filterParams: {
                mapper: (x) => x || undefined
            },
			render: (x) => x.fcnt != undefined ? x.fcnt.toString() : ''
		}, {
			key: "port",
			title: "PORT",
			type: "text",
			filterable: true,
            filterType: 'text',
			filterField: 'search_port',
            filterSearchMinLength: 1,
            filterParams: {
                mapper: (x) => x || undefined
			},
			render: (x) => x.port != undefined ? x.port.toString() : ''
		},
		{
			key: "transmissionStatus",
			title: "STATUS",
			type: "text",
			filterable: false,
			filterField: 'transmissionStatus',
			filterType: 'select',
			filterParams: {
				options: [
					{
						label: "GATEWAY_STATUS_ANY",
						value: ""
					},
					{
						label: "PACKET_STATUS_0",
						value: 0
					},
					{
						label: "PACKET_STATUS_1",
						value: 1
					},
					{
						label: "PACKET_STATUS_2",
						value: 2
					},
					{
						label: "PACKET_STATUS_3",
						value: 3
					},
					{
						label: "PACKET_STATUS_4",
						value:4
					},
					{
						label: "PACKET_STATUS_5",
						value: 5
					}
				]
			},
			render: (x) => (
				x.rssi && "" ||
				x.transmissionStatus == 0 && $translate("PACKET_STATUS_0") ||
				(x.transmissionStatus == 1 && x.confirmed == true) && $translate("PACKET_STATUS_1") ||
				(x.transmissionStatus == 1 && x.confirmed == false) && $translate("PACKET_STATUS_1_1") ||
				x.transmissionStatus == 2 && $translate("PACKET_STATUS_2") ||
				x.transmissionStatus == 3 && $translate("PACKET_STATUS_3") ||
				x.transmissionStatus == 4 && $translate("PACKET_STATUS_4") ||
				x.transmissionStatus == 5 && $translate("PACKET_STATUS_5"))
		}, {
			key: "dr_used",
			title: "DATA_RATE",
			type: "text",
			filterable: false,
            filterType: 'text',
            filterField: 'dr_used',
            filterParams: {
                mapper: (x) => x || undefined
            }
		}, {
			key: "rssi",
			title: "RSSI",
			type: "text_with_tooltip",
			tooltip_props: {
                tooltipClass: "increase-popover-width",
                appendToBody: true,
                trigger: "mouseenter"
            },
            render_tooltip: x => (x.snr ? 'SNR: ' + x.snr : '' ) + (((x.snr) && (x.freq)) ? ', ' : '') + (x.freq ? 'Frequency: ' + Math.round((x.freq / 1000000) * 10000) / 10000 + ' MHz': '' ),
            render: x => x.rssi,
			filterable: false,
            filterType: 'text',
            filterField: 'rssi',
            filterParams: {
                mapper: (x) => x || undefined
            }
		}, {
			key: "decrypted",
			title: "DECRYPTED",
			render: (x) => x.decrypted ? $translate("DECRYPTED") : $translate("ENCRYPTED")
		}];

		if (vm.show_mac_msg) {
			vm.columns.splice(5, 0, {
				key: "mac_msg",
				title: "MAC_MSG",
				type: "text",
				visible: (entry, payload) => vm.can_view_mac_msg,
				render: (packet) => packet.mac_msg && vm.can_view_mac_msg === true ? (
					getDecodedLorawanMsgAsText(packet.mac_msg, hexRegex.test(packet.mac_msg), vm.lorawanVersion, vm.RFRegion, vm.RegParamsRevision)) : ""
			})
		}

		if (vm.show_decoded_payload) {
			vm.columns.splice(9, 0, {
				key: "decoded",
				title: "Decoded Payload",
				type: "custom_text",
				style: {
					paddingTop: "0",
					paddingBottom: "0"
				},
				render: function(data,type,row,meta) {

					if (data.decoded) {
						var decodedObj = data.decoded;
						var newDecodedObj = {};

						Object.keys(decodedObj).forEach(function(key) {
							if (decodedObj[key] != null){
								newDecodedObj[key] = decodedObj[key];
							}
						});

						// finding the key with most characters
						var longestKeyLength = null;
						Object.keys(newDecodedObj).forEach(function (key) {

							if (!longestKeyLength) {
								longestKeyLength = key.length;
							}
							else if (key.length > longestKeyLength) {
								longestKeyLength = key.length;
							}
						});
						var a = '<div class="column-inside-container container">';
						Object.keys(newDecodedObj).forEach(function (key) {

							var keyString = key;
							var keyLength = keyString.length;
							for (var i = keyLength; i < longestKeyLength; i++) {
								keyString += ' ';
							}

							var result;
							if (typeof newDecodedObj[key] === "object") {
								var jsonStringify = JSON.stringify(newDecodedObj[key]).replace(/,/g, ", ");
								var strReplace = jsonStringify.replace(/\"([^(\")"]+)\":/g,"$1:").slice(1, -1);
								result = strReplace.replace(/\"|'|null| _/gi, "");
							} else {
								result = newDecodedObj[key];
							}
							a += '<div class="row"><div class="col-md-12 key-container">' + keyString + ' = ' + result + '</div></div>';
						});
						a += '</div>';

						return a;
					}

					return '';
				}
			})
		}

		if (vm.show_data) {
			vm.columns.splice(9, 0, {
				key: "data",
				title: "DATA",
				type: "text",
				render: (x) => (x.dataFrame && formatPayload(x.dataFrame) || x.data && formatPayload(x.data))
			})
		}

		if (vm.show_position_estimates) {
			vm.columns.splice(9, 0, {
				key: "position_estimates",
				title: "Latitude Longitude Altitude",
				type: "custom_text",
				render: function(data, type, row, meta) {
					var str = '';
					if (data.latitude) {
						str += data.latitude;
						str += '</br>';
					}

					if (data.longitude) {
						str += data.longitude;
						str += '</br>';
					}

					if (data.altitude) {
						str += data.altitude;
					}

					return str;
				}
			})
		}

		vm.reloadData();
	};

	vm.reloadData = () => {
        console.log("This should get overwritten");
	};
	vm.loadUserData();
	Promise.all([vm.loadUserData()]).then(vm.initDataTable)
};

app.controller("PayloadListController", ["DeviceService", "UserService", "ToastService", "MessageService", "PayloadService", "$filter", "$uibModal", "$log", "$scope", PayloadListController])

// Disable close dropdown
app.directive('disableAutoClose', function() {
	return {
		link: function($scope, $element) {
		  	$element.on('click', function($event) {
				$event.stopPropagation();
			});
		}
	};
});
