import * as React from "react";
import {
    Button, ButtonToolbar, Col, ControlLabel, Form, FormControl, FormGroup, HelpBlock,
    Modal, Nav, NavItem, OverlayTrigger, Popover, Row, Tab, Table, ToggleButton, ToggleButtonGroup
} from "react-bootstrap";
import { strings } from "./../../services/Localization";
import { getPageData, getPagination } from "../../services/BasicDassQueries";
import { GetTenantName, LoggedUserInfo, UpdateUserSettings,
         CreateNewAccount, GetAccountTemplate, GetAuthServers} from "./../../services/UserSettings";
import { toast } from "./../../utils/Toaster";
import TenancyTable from "./TenancyTable";
import OrganizationTable from "./OrganizationTable";
import ProfilesRights from "./ProfilesRights";
import { IConstants } from "../../types";
import SelectDropdown from "react-select";
import { isEmail } from "validator";

// import * as XRegExp from "xregexp";                      // bundle 8.71
// import * as XRegExp from "xregexp/xregexp-all";         // bundle 9.06

import * as XRegExp from "xregexp";
import * as base from "xregexp/lib/addons/unicode-base";
import * as categories from "xregexp/lib/addons/unicode-categories";

base(XRegExp);
categories(XRegExp);

const UserIdRegExp = XRegExp("^[\\p{L}0-9@_.-]{6,60}$");
const emptyUuid = "00000000-0000-0000-0000-000000000000";


const DefaultSizePerPage = 3;
const OrganizationDefaultSizePerPage = 3;

declare const constants: IConstants;


interface IPasswordPolicy {
    enable_complex_password: boolean;
    enforce_password_history: boolean;
    max_password_age: number;
    min_password_length: number;
}

interface IPasswordPolicies {
    admin: IPasswordPolicy;
    user: IPasswordPolicy;
    app:  IPasswordPolicy;
}


interface IUserSettingsProps {
    ToggleModal?: () => void;
    CallOnClose?: (updated: boolean) => void;

    ShowModal?: boolean;

    ModalTitle?: string;
    EditUser: any;
    LoggedUser?: any;
    NewUserType?: "user" | "customer" | "org";
}

interface IUserSettingsState {
    ActiveProfileTab: string;
    ActualTenant: any;
    ConfirmPassword: string;
    CurrentPage: number;
    CurrentPageOrganization: number;
    DisableButton: boolean;
    Filtering: any[];
    FilteringOrganization: any[];
    isMe: boolean;

    Loading: boolean;
    NewPassword: string;
    OldPassword: string;
    PageLimit: number;
    PaginationData: {
        pages: Array<{ page_state: string }>;
        per_page: number;
        total: number;
    };
    PageLimitOrganizations: number;
    PaginationDataOrganizations: {
        pages: Array<{ page_state: string }>;
        per_page: number;
        total: number;
    },

    ShowTenantTable: boolean;
    ShowOrganizationTable: boolean;
    Sorting: {
        sortName: string;
        sortOrder: string;
    };
    SortingOrganization: {
        sortName: string;
        sortOrder: string;
    };

    Tenants: any[];
    Organizations: any[];
    tagsData: any[];

    newTagName: string;
    newTagDesc: string;
    isTagNameValid: boolean;
    globalTagNameValid: boolean;
    oldTagName: string;
    oldTagDesc: string;
    initialTagsLength: number;
    sameTagName: boolean;
    hasSameTagName: boolean;
    unlockButtonUserSettings: string;
    unlockChangeText: string;
    readOnly: boolean;
    ShowModal: boolean;

    EditUser: any;
    OrigUser: any;
    LoggedUser: any;

    Environment: string;

    authServers: any[];
    TypeText: string;
    Errors: IErrors;
}



interface IYesNoButton {
    value: boolean | string;
    onChange: (event) => void;
    disabled?: boolean;
    help?: string;
    label?: string;
    controlId?: string;
    dict?: IRadioDictionaryElement[];
    radioButtonClassName?: string;
}

interface IRadioDictionaryElement {
    value: string;
    label: string;
}

const booleanRadioDict: IRadioDictionaryElement[] = [
    { label: strings.YES, value: "true" },
    { label: strings.NO, value: "false" },
]

interface IErrors {
    error: boolean;
    errNumDevices: boolean;
    errNumGateways: boolean;
    errEnvironment: boolean;
    errEmail: boolean;
    errUserId: boolean;
    errPassword: boolean;
    errPasswordConfirm: boolean;
    errPasswordNotComplex: boolean;
    errPasswordTooShort: boolean;
    errOldPassword: boolean;
    errSubject: boolean;

    infoPasswordNoUpper: boolean;
    infoPasswordNoLower: boolean;
    infoPasswordNoSpecial: boolean;
    infoPasswordNoNumber: boolean;
    infoPasswordMinLength: number;
    infoPasswordIsComplex: boolean;
}


const listOfRights = [
    "administrator",
    "can_register",
    "can_access_gtw_info",
    "gtw_admin",
    "can_own_gtw",
    "can_add_gtw",
    "can_mng_gtw",
    "customer_admin",
    "sys_admin",
    "loraloc_enable",
    "token_manager",
    "can_view_mac_msg",
    "can_access_gtw_loc_info",
    "tenant_admin",
    "org_admin",
    "data_archive_admin",
    "can_access_logs",
    "can_register_joinserver",
    "can_access_gtw_unfiltered",
    "can_access_gtw_trace",
    "can_set_alarms",

    "can_create_device_profile",
    "can_inspect_device_profile",
    "can_link_device_profile",
    "can_list_device_profile",

    "can_create_service_profile",
    "can_inspect_service_profile",
    "can_link_service_profile",
    "can_list_service_profile",

    "can_create_connectivity_profile",
    "can_inspect_connectivity_profile",
    "can_link_connectivity_profile",
    "can_list_connectivity_profile",

    "can_create_roaming_profile",
    "can_inspect_roaming_profile",
    "can_link_roaming_profile",
    "can_list_roaming_profile",

    "can_create_qos_profile",
    "can_inspect_qos_profile",
    "can_link_qos_profile",
    "can_list_qos_profile",

    "can_create_channel_profile",
    "can_inspect_channel_profile",
    "can_link_channel_profile",
    "can_list_channel_profile",

    "can_restrict_service_profile",
];

const listToIgnore = [
    "email_verified", "email_to_be_verified", "host", "push_subscription", "data_format", "retry_policy", "always_push", "linked_with_tenant",
    "is_customer", "is_organisation", "num_devices", "is_tenant", "_license", "_environment"
];



function renderYesNoRadio(props: IYesNoButton) {

    const dict     = props.dict || booleanRadioDict;
    const disabled = props.disabled != null ? props.disabled : false;

    const buttons: any[] = [];
    for (const elem of dict) {
        buttons.push(
            <ToggleButton key={elem.value} disabled={disabled} value={elem.value} className={props.radioButtonClassName || ""}>
                {elem.label}
            </ToggleButton>
        );
    }

    return (
        <ButtonToolbar>
            <ToggleButtonGroup name="TenantButtons" type="radio" defaultValue="false"
                                disabled={disabled}
                                value={props.value + ""} onChange={props.onChange} >
                {buttons}
            </ToggleButtonGroup>
        </ButtonToolbar>
    );
}


function renderFormGroupYesNoRadio(props: IYesNoButton) {
    return (
        <FormGroup controlId={props.controlId}>
            <Col componentClass={ControlLabel} sm={5} >
                <strong className="new_style_font_weight">
                    {props.label}
                </strong>
            </Col>
            <Col sm={7}>
                {renderYesNoRadio(props)}

                {props.help && (
                    <HelpBlock>
                        <small className="new_style_help_block_color">
                            {props.help}
                        </small>
                    </HelpBlock>
                )}

            </Col>
        </FormGroup>
    );
}





export default class UserSettingsModal extends React.Component<IUserSettingsProps, IUserSettingsState> {
    constructor(props) {
        super(props);
        const { EditUser, LoggedUser } = this.props;

        let TypeText = strings.USER_TYPE_USER;
        if (EditUser && EditUser.is_customer || this.props.NewUserType === "customer") {
            TypeText = strings.USER_TYPE_CUSTOMER;
        } else if (EditUser && EditUser.is_organisation || this.props.NewUserType === "org") {
            TypeText = strings.USER_TYPE_ORGANIZATION;
        }

        this.state = {
            ActiveProfileTab: "",
            ActualTenant: null,
            ConfirmPassword: "",
            CurrentPage: 1,
            CurrentPageOrganization: 1,
            DisableButton: false,
            Filtering: [],
            FilteringOrganization: [],
            isMe: !!(EditUser && LoggedUser && EditUser.userid === LoggedUser.userid),

            Loading: true,
            NewPassword: "",
            OldPassword: "",
            PageLimit: DefaultSizePerPage,
            PaginationData: {
                pages: [],
                per_page: DefaultSizePerPage,
                total: 1,
            },
            PageLimitOrganizations: OrganizationDefaultSizePerPage,
            PaginationDataOrganizations: {
                pages: [],
                per_page: OrganizationDefaultSizePerPage,
                total: 1,
            },

            ShowTenantTable: false,
            ShowOrganizationTable: false,
            Sorting: {
                sortName: undefined as any,
                sortOrder: undefined as any,
            },
            SortingOrganization: {
                sortName: undefined as any,
                sortOrder: undefined as any,
            },
            Tenants: [],
            Organizations: [],
            tagsData: [],
            newTagName: "",
            newTagDesc: "",
            isTagNameValid: true,
            globalTagNameValid: true,
            oldTagName: "",
            oldTagDesc: "",
            initialTagsLength: 0,
            sameTagName: false,
            hasSameTagName: false,
            unlockButtonUserSettings: "glyphicon-lock",
            unlockChangeText: strings.CLICK_TO_UNLOCK,
            readOnly: this.props.NewUserType == null,
            ShowModal: true,

            EditUser: EditUser || {},
            OrigUser: EditUser || {},
            LoggedUser: LoggedUser || {},

            Environment: JSON.stringify((EditUser || {}).environment, null, 4) || "",

            authServers: [],
            TypeText,
            Errors: {} as any,
        };

        this.newTagNameRef = React.createRef();
        this.newTagDescRef = React.createRef();

        this.inputRefs = [];
    }

    public setRef = (ref) => {
        this.inputRefs.push(ref);
    };

    public closeModal = (updated = false) => {
        if (this.props.ToggleModal) {
            this.props.ToggleModal();
        } else {
            this.setState({ ShowModal: false });
        }
        if (this.props.CallOnClose) {
            this.props.CallOnClose(updated);
        }
    }

    public async componentDidMount() {
        // document.addEventListener("mousedown", this.handleClickOutside);

        try {

            let LoggedUser = this.props.LoggedUser;

            if (LoggedUser == null) {

                const response: any = await LoggedUserInfo();
                if (response.status === 200) {
                    const UserResponse = await response.json();
                    LoggedUser = UserResponse.user;
                    this.setState({ LoggedUser });
                } else {
                    this.closeModal();
                }
            }


            let EditUser = this.state.EditUser;

            if (LoggedUser.sys_admin) {
                const authServers = await GetAuthServers();

                // Set labels for SelectDropdown to use the table directly
                for (const as of authServers) {
                    (as as any).value = as.auth_server_id;
                    (as as any).label = as.auth_server_id;
                }

                this.setState({ authServers });
            }

            if (this.props.NewUserType) {
                // get defaults
                EditUser = await GetAccountTemplate(this.props.NewUserType);
                EditUser.auth_server = LoggedUser.auth_server;

                if (LoggedUser.sys_admin) {
                    EditUser.auth_server_id = LoggedUser.auth_server_id;
                }

                if (this.props.NewUserType === "customer") {
                    EditUser.is_customer = true;
                } else if (this.props.NewUserType === "org") {
                    EditUser.is_organisation = true;
                }
                this.setState({ EditUser, OrigUser: EditUser });
            }


            if (LoggedUser.tenant_admin && EditUser.is_customer && EditUser.tenant_id && EditUser.tenant_id !== emptyUuid) {
                let TenantName = EditUser.tenant_id;
                try {
                    const response: any = await GetTenantName(EditUser.tenant_id);
                    if (response.status === 200) {
                        const tenants: any[] = await response.json();

                        for (let i = 0; i < tenants.length; i++) {
                            if (tenants[i].tenantid == EditUser.tenant_id) {
                                TenantName = tenants[i];
                                break;
                            }
                        }
                    }

                    this.setState({ ActualTenant: TenantName });
                } catch (error) {
                    this.setState({ ActualTenant: TenantName });
                }
            }


            // copy tags into tag array
            const tagsArray = [] as any;
            let counter = 0;

            if (EditUser && EditUser.tags) {
                for (const tagKey of Object.keys(EditUser.tags)) {
                    tagsArray.push({
                        id: counter++,
                        name: tagKey,
                        desc: EditUser.tags[tagKey],
                        editable: "hide-input-container",
                        validName: true,
                        isSameName: false,
                    });
                }
            }

            this.setState({
                tagsData: tagsArray,
                initialTagsLength: tagsArray.length,
            }, () => {
                this.checkInputValidity();
            });

            this.setState({ Loading: false });


        } catch (error) {
            console.log(error);
            this.closeModal();
        }
    }

    private newTagNameRef: React.RefObject<any>;
    private newTagDescRef: React.RefObject<any>;
    private inputRefs;

    public componentWillUnmount() {
        // document.removeEventListener("mousedown", this.handleClickOutside);
    }

    public handleClickOutside = (e: any) => {
        try {
            const parts = e.target.className.split(" ");
            let matches = 0;
            let onotherCheck = 0;
            for (let i = 0; i < parts.length; i++) {
                if (parts[i] === "edit-icon" || parts[i] === "edit-tag") {
                    matches++;
                }

                if (parts[i] === "tag-input-container") {
                    onotherCheck++;
                }
            }

            if (matches < 2 && onotherCheck <= 0) {  // we have cliked outside the edit icon or the input fields itself and therefore hide the input text for the tags
                const tags = this.state.tagsData;
                for (let i = 0; i < tags.length; i++) {
                    if (tags[i].validName) {
                        tags[i].editable = "hide-input-container";
                    }
                }

                this.setState({
                    tagsData: tags,
                });
            }

            return;
        }
        catch(error) {
            return;
        }
    }



    public HandleChangeRights = async (value, right, type = "") => {

        const EditUser = { ...this.state.EditUser };

        EditUser[right] = (type === "string") ? value : JSON.parse(value);

        // Now impose the hierachical rights
        if (EditUser.org_admin) {
            EditUser.customer_admin = true;
        }
        if (EditUser.tenant_admin) {
            EditUser.customer_admin = true;
        }
        if (EditUser.customer_admin) {
            EditUser.administrator = true;
        }

        if (!EditUser.can_own_gtw) {
            EditUser.can_add_gtw = false;
            EditUser.can_mng_gtw = false;
        }


        /* Seems not needed, must be handled already in the sub-component

        const profileRights = ["inspect", "create", "link"];
        const profileTypes = ["device", "service", "connectivity", "roaming", "qos", "channel"];
        for (const type of profileTypes) {
            if (!EditUser[`can_list_${type}_profile`]) {
                for (const right of profileRights) {
                    EditUser[`can_${right}_${type}_profile`] = false;
                }
            }
        }
        */

        this.setState({ EditUser }, () => this.checkInputValidity());
    }



    public LoadTenants = async (
        LimitPerPage = this.state.PageLimit,
        CurrentPage = this.state.CurrentPage,
        Filtering = this.state.Filtering,
        Sorting = this.state.Sorting,
    ) => {

        if (this.state.LoggedUser.customer_admin === false) {
            return;
        }

        try {
            const GetTenantsPages = await getPagination("tenants",
                LimitPerPage,
                Filtering,
                Sorting,
            );
            if (GetTenantsPages.status === 200) {
                const PaginationData = GetTenantsPages.data;
                if (Math.ceil(PaginationData.total / PaginationData.per_page) < CurrentPage) {
                    CurrentPage = Math.ceil(PaginationData.total / PaginationData.per_page);
                    if (CurrentPage < 1) {
                        CurrentPage = 1;
                    }
                }
                if (JSON.stringify(Filtering) !== JSON.stringify(this.state.Filtering)) {
                    CurrentPage = 1;
                }
                this.setState({
                    CurrentPage,
                    Filtering,
                    PaginationData,
                    Sorting,
                });
                if (PaginationData.pages.length > 0) {
                    this.TenantsPageList(
                        PaginationData.pages[CurrentPage - 1].page_state,
                        LimitPerPage,
                        Filtering,
                        Sorting,
                    );
                } else {
                    this.setState({
                        Tenants: [],
                    });
                }
            } else {
                let message = "";
                switch (GetTenantsPages.status) {
                    case 400: message = strings.GET_PAGINATION_ERROR_400;  break;
                    case 401: message = strings.UNAUTHORIZED_ERROR;        break;
                    case 404: message = strings.GET_PAGINATION_ERROR_404;  break;
                    case 413: message = strings.GET_PAGINATION_ERROR_413;  break;
                    default:  message = strings.MSG_ERROR_LOADING_TENANTS; break;
                }
                toast.error(message);
                this.setState({
                    Tenants: [],
                });
            }
        } catch (error) {
            console.log(error);
            toast.error(strings.MSG_ERROR_LOADING_TENANTS);
        }
    }

    public LoadOrganizations = async (
        LimitPerPage = this.state.PageLimitOrganizations,
        CurrentPage = this.state.CurrentPageOrganization,
        Filtering = this.state.FilteringOrganization,
        Sorting = this.state.SortingOrganization,
    ) => {
        if (this.state.LoggedUser.customer_admin === false) {
            return;
        }

        try {
            const GetOrganizationsPages = await getPagination(
                "organisations",
                LimitPerPage,
                Filtering,
                Sorting,
            );
            const PaginationData = GetOrganizationsPages.data;
            if (Math.ceil(PaginationData.total / PaginationData.per_page) < CurrentPage) {
                CurrentPage = Math.ceil(PaginationData.total / PaginationData.per_page);
                if (CurrentPage < 1) {
                    CurrentPage = 1;
                }
            }
            if (JSON.stringify(Filtering) !== JSON.stringify(this.state.Filtering)) {
                CurrentPage = 1;
            }
            this.setState({
                CurrentPageOrganization: CurrentPage,
                FilteringOrganization: Filtering,
                PaginationDataOrganizations: PaginationData,
                SortingOrganization: Sorting,
            });
            if (PaginationData.pages.length > 0) {
                this.OrganizationsPageList(
                    PaginationData.pages[CurrentPage - 1].page_state,
                    LimitPerPage,
                    Filtering,
                    Sorting,
                );
            } else {
                this.setState({
                    Organizations: [],
                });
            }
        } catch (error) {
            let message = "";
            switch (error.status) {
                case 400: message = strings.GET_PAGINATION_ERROR_400;  break;
                case 401: message = strings.UNAUTHORIZED_ERROR;        break;
                case 404: message = strings.GET_PAGINATION_ERROR_404;  break;
                case 413: message = strings.GET_PAGINATION_ERROR_413;  break;
                default:  message = strings.MSG_ERROR_LOADING_TENANTS; break;
            }
            toast.error(message);
            this.setState({
                Organizations: [],
            });
        }
    }

    public TenantsPageList = async (
        PageState = this.state.PaginationData.pages[0].page_state,
        PageLimit = this.state.PageLimit,
        Filtering = this.state.Filtering,
        Sorting = this.state.Sorting,
    ) => {
        this.setState({
            Tenants: [],
        });
        try {
            const GetCurrentPage = await getPageData("tenants", PageLimit, PageState, Filtering, Sorting);
            if (GetCurrentPage.status === 200) {
                this.setState({
                    Tenants: GetCurrentPage.data,
                });
            } else {
                let message = "";
                switch (GetCurrentPage.status) {
                    case 400: message = strings.GET_PAGINATION_ERROR_400;  break;
                    case 401: message = strings.UNAUTHORIZED_ERROR;        break;
                    case 403: message = strings.FORBIDDEN_ERROR;           break;
                    case 410: message = strings.GET_PAGE_STATE_ERROR_410;  break;
                    case 416: message = strings.GET_PAGE_STATE_ERROR_416;  break;
                    default:  message = strings.MSG_ERROR_LOADING_TENANTS; break;
                }
                toast.error(message);
            }
        } catch (error) {
            console.log(error);
            toast.error(strings.MSG_ERROR_LOADING_TENANTS);
        }
    }

    public ShowTenantTable = () => {
        this.setState({
            ShowTenantTable: !this.state.ShowTenantTable,
        });
        if (this.state.ShowTenantTable === false) {
            this.LoadTenants();
        }
    }

    public OrganizationsPageList = async (
        PageState = this.state.PaginationData.pages[0].page_state,
        PageLimit = this.state.PageLimitOrganizations,
        Filtering = this.state.FilteringOrganization,
        Sorting = this.state.SortingOrganization,
    ) => {
        this.setState({
            Organizations: [],
        });
        try {
            const GetCurrentPage = await getPageData("organisations", PageLimit, PageState, Filtering, Sorting);
            this.setState({
                Organizations: GetCurrentPage.data,
            });
        } catch (error) {
            let message = "";
            switch (error.status) {
                case 400: message = strings.GET_PAGINATION_ERROR_400; break;
                case 401: message = strings.UNAUTHORIZED_ERROR;       break;
                case 403: message = strings.FORBIDDEN_ERROR;          break;
                case 410: message = strings.GET_PAGE_STATE_ERROR_410; break;
                case 416: message = strings.GET_PAGE_STATE_ERROR_416; break;
                default:  message = strings.MSG_ERROR_LOADING_ORGANIZATIONS; break;
            }
            toast.error(message);
        }
    }

    public ShowOrganizationTable = () => {
        this.setState({
            ShowOrganizationTable: !this.state.ShowOrganizationTable,
        });
        if (this.state.ShowOrganizationTable === false) {
            this.LoadOrganizations();
        }
    }

    public changeTab = () => {
        this.setState({
            ShowTenantTable: false,
            ShowOrganizationTable: false,
        });
    }

    public SelectTenant = (SelectedTenant) => {
        this.setState({
            EditUser: {...this.state.EditUser, tenant_id: SelectedTenant.tenantid},
        });
    }

    public SelectOrganization = (SelectedOrganization) => {
        this.setState({
            EditUser: {...this.state.EditUser, organisationid: SelectedOrganization.userid},
        });
    }

    public ChangeProfileTab = (profile) => {
        const { LoggedUser } = this.state;
        let { DisableButton } = this.state;

        if (LoggedUser[`can_list_${profile}_profile`] !== undefined) {
            if (LoggedUser[`can_list_${profile}_profile`]) {
                DisableButton = false;
            } else {
                DisableButton = true;
            }
        }
        this.setState({
            ActiveProfileTab: profile,
            DisableButton,
        });
    }

    public HandlePasswordPolicy = (value, right, type) => {
        const EditUser = { ...this.state.EditUser };
        EditUser.password_policies = { ...EditUser.password_policies }
        EditUser.password_policies[type] = { ...EditUser.password_policies[type] };

        if (value !== "") {
            EditUser.password_policies[type][right] = JSON.parse(value);
        } else {
            EditUser.password_policies[type][right] = null;
        }
        this.setState({ EditUser }, () => this.checkInputValidity());
    }

    public PopOverHover = (content) => {
        return (
            <Popover
                id="PasswordPolicy"
                className="pass_policy_popover_new_style"
            >
                {content}
            </Popover>
        );
    }

    public transformTags() {

        const tags = this.state.tagsData;
        const tagsArray = {};
        for (let i = 0; i < tags.length; i++) {
            tagsArray[tags[i].name] = tags[i].desc;
        }

        return tagsArray;
    }

    /**
     * The user pressed "OK"
     */
    public SubmitUserRights = async () => {

        const { LoggedUser, EditUser, OrigUser } = this.state;

        if (this.state.newTagName || this.state.newTagDesc) {
            toast.error("TAGS not completed");
            return;
        }

        const canChangePassword = EditUser.auth_server && EditUser.auth_server.indexOf("password") >= 0;
        const canChangeSubject  = EditUser.auth_server && EditUser.auth_server.indexOf("subject") >= 0;

        try {
            const setobj = {...EditUser};

            delete setobj.password;
            if (canChangePassword && this.state.NewPassword) {
                setobj.password = this.state.NewPassword;
                if (this.state.OldPassword) {
                    setobj.old_password = this.state.OldPassword;
                }
            }

            if (!canChangeSubject) {
                delete setobj.subject;
            }

            setobj.tags = this.transformTags();
            setobj.environment = JSON.parse(this.state.Environment || "{}");


            // remove fields that should just be ignored (i.e. status and internal added values)
            // and the push data settings that we don't manipuate in this view
            for (const key of listToIgnore) {
                delete setobj[key];
            }

            // Handle special case of disable list_xx_profile
            for (const pft of ["device", "service", "connectivity", "roaming", "channel", "qos"]) {
                if (setobj["can_list_" + pft + "_profile"] === false) {
                    setobj["can_create_" + pft + "_profile"]  = false;
                    setobj["can_link_" + pft + "_profile"]    = false;
                    setobj["can_inspect_" + pft + "_profile"] = false;
                }
            }


            // remove all rights that we (the logged user) do not have rights to set
            for (const right of listOfRights) {
                if (LoggedUser[right] == null) {
                    delete setobj[right];
                }
            }

            // Delete fields that are null
            for (const key of Object.keys(setobj)) {
                if (setobj[key] == null) {
                    delete setobj[key];
                }
            }

            // If we are editing an account, we only send what is different
            if (this.props.NewUserType == null) {
                for (const key of Object.keys(setobj)) {
                    if (setobj[key] === OrigUser[key]) {
                        delete setobj[key];
                    }
                }
            }



            // The following fields can only be set under certain rights, remove the field if the logged
            // user doesn't have the sufficient right.
            if (!LoggedUser.sys_admin) {
                delete setobj.verbose_log;
                delete setobj.environment;
            }
            if (!LoggedUser.data_archive_admin) {
                delete setobj.auto_archive_data;
            }
            if (!LoggedUser.can_set_alarms) {
                delete setobj.email_alarm_subscription;
            }

            console.log(setobj);

            // Clean object
            for (const key of Object.keys(setobj)) {
                if (setobj[key] == null) {
                    delete setobj[key];
                }
            }

            if (!(this.props.NewUserType ||
                  (this.state.OrigUser.userid !== this.state.EditUser.userid && LoggedUser.sys_admin))) {
                delete setobj.userid;
            }

            const UpdateResponse = this.props.NewUserType ?
                            await CreateNewAccount(this.props.NewUserType, setobj) :
                            await UpdateUserSettings(this.state.OrigUser.userid, setobj);

            if (UpdateResponse.status === 200) {

                toast.success(strings.formatString(this.props.NewUserType
                                ? strings.MSG_USER_CREATE_SUCCESS_BODY
                                : strings.MSG_USER_UPDATE_SUCCESS_BODY,
                                { ut: this.state.TypeText }));
                this.closeModal(true);

            } else {
                let message = await UpdateResponse.text();
                switch (UpdateResponse.status) {
                    case 400: break;
                    case 401: message = strings.UPDATE_USER_ERROR_401; break;
                    case 403: message = strings.UPDATE_USER_ERROR_403;  break;
                    case 412: message = strings.PASSWORD_FAILED; break;
                    case 409: message = strings.MSG_USER_CREATE_FAIL_BODY; break;
                    default:  break;
                }
                toast.error(strings.formatString(message, { ut: this.state.TypeText }));
            }
        } catch (error) {
            console.log(error);
            toast.error(strings.MSG_USER_UPDATE_FAIL_BODY);
        }
    }

    public async emailVerify(resend: boolean) {
        try {
            await UpdateUserSettings(this.state.OrigUser.userid, {}, "?email_verification=" + (resend ? "resend" : "ignore"));

            const { EditUser } = this.state;
            if (resend) {
                EditUser.email_to_be_verified = EditUser.email_to_be_verified || EditUser.email;
            } else {
                EditUser.email_to_be_verified = "";
            }
            this.setState({ EditUser });

        } catch (error) {
            console.log(error);
            toast.error(strings.MSG_USER_UPDATE_FAIL_BODY);
        }

    }


    public GetBooleanRight = (right: string, def: boolean | string = false) => {
        return this.state.EditUser[right] || def;
    }


    public NumberFieldValue = (right: string, tab: string, type = "") => {
        const EditUser = this.state.EditUser;
        if (tab === "PasswordPolicy") {
            return EditUser.password_policies[type][right];
        } else if (tab === "Settings") {
            return EditUser[right] || "";
        }
    }

    public editTag(index) {

        console.log("edit tag", index);


        if (this.state.globalTagNameValid) {
            let currentTagName = "";
            let currentTagDesc = "";

            const tags = this.state.tagsData;
            for (let i = 0; i < tags.length; i++) {
                if (tags[i].id === index && this.state.globalTagNameValid) {
                    tags[i].editable = "show-input-container";

                    currentTagName = tags[i].name;
                    currentTagDesc = tags[i].desc;

                    const that = this;
                    setTimeout(function() {
                        if (that.inputRefs && that.inputRefs[i]) {
                            that.inputRefs[i].focus();
                        }

                    }, 0);
                }
                else if (this.state.globalTagNameValid) {
                    tags[i].editable = "hide-input-container";
                }
            }

            this.setState({
                tagsData: tags,
                oldTagName: currentTagName,
                oldTagDesc: currentTagDesc,
            });
        }
    }

    public saveEditingTag() {

        console.log("saveEditingTag");


        if (!this.state.hasSameTagName) {
            const tags = this.state.tagsData;
            for (let i = 0; i < tags.length; i++) {
                if (tags[i].validName) {
                    tags[i].editable = "hide-input-container";
                }
            }

            this.setState({
                tagsData: tags,
            });
        }
    }

    public deleteTag(index) {


        console.log("delete tag", index);

        const tags = this.state.tagsData;
        const newTags = [] as any;
        let globalTagNameValidHelper = this.state.globalTagNameValid;
        for (let i = 0; i < tags.length; i++) {
            if (tags[i].id !== index) {
                newTags.push(tags[i]);
            }
            else if (!tags[i].validName && this.state.isTagNameValid) {
                globalTagNameValidHelper = true;
            }
        }

        this.setState({
            tagsData: newTags,
            globalTagNameValid: globalTagNameValidHelper,
        });
    }

    public tagDescChange(index, e) {
        const tags = this.state.tagsData;
        for (let i = 0; i < tags.length; i++) {
            if (tags[i].id === index) {
                tags[i].desc = e.target.value;
            }
        }

        this.setState({
            tagsData: tags,
        });
    }

    public tagNameChange(index, e) {
        const tags = this.state.tagsData;
        let globalCurrentTagNameValid = this.state.globalTagNameValid && this.state.isTagNameValid;
        let bHasSameTagName = false;
        for (let i = 0; i < tags.length; i++) {
            if (tags[i].id === index) {
                tags[i].name = e.target.value;

                let regexTagName = /^[a-zA-Z\d\-_]*$/;
                let result = tags[i].name.match(regexTagName);
                if (!result) {
                    tags[i].validName = false;
                    globalCurrentTagNameValid = false;
                }
                else {
                    tags[i].validName = true;
                    globalCurrentTagNameValid = true && this.state.isTagNameValid;
                }

                let matchCounter = 0;
                for (let j = 0; j < tags.length; j++) {
                    if (tags[j].name == e.target.value) {
                        matchCounter++;
                    }
                }

                if (matchCounter > 1) {
                    tags[i].isSameName = true;
                    bHasSameTagName = true;
                }
                else {
                    tags[i].isSameName = false;
                }

                break;
            }
        }

        this.setState({
            tagsData: tags,
            globalTagNameValid: globalCurrentTagNameValid,
            hasSameTagName: bHasSameTagName,
        });
    }

    public handleKeyPress = (e) => {
        if (e.key === "Enter" && e.target.value != '') {

            if (this.state.newTagName != "" && this.state.newTagDesc != "") {
                this.insertNewTag();
            }
            else if (e.target.classList.contains("new-tag-name")) {
                this.newTagDescRef.current.focus();
            }
            else if (e.target.classList.contains("new-tag-description")) {
                this.newTagNameRef.current.focus();
            }
        }
    }

    public handleKeyPressOnEditTag = (e) => {
        if(e.key === "Enter" && e.target.value != '' && !this.state.hasSameTagName) {

            const tags = this.state.tagsData;
            for (let i = 0; i < tags.length; i++) {
                if (tags[i].validName) {
                    tags[i].editable = "hide-input-container";
                }
            }

            this.setState({
                tagsData: tags,
            });
        }
    }

    public newTagNameChange(e) {
        const that = this;
        setTimeout(function() {
            let bFlag = false;
            const tags = that.state.tagsData;
            for (let i = 0; i < tags.length; i++) {
                if (tags[i].name == that.state.newTagName) {
                    bFlag = true;
                    break;
                }
            }

            that.setState({
                sameTagName: bFlag,
            });

        }, 0);

        let regexTagName = /^[a-zA-Z\d\-_]*$/;
        let result = e.target.value.match(regexTagName);

        if(result != null){
            this.setState({
                isTagNameValid: true,
                globalTagNameValid: true
            });
        } else {
            if(e.target.value == ""){
                this.setState({
                    isTagNameValid: true,
                    globalTagNameValid: true
                });
            } else {
                this.setState({
                    isTagNameValid: false,
                    globalTagNameValid: false
                });
            }
        }

        const tags = this.state.tagsData;
        for (let i = 0; i < tags.length; i++) {
            if (!tags[i].validName) {
                this.setState({
                    globalTagNameValid: false,
                });

                break;
            }
        }

        this.setState({
            newTagName: e.target.value,
        });

        this.handleKeyPress(e);
    }

    public newTagDescChange(e) {
        this.setState({
            newTagDesc: e.target.value,
        });

        this.handleKeyPress(e);
    }

    public insertNewTag() {
        let bFlag = false;
        const tags = this.state.tagsData;
        for (let i = 0; i < tags.length; i++) {
            if (tags[i].name == this.state.newTagName) {
                bFlag = true;
                break;
            }
        }

        this.setState({
            sameTagName: bFlag,
        });

        if (bFlag) {
            return;
        }

        if (this.state.newTagName !== "" && this.state.newTagDesc !== "" && this.state.isTagNameValid) {
            const tags = this.state.tagsData;
            const tag = {
                id: this.state.initialTagsLength,
                name: this.state.newTagName,
                desc: this.state.newTagDesc,
                editable: "hide-input-container",
                validName: true,
                isSameName: false,
            };

            this.newTagNameRef.current.focus();

            let newTagsLength = this.state.initialTagsLength + 1;

            tags.push(tag);
            this.setState({
                tagsData: tags,
                newTagName: "",
                newTagDesc: "",
                initialTagsLength: newTagsLength,
            });
        }
        else if (this.state.newTagDesc !== "" && !this.state.isTagNameValid) {
            this.newTagNameRef.current.focus();
        }
        else if (this.state.newTagDesc == "") {
            this.newTagDescRef.current.focus();
        }
        else if (this.state.newTagName == "" && this.state.newTagDesc == "") {
            this.newTagNameRef.current.focus();
        }
    }

    public clearTagInputs() {
        let globalTagNameValidHelper = this.state.globalTagNameValid;
        if (!this.state.isTagNameValid) {
            globalTagNameValidHelper = true;
        }

        const tags = this.state.tagsData;
        for (let i = 0; i < tags.length; i++) {
            if (!tags[i].validName) {
                globalTagNameValidHelper = false;

                break;
            }
        }

        this.setState({
            newTagName: "",
            newTagDesc: "",
            globalTagNameValid: globalTagNameValidHelper,
            isTagNameValid: true,
            sameTagName: false,
        });
    }

    public clearCurrentTag(tagId){

        console.log("clearCurrentTag", tagId);


        const tags = this.state.tagsData;
        for (let i = 0; i < tags.length; i++) {

            if (tags[i].id == tagId) {
                tags[i].name = this.state.oldTagName;
                tags[i].desc = this.state.oldTagDesc;
                tags[i].validName = true;
                tags[i].isSameName = false;
            }
        }
        const hasSameTagNameHelper = this.state.hasSameTagName;
        this.setState({
            tagsData: tags,
            globalTagNameValid: true && this.state.isTagNameValid,
            hasSameTagName: false && hasSameTagNameHelper,
        });
    }

    public unlockUserSettings = (e) => {
        this.setState({
            unlockButtonUserSettings: "glyphicon-edit",
            unlockChangeText: strings.EDIT_DEVICE_PARAMETERS,
            readOnly: false,
        });
    }




    public renderAdminRightsPanel = () => {

        const { EditUser, LoggedUser, isMe, readOnly } = this.state;


        return (<Form horizontal>
            {LoggedUser.tenant_admin &&
                renderFormGroupYesNoRadio({
                    value: EditUser.tenant_admin || false,
                    onChange: (value) => { this.HandleChangeRights(value, "tenant_admin") },
                    disabled: readOnly || isMe,
                    help: strings.EDIT_USER_TENANCY_ADMIN_RIGHTS_HELP,
                    label: strings.EDIT_USER_TENANCY_ADMIN_RIGHTS_LABEL,
                    controlId: "TenantAdminRights",
                })
            }

            {LoggedUser.org_admin &&
                renderFormGroupYesNoRadio({
                    value: EditUser.org_admin || false,
                    onChange: (value) => { this.HandleChangeRights(value, "org_admin") },
                    disabled: readOnly || isMe,
                    help: strings.EDIT_ORGANIZATION_ADMIN_RIGHTS_HELP,
                    label: strings.EDIT_ORGANIZATION_ADMIN_RIGHTS_LABEL,
                    controlId: "OrgAdminRights",
                })
            }

            {LoggedUser.customer_admin &&
                renderFormGroupYesNoRadio({
                    value: EditUser.customer_admin || false,
                    onChange: (value) => { this.HandleChangeRights(value, "customer_admin") },
                    disabled: readOnly || EditUser.org_admin || EditUser.tenant_admin || isMe,
                    help: strings.EDIT_CUSTOMER_ADMIN_RIGHTS_HELP,
                    label: strings.EDIT_CUSTOMER_ADMIN_RIGHTS_LABEL,
                    controlId: "CustomerAdminRights",
                })
            }

            {LoggedUser.administrator &&
                renderFormGroupYesNoRadio({
                    value: EditUser.administrator || false,
                    onChange: (value) => { this.HandleChangeRights(value, "administrator") },
                    disabled: readOnly || EditUser.customer_admin || isMe,
                    help: strings.EDIT_USER_ADMIN_RIGHTS_HELP,
                    label: strings.EDIT_USER_ADMIN_RIGHTS_LABEL,
                    controlId: "AdminRights",
                })
            }

            {(LoggedUser.administrator && LoggedUser.gtw_admin) &&
                renderFormGroupYesNoRadio({
                    value: EditUser.gtw_admin || false,
                    onChange: (value) => { this.HandleChangeRights(value, "gtw_admin") },
                    disabled: readOnly || isMe,
                    help: strings.EDIT_USER_GATEWAY_ADMIN_RIGHTS_HELP,
                    label: strings.EDIT_USER_GATEWAY_ADMIN_RIGHTS_LABEL,
                    controlId: "GatewayAdminRights",
                })
            }

            {(LoggedUser.administrator && LoggedUser.can_register) &&
                renderFormGroupYesNoRadio({
                    value: EditUser.can_register || false,
                    onChange: (value) => { this.HandleChangeRights(value, "can_register") },
                    disabled: readOnly || isMe,
                    help: strings.EDIT_USER_DEVICE_ADMIN_RIGHTS_HELP,
                    label: strings.EDIT_USER_DEVICE_ADMIN_RIGHTS_LABEL,
                    controlId: "RegistrationRights",
                })
            }
        </Form>);
    }



    public renderGatewayRightsPanel = () => {

        const { EditUser, LoggedUser, isMe, readOnly } = this.state;


        return (
        <Form horizontal>
            {(LoggedUser.administrator && LoggedUser.can_access_gtw_info) &&
                renderFormGroupYesNoRadio({
                    value: this.GetBooleanRight("can_access_gtw_info"),
                    onChange: (value) => { this.HandleChangeRights(value, "can_access_gtw_info") },
                    disabled: readOnly || isMe,
                    help: strings.EDIT_USER_NETWORK_ACCESS_HELP,
                    label: strings.EDIT_USER_NETWORK_ACCESS_LABEL,
                    controlId: "AccessGtwInfo",
                })
            }

            {(LoggedUser.can_access_gtw_unfiltered && LoggedUser.can_own_gtw) &&
                renderFormGroupYesNoRadio({
                    value: this.GetBooleanRight("can_access_gtw_unfiltered"),
                    onChange: (value) => { this.HandleChangeRights(value, "can_access_gtw_unfiltered") },
                    disabled: readOnly || isMe,
                    help: strings.EDIT_USER_NETWORK_ACCESS_UNFILTERED_HELP,
                    label: strings.EDIT_USER_NETWORK_ACCESS_UNFILTERED_LABEL,
                    controlId: "GtwUnfilteredAccess",
                })
            }


            {(LoggedUser.gtw_admin && LoggedUser.can_own_gtw) &&
                renderFormGroupYesNoRadio({
                    value: this.GetBooleanRight("can_own_gtw"),
                    onChange: (value) => { this.HandleChangeRights(value, "can_own_gtw") },
                    disabled: readOnly || isMe,
                    help: strings.EDIT_USER_GATEWAY_OWNERSHIP_HELP,
                    label: strings.EDIT_USER_GATEWAY_OWNERSHIP_LABEL,
                    controlId: "GtwOwnership",
                })
            }


            {(LoggedUser.gtw_admin && LoggedUser.can_add_gtw) &&
                renderFormGroupYesNoRadio({
                    value: this.GetBooleanRight("can_add_gtw"),
                    onChange: (value) => { this.HandleChangeRights(value, "can_add_gtw") },
                    disabled: readOnly  || isMe || !EditUser.can_own_gtw,
                    help: strings.EDIT_USER_GATEWAY_ADD_HELP,
                    label: strings.EDIT_USER_GATEWAY_ADD_LABEL,
                    controlId: "AddGateway",
                })
            }


            {(LoggedUser.gtw_admin && LoggedUser.can_mng_gtw) &&
                renderFormGroupYesNoRadio({
                    value: this.GetBooleanRight("can_mng_gtw"),
                    onChange: (value) => { this.HandleChangeRights(value, "can_mng_gtw") },
                    disabled: readOnly || isMe || !EditUser.can_own_gtw,
                    help: strings.EDIT_USER_GATEWAY_MANAGE_HELP,
                    label: strings.EDIT_USER_GATEWAY_MANAGE_LABEL,
                    controlId: "AddGateway",
                })
            }


            {(LoggedUser.administrator && LoggedUser.can_access_gtw_loc_info) &&
                renderFormGroupYesNoRadio({
                    value: this.GetBooleanRight("can_access_gtw_loc_info"),
                    onChange: (value) => { this.HandleChangeRights(value, "can_access_gtw_loc_info") },
                    disabled: readOnly || isMe,
                    help: strings.EDIT_USER_NETWORK_ACCESS_LOC_INFO_HELP,
                    label: strings.EDIT_USER_NETWORK_ACCESS_LOC_INFO,
                    controlId: "GtwLocation",
                })
            }


            {(LoggedUser.gtw_admin && LoggedUser.can_access_gtw_trace) &&
                renderFormGroupYesNoRadio({
                    value: this.GetBooleanRight("can_access_gtw_trace"),
                    onChange: (value) => { this.HandleChangeRights(value, "can_access_gtw_trace") },
                    disabled: readOnly || isMe,
                    help: strings.EDIT_USER_GATEWAY_TRACE_HELP,
                    label: strings.EDIT_USER_GATEWAY_TRACE_LABEL,
                    controlId: "GtwLocation",
                })
            }

        </Form>);
    }


    public renderLocationPanel = () => {

        const { isMe, readOnly } = this.state;

        return <Form horizontal>
            {
                renderFormGroupYesNoRadio({
                    value: this.GetBooleanRight("loraloc_enable"),
                    onChange: (value) => { this.HandleChangeRights(value, "loraloc_enable") },
                    disabled: readOnly || isMe,
                    help: strings.EDIT_USER_LORA_LOCATION_HELP,
                    label: strings.EDIT_USER_LORA_LOCATION_LABEL,
                    controlId: "LoraLocationService",
                })
            }
        </Form>;
    }



    public renderSystemPanel = () => {

        const { LoggedUser, isMe, readOnly } = this.state;



        return <Form horizontal>
            {LoggedUser.sys_admin &&
                 renderFormGroupYesNoRadio({
                    value: this.GetBooleanRight("sys_admin"),
                    onChange: (value) => { this.HandleChangeRights(value, "sys_admin") },
                    disabled: readOnly || isMe,
                    help: strings.EDIT_USER_SYSTEM_ADMIN_HELP,
                    label: strings.EDIT_USER_SYSTEM_ADMIN_LABEL,
                    controlId: "SystemAdminRights",
                })
            }

            {LoggedUser.data_archive_admin &&
                renderFormGroupYesNoRadio({
                    value: this.GetBooleanRight("data_archive_admin"),
                    onChange: (value) => { this.HandleChangeRights(value, "data_archive_admin"); },
                    disabled: readOnly || isMe,
                    help: strings.EDIT_USER_DATA_ARCHIVE_ADMIN_HELP,
                    label: strings.EDIT_USER_DATA_ARCHIVE_ADMIN_LABEL,
                    controlId: "DataArchiveAdminRights",
                })
            }

            {LoggedUser.can_access_logs &&
                renderFormGroupYesNoRadio({
                    value: this.GetBooleanRight("can_access_logs"),
                    onChange: (value) => { this.HandleChangeRights(value, "can_access_logs"); },
                    disabled: readOnly || isMe,
                    help: strings.EDIT_USER_CAN_ACCESS_LOGS_HELP,
                    label: strings.EDIT_USER_CAN_ACCESS_LOGS_LABEL,
                    controlId: "DataArchiveAdminRights",
                })
            }

            {LoggedUser.can_view_mac_msg &&
                renderFormGroupYesNoRadio({
                    value: this.GetBooleanRight("can_view_mac_msg"),
                    onChange: (value) => { this.HandleChangeRights(value, "can_view_mac_msg"); },
                    disabled: readOnly || isMe,
                    help: strings.MAC_MSG_HELP,
                    label: strings.MAC_MSG,
                    controlId: "MacMsgRights",
                })
            }

            {LoggedUser.can_register_joinserver &&
                renderFormGroupYesNoRadio({
                    value: this.GetBooleanRight("can_register_joinserver"),
                    onChange: (value) => { this.HandleChangeRights(value, "can_register_joinserver"); },
                    disabled: readOnly || isMe,
                    help: strings.EDIT_USER_CAN_REGISTER_JOINSERVER_HELP,
                    label: strings.EDIT_USER_CAN_REGISTER_JOINSERVER_LABEL,
                    controlId: "CanRegisterJoinserver",
                })
            }

            {LoggedUser.can_set_alarms &&
                renderFormGroupYesNoRadio({
                    value: this.GetBooleanRight("can_set_alarms"),
                    onChange: (value) => { this.HandleChangeRights(value, "can_set_alarms"); },
                    disabled: readOnly || isMe,
                    help: strings.EDIT_USER_CAN_SET_ALARMS_HELP,
                    label: strings.EDIT_USER_CAN_SET_ALARMS_LABEL,
                    controlId: "CanRegisterSetAlarms",
                })
            }

        </Form>;
    }


    /**
     * Check all input fields for error. This is used to show the red box on the invalid field, and to
     * ghost the "submit" button
     */
    public checkInputValidity() {

        const { EditUser, LoggedUser, OrigUser } = this.state;

        const canChangePassword = EditUser.auth_server && EditUser.auth_server.indexOf("password") >= 0;
        const canChangeSubject  = EditUser.auth_server && EditUser.auth_server.indexOf("subject") >= 0;


        const errNumDevices  = EditUser.max_num_devices < 0;
        const errNumGateways = EditUser.max_num_gateways < 0;

        const emailChanged  = (EditUser.email || "") !== (this.state.OrigUser.email || "");
        const errEmail      = emailChanged && !isEmail(EditUser.email || "");

        let errEnvironment = false;
        if (this.state.Environment) {
            try {
                JSON.parse(this.state.Environment);
            } catch (e) {
                errEnvironment = true;
            }
        }

        const errUserId   = (this.props.NewUserType || OrigUser.userid !== EditUser.userid) && !UserIdRegExp.test(EditUser.userid || "");



        //- check password --------------------------------------------

        let infoPasswordNoUpper = false;
        let infoPasswordNoLower = false;
        let infoPasswordNoSpecial = false;
        let infoPasswordNoNumber = false;
        let infoPasswordMinLength = 8;
        let infoPasswordIsComplex = false;

        let errPasswordNotComplex = false;
        let errPasswordTooShort = false;
        let errPassword = false;

        // The password is validated only if canChangePassword is true
        // Then for an edit, we only validate if the password is entered.
        // for a new user, we force validate always except if a valid email is entered.

        if (canChangePassword &&
                (this.state.NewPassword.length > 0 ||
                 this.props.NewUserType && !(EditUser.email && !errEmail))) {

            // FIXME: how to get the correct user password policy
            const password = this.state.NewPassword;
            const isAdmin  = EditUser.administrator || EditUser.customer_admin || EditUser.tenant_admin || EditUser.sys_admin;
            const passwordPolicies: IPasswordPolicies = EditUser.password_policies || LoggedUser.password_policies || {};
            const policy   = passwordPolicies[isAdmin ? "admin" : "user"];

            console.log("policy", policy);

            if (policy) {
                if (policy.enable_complex_password) {
                    infoPasswordIsComplex = true;
                    const lower   = (password.match(/[a-z]/)) ? 1 : 0;
                    const upper   = (password.match(/[A-Z]/)) ? 1 : 0;
                    const number  = (password.match(/[0-9]/)) ? 1 : 0;
                    const special = (password.match(/[- !"#$%&'()*+,./:;<=>?@[\\\]^_`{|}~]/)) ? 1 : 0;

                    infoPasswordNoUpper = upper === 0;
                    infoPasswordNoLower = lower === 0;
                    infoPasswordNoSpecial = special === 0;
                    infoPasswordNoNumber = number === 0;

                    if (lower + upper + number + special < 3) {
                        errPasswordNotComplex = true;
                        errPassword = true;
                    }
                }
                if (policy.min_password_length >= 4) {
                    infoPasswordMinLength = policy.min_password_length;
                }
            }
            errPasswordTooShort = errPassword = password.length < infoPasswordMinLength;
        }

        const errOldPassword = this.props.NewUserType == null &&
                    (this.state.LoggedUser.forwarding_userid || this.state.LoggedUser.userid) === this.state.OrigUser.userid &&
                    this.state.NewPassword.length > 0 && this.state.OldPassword.length === 0;
        const errPasswordConfirm = canChangePassword && this.state.NewPassword !== this.state.ConfirmPassword;
        const errSubject = canChangeSubject && false;

        const Errors: IErrors = {
            error: false,   // any error
            errNumDevices,
            errNumGateways,
            errEnvironment,
            errEmail,
            errUserId,
            errPassword,
            errPasswordConfirm,
            errPasswordNotComplex,
            errPasswordTooShort,
            errOldPassword,
            errSubject,

            infoPasswordNoUpper,
            infoPasswordNoLower,
            infoPasswordNoSpecial,
            infoPasswordNoNumber,
            infoPasswordMinLength,
            infoPasswordIsComplex,
        };

        // "OR" all errors
        for (const key of Object.keys(Errors)) {
            if (key.startsWith("err") && Errors[key]) {
                Errors.error = true;
            }
        }

        this.setState({ Errors });
    }


    public renderSettingsPanel = () => {
        const { EditUser, LoggedUser, Errors } = this.state;

        return <Form horizontal>
            {constants.enable_limit_devices === true && (
                <FormGroup
                    controlId="MaxNumDevices"
                    validationState={ Errors.errNumDevices ? "error" : null }
                >
                    <Col componentClass={ControlLabel} sm={5} >
                        <strong className="new_style_font_weight">
                            {strings.MAX_DEVICES}
                        </strong>
                    </Col>
                    <Col sm={7}>
                        <FormControl className="limitDevicesGataways"
                            disabled={EditUser.is_customer ? (!LoggedUser.customer_admin || this.state.readOnly) : (!LoggedUser.administrator || this.state.readOnly)}
                            type="number"
                            value={this.NumberFieldValue("max_num_devices", "Settings" )}
                            onChange={(event: any) => {this.HandleChangeRights(event.target.value, "max_num_devices", "number"); }}
                        />
                        <HelpBlock>
                            <small className="new_style_help_block_color" >
                                {strings.MAX_DEVICES_HELP}
                            </small>
                        </HelpBlock>
                    </Col>
                </FormGroup>
            )}

            {constants.enable_limit_gateways === true && (
                <FormGroup
                    controlId="MaxNumGtws"
                    validationState={ Errors.errNumGateways ? "error" : null }
                >
                    <Col componentClass={ControlLabel} sm={5} >
                        <strong className="new_style_font_weight">
                            {strings.MAX_GATEWAYS}
                        </strong>
                    </Col>
                    <Col sm={7}>
                        <FormControl className="limitDevicesGataways"
                            // tslint:disable-next-line:max-line-length
                            disabled={EditUser.is_customer ? (!LoggedUser.customer_admin || this.state.readOnly) : (!LoggedUser.administrator || this.state.readOnly)}
                            type="number"
                            value={this.NumberFieldValue("max_num_gateways", "Settings")}
                            onChange={(event: any) => { this.HandleChangeRights(event.target.value, "max_num_gateways", "number"); }}
                        />
                        <HelpBlock>
                            <small className="new_style_help_block_color" >
                                {strings.MAX_GATEWAYS_HELP}
                            </small>
                        </HelpBlock>
                    </Col>
                </FormGroup>
            )}

            {LoggedUser.sys_admin &&
                renderFormGroupYesNoRadio({
                    value: this.GetBooleanRight("verbose_log"),
                    onChange: (value) => { this.HandleChangeRights(value, "verbose_log"); },
                    disabled: this.state.readOnly,
                    help: strings.EDIT_USER_EXTENDED_LOGGING_HELP,
                    label: strings.EDIT_USER_EXTENDED_LOGGING_LABEL,
                    controlId: "DataArchiveAdminRights",
                })
            }


            {LoggedUser.can_set_alarms &&
                renderFormGroupYesNoRadio({
                    value: this.GetBooleanRight("email_alarm_subscription", ""),
                    onChange: (value) => { this.HandleChangeRights(value, "email_alarm_subscription", "string"); },
                    disabled: this.state.readOnly,
                    help: strings.EMAIL_ALARM_SUBSCRIPTION_HELP,
                    label: strings.EMAIL_ALARM_SUBSCRIPTION,
                    controlId: "EmailAlarmSubscription",
                    dict: [
                        { label: strings.YES,     value: "all"},
                        { label: strings.NO,      value: ""},
                    ]
                })
            }

            {LoggedUser.data_archive_admin &&
                renderFormGroupYesNoRadio({
                    value: this.GetBooleanRight("auto_archive_data"),
                    onChange: (value) => { this.HandleChangeRights(value, "auto_archive_data", "string"); },
                    disabled: this.state.readOnly,
                    help: strings.EDIT_USER_AUTO_ARCHIVE_DATA_HELP,
                    label: strings.EDIT_USER_AUTO_ARCHIVE_DATA_LABEL,
                    controlId: "AutoArchiveData",
                    dict: [
                        { label: strings.YES,     value: "enabled"},
                        { label: strings.NO,      value: "disabled"},
                        { label: strings.DEFAULT, value: "default"},
                    ]
                })
            }

        </Form>
    }


    public renderUserInfoPanel = () => {

        const { EditUser, Errors } = this.state;
        const emailNoChange = (EditUser.email || "") === (this.state.OrigUser.email || "");

        return <Form horizontal>

            <FormGroup controlId="Email" className="margin_bottom_0"
                validationState={Errors.errEmail ? "error" : null }
            >
                <Col smOffset={1} sm={11} >
                    <ControlLabel>
                        <strong className="new_style_font_weight">
                            {strings.EDIT_EMAIL_LABEL}
                        </strong>
                    </ControlLabel>
                    <FormControl
                        type="text"
                        className="new_style_focus"
                        disabled={this.state.readOnly}
                        value={EditUser.email || ""}
                        onChange={(event: any) => { this.HandleChangeRights(event.target.value, "email", "string") }}
                    />

                    <HelpBlock>
                        <small className={"new_style_help_block_color" + (" dark-anchor" + (this.state.readOnly ? "-disable" : ""))} >
                            {EditUser.email && emailNoChange && (EditUser.email_verified
                                ? (<><span className="glyphicon glyphicon-ok marker_green" /> {"Email address has been verified. "} </>)
                                : (<><span className="glyphicon glyphicon-remove marker_ed" /> {"Email address has NOT been verified. "}
                                    {!EditUser.email_to_be_verified && <a onClick={() => this.emailVerify(true)}>Send verification email</a>}
                                    </>))}

                            {EditUser.email_to_be_verified && emailNoChange && (<span className={"dark-anchor" + (this.state.readOnly ? "-disable" : "")}>
                                {`Email address '${EditUser.email_to_be_verified}' waiting to be verified`} (
                                    <a onClick={() => this.emailVerify(false) }>Ignore</a>
                                    , <a onClick={() => this.emailVerify(true)}>Resend email</a>
                                )
                                </span>)
                            }
                        </small>
                    </HelpBlock>
                </Col>
            </FormGroup>

            <FormGroup controlId="UserInfo" className="margin_bottom_0" >
                <Col smOffset={1} sm={11} >
                    <ControlLabel>
                        <strong className="new_style_font_weight">
                            {strings.EDIT_USER_INFO_LABEL}
                        </strong>
                    </ControlLabel>
                    <FormControl
                        componentClass="textarea"
                        disabled={this.state.readOnly}
                        value={EditUser.userinfo || ""}
                        className="new_style_textarea"
                        onChange={(event: any) => {this.HandleChangeRights(event.target.value, "userinfo", "string")}}
                    />
                    <HelpBlock>
                        <small className="new_style_help_block_color" >
                            {strings.EDIT_USER_INFO_HELP}
                        </small>
                    </HelpBlock>
                </Col>
            </FormGroup>

        </Form>;
    }

    public renderPasswordPanel = () => {

        const { Errors, LoggedUser, EditUser } = this.state;
        const needOldPassword = this.props.NewUserType == null &&
                (LoggedUser.forwarding_userid || LoggedUser.userid) === EditUser.userid;

        return <Form horizontal>

            {needOldPassword && <FormGroup
                controlId="OldPassword"
                className="margin_bottom_0"
                validationState={Errors.errOldPassword ? "error" : null}
            >
                <Col smOffset={1} sm={11} >
                    <ControlLabel>
                        <strong className="new_style_font_weight">
                            {strings.EDIT_USER_OLD_PASSWORD}
                        </strong>
                    </ControlLabel>

                    <FormControl
                        type="password"
                        className="new_style_focus"
                        autoComplete={"old-password"}
                        disabled={this.state.readOnly}
                        onChange={(event: any) => {
                            this.setState({ OldPassword: event.target.value }, () => this.checkInputValidity());
                        }}
                    />
                    <HelpBlock>
                        <small className="new_style_help_block_color" >
                            {strings.EDIT_USER_OLD_PASSWORD_HELP}
                        </small>
                    </HelpBlock>
                </Col>
            </FormGroup>}


            <FormGroup
                controlId="NewPassword"
                className="margin_bottom_0"
                validationState={Errors.errPassword ? "error" : null}
            >
                <Col smOffset={1} sm={11} >
                    <ControlLabel>
                        <strong className="new_style_font_weight">
                            {strings.PASSWORD}
                        </strong>
                    </ControlLabel>

                    <FormControl
                        type="password"
                        className="new_style_focus"
                        autoComplete={"new-password"}
                        disabled={this.state.readOnly}
                        onChange={(event: any) => {
                            this.setState({ NewPassword: event.target.value }, () => this.checkInputValidity());
                        }}
                    />

                    <HelpBlock className={"new_style_help_block_color new_password" + (" dark-anchor" + (this.state.readOnly ? "-disable" : ""))}>
                        <div>
                            {Errors.infoPasswordIsComplex  && (
                                <span>
                                    {(Errors.errPasswordNotComplex === Errors.infoPasswordNoUpper) && (
                                        <span>
                                            <span className={Errors.infoPasswordNoUpper ? "glyphicon glyphicon-remove marker_ed" : "glyphicon glyphicon-ok marker_green"} />
                                            <span className="size_sma color_text font_size_11">
                                                {strings.UPPER_CASE}
                                            </span>
                                        </span>
                                    )}
                                    {(Errors.errPasswordNotComplex === Errors.infoPasswordNoLower) && (
                                        <span>
                                            <span className={Errors.infoPasswordNoLower ? "glyphicon glyphicon-remove marker_ed" : "glyphicon glyphicon-ok marker_green"} />
                                            <span className="size_sma color_text font_size_11">
                                                {strings.LOWER_CASE}
                                            </span>
                                        </span>
                                    )}
                                    {(Errors.errPasswordNotComplex === Errors.infoPasswordNoSpecial) && (
                                        <span>
                                            <span className={Errors.infoPasswordNoSpecial ? "glyphicon glyphicon-remove marker_ed" : "glyphicon glyphicon-ok marker_green"} />
                                            <span className="size_sma color_text font_size_11">
                                                {strings.SPECIAL_CHARACTERS}
                                            </span>
                                        </span>
                                    )}
                                    {(Errors.errPasswordNotComplex === Errors.infoPasswordNoNumber) && (
                                        <span>
                                            <span className={Errors.infoPasswordNoNumber ? "glyphicon glyphicon-remove marker_ed" : "glyphicon glyphicon-ok marker_green"} />
                                            <span className="size_sma color_text font_size_11">
                                                {strings.NUMBER}
                                            </span>
                                        </span>
                                    )}
                                </span>
                            )}
                            {Errors.errPasswordTooShort && (
                                <span>
                                    <span className={Errors.errPasswordTooShort ? "glyphicon glyphicon-remove marker_ed" : "glyphicon glyphicon-ok marker_green"} />
                                    <span className="size_sma color_text font_size_11">
                                        {strings.formatString(strings.NEW_PASSWORD_LENGTH, { length_value: Errors.infoPasswordMinLength })}
                                    </span>
                                </span>
                            )}
                        </div>
                    </HelpBlock>
                </Col>
            </FormGroup>

            {/*- Confirm PASSWORD --------------------------------------------------------*/}

            <FormGroup
                controlId="ConfirmPassword"
                className="margin_bottom_0"
                validationState={Errors.errPasswordConfirm ? "error" : null}
            >
                <Col smOffset={1} sm={11} >
                    <ControlLabel>
                        <strong className="new_style_font_weight">
                            {strings.EDIT_USER_PASSWORD_CONFIRM}
                        </strong>
                    </ControlLabel>

                    <FormControl
                        value={this.state.ConfirmPassword}
                        type="password"
                        className="new_style_focus"
                        autoComplete={"new-password"}
                        disabled={this.state.readOnly}
                        onChange={(event: any) => {
                            this.setState({ ConfirmPassword: event.target.value }, () => this.checkInputValidity());
                        }}
                    />
                    <HelpBlock className={"new_style_help_block_color new_password" + (" dark-anchor" + (this.state.readOnly ? "-disable" : ""))}>
                        {(this.state.NewPassword.length > 0) && (
                            <div>
                                <span className={(
                                this.state.NewPassword === this.state.ConfirmPassword) ?
                                    "glyphicon glyphicon-ok marker_green" :
                                    "glyphicon glyphicon-remove marker_ed"
                                }></span>
                                <span className="size_sma color_text font_size_11">
                                    {strings.PASSWORD_MATCH}
                                </span>
                            </div>
                        )}
                    </HelpBlock>
                </Col>
            </FormGroup>

        </Form>;
    }




    public renderEnvironmentPanel = () => {

        const { EditUser, Errors } = this.state;


        return <Form horizontal>
            <FormGroup controlId="UserInfo" className="margin_bottom_0"
                validationState={Errors.errEnvironment ? "error" : null}
            >
                <Col smOffset={1} sm={11} >
                    <ControlLabel>
                        <strong className="new_style_font_weight">
                            Local Environment Settings
                        </strong>
                    </ControlLabel>
                    <FormControl
                        componentClass="textarea"
                        rows={6}
                        disabled={this.state.readOnly}
                        value={this.state.Environment}
                        className="new_style_textarea"
                        onChange={(event: any) => {
                            this.setState({ Environment: event.target.value }, () => this.checkInputValidity());
                        }}
                    />
                    <HelpBlock>
                        <small className="new_style_help_block_color" >
                            Edit the account environment in valid JSON format.
                        </small>
                    </HelpBlock>
                </Col>
            </FormGroup>


            <FormGroup controlId="UserInfo" className="margin_bottom_0">
                <Col smOffset={1} sm={11} >
                    <ControlLabel>
                        <strong className="new_style_font_weight">
                            Environment
                        </strong>
                    </ControlLabel>
                    <FormControl
                        componentClass="textarea"
                        rows={6}
                        disabled={true}
                        value={JSON.stringify(EditUser._environment, null, 4) || ""}
                        className="new_style_textarea"
                    />
                    <HelpBlock>
                        <small className="new_style_help_block_color" >
                            Current resulting environment (note does not reflect any changes in the local environment settings above).
                        </small>
                    </HelpBlock>
                </Col>
            </FormGroup>


        </Form>;
    }



    public renderTenancyPanel = () => {
        return <Form horizontal>
            <FormGroup>
                <Col smOffset={1} sm={11}>
                    <div className="margin-bottom-tenancy">
                        <strong className="display_inline">
                            {strings.EDIT_CUSTOMER_TENNANCY_LABEL}:
                        </strong> &nbsp;

                        {this.state.ActualTenant && this.state.ActualTenant.name && (
                            <div className="display_inline">
                                {strings.formatString(strings.TENANT_MSG, { name: this.state.ActualTenant.name })}
                            </div>
                        )}

                        {this.state.ActualTenant && !this.state.ActualTenant.name && (
                            <div className="display_inline">
                                {strings.formatString(strings.TENANT_DELETED_MSG, { deletedTenantId: this.state.ActualTenant })}
                            </div>
                        )}

                        {!this.state.ActualTenant && (
                            <div className="display_inline">
                                {strings.NO_TENANT_ASSIGNED}
                            </div>
                        )}
                    </div>

                    {this.state.ShowTenantTable === false && (
                        <Button
                            className={`pull-right change-tenant-btn ${this.state.readOnly == true ? "" : "black_b_btn"}`}
                            disabled={this.state.readOnly} onClick={this.ShowTenantTable}
                        >
                            {strings.CHANGE_TENANT}
                        </Button>
                    )}

                    {this.state.ShowTenantTable && (
                        <Button className="pull-right red_b_btn_cancel" disabled={this.state.readOnly} onClick={this.ShowTenantTable}>
                            {strings.CANCEL}
                        </Button>
                    )}
                    <HelpBlock>
                        <small className="new_style_help_block_color">
                            {strings.EDIT_CUSTOMER_TENNANCY_HELP}
                        </small>
                    </HelpBlock>

                </Col>

                {this.state.ShowTenantTable && (
                    <Col sm={12} className="all_padding_0" id="data_table_modal">
                        <TenancyTable
                            Tenants={this.state.Tenants}
                            TablePagination={this.LoadTenants}
                            GetTableData={this.TenantsPageList}
                            PaginationData= {this.state.PaginationData}
                            SelectedRow={this.SelectTenant}
                        />
                    </Col>
                )}
            </FormGroup>
        </Form>;
    }


    public renderUserOrgPanel = () => {
        return <Form horizontal>
            <FormGroup>
                <Col smOffset={1} sm={11}>
                    {this.state.ShowOrganizationTable === false && (
                        <Button className="pull-right black_b_btn" disabled={this.state.readOnly}
                                onClick={this.ShowOrganizationTable} >
                            {strings.CHANGE_ORGANIZATION}
                        </Button>
                    )}
                    {this.state.ShowOrganizationTable && (
                        <Button className="pull-right red_b_btn_cancel" onClick={this.ShowOrganizationTable} >
                            {strings.CANCEL}
                        </Button>
                    )}
                </Col>
                {this.state.ShowOrganizationTable && (
                    <Col sm={12} className="all_padding_0" id="data_table_modal" >
                        <OrganizationTable
                            Organizations={this.state.Organizations}
                            TablePagination={this.LoadOrganizations}
                            GetTableData={this.OrganizationsPageList}
                            PaginationData= {this.state.PaginationDataOrganizations}
                            SelectedRow={this.SelectOrganization}
                        />
                    </Col>
                )}
            </FormGroup>
        </Form>;
    }



    public renderProfilesPanel = () => {

        const EditUser = this.state.EditUser;
        const LoggedUser = this.state.LoggedUser;

        const DeviceProfiles = LoggedUser.can_list_device_profile ||
                            LoggedUser.can_inspect_device_profile || LoggedUser.can_create_device_profile ||
                            LoggedUser.can_link_device_profile;
        const ServiceProfiles = LoggedUser.can_list_service_profile ||
                            LoggedUser.can_inspect_service_profile || LoggedUser.can_create_service_profile ||
                            LoggedUser.can_link_service_profile || LoggedUser.can_restrict_service_profile;
        const ConnectivityProfiles = LoggedUser.can_list_connectivity_profile ||
                            LoggedUser.can_inspect_connectivity_profile ||
                            LoggedUser.can_create_connectivity_profile || LoggedUser.can_link_connectivity_profile;
        const RoamingProfiles = LoggedUser.can_list_roaming_profile ||
                            LoggedUser.can_inspect_roaming_profile || LoggedUser.can_create_roaming_profile || LoggedUser.can_link_roaming_profile;
        const ChannelProfiles = LoggedUser.can_list_channel_profile ||
                            LoggedUser.can_inspect_channel_profile || LoggedUser.can_create_channel_profile || LoggedUser.can_link_channel_profile;
        const QoSProfiles = LoggedUser.can_list_qos_profile || LoggedUser.can_inspect_qos_profile ||
                            LoggedUser.can_create_qos_profile || LoggedUser.can_link_qos_profile;

        return <>
            <ButtonToolbar className="margin_left_0">

                {DeviceProfiles && (
                    <Button
                        className="button_profiles"
                        active={(this.state.ActiveProfileTab === "device") ? true : false }
                        onClick={() => { this.ChangeProfileTab("device"); }}
                    >
                        {strings.NAV_DEVICE_PROFILES}
                    </Button>
                )}

                {ServiceProfiles && (
                    <Button
                        className="button_profiles"
                        active={(this.state.ActiveProfileTab === "service") ? true : false }
                        onClick={() => { this.ChangeProfileTab("service"); }}
                    >
                        {strings.NAV_SERVICE_PROFILES}
                    </Button>
                )}
                {ConnectivityProfiles && (
                    <Button
                        className="button_profiles"
                        active={ (this.state.ActiveProfileTab === "connectivity") ? true : false }
                        onClick={() => { this.ChangeProfileTab("connectivity"); }}
                    >
                        {strings.NAV_CONNECTIVITY_PROFILES}
                    </Button>
                )}
                {RoamingProfiles && (
                    <Button
                        className="button_profiles"
                        active={ (this.state.ActiveProfileTab === "roaming") ? true : false }
                        onClick={() => { this.ChangeProfileTab("roaming"); }}
                    >
                        {strings.NAV_ROAMING_PROFILES}
                    </Button>
                )}
                {ChannelProfiles && (
                    <Button
                        className="button_profiles"
                        active={ (this.state.ActiveProfileTab === "channel") ? true : false }
                        onClick={() => { this.ChangeProfileTab("channel"); }}
                    >
                        {strings.NAV_CHANNEL_PROFILES}
                    </Button>
                )}
                {QoSProfiles && (
                    <Button
                        className="button_profiles"
                        active={ (this.state.ActiveProfileTab === "qos") ? true : false }
                        onClick={() => { this.ChangeProfileTab("qos"); }}
                    >
                        {strings.NAV_QOS_PROFILES}
                    </Button>
                )}
            </ButtonToolbar>

            {(this.state.ActiveProfileTab !== undefined) && (
                <ProfilesRights
                    ActiveTab={this.state.ActiveProfileTab}
                    ForwardUser={EditUser}
                    readOnly={this.state.readOnly}
                    User={LoggedUser}
                    HandleRightChange={this.HandleChangeRights}
                    DisableButton={this.state.DisableButton}
                />
            )}
        </>;
    }




    public renderPasswordSpecificPolicy = (type: "user" | "admin" | "app", title: string) => {

        const EditUser = this.state.EditUser;
        const PasswordPolicies = EditUser.password_policies;


        return <Form className="pass_policy_box_bg">
            <FormGroup controlId={type + "policy"} className="padding_top_1" >
                <h4 className="pass_policy_h">
                    <strong className="new_style_font_weight">
                        {title}
                    </strong>
                </h4>
            </FormGroup>

            <FormGroup controlId="PassHistory" className="margin_bottom_0" >
                <Col componentClass={ControlLabel} className="pass_policy_label padding_left_right_0 pull-left margin_left_0">
                    {strings.PASSWORD_HISTORY}
                </Col>
                <Col className="padding_left_right_0 pull-right">

                    { renderYesNoRadio({
                        disabled: this.state.readOnly,
                        radioButtonClassName: "pass_policy_btn_new_style",
                        value: PasswordPolicies[type].enforce_password_history,
                        onChange: (value) => { this.HandlePasswordPolicy(value, "enforce_password_history", type)}
                    })}

                </Col>
                <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={this.PopOverHover(
                        strings.PASSWORD_HISTORY_DESC_HOVER,
                    )}
                >
                    <HelpBlock className="pass_policy_desc inline_block">
                        {strings.PASSWORD_HISTORY_DESC}
                    </HelpBlock>
                </OverlayTrigger>
            </FormGroup>

            <FormGroup controlId="ComplexPass" className="margin_bottom_0" >
                <Col componentClass={ControlLabel} className="pass_policy_label padding_left_right_0 pull-left margin_left_0">
                    {strings.COMPLEX_PASSWORD}
                </Col>
                <Col className="padding_left_right_0 pull-right" >

                    { renderYesNoRadio({
                        disabled: this.state.readOnly,
                        radioButtonClassName: "pass_policy_btn_new_style",
                        value: PasswordPolicies[type].enable_complex_password,
                        onChange: (value) => { this.HandlePasswordPolicy(value, "enable_complex_password", type)}
                    })}

                </Col>
                <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={this.PopOverHover(
                        strings.COMPLEX_PASSWORD_DESC_HOVER,
                    )}
                >
                    <HelpBlock
                        className="pass_policy_desc
                        inline_block">
                        {strings.COMPLEX_PASSWORD_DESC}
                    </HelpBlock>
                </OverlayTrigger>
            </FormGroup>

            <FormGroup
                controlId={type + "MinPassLength"}
                className="margin_bottom_0"
                validationState={
                    ((PasswordPolicies[type].min_password_length !== null) &&
                    (PasswordPolicies[type].min_password_length < 8  || PasswordPolicies[type].min_password_length % 1 !== 0)) ?
                                "error" : null
                }
            >
                <Col componentClass={ControlLabel} className="pass_policy_label padding_left_right_0 pull-left margin_left_0">
                    {strings.PASSWORD_LENGTH}
                </Col>

                <Col className="padding_left_right_0 pull-right" >
                    <FormControl
                        type="number"
                        min="8"
                        disabled={this.state.readOnly}
                        className="pass_policy_input"
                        value={this.NumberFieldValue("min_password_length", "PasswordPolicy", type)}
                        onChange={(event: any) => {
                            this.HandlePasswordPolicy(event.target.value, "min_password_length", type);
                        }}
                    />
                </Col>
                <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={this.PopOverHover(strings.PASSWORD_LENGTH_DESC_HOVER)}
                >
                    <HelpBlock className="pass_policy_desc inline_block" >
                        {strings.PASSWORD_LENGTH_DESC}
                    </HelpBlock>
                </OverlayTrigger>
            </FormGroup>

            <FormGroup controlId={type + "MaxPassAge"} className="margin_bottom_0" >
                <Col
                    componentClass={ControlLabel}
                    className="pass_policy_label
                        padding_left_right_0 pull-left
                        margin_left_0"
                >
                    {strings.PASSWORD_AGE}
                </Col>

                <Col className="padding_left_right_0 pull-right" >
                    <FormControl
                        type="number"
                        className="pass_policy_input"
                        disabled={this.state.readOnly}
                        value={this.NumberFieldValue("max_password_age", "PasswordPolicy", type )}
                        onChange={(event: any) => {
                            this.HandlePasswordPolicy(event.target.value, "max_password_age", type);
                        }}
                    />
                </Col>
                <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={this.PopOverHover(strings.PASSWORD_AGE_DESC_HOVER)}
                >
                    <HelpBlock className="pass_policy_desc inline_block" >
                        {strings.PASSWORD_AGE_DESC}
                    </HelpBlock>
                </OverlayTrigger>
            </FormGroup>
        </Form>;
    }



    public renderPasswordPolicyPanel = () => {

        return <Row className="pass_policy_row padding_top_0_important">
            <Col md={4}>
                {this.renderPasswordSpecificPolicy("user", strings.USERS)}
            </Col>

            <Col md={4}>
                {this.renderPasswordSpecificPolicy("admin", strings.ADMINISTRATORS)}
            </Col>

            <Col md={4}>
                {this.renderPasswordSpecificPolicy("app", strings.NAV_APPLICATIONS_ITEM)}
            </Col>
        </Row>;
    }




    public renderTagsPanel = () => {

        const that = this;
        const listTags = this.state.tagsData.map((tag) => {
            return (
                <tr key={tag.id}>
                    <td className={`tag-name-input ${tag.editable}`}>
                        <div className="hide-value">
                            { tag.name }
                        </div>

                        <div className="hide-input">
                            <input
                                type="text"
                                value={tag.name}
                                className="tag-input-container"
                                onKeyPress={that.handleKeyPressOnEditTag.bind(that)}
                                ref={that.setRef}
                                onChange={that.tagNameChange.bind(that, tag.id)} />

                                {!tag.validName && (
                                    <span className="popover-tag-name-react">
                                        {strings.TAG_NAME_AVAILABLE_CHARS}
                                    </span>
                                )}

                                {tag.isSameName && (
                                    <span className="popover-tag-name-react">
                                        {strings.TAG_NAME_UNIQUE}
                                    </span>
                                )}
                        </div>
                    </td>

                    <td className={`tag-desc-input ${tag.editable}`}>
                        <div className="hide-value">
                            { tag.desc }
                        </div>

                        <div className="hide-input">
                            <input
                            type="text"
                            value={tag.desc}
                            className="tag-input-container"
                            onKeyPress={that.handleKeyPressOnEditTag.bind(that)}
                            onChange={that.tagDescChange.bind(that, tag.id)} />
                        </div>
                    </td>

                    <td className="tag-acion-container">
                        <div className={`current-tag-edit-btns ${tag.editable}`}>
                            <button
                                className="far fa-edit fa-fw edit-icon edit-tag"
                                disabled={that.state.readOnly}
                                onClick={that.editTag.bind(that, tag.id)} >
                            </button>

                            <button
                                className="far fa-trash-alt fa-fw delete-icon"
                                disabled={that.state.readOnly}
                                onClick={that.deleteTag.bind(that, tag.id)} >
                            </button>
                        </div>

                        <div className={`current-tag-action-btns ${tag.editable}`}>
                            <button
                                className="fas fa-check fa-fw edit-icon"
                                onClick={that.saveEditingTag.bind(that)} >
                            </button>

                            <button
                                className="fas fa-times fa-fw clear-input"
                                onClick={that.clearCurrentTag.bind(that, tag.id)} >
                            </button>
                        </div>
                    </td>
                </tr>
            );
        });

        return <Row className="pass_policy_row padding_top_0_important">
            <Table className="table table-striped table-hover new_style_dark tags-table">
                <thead>
                    <tr>
                        <th>{strings.EDIT_USER_TAGS_NAME}</th>
                        <th>{strings.EDIT_USER_TAGS_DESCRIPTION}</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody className="table-body">
                    { listTags }

                    <tr className="new-tag-container">
                        <td>
                            <input
                                ref={this.newTagNameRef}
                                type="text"
                                value={this.state.newTagName}
                                className="new-tag new-tag-name"
                                disabled={this.state.readOnly}
                                placeholder="Enter Tag ID"
                                onKeyPress={this.handleKeyPress}
                                onChange={this.newTagNameChange.bind(that)}
                            />

                            {!this.state.isTagNameValid && (
                                <span className="popover-new-tag-name">
                                    {strings.TAG_NAME_AVAILABLE_CHARS}
                                </span>
                            )}

                            {this.state.sameTagName && (
                                <span className="popover-tag-name-react">
                                    {strings.TAG_NAME_UNIQUE}
                                </span>
                            )}
                        </td>

                        <td>
                            <input
                                ref={this.newTagDescRef}
                                type="text"
                                value={this.state.newTagDesc}
                                className="new-tag new-tag-description"
                                disabled={this.state.readOnly}
                                placeholder="Enter Tag Value"
                                onKeyPress={this.handleKeyPress}
                                onChange={this.newTagDescChange.bind(that)}
                            />
                        </td>

                        <td className="tag-acion-container">
                            <button
                                className="fas fa-check fa-fw edit-icon"
                                disabled={this.state.readOnly}
                                onClick={that.insertNewTag.bind(that)} >
                            </button>

                            <button
                                className="fas fa-times fa-fw clear-input"
                                disabled={this.state.readOnly}
                                onClick={that.clearTagInputs.bind(that)} >
                            </button>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </Row>;
    }







    public render() {
        const { EditUser, LoggedUser, Errors } = this.state;


        const canChangePassword = EditUser.auth_server && EditUser.auth_server.indexOf("password") >= 0;
        const canChangeSubject  = EditUser.auth_server && EditUser.auth_server.indexOf("subject") >= 0;

        const DeviceProfiles = LoggedUser.can_list_device_profile ||
                        LoggedUser.can_inspect_device_profile || LoggedUser.can_create_device_profile ||
                        LoggedUser.can_link_device_profile;
        const ServiceProfiles = LoggedUser.can_list_service_profile ||
                    LoggedUser.can_inspect_service_profile || LoggedUser.can_create_service_profile ||
                    LoggedUser.can_link_service_profile || LoggedUser.can_restrict_service_profile;
        const ConnectivityProfiles = LoggedUser.can_list_connectivity_profile ||
                        LoggedUser.can_inspect_connectivity_profile ||
                        LoggedUser.can_create_connectivity_profile || LoggedUser.can_link_connectivity_profile;
        const RoamingProfiles = LoggedUser.can_list_roaming_profile ||
                        LoggedUser.can_inspect_roaming_profile || LoggedUser.can_create_roaming_profile || LoggedUser.can_link_roaming_profile;
        const ChannelProfiles = LoggedUser.can_list_channel_profile ||
                        LoggedUser.can_inspect_channel_profile || LoggedUser.can_create_channel_profile || LoggedUser.can_link_channel_profile;
        const QoSProfiles = LoggedUser.can_list_qos_profile || LoggedUser.can_inspect_qos_profile ||
                        LoggedUser.can_create_qos_profile || LoggedUser.can_link_qos_profile;


/* FIXME:
        const PasswordPolicies = EditUser.password_policies;

        const minPassFieldValidation = PasswordPolicies &&
                (((PasswordPolicies.user.min_password_length !== null) && (PasswordPolicies.user.min_password_length < 8 || PasswordPolicies.user.min_password_length % 1 !== 0)) ||
                ((PasswordPolicies.admin.min_password_length !== null) && (PasswordPolicies.admin.min_password_length < 8 || PasswordPolicies.admin.min_password_length % 1 !== 0)) ||
                ((PasswordPolicies.app.min_password_length !== null) && (PasswordPolicies.app.min_password_length < 8 || PasswordPolicies.app.min_password_length % 1 !== 0)));
*/

        const tabs: Array<{
            key: string;
            condition: boolean;
            label: string;
            panel: () => JSX.Element;
        }> = [
            {
                condition: true,
                key: "EditUserInfo",
                label: strings.EDIT_USER_INFO,
                panel: this.renderUserInfoPanel,
            },
            {
                condition: canChangePassword,
                key: "Password",
                label: strings.PASSWORD,
                panel: this.renderPasswordPanel,
            },
            {
                condition: LoggedUser.administrator,
                key: "AdminRights",
                label: strings.EDIT_USER_ADMIN_RIGHTS,
                panel: this.renderAdminRightsPanel,
            },
            {
                condition: LoggedUser.administrator && (LoggedUser.can_access_gtw_info ||
                    (LoggedUser.can_own_gtw && LoggedUser.gtw_admin) ||
                    (LoggedUser.can_add_gtw && LoggedUser.gtw_admin && LoggedUser.can_own_gtw) ||
                    (LoggedUser.can_mng_gtw && LoggedUser.gtw_admin && LoggedUser.can_own_gtw) ||
                    LoggedUser.can_access_gtw_loc_info),
                key: "Gateways",
                label: strings.EDIT_USER_GATEWAYS,
                panel: this.renderGatewayRightsPanel,
            },
            {
                condition: LoggedUser.loraloc_enable,
                key: "LoraLocation",
                label: strings.EDIT_USER_LORA_LOCATION,
                panel: this.renderLocationPanel,
            },
            {
                condition: LoggedUser.sys_admin || LoggedUser.data_archive_admin || LoggedUser.can_access_logs,
                key: "SystemSettings",
                label: strings.EDIT_USER_SYSTEM_RIGHTS,
                panel: this.renderSystemPanel,
            },
            {
                condition: LoggedUser.sys_admin,
                key: "Environment",
                label: "Environment",
                panel: this.renderEnvironmentPanel,
            },
            {
                condition: constants.enable_limit_devices || constants.enable_limit_gateways ||
                LoggedUser.can_view_mac_msg,
                key: "Settings",
                label: strings.SETTINGS,
                panel: this.renderSettingsPanel,
            },
            {
                condition: LoggedUser.tenant_admin && (LoggedUser.customer_admin && EditUser.is_customer) || (LoggedUser.org_admin && EditUser.is_organisation),
                key: "UserTenancy",
                label: strings.EDIT_USER_TENANCY,
                panel: this.renderTenancyPanel,
            },
            {
                condition: LoggedUser.org_admin && EditUser.is_customer,
                key: "UserOrganization",
                label: strings.EDIT_USER_ORGANIZATION,
                panel: this.renderUserOrgPanel,
            },
            {
                condition: DeviceProfiles || ServiceProfiles || ConnectivityProfiles ||
                            RoamingProfiles || ChannelProfiles || QoSProfiles,
                key: "Profiles",
                label: strings.PROFILES,
                panel: this.renderProfilesPanel,
            },
            {
                condition: LoggedUser.customer_admin && constants.enable_password_policies &&
                                (EditUser.is_customer), //|| PassPolicy),
                key: "PasswordPolicy",
                label: strings.EDIT_USER_PASSWORD_POLLICY,
                panel: this.renderPasswordPolicyPanel,
            },
            {
                condition: true,
                key: "Tags",
                label: strings.EDIT_USER_TAGS,
                panel: this.renderTagsPanel,
            },
        ];


        const tabsLabels: JSX.Element[] = [];
        const tabsPanels: JSX.Element[] = [];
        for (const tab of tabs) {
            if (tab.condition) {
                tabsLabels.push(<NavItem key={tab.key} eventKey={tab.key}>{tab.label}</NavItem>);
                tabsPanels.push(<Tab.Pane key={tab.key} eventKey={tab.key}>{tab.panel()}</Tab.Pane>);
            }
        }

        return (
            <div>
                <Modal
                    show={this.props.ShowModal != null ? this.props.ShowModal : this.state.ShowModal}
                    onHide={this.closeModal}
                    bsSize="lg"
                    className="ReactModal"
                    backdrop="static"
                >
                    <Modal.Header closeButton={true} >
                        <Modal.Title>
                            {this.props.ModalTitle || `${this.props.NewUserType ? strings.ADD_USER_ADD : strings.ADD_USER_EDIT} ${this.state.TypeText}` || ""}
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="all_padding_0 react_user_settings">
                    {this.state.Loading ? (
                    <Row className="new_style_modal_body white-background padding_top_30 padding_bottom_30">
                        <Col sm={12} className="LoaderWrapper">
                            <i className="fas fa-spinner fa-spin fa-5x"></i>
                        </Col>
                    </Row>
                    ) :
                    (<div>
                        <Row className="new_style_modal_body white-background padding_top_30">
                            <Col sm={12} className="all_padding_0">
                                <Form horizontal>

                                    {/*- Select AUTH SERVER --------------------------------------------------------*/}

                                    { LoggedUser.sys_admin && this.state.authServers.length > 1 && <FormGroup controlId="AccountID" >
                                        <Col
                                            componentClass={ControlLabel}
                                            className="new_style_font_weight font_size_18"
                                            sm={3}
                                        >
                                            {strings.AUTHENTICATION_SERVER}
                                        </Col>

                                        <Col sm={9}>
                                            <SelectDropdown
                                                value={this.state.authServers.find((el) => el.value === this.state.EditUser.auth_server_id)}
                                                options={this.state.authServers}
                                                isSearchable={true}
                                                isDisabled={this.state.readOnly}
                                                onChange={(changed) => {
                                                    const EditUser = {...this.state.EditUser};
                                                    EditUser.auth_server_id = changed.auth_server_id;
                                                    const properties: string[] = [];
                                                    if (changed.use_password) { properties.push("password"); }
                                                    if (changed.use_subject)  { properties.push("subject"); }
                                                    EditUser.auth_server = properties.toString();
                                                    this.setState({ EditUser });
                                                }}
                                            />
                                        </Col>
                                    </FormGroup>}


                                    {/*- Edit User ID --------------------------------------------------------*/}

                                    <FormGroup controlId="AccountID"
                                        validationState={Errors.errUserId ? "error" : null }
                                    >
                                        <Col
                                            componentClass={ControlLabel}
                                            className="new_style_font_weight font_size_18"
                                            sm={3}
                                        >
                                            {strings.EDIT_USER_TITLE_ACCOUNT_ID}
                                        </Col>
                                        <Col sm={9}>
                                            <FormControl
                                                type="text"
                                                className="new_style_focus"
                                                disabled={this.state.readOnly || (this.props.NewUserType == null && !LoggedUser.sys_admin)}
                                                required={true}
                                                autoComplete={"off"}
                                                value={EditUser.userid}
                                                onChange={(event: any) => this.HandleChangeRights(event.target.value, "userid", "string")}
                                            />

                                            <HelpBlock className="new_style_help_block_color font_size_11" >
                                                {strings.EDIT_USER_ACCOUNT_ID_HELP}
                                            </HelpBlock>

                                        </Col>
                                    </FormGroup>


                                    {/*- Edit SUBJECT --------------------------------------------------------*/}

                                    { canChangeSubject && <FormGroup
                                        controlId="SetSubject"
                                        className="margin_b_45"
                                        validationState={Errors.errSubject ? "error" : null}
                                    >
                                        <Col
                                            componentClass={ControlLabel}
                                            className="new_style_font_weight font_size_18"
                                            sm={3}
                                        >
                                            {strings.EDIT_USER_SUBJECT}
                                        </Col>
                                        <Col sm={9}>
                                            <FormControl
                                                value={EditUser.subject}
                                                type="text"
                                                className="new_style_focus"
                                                disabled={this.state.readOnly}
                                                onChange={(event: any) => this.HandleChangeRights(event.target.value, "subject", "string")}
                                            />
                                            <HelpBlock className="new_style_help_block_color font_size_11" >
                                                {strings.EDIT_USER_SUBJECT_HELP}
                                            </HelpBlock>
                                        </Col>

                                    </FormGroup> }
                                </Form>
                            </Col>
                        </Row>

                        {/*-- TAB container --------------------------------------------------------------- */}
                        <Row>
                            <Col sm={12} className="all_padding_0">
                                <Tab.Container id="UserSettingsTab" defaultActiveKey="EditUserInfo">
                                    <Row className="clearfix">
                                        <Col className="react_new_style_tabs tabs_style">
                                            <Nav bsStyle="pills" className="react_nav_tabs" stacked>
                                                {tabsLabels}
                                            </Nav>
                                        </Col>
                                        <Col className="react_tab_content_bg style_tabs">
                                            <Tab.Content animation >
                                                {tabsPanels}
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </Col>
                        </Row>
                    </div>)}
                    </Modal.Body>


                    {/*-- FOOTER ------------------------------------------------------------------------ */}

                    <Modal.Footer className="new_style_modal_footer">
                        {this.props.NewUserType == null && <Button
                            className={`glyphicon pull-left ${this.state.unlockButtonUserSettings}`}
                            onClick={this.unlockUserSettings}
                            >
                        </Button>}
                        {this.props.NewUserType == null && <small className="pull-left text-near-glyphicon">
                            {this.state.unlockChangeText}
                        </small>}

                        {this.state.Loading === false && (
                            <Button
                                className="new_style_btn_ok"
                                onClick={this.SubmitUserRights}
                                disabled={this.state.readOnly || Errors.error || !!(this.state.newTagName || this.state.newTagDesc)}
                            >
                                {`${this.props.NewUserType ? strings.ADD_USER_ADD_NEW : strings.ADD_USER_UPDATE} ${this.state.TypeText}`}
                            </Button>
                        )}

                        <Button
                            className="new_style_btn_cancel"
                            onClick={() => this.closeModal()}
                        >
                            {strings.CANCEL}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
