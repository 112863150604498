
declare const headerContentTypeJson;
declare const headerNoContentType;

export const DefaultSizePerPage = 50;

export const AllUserList = (UserFilter?: string) => {
    return new Promise<Response>(async (resolve, reject) => {
        try {
            const query = UserFilter ? "?search_id=" + UserFilter : "";
            const response = await fetch("/uiapi/rest/users" + query, {
                credentials: "same-origin",
                headers: headerNoContentType,
                method: "GET",
            });

            if (response.status == 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const AllCustomerList = (CustomerFilter?: string) => {
    return new Promise<Response>(async (resolve, reject) => {
        try {
            const query = CustomerFilter ? "?search_id=" + CustomerFilter : "";
            const response = await fetch("/uiapi/rest/customers" + query, {
                credentials: "same-origin",
                headers: headerNoContentType,
                method: "GET",
            });

            if (response.status == 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};




declare const constants;
export type ProfileApiType = "device-profiles" | "service-profiles" | "qos-profiles" |
                     "channel-profiles" | "roaming-profiles" | "connectivity-profiles";

export const XProfilesList = (type: ProfileApiType) => {
    return new Promise<Response>(async (resolve, reject) => {
        try {
            const response = await fetch(`/uiapi/rest/${type}`, {
                credentials: "same-origin",
                headers: headerNoContentType,
                method: "GET",
            });

            if (response.status === 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            const data = await response.json();
            resolve(data);
        } catch (e) {
            reject(e);
        }
    });
};

// FIXME: AllDeviceProfiles and DeviceProfilesList seems exactly the same
export const AllDeviceProfiles = (type: ProfileApiType) => {
    return new Promise<Response>(async (resolve, reject) => {
        try {
            const response = await fetch(`/uiapi/rest/${type}`, {
                credentials: "same-origin",
                headers: headerNoContentType,
                method: "GET",
            });

            if (response.status === 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

interface IQueryParamListElem {
    Property: string;
    Value: string;
}

export const XProfileSchema = (type: ProfileApiType, Parameters?: IQueryParamListElem[]) => {
    return new Promise<Response>(async (resolve, reject) => {
        let query = "";
        if (Parameters) {
            for (const param of Parameters) {
                query += "&" + param.Property + "=" + param.Value;
            }
        }
        try {
            const response = await fetch(`/uiapi/rest/${type}?get_schema=true` + query, {
                credentials: "same-origin",
                headers: headerNoContentType,
                method: "GET",
            });

            if (response.status === 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            const data = await response.json();
            resolve(data);
        } catch (error) {
            reject(error);
        }
    });
};

export const EditXProfile = (type: ProfileApiType, ProfileUUID: string) => {
    return new Promise<Response>(async (resolve, reject) => {
        try {
            const response = await fetch(`/uiapi/rest/${type}/` + ProfileUUID, {
                credentials: "same-origin",
                headers: headerNoContentType,
                method: "GET",
            });

            if (response.status === 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            const data = await response.json();
            resolve(data);
        } catch (error) {
            reject(error);
        }
    });
};

// FIXME: What is the difference between EditDeviceProfile and GetDeviceProfile?
export const GetXProfile = (type: ProfileApiType, ProfileUUID: string) => {
    return new Promise<Response>(async (resolve, reject) => {
        try {
            const response = await fetch(`/uiapi/rest/${type}/${ProfileUUID}`, {
                credentials: "same-origin",
                headers: headerNoContentType,
                method: "GET",
            });

            if (response.status === 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const UpdateXProfile = (type: ProfileApiType, ProfileUUID: string, ProfileData: any) => {
    return new Promise<Response>(async (resolve, reject) => {
        try {
            const response = await fetch(`/uiapi/rest/${type}/${ProfileUUID}`, {
                body: JSON.stringify(ProfileData),
                credentials: "same-origin",
                headers: headerContentTypeJson,
                method: "PUT",
            });

            if (response.status === 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const AddXProfile = (type: ProfileApiType, Data: any) => {
    return new Promise<Response>(async (resolve, reject) => {
        try {
            const response = await fetch(`/uiapi/rest/${type}`, {
                body: JSON.stringify(Data),
                credentials: "same-origin",
                headers: headerContentTypeJson,
                method: "POST",
            });

            if (response.status === 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const DeleteXProfile = (type: ProfileApiType, ProfilesUUID: string) => {
    return new Promise<Response>(async (resolve, reject) => {
        try {
            const response = await fetch(`/uiapi/rest/${type}/${ProfilesUUID}`, {
                credentials: "same-origin",
                headers: headerNoContentType,
                method: "DELETE",
            });

            if (response.status === 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const DeleteXProfilesBulk = async (type: ProfileApiType, ProfilesUUID: Array<{ profile_uuid: string }>) => {
    const promises: any = [];
    const AllPromises: any = [];
    let MaximumRequests = 10;
    if (constants.max_concurrent_requests) {
        MaximumRequests = constants.max_concurrent_requests;
    }
    ProfilesUUID.map(async (Profile) => {
        const promise = DeleteXProfile(type, Profile.profile_uuid);
        AllPromises.push(promise);
        promises.push(promise);
        if (promises.length >= MaximumRequests) {
            promises.length = 0;
            await Promise.all(promises);
        }
    });
    await Promise.all(promises);
    return await Promise.all(AllPromises);
};

export const CreateLinkedXProfile = (type: ProfileApiType, Data: any) => {
    return new Promise<Response>(async (resolve, reject) => {
        try {
            const response = await fetch(`/uiapi/rest/${type}`, {
                body: JSON.stringify(Data),
                credentials: "same-origin",
                headers: headerContentTypeJson,
                method: "POST",
            });

            if (response.status === 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const GetLinkedXProfile = (type: ProfileApiType, ProfileUUID: string) => {
    return new Promise<Response>(async (resolve, reject) => {
        try {
            const response = await fetch(`/uiapi/rest/${type}/${ProfileUUID}`, {
                credentials: "same-origin",
                headers: headerNoContentType,
                method: "GET",
            });

            if (response.status === 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const UpdateLinkedXProfile = (type: ProfileApiType, ProfileUUID: string, ProfileData: any) => {
    return new Promise<Response>(async (resolve, reject) => {
        try {
            const response = await fetch(`/uiapi/rest/${type}/${ProfileUUID}`, {
                body: JSON.stringify(ProfileData),
                credentials: "same-origin",
                headers: headerContentTypeJson,
                method: "PUT",
            });

            if (response.status === 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const UnlinkLinkedXProfile = (type: ProfileApiType, ProfileUUID: string, TargetProfileUUID: string) => {
    return new Promise<Response>(async (resolve, reject) => {
        try {
            const response = await fetch(`/uiapi/rest/${type}/${ProfileUUID}`, {
                body: JSON.stringify({ copy_from_profile_uuid: TargetProfileUUID }),
                credentials: "same-origin",
                headers: headerContentTypeJson,
                method: "PUT",
            });

            if (response.status === 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const GetSourceXProfiles = (type: ProfileApiType, SourceUser: string) => {
    return new Promise<Response>(async (resolve, reject) => {
        try {
            const response = await fetch(`/uiapi/rest/${type}?source_userid=${SourceUser}`, {
                credentials: "same-origin",
                headers: headerNoContentType,
                method: "GET",
            });

            if (response.status === 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const SearchXProfileName = (type: ProfileApiType, SearchValue: string, SourceUser: string) => {
    return new Promise<Response>(async (resolve, reject) => {
        try {

            let query = "?search_name=" + encodeURIComponent(SearchValue);
            if (SourceUser) { query += "&source_userid=" + encodeURIComponent(SourceUser); }

            const response = await fetch(`/uiapi/rest/${type}` + query, {
                credentials: "same-origin",
                headers: headerNoContentType,
                method: "GET",
            });

            if (response.status === 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const SearchXProfileDescription = (type: ProfileApiType, SearchValue: string, SourceUser: string) => {
    return new Promise<Response>(async (resolve, reject) => {
        try {

            let query = "?search_description=" + encodeURIComponent(SearchValue);
            if (SourceUser) { query += "&source_userid=" + encodeURIComponent(SourceUser); }


            const response = await fetch(`/uiapi/rest/${type}` + query, {
                credentials: "same-origin",
                headers: headerNoContentType,
                method: "GET",
            });

            if (response.status === 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const SearchXProfileUUID = (type: ProfileApiType, SearchValue: string, SourceUser: string) => {
    return new Promise<Response>(async (resolve, reject) => {
        try {

            let query = "?search_uuid=" + encodeURIComponent(SearchValue);
            if (SourceUser) { query += "&source_userid=" + encodeURIComponent(SourceUser); }

            const response = await fetch(`/uiapi/rest/${type}` + query, {
                credentials: "same-origin",
                headers: headerContentTypeJson,
                method: "PUT",
            });

            if (response.status === 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const PayloadDecoderTemplate = () => {
    return new Promise<Response>(async (resolve, reject) => {
        try {
            const response = await fetch("/uiapi/rest/device-profiles?get_schema=template-payload-decoder", {
                credentials: "same-origin",
                headers: headerNoContentType,
                method: "GET",
            });

            if (response.status === 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};
