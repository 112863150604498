import * as React from "react";
import * as ReactDOM from "react-dom";

import EditPositionModal from "./../components/Device/NetworkMap/EditPositionModal";
import { toast } from "./../utils/Toaster";
import { strings } from "./../services/Localization";
import { UpdateGateway } from "./../services/Devices/NetworkMap";
import { Whoami } from "./../services/Login";

declare const angular;

export class GatewayModals {

    public UpdateGatewayPosition = async (marker) => {
        const GatewayEl = document.querySelector(".my_Gataway_style") as HTMLAnchorElement;
        const controller = angular.element(GatewayEl).controller();
        const modalRoot = document.getElementById("reactModalRootEGateway") as HTMLElement;
        if (marker && marker.position && marker.data) {
            const dataToSend = {
                "latitude": marker.position.latitude,
                "longitude": marker.position.longitude
            };
            if (marker.position.altitude) {
                dataToSend["altitude"] = marker.position.altitude || 0;
            }
            if (marker.data.hasOwnProperty("name")) {
                dataToSend["name"] = marker.data.name
            }
            const res: any = await UpdateGateway(
                marker.data.id,
                dataToSend
            );
            const data = await res.text();
            if (res.status === 200) {
                controller.loadGateways();
                toast.success(strings.MSG_GATEWAY_POS_UPDATE_SUCCESS);
            } else {
                if (res.status === 404) {
                    toast.error(strings.formatString(
                        strings.MSG_GATEWAY_ID_NOT_FOUND,
                        {
                            gateway_id: marker.data.id.match(/.{2}/g).join("-").toUpperCase()
                        }
                    ));
                } else {
                    toast.error(strings.MSG_GATEWAY_POS_UPDATE_FAIL);
                }
                console.log(data);
            }
        }
        ReactDOM.unmountComponentAtNode(modalRoot);
    }

    public async showPositionOnMap(gateway) {
        try {
            const res: any = await Whoami();
            if (res !== null) {
                const modalRoot = document.getElementById("reactModalRootEGateway") as HTMLElement;
                ReactDOM.unmountComponentAtNode(modalRoot);
                ReactDOM.render(
                    <EditPositionModal
                        Data={gateway}
                        ShowModal={true}
                        OnConfirm={this.UpdateGatewayPosition}
                        CloseModal={() => {
                            ReactDOM.unmountComponentAtNode(modalRoot);
                        }}
                        ReadOnly={res.user.can_mng_gtw ? false : true}
                    />,
                    modalRoot,
                );
            }
        } catch (e) {
            console.log(e);
        }
    }
}
