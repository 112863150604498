import * as React from "react";
import * as ReactDOM from "react-dom";
import { IUser } from "../dassTypes";
import UserSettingsModal from "../components/Users/UserSettingsModal";

export class UserSettingsModalCompClass {

    public showUserSettingsModal(user: IUser, title: string, newUserType: "user" | "customer" | "org", callOnClose: (updated: boolean) => void) {

        const modalRoot = document.getElementById("reactModalRootUsers") as HTMLElement;
        ReactDOM.unmountComponentAtNode(modalRoot);
        ReactDOM.render(
            <UserSettingsModal EditUser={user} ModalTitle={title} NewUserType={newUserType} CallOnClose={callOnClose}/>,
            modalRoot,
        );
    }

}


