import * as moment from "moment";
import * as React from "react";
import { Button, Col, Dropdown, Glyphicon, Grid, MenuItem, Overlay, Popover, Row } from "react-bootstrap";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import * as ReactDOM from "react-dom";
import { DefaultSizePerPage } from "../../services/Profiles/Profiles";
import { toast } from "../../utils/Toaster";
import DateTimeRangePicker from "./../../components/DateTimeRangePicker/DateTimeRangePicker";
import { AlertModal } from "./../../components/Modals/AlertModal";
import { LocaleDate } from "./../../controllers/GlobalController";
import { TableFilterRegex } from "./../../controllers/TableFilterRegex";
import { GetLogLevel, SetLogLevel, GetTableRequest } from "./../../services/Applications/EventLog";
import { strings } from "./../../services/Localization";
import { Whoami } from "./../../services/Login";

const WindowWidth = window.matchMedia("(max-width: 767px)");


export interface IGetTableFilter {
    colName: string;
    colValue: string;
}

interface ILogLevelOption {
    duration: number;
    level: "error" | "info" | "debug";
    label: string;
}
interface IPageState {
    page_state: string;

    // stream=process only fields
    _ign: number;
    row: any;
    _scan: number;
    _cnt: number;
}
interface IPagination {
    pages: IPageState[];
    total: number;
    per_page: number;
}

interface IState {
    CurrentPage: number;
    EventLogs: any[];
    FilterValues: IGetTableFilter[]
    FilterColumns: any;

    LoadingData: boolean;
    LoadingTableData: boolean;
    LogLevelOptions: ILogLevelOption[];

    LoggedUser: any;
    Pagination: IPagination;
    QueryOptions: any;

    SizePerPage: number;
    PaginationSize: number;
    SelectedLogLevel: string;

    sortName?: string;
    sortOrder?: string;
    CountClicks: number;
    ShowAlertModal: boolean;
    ShowPopover: boolean;
}

function capsify(str: string) {
    return str.substring(0, 1).toLocaleUpperCase() + str.substring(1);
}


export default class EventLog extends React.Component<any, IState> {

    private AbortPagingLoad: () => void;
    private AbortPageLoad: () => void;
    private LogLevelRef: any =  React.createRef<HTMLElement>();

    constructor(props) {
        super(props);
        this.state = {
            CurrentPage: 1,
            EventLogs: [],
            FilterColumns: {},
            FilterValues: [],

            LoadingData: false,
            LoadingTableData: false,
            LogLevelOptions: [],
            LoggedUser: null,
            Pagination: {
                pages: [],
                total: 0,
                per_page: 0
            },
            PaginationSize: 5,
            SelectedLogLevel: "",
            SizePerPage: DefaultSizePerPage,
            sortName: undefined,
            sortOrder: undefined,
            CountClicks: 0,
            ShowAlertModal: false,
            ShowPopover: false,

            QueryOptions: {},
        };
    }

    public componentDidMount() {
        this.LoggedIn();
        this.SizePerPageForMobile(WindowWidth);
    }

    public componentWillUnmount() {
        WindowWidth.removeListener(this.SizePerPageForMobile);
    }

    public SizePerPageForMobile = (width) => {
        if (width.matches) {
            this.setState({
                PaginationSize: 3,
            });
        } else {
            this.setState({
                PaginationSize: 5,
            });
        }
    }

    public LoggedIn = async () => {
        try {
            const response: any = await Whoami();
            if (response.user) {
                // tslint:disable-next-line:no-bitwise
                if (response.user._license && ((response.user._license & 0x10) === 0)) {
                    this.setState({
                        ShowAlertModal: true,
                    });
                } else {
                    this.setState({
                        LoggedUser: response.user,
                    });
                    this.GetEventLogsPagination();
                    this.GetLogLevelInfo();
                }
            } else {
                this.setState({
                    LoggedUser: null,
                });
                window.location.href = "/app/signin";
            }
        } catch (error) {
            console.log(`Error: ${error}`);
            this.setState({
                LoggedUser: null,
            });
            window.location.href = "/app/signin";
        }
    }

    public GetEventLogsPagination = async (
        SizePerPage = this.state.SizePerPage,
        Sorting = {
            sortName: this.state.sortName,
            sortOrder: this.state.sortOrder,
        },
        FilterValues = this.state.FilterValues,
    ) => {

        const pages: IPageState[] = [];
        const rows: any[] = [];
        const me = this;

        if (this.AbortPageLoad) { this.AbortPageLoad(); }
        if (this.AbortPagingLoad) { this.AbortPagingLoad(); }

        // construct the query options for the paging, and save them to use for each page query.
        const options = {
            limit: SizePerPage
        };
        if (Sorting && Sorting.sortName) {
            options[Sorting.sortName] = Sorting.sortOrder;
        }
        if (FilterValues) {
            for (const filter of FilterValues) {
                options[filter.colName] = filter.colValue;
            }
        }
        this.setState({
            QueryOptions: options,
            Pagination: { pages, total: 0, per_page: SizePerPage },
            EventLogs: rows,
            LoadingData: true,
            LoadingTableData: true,
        });

        const oboeCallback = (oboeObj) => {

            me.AbortPagingLoad = () => {
                try { oboeObj.abort(); } catch(e) { console.log(e); }
                me.AbortPagingLoad = null as any;
            }

            // Register to receive the pages array elemets on the fly
            oboeObj.node("pages.*", (pagesNode: IPageState, path, ancestor) => {

                if (pagesNode) {
                    if (pagesNode._ign === undefined) {
                        pages.push(pagesNode);
                        me.setState({
                            CurrentPage: 1,
                            Pagination: { pages, total: 0, per_page: SizePerPage }
                        });
                    }
                    if (pagesNode._ign && pagesNode.row) {
                        rows.push(pagesNode.row);
                        me.setState({
                            EventLogs: rows,
                        });
                    }
                    if (pagesNode._scan) {
                        // console.log("progress: ", pagesNode);
                    }
                }
            });
        }

        try {

            const response: any = await GetTableRequest("/uiapi/rest/pushmode/log",
                    Object.assign({ get_pages: true, stream: "progress" }, options), oboeCallback);

            response.json.pages = pages;        // need to replace with the filtered array
            const Pagination: IPagination = response.json;   // await response.json();
            this.setState({
                CurrentPage: 1,
                Pagination,
            });

            // After loading the pages, we may have to load the first page (though normally it will
            // be loaded during the steam load). We only need to load it if we have a first page state
            // and the count is greater than zero.
            if (Pagination.pages.length > 0 && Pagination.total > 0) {

                // There is data, so we should load the first page - but if it has already be loaded by
                // the stream processing we can skip now. The cases where we need to load are only cases
                // where the first page cannot be streamed - like in the case of sorting.
                if (rows.length === 0) {
                    this.GetEventLogData(Pagination.pages[0].page_state);
                } else {
                    this.setState({
                        LoadingData: false,
                        LoadingTableData: false,
                    });
                }
            } else {
                this.setState({
                    EventLogs: [],
                    LoadingData: false,
                    LoadingTableData: false,
                });
            }

        } catch (error) {
            let message = strings.EVENT_LOG_CANT_GET_DATA;
            if (error.statusCode === 401) {
                window.location.href = '/app/signout?resignin';
            } else if (error.statusCode >= 400 && error.statusCode < 500 && error.body &&
                        error.headers["content-type"] && error.headers["content-type"].indexOf("text/plain") >= 0) {
                message = capsify(error.body);
            }

            toast.error(message);
            this.setState({
                LoadingData: false,
                LoadingTableData: false,
            });
        }
    }

    public GetEventLogData = async (PageState: string) => {

        const rows: any[] = [];
        const me = this;

        if (me.AbortPageLoad) { me.AbortPageLoad(); }

        const oboeCallback = (oboeObj) => {

            me.AbortPageLoad = () => {
                try { oboeObj.abort(); } catch(e) { console.log(e); }
                me.AbortPageLoad = null as any;
            }

            // Register to receive the pages array elemets on the fly
            oboeObj.node("results.*", (row: any, path, ancestor) => {
                if (row && row._ign == undefined) {
                    rows.push(row);
                }
                me.setState({ EventLogs: rows });
            });
        }

        try {

            await GetTableRequest("/uiapi/rest/pushmode/log",
                    Object.assign({ page_state: PageState, stream: "progress",
                                    paged_results: true }, this.state.QueryOptions),
                    oboeCallback);

            this.setState({
                EventLogs: rows,
                LoadingData: false,
                LoadingTableData: false,
            });

        } catch (error) {

            let message = strings.EVENT_LOG_CANT_GET_DATA;
            if (error.statusCode === 401) {
                window.location.href = '/app/signout?resignin';
            } else if (error.statusCode === 404) {
                message = strings.EVENT_LOG_TABLE_DATA_RES_404;
            } else if (error.statusCode >= 400 && error.statusCode < 500 && error.body &&
                    error.headers["content-type"] && error.headers["content-type"].indexOf("text/plain") >= 0) {
                message = capsify(error.body);
            }

            toast.error(message);
            this.setState({
                EventLogs: [],
                LoadingData: false,
                LoadingTableData: false,
            });
        }
    }

    public LoadingData = () => {
        this.setState({
            LoadingData: true,
            LoadingTableData: true,
        });
        this.GetEventLogsPagination();
    }

    public onFilterChange = async (FilterObj) => {
        this.setState({
            FilterColumns: FilterObj,
        });
        const Sorting = await this.SortingOptions();
        const FilterValues = await this.FilterOptions(FilterObj);
        this.GetEventLogsPagination(
            this.state.SizePerPage,
            Sorting,
            FilterValues,
        );
        this.setState({
            FilterValues,
        });
    }

    public onPageChange = async (page: number, sizePerPage: number) => {
        const Sorting = await this.SortingOptions();
        const FilterValues = await this.FilterOptions();
        if ((page > 0) && (page <= this.state.Pagination.pages.length) &&
            (this.state.SizePerPage === sizePerPage)) {

            this.setState({
                CurrentPage: page,
                SizePerPage: sizePerPage,
            });

            this.GetEventLogData(
                this.state.Pagination.pages[page - 1].page_state);

        } else if ((page > 0) && (page <= this.state.Pagination.pages.length) &&
            (this.state.SizePerPage !== sizePerPage)) {

            this.setState({
                CurrentPage: page,
                SizePerPage: sizePerPage,
            });
            this.GetEventLogsPagination(
                sizePerPage,
                Sorting,
                FilterValues,
            );
        } else if (this.state.SizePerPage !== sizePerPage) {
            this.setState({
                SizePerPage: sizePerPage,
            });
            this.GetEventLogsPagination(
                sizePerPage,
                Sorting,
                FilterValues,
            );
        }
    }

    public FilterOptions = (FilterColumns = this.state.FilterColumns) => {
        const FilterValues: any = [];

        if (Object.keys(FilterColumns).length > 0) {
            Object.keys(FilterColumns).map((filter: any) => {
                let SetFilterValue: any = {};
                if (filter === "ts") {
                    Object.keys(FilterColumns[filter].value.callback).map((value) => {
                        SetFilterValue.colName = (value === "startDate") ? "from_date" : "to_date";
                        SetFilterValue.colValue = FilterColumns[filter].value.callback[value];
                        FilterValues.push(SetFilterValue);
                        SetFilterValue = {};
                    });
                } else if (filter === "deveui") {
                    let Value = FilterColumns[filter].value;
                    Value = Value.toLowerCase().replace(/-/g, "");
                    SetFilterValue.colName = "search_deveui";
                    SetFilterValue.colValue = Value;
                    FilterValues.push(SetFilterValue);
                } else if (filter === "evt") {
                    let Value = FilterColumns[filter].value;
                    SetFilterValue.colName = "search_event";
                    SetFilterValue.colValue = Value;
                    FilterValues.push(SetFilterValue);
                } else if (filter === "app") {
                    let Value = FilterColumns[filter].value;
                    SetFilterValue.colName = "search_application";
                    SetFilterValue.colValue = Value.replace(/#/g, " ");
                    FilterValues.push(SetFilterValue);
                } else if (filter === "txt") {
                    let Value = FilterColumns[filter].value;
                    SetFilterValue.colName = "search_message";
                    SetFilterValue.colValue = Value;
                    FilterValues.push(SetFilterValue);
                }
            });
        }
        return FilterValues;
    }

    public SortingOptions = (sortName = this.state.sortName, sortOrder = this.state.sortOrder) => {
        switch (sortName) {
            case "ts":
                sortName = "timestamp";
                break;
            case "app":
                 sortName = "app";
                 break;
            case "deveui":
                 sortName = "deveui";
                 break;
            default:
                sortName = undefined;
                break;
        }
        const Sorting = {
            sortName,
            sortOrder,
        };
        return Sorting;
    }

    public onSortChange = async (sortName, sortOrder) => {
        const Sorting = await this.SortingOptions(sortName, sortOrder);
        const FilterValues = await this.FilterOptions();
        let CountClicks = this.state.CountClicks;
        if (this.state.sortName === sortName) {
            CountClicks = CountClicks + 1;
        } else {
            CountClicks = 0;
        }
        this.setState({
            CountClicks,
            sortName,
            sortOrder,
        });
        if (CountClicks === 2) {
            Sorting.sortName = undefined,
            Sorting.sortOrder = undefined,
            this.setState({
                CountClicks: 0,
                sortName: undefined,
                sortOrder: undefined,
            });
            this.GetEventLogsPagination(
                this.state.SizePerPage,
                Sorting,
                FilterValues,
            );
        } else {
            this.GetEventLogsPagination(
                this.state.SizePerPage,
                Sorting,
                FilterValues,
            );
        }
    }

    public renderPaginationPanel = (props: any) => {
        setTimeout(() => {
            if (props.currPage < 1) {
                props.changePage(1);
            } else if (props.currPage > props.totalPages) {
                props.changePage(props.totalPages);
            }
        }, 100);
        return (
            <div className="container-fluid ProfilesTablePagination">
                <div className="row React_marg_11_top">
                    <div className="col-sm-2 col-sm-offset-5 React_per_page_style">
                        <span>{strings.SHOWING_PER_PAGE} </span>
                        {props.components.sizePerPageDropdown}
                    </div>
                    <div className="col-sm-5={true}" id="React_pagination_style">{props.components.pageList}</div>
                </div>
            </div>
        );
    }

    public renderSizePerPageDropDown = (props) => {
        return (
            <select
                className="form-control custom_size_per_page_drop_down"
                defaultValue={props.currSizePerPage}
                onChange={(event) => props.changeSizePerPage(event.target.value)}
            >
                {
                    props.sizePerPageList.map((optionVal, index) => {
                        return (
                            <option
                                key={index}
                                value={optionVal.value}
                            >
                                {optionVal.text}
                            </option>
                        );
                    })
                }
            </select>
        );
    }

    public TxtFormat = (text) => {
        const find = text.match(/ReqBody|RespBody/g);
        if (find && find.length > 0) {
            let oldText = "";
            let newText = "";
            find.map((s) => {
                const regex = new RegExp("ReqBody", "g");
                const rpl = "<br>"+"ReqBody";
                oldText = text.replace(regex, rpl);
            });
            find.map((s) => {
                const regex = new RegExp("RespBody", "g");
                const rpl = "<br>"+"RespBody";
                newText = oldText.replace(regex, rpl);
            });
            return newText;
        } else {
            return text;
        }
    }

    public DateFormat = (date) => {
        if (date) {
            return moment(date).format(LocaleDate().DateTimeFormat);
        } else {
            return "";
        }
    }

    public LogLevelHandler = async (level, duration) => {
        this.setState({
            SelectedLogLevel: level,
        });

        try {
            const res: any = await SetLogLevel(level, duration);
            const message = await res.text();
            if (res.status === 200) {
                this.GetLogLevelInfo();
            } else {
                toast.error(message);
            }
        } catch (err) {
            console.log("Set log level => ", err);
            toast.error(strings.EVENT_LOG_LOG_LEVEL_ERROR);
        }
    }

    public GetLogLevelInfo = async () => {
        let { SelectedLogLevel } = this.state;
        const { LoggedUser } = this.state;
        const LogLevelOptions: any = [
            {
                duration: 0,
                level: "error",
            },
            {
                duration: 24,
                label: "1D",
                level: "info",
            },
        ];
        if (LoggedUser.sys_admin) {
            const values = ["3D", "1W", "2W"];
            [{
                duration: [72, 168, 336],
                label: values,
                level: "info",
            },
            {
                duration: [24, 72, 168, 336],
                label: ["1D", ...values],
                level: "debug",
            }].map((value: any) => {
                let option: any = {};
                if (value.duration.length > 0) {
                    value.duration.map((dur, index) => {
                        option.duration = dur;
                        option.label = value.label[index];
                        option.level = value.level;
                        LogLevelOptions.push(option);
                        option = {};
                    });
                }
            });
        } else if (LoggedUser.administrator || LoggedUser.customer_admin) {
            [{
                duration: [72, 168],
                label: ["3D", "1W"],
                level: "info",
            }].map((value: any) => {
                let option: any = {};
                if (value.duration.length > 0) {
                    value.duration.map((dur, index) => {
                        option.duration = dur;
                        option.label = value.label[index];
                        option.level = value.level;
                        LogLevelOptions.push(option);
                        option = {};
                    });
                }
            });
        }
        try {
            const res: any = await GetLogLevel();
            if (res.status === 200) {
                const body = await res.json();
                if (body.log_level) {
                    SelectedLogLevel = body.log_level;
                } else {
                    SelectedLogLevel = "";
                }
                this.setState({
                    LogLevelOptions,
                    SelectedLogLevel,
                });
            }
        } catch (err) {
            console.log("Cannot get Log Level => ", err);
        }
    }

    public CustomRangePicker = (filterHandler) => {
        return <DateTimeRangePicker RangeHandler={filterHandler} />;
    }
    public customTableFilterRegex = (filterHandler, customFilterParameters) => {
        return <TableFilterRegex FilterHandler = {filterHandler} customFilterParameters = {customFilterParameters} />;
    }

    public PopOverHover = (content) => {
        return (
            <Popover
                id="log_level_popover"
            >
                {content}
            </Popover>
        );
    }

    public render() {
        const {
            EventLogs,
            LoadingData,
            LoadingTableData,
            LogLevelOptions,
            LoggedUser,
            ShowAlertModal,
            SelectedLogLevel,
        } = this.state;
        if (LoggedUser === null) {
            return (
                <AlertModal
                    ShowModal={ShowAlertModal}
                    CloseModal={async () => {
                        await this.setState({
                            ShowAlertModal: true,
                        });
                        window.location.href = "/app";
                    }}
                    ModalBody={strings.NO_RIGHTS_TO_SEE_THIS_PAGE}
                    ConfirmBtn={true}
                    OnConfirm={async () => {
                        await this.setState({
                            ShowAlertModal: false,
                        });
                        window.location.href = "/app";
                    }}
                />
            );
        } else {
            const options: any = {
                alwaysShowAllBtns: true,
                firstPage: strings.FIRST,
                lastPage: strings.LAST,
                nextPage: strings.NEXT,
                noDataText: (LoadingTableData ?
                    <div>
                        <i className="fas fa-spinner fa-spin"></i>&nbsp;{strings.LOADING}...
                    </div> :
                    strings.NO_ITEMS_MATCHING
                ),
                onFilterChange: this.onFilterChange,
                onPageChange: this.onPageChange,
                onSortChange: this.onSortChange,
                page: this.state.CurrentPage,
                pageStartIndex: 1,
                paginationPanel: this.renderPaginationPanel,
                paginationPosition: "top",
                paginationSize: this.state.PaginationSize,
                prePage: strings.PREVIOUS,
                sizePerPage: this.state.SizePerPage,
                sizePerPageDropDown: this.renderSizePerPageDropDown,
                sizePerPageList: [{
                    text: "3", value: 3,
                }, {
                    text: "10", value: 10,
                }, {
                    text: "25", value: 25,
                }, {
                    text: "50", value: 50,
                }, {
                    text: "100", value: 100,
                }],
                sortIndicator: false,
                sortName: this.state.sortName,
                sortOrder: this.state.sortOrder,
                withFirstAndLast: true,
            };
            const CustomFilter: any = "CustomFilter";

            return(
                <Grid fluid={true} className="EventLogs">
                    <h2>{strings.EVENT_LOG_PAGE_TITLE}</h2>
                    <Row className="show-grid ReactPages">
                        <Col sm={5} className="positionRight position_ab">
                            <Button className="black_b_btn" onClick={this.LoadingData}>
                                <Glyphicon
                                    glyph="refresh"
                                    className={LoadingData ? "spinning" : ""}
                                /> {strings.REFRESH_LIST}
                            </Button>
                            <Overlay
                                show={this.state.ShowPopover || false}
                                placement="right"
                                target={() => ReactDOM.findDOMNode(this.LogLevelRef)}
                                container={this}
                            >
                                {this.PopOverHover(
                                    strings.LOG_LEVEL_DROPDOWN_DESC,
                                )}
                            </Overlay>
                            <Dropdown
                                id="LogLevelBtn"
                                className="LogLevel"
                                pullRight={true}
                            >
                                <Dropdown.Toggle
                                    className="black_b_btn"
                                    bsRole="toggle"
                                    ref={(button) => this.LogLevelRef = button}
                                    onMouseEnter={() => this.setState({ ShowPopover: true })}
                                    onMouseLeave={() => this.setState({ ShowPopover: false })}
                                    style={{position: "relative"}}
                                >
                                    {`${strings.EVENT_LOG_LOG_LEVEL}${(SelectedLogLevel) ?
                                        ": " + strings[`EVENT_LOG_LEVEL_${SelectedLogLevel.toUpperCase()}_OPTION`] +
                                        " " : ""}`}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {(LogLevelOptions.length > 0) && (
                                        LogLevelOptions.map((option, index) => {
                                            let Label = strings[`EVENT_LOG_LEVEL_${option.level.toUpperCase()}_OPTION`];
                                            if (option.level !== "error") {
                                                Label = Label + ": " +
                                                strings[`EVENT_LOG_TIME_DURATION_${option.label}_OPTION`];
                                            }
                                            return <MenuItem
                                                key={index}
                                                eventKey={index}
                                                bsClass="LogLevelList"
                                                onClick={() => this.LogLevelHandler(
                                                    option.level,
                                                    option.duration,
                                                )}
                                            >
                                                {Label}
                                            </MenuItem>;
                                        })
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                    <Row className="show-grid EventLogsTableContainer">
                        <Col sm={12}>
                            <BootstrapTable
                                multiColumnSort={1}
                                ref="table"
                                data={(EventLogs)}
                                striped={true}
                                hover={true}
                                pagination={true}
                                options={options}
                                tableContainerClass="ReactPages new_style_dark react_new_style_dark EventLogsTable"
                                remote={true}
                                fetchInfo={{
                                    dataTotalSize: (this.state.Pagination.total > 0) ?
                                        this.state.Pagination.total : 1,
                                }}
                            >
                                <TableHeaderColumn
                                    dataField="ts"
                                    className="event_time_table"
                                    columnClassName="event_time_table"
                                    dataSort={true}
                                    dataFormat={this.DateFormat}
                                    isKey
                                    filter={{
                                        getElement: this.CustomRangePicker,
                                        type: CustomFilter,
                                    }}
                                >
                                    {strings.EVENT_LOGS_TABLE_COL_TIMESTAMP}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="evt"
                                    className="evt_table"
                                    columnClassName="evt_table"
                                    filter={{
                                        customFilterParameters: {
                                            length: 1,
                                            placeholder: strings.EVENT_LOGS_TABLE_COL_EVENT,
                                            regex: /^(?:[!]?[a-z]+)(?:[&|][!]?[a-z]+)*$/i
                                        } as any,
                                        getElement: this.customTableFilterRegex,
                                        type: CustomFilter,
                                    }}
                                >
                                    {strings.EVENT_LOGS_TABLE_COL_EVENT}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="app"
                                    className="app_table"
                                    columnClassName="app_table"
                                    filter={{
                                        customFilterParameters: {
                                            length: 1,
                                            placeholder: strings.EVENT_LOGS_TABLE_COL_APPLICATION,
                                            regex: "^(?:[!]?(?:[ ]|[\\p{L}0-9@._-]+))(?:[&|][!]?(?:[ ]|[\\p{L}0-9@._-]+))*$",
                                            replaceSpace: "#",
                                        } as any,
                                        getElement: this.customTableFilterRegex,
                                        type: CustomFilter,
                                    }}
                                >
                                    {strings.EVENT_LOGS_TABLE_COL_APPLICATION}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="deveui"
                                    className="deveui_table"
                                    columnClassName="deveui_table"
                                    filter={{
                                        customFilterParameters: {
                                            length: 1,
                                            placeholder: strings.DEVEUI,
                                            regex: /^(([0-9a-f]{2})-){7}([0-9a-f]{2})$|^([0-9a-f]{1,16})$/i
                                        } as any,
                                        getElement: this.customTableFilterRegex,
                                        type: CustomFilter,
                                    }}
                                >
                                    {strings.DEVEUI}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="txt"
                                    className="txt_table"
                                    columnClassName="txt_table"
                                    dataFormat={this.TxtFormat}
                                    filter={{
                                        customFilterParameters: {
                                            length: 1,
                                            placeholder: strings.EVENT_LOGS_TABLE_COL_TEXT,
                                            regex: /^.+$/i
                                        } as any,
                                        getElement: this.customTableFilterRegex,
                                        type: CustomFilter,
                                    }}
                                >
                                    {strings.EVENT_LOGS_TABLE_COL_TEXT}
                                </TableHeaderColumn>
                            </BootstrapTable>
                        </Col>
                    </Row>
                </Grid>
            );
        }
    }
}
