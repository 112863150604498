var app = angular.module('dassUiModule');

app.controller('CustomErrorModalController', ["$scope", "$uibModalInstance", "items", 
    function ($scope, $uibModalInstance, items) {
	
    var vm = this;
    vm.items = items;

    vm.ok = function () {
      $uibModalInstance.close();
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
      
      window.location.href = "/app";
    };

    // this function is used to prevent closing the modal when clicked in the background
    $scope.$on('modal.closing', (event, reason, closed) => {
        if (!closed) {
            event.preventDefault();
        }
    });

}]);
