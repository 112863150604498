import LocalizedStrings from "react-localization";
import * as english from "../../../localization/en";
import * as chinese from "../../../localization/zh";

export const strings = new LocalizedStrings({
    en: english,
    zh: chinese,
});

strings.setLanguage(decodeURIComponent(document.cookie).slice(-2));
