(function (angular) {
    var app = angular.module('dassUiModule');
    app.factory('ToastService', ToastServiceFn);

    app.directive("toast", function () {
        return {
            scope: {
                index: '@',
                message: '@',
                icon: '@',
                context: '@',
                manualDismiss: '@'
            },
            link:  ['$scope', '$element', function ($scope, $element) {
                var vm = this;
            }],
            controller: ['$scope', '$element', 'ToastService', '$timeout', function ($scope, $element, ToastService, $timeout) {
                var vm = this;
                if ($scope.manualDismiss) {
                    var element = $element.find("div");
                    element.removeClass("show");
                }

                vm.updateIndex = (idx) =>  {
                    vm.index = idx;
                    $scope.index = idx;
                }

                vm.destroyToast = function() {
                    var element = $element.find("div");
                    element.empty();
                    element.remove();
                    $element.remove();
                }
                vm.hideToast = function () {
                    var element = $element.find("div");
                    element.addClass("dismissed");
                    $timeout(() => {
                        ToastService.toastRemoved(this);
                        vm.destroyToast();
                        $scope.$digest();
                    }, 500);
                }
            }],
            controllerAs: "vm",
            template: `
                <div ng-click="vm.hideToast()" ng-class="['toast', context, {'show': !manualDismiss}, {'nag': manualDismiss}]" style="bottom: {{ 20 + index * 60}}px">
                <button class="toastAngular Toastify__close-button Toastify__close-button--error" type="button" aria-label="close">✖</button>
                    <i ng-if="icon" class="glyphicon glyphicon-{{icon}}"></i> {{ message }}
                </div>`
        };
    });

    ToastServiceFn.$inject = ['$log', "$q", "$rootScope", "$compile", '$timeout'];

    function ToastServiceFn($log, $q, $rootScope, $compile, $timeout) {
        var svc = this;
        svc.toasts = [];

        // Basically shows a toast that has to be manually dismissed, and doesn't have a timeout
        function showNagMessageImpl(msg, context, icon) {
            return showMessageImpl(msg, context, icon, true);
        }
        
        function updateToastPositions() {
            svc.toasts.forEach((x, idx) => {
                x.updateIndex(idx);
            });
        }

        function toastAddedImpl(controller) {
            svc.toasts.push(controller);
            updateToastPositions();
        }

        function toastRemovedImpl(controller) {
            svc.toasts = svc.toasts.filter(x => x && x.index !== controller.index);
            updateToastPositions();
        }

        function showMessageImpl(msg, context, icon, manualDismiss) {
            manualDismiss = manualDismiss || context === 'error';
            const strElement = `<toast icon=${icon} context=${context} index=${svc.toasts.length} message="${msg}" manual-dismiss="${manualDismiss}"></toast>`;
            var toastElement = angular.element(strElement);
            angular.element(document.body).append(toastElement);
            $compile(toastElement)($rootScope);
            var ctrl = toastElement.controller("toast");

            toastAddedImpl(ctrl);
            if (!manualDismiss) {
                $timeout(function () { 
                    var ctrl = toastElement.controller("toast");
                    if (ctrl) {
                        toastRemovedImpl(ctrl);
                        ctrl.destroyToast();
                    }
                }, 5000);
            }
        }

        return {
            showNagMessage: showNagMessageImpl,
            showMessage: showMessageImpl,
            toastAdded: toastAddedImpl,
            toastRemoved: toastRemovedImpl
        }
    }
})(angular);
