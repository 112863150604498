import * as React from "react";
import { getWelcomePage } from "../../services/UserSettings";
import { strings } from "../../services/Localization";
import { Whoami } from "../../services/Login";
import "./welcome.css";
declare const constants;
// import config  from '../../../../../server/config';
export class Welcome extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            signedIn: false,
            ready: false,
            customPage: "",
        };
    }

    public async componentWillMount() {
        try {
            const response: any = await Whoami();
            if (response.user) {
                let customPage = "";
                if (response.user._environment.welcome_page_id) {
                    try {
                        const pages = await getWelcomePage();
                        customPage = pages.welcomePage;
                    } catch (e) {}
                }
                this.setState({ signedIn: true, customPage });
            }
        } catch (error) { }
        this.setState({ ready: true });
    }

    public render() {
        return (
            <div>
                {this.state.ready && this.state.customPage && <div dangerouslySetInnerHTML={{__html: this.state.customPage}} />}
                {this.state.ready && !this.state.customPage && <div className="container">

                    {constants.hideWelcomeBox !== true && <div>
                        <div className="col-md-10 welcomePage">
                            <div className="col-md-6 welcomePageCenter">
                                <div className="welcomePageContent">
                                    <h1 className="welcomePageTitle">{constants.welcome_title}</h1>
                                    <p className="welcomePageMessage">{constants.welcome_text}</p>
                                    {this.state.signedIn === false && (
                                        <div>
                                            <p>
                                                <a className="btn btn-lg welcomePageButtonLearn"
                                                    href={constants.welcome_link} role="button">{strings.WELCOME_LEARN_MORE}</a>
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6 welcomePageCenter">
                                <div className="welcomePageContent">
                                    {constants.welcome_right_side_image !== "" &&
                                        <img src={constants.welcome_right_side_image || "/theme/images/mock-up.png"} className="img-responsive" alt="" />
                                    }
                                    {constants.welcome_right_side_text !== "" &&
                                        <p className="text_under_mc">{constants.welcome_right_side_text || strings.WELCOME_RIGHT_MESSAGE}</p>
                                    }
                                </div>
                                <div className="welcomePowerBy p-r-0">
                                    <div title={constants.version_long}>{constants.version}</div>
                                    <div className="text_center_style">{strings.POWERED_BY}
                                        <img className="logo_orbiwise" src={"images/logo-orbiwise-noir-horizontal.png"} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}

                </div>}
                {constants.hideWelcomeBox === true && (
                    <div className="welcomePowerBy">
                        <small>
                            {strings.POWERED_BY}
                            <img className="logo" src={"images/logo-orbiwise-noir-horizontal.png"} />
                        </small>
                    </div>
                )}
            </div>
        );
    }
}
