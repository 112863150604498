var app = angular.module('dassUiModule');

function GatewayController(GatewayService, ToastService, UserService, MessageService, $timeout, $filter, $uibModal, $log) {
    const $translate = $filter("translate");
    const formatDeveui = $filter("formatDeveui");

    let vm = this;
	vm.workingCount = 0;
	vm.dataType = '/uiapi/rest/gateways';
	vm.selectedGateways = 0;
	vm.numberOfGateways = 0;
	vm.scanning         = 0;
	vm.constants = constants;

	vm.progressCb = (ctrl, done) => {
		vm.scanning         = done ? 0 : ctrl.scanned;
		vm.numberOfGateways = ctrl.pageData && ctrl.pageData.total || 0;
	}

	vm.loadGateways = (gateway) => {
		if (!vm.reloadData) return;
        var reloadPromise = vm.reloadData();
        if (reloadPromise) {

            vm.workingCount += 1;
            reloadPromise.then(() => {
				vm.type = type;
				vm.workingCount -= 1;

            }).catch(err => {
                vm.workingCount -= 1;
            });
		}
		return reloadPromise;
	};

	vm.tableSelectionChanged = (gateway) => {
        console.log("Selection changed ", gateway);

        vm.selectedGateways = gateway;
	};

	vm.gatewayConfiguration = (gateway) => {
		window.eGatewayConfigModal.showEnterpriseConfigModal(gateway.id, vm.user);
	};

	vm.viewGatewayOnMap = (gateway) => {
		window.gatewayModals.showPositionOnMap(gateway);
	};

	vm.addGateway = () => {
		const modalInstance = $uibModal.open({
			backdrop: "static",
			animation: vm.animationsEnabled,
			templateUrl: 'addGatewayModalContent.html',
			controller: 'AddGatewayModalCtrl',
			size: "",
			resolve: {
				items: () => {
					return [];
				}
			}
		});

		modalInstance.result.then((gateway) => {
            GatewayService.addGateway(gateway).then(
				(response) => {
					ToastService.showMessage($translate('MSG_GATEWAY_ADDED_SUCCESS_BODY', gateway), "success")

					vm.loadGateways();
				},
				(response) => {
					if(response.status === 408){
						ToastService.showMessage($translate(response.data), "error")
					}
					if(response.status === 412){
                        ToastService.showMessage($translate('MAX_NUM_REACHED', {
                            item: "gateways"
                        }), "error");
					}
					if((response.status != 408) && (response.status != 412)) {
						ToastService.showMessage($translate('MSG_GATEWAY_ADDED_FAIL_BODY'), "error")
					}
				}
			);
		}, () => {
			$log.info('Modal dismissed at: ' + new Date());
		});
	};

	vm.deleteGateway = (gateway) => {
		MessageService.showMessage({
			title: $translate('MSG_DELETE_GATEWAY_TITLE'),
			body: $translate('MSG_DELETE_GATEWAY_BODY', gateway)
		}).then((ok) => {
			console.log("sending delete gateway");
			GatewayService.deleteGateway(gateway.id).then(
				(response) => {
					ToastService.showMessage($translate('MSG_DELETE_GATEWAY_SUCCESS_BODY'), "success");
					vm.loadGateways();
				},
				(response) => {
					ToastService.showMessage($translate('MSG_DELETE_GATEWAY_FAIL_BODY'), "error");
				}
			);
		});
	};

	vm.deleteGateways = () => {
		MessageService.showMessage({
			title: $translate('MSG_DELETE_GATEWAYS_TITLE'),
			body: $translate('MSG_DELETE_GATEWAYS_BODY', {
				count: vm.selectedGateways.length
			})
		}).then((ok) => {
			console.log("sending delete gateway");

			GatewayService.deleteGateways(vm.selectedGateways).then(
				(response) => {
					ToastService.showMessage($translate('MSG_DELETE_GATEWAYS_SUCCESS_BODY', vm.selectedGateways), "success");
					vm.loadGateways();
				},
				(response) => {
					ToastService.showMessage($translate('MSG_DELETE_GATEWAYS_FAIL_BODY',vm.selectedGateways), "error");
				}
			);
		});
	};

	vm.open = function () {
		var modalInstance = $uibModal.open({
		  backdrop: "static",
		  animation: true,
		  ariaLabelledBy: 'modal-title',
		  ariaDescribedBy: 'modal-body',
		  templateUrl: 'customErrorModal.html',
		  controller: 'CustomErrorModalController as vm',
		  size: 'error-modal-class',
		  resolve: {
			items: () => {
				return {
					error: "The User does not have permission to see this page.",
					title: "Error"
				};
			}
		   }
		});

		modalInstance.result.then(function () {
			window.location.href = "/app";
		});
	};

    vm.loadUserData = () => {
        vm.workingCount += 1;
		return UserService.getUserData().then(
            (userData) => {
				vm.user = userData.user;

				if(!userData.user.can_own_gtw){
					vm.open();
				}
                vm.currentDevice = userData.currentDevice;
                vm.workingCount -= 1;
            },
            (response) => {
                console.log("error getting user data:" + response.status);
                vm.workingCount -= 1;
            }
        )
	}

	vm.openEnterpriseAddGatewayModal = () => {

        // calling the modal functionality written in React
        window.eAddGatewayModal.showEnterpriseAddModalModal({});
	}

	const StatusValues = {
		ANY:                   { label: "GATEWAY_STATUS_ANY",    value: "" },
		NEVER_SEEN:            { label: "GTW_STATUS_NEVER_SEEN",            col: "black" },
		GTW_INIT:              { label: "GTW_STATUS_GTW_INIT",              col: "red", t: "o", col: "red" },
		OK:                    { label: "GTW_STATUS_OK",                    col: "green" },
		OFF:                   { label: "GTW_STATUS_OFF",                   col: "red", t: "o" },
		BACKHAUL_ISSUE:        { label: "GTW_STATUS_BACKHAUL_ISSUE",        col: "red", t: "o" },
		OFF_OR_BACKHAUL_ISSUE: { label: "GTW_STATUS_OFF_OR_BACKHAUL_ISSUE", col: "red", t: "o" },
		RADIO_OFF:             { label: "GTW_STATUS_RADIO_OFF",             col: "red", t: "o" },

		WARNING:     { text: "Warning",     col: "yellow", t: "n" },
		MINOR_ISSUE: { text: "Minor Issue", col: "orange", t: "n" },
		MAJOR_ISSUE: { text: "Major Issue", col: "red",    t: "n" },
	}

	const useColors = true;
	const statusIcons = {
		red: "fas fa-exclamation-triangle" + (useColors ? " led_red" : ""),
		orange: "fas fa-exclamation-circle" + (useColors ? " led_orange" : ""),
		yellow: "fas fa-info-circle" + (useColors ? " led_yellow" : ""),
		green: "fas fa-check" + (useColors ? "" : ""),
		black: "fas fa-question" + (useColors ? "" : ""),
	}



	vm.initDataTable = () => {

		const options = [];
		const t = vm.user && vm.user.can_access_gtw_unfiltered ? "n" : "o";

		for (const key of Object.keys(StatusValues)) {
			const val = StatusValues[key];
			if (!val.t || val.t === t) {
				options.push({ label: val.label || val.text, value: val.value != null ? val.value : key });
			}
		}

		vm.loadGateways();
		vm.actions= [
		{
			type: "action",
			text: "GATEWAY_CONFIGURATION",
			icon: "fas fa-cog fa-fw",
			action: (gateway)=> vm.gatewayConfiguration(gateway),
			visible: (entry, gateway) => gateway.altitude != null &&
									     vm.user.account_status==="active", // the altitude field should always be there
		},{
			type: "action",
			text: "VIEW_GATEWAY",
			icon: "fas fa-map-marked-alt fa-fw",
			action: (gateway)=> vm.viewGatewayOnMap(gateway),
			visible: (entry, gateway) => gateway.altitude != null &&
										 vm.user.account_status==="active",	// the altitude field should always be there
		},{
			type: "separator",
			visible: (entry, gateway) => gateway.altitude != null && (vm.user.can_mng_gtw || vm.user.can_add_gtw) &&
										 vm.user.account_status==="active",
		},{
			type: "action",
			text: "DELETE_GATEWAY",
			icon: "far fa-trash-alt fa-fw",
			action: (gateway)=> vm.deleteGateway(gateway),
			visible: (entry, gateway) => vm.user.can_mng_gtw || vm.user.can_add_gtw,
		}];

		vm.bulkActions= [{
			type: "action",
			text: "DELETE_GATEWAYS",
			icon: "far fa-trash-alt fa-fw",
			action: vm.deleteGateways
		}];

		vm.columns= [{
			key: "id",
			type: "text",
			title: "GATEWAY_ID",
			filterable: true,
			filterField: 'search_id',
			filterType: 'text',
			filterSearchMinLength: 1,
			filterParams: {
				mapper: (x) => x || undefined
			}
		},
		{
			key: "status",
			title: "STATUS",
			type: "icon_with_tooltip",
			filterable: true,
			filterField: 'search_status',
			filterType: 'select',
			filterParams: {
				options
			},
			render_icon: (x) => statusIcons[StatusValues[x.status].col] || "",
			render_tooltip: (x) => {
				let tip = "";
				if (StatusValues[x.status] != null) {
					tip = (StatusValues[x.status].label ? $translate(StatusValues[x.status].label) : StatusValues[x.status].text);
				}
				if (x.active_alarms && x.active_alarms.length > 0) {
					tip += "<pre>";
					let br = "";
					for (const alrm of x.active_alarms) {
						if (alrm && alrm.description) {
							tip += br + alrm.description;
							br = "\n";
						}
					}
					tip += "</pre>"
				}
				return tip;
			}
		},
		{
			key: "name",
			title: "GATEWAY_NAME",
			type: "text",
			filterable: true,
			filterField: 'search_name',
			filterType: 'text',
			filterSearchMinLength: 1,
			filterParams: {
				mapper: (x) => x || undefined
			}
		}]
	};

	Promise.all([vm.loadUserData()]).then(vm.initDataTable);
};

app.controller("GatewayController", ["GatewayService", "ToastService", "UserService", "MessageService", "$timeout", "$filter", "$uibModal", "$log", GatewayController])
