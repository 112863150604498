
declare const headerContentTypeJson;
declare const headerNoContentType;

export const LoggedUserInfo = () => {
    return new Promise<Response>(async (resolve, reject) => {

        try {
            const response = await fetch("/whoami", {
                credentials: "same-origin",
                headers: headerNoContentType,
                method: "GET",
            });

            if (response.status == 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const GetAccountTemplate = async (accountType: "user" | "customer" | "org") => {

    try {
        const path = {
            user: "users",
            customer: "customers",
            org: "organisations",
        };

        const response = await fetch(`/uiapi/rest/${path[accountType]}?get_defaults=true`, {
            credentials: "same-origin",
            headers: headerNoContentType,
            method: "GET",
        });

        if (response.status == 401) { // the status should be changed!!!
            window.location.href = '/app/signout?resignin';
        }

        return await response.json();

    } catch (error) {
        throw error;
    }
}


interface IAuthServer {
    auth_server_id: string;
    auth_server_name: string;
    type: string;
    use_password: boolean;
    use_subject: boolean;
}

export const GetAuthServers = async () => {

    try {
        const response = await fetch("/uiapi/rest/auth/servers", {
            credentials: "same-origin",
            headers: headerNoContentType,
            method: "GET",
        });
        if (response.status == 401) { // the status should be changed!!!
            window.location.href = '/app/signout?resignin';
        }
        return await response.json() as IAuthServer[];

    } catch (error) {
        throw error;
    }

}


export const CreateNewAccount = async (accountType: "user" | "customer" | "org", user: any) => {

    try {
        const path = {
            user: "users",
            customer: "customers",
            org: "organisations",
        };

        console.log(JSON.stringify(user));


        const response = await fetch(`/uiapi/rest/${path[accountType]}`, {
            body: JSON.stringify(user),
            credentials: "same-origin",
            headers: headerContentTypeJson,
            method: "POST",
        });

        if (response.status == 401) { // the status should be changed!!!
            window.location.href = '/app/signout?resignin';
        }

        return response;

    } catch (error) {
        throw error;
    }
}




export const ForwardUserInfo = (UserID) => {
    return new Promise<Response>(async (resolve, reject) => {
        try {
            const response = await fetch(`/uiapi/rest/users/${UserID}`, {
                credentials: "same-origin",
                headers: headerNoContentType,
                method: "GET",
            });

            if (response.status == 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const UpdateUserSettings = async (userid: string, settings: any, query = "") => {
    const response = await fetch("/uiapi/rest/users/" + userid + query, {
        body: JSON.stringify(settings),
        credentials: "same-origin",
        headers: headerContentTypeJson,
        method: "PUT",
    });

    if (response.status == 401) { // the status should be changed!!!
        window.location.href = '/app/signout?resignin';
    }

    return response;
};

export const GetTenantName = (tenantId) => {
    return new Promise<Response>(async (resolve, reject) => {
        try {
            const response = await fetch(`/uiapi/rest/tenants?search_id=${tenantId}`, {
                credentials: "same-origin",
                headers: headerNoContentType,
                method: "GET",
            });

            if (response.status == 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

// TODO: this functions is not sure to work
export const GetOrganizationName = (organizationId) => {
    return new Promise<Response>(async (resolve, reject) => {
        try {
            const response = await fetch(`/uiapi/rest/organisations/${organizationId}`, {
                credentials: "same-origin",
                headers: headerNoContentType,
                method: "GET",
            });

            if (response.status == 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const GetLicense = () => {
    return new Promise<Response>(async (resolve, reject) => {
        try {
            const response = await fetch("/uiapi/rest/license", {
                credentials: "same-origin",
                headers: headerNoContentType,
                method: "GET",
            });

            if (response.status == 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const UpdateLicense = (license) => {
    return new Promise<Response>(async (resolve, reject) => {
        try {
            const response = await fetch("/uiapi/rest/license", {
                body: JSON.stringify(license),
                credentials: "same-origin",
                headers: headerContentTypeJson,
                method: "PUT",
            });

            if (response.status == 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const GetSoftwareInfo = () => {
    return new Promise<Response>(async (resolve, reject) => {
        try {
            const response = await fetch("/uiapi/rest/users/$?_get_eula=true", {
                credentials: "same-origin",
                headers: headerNoContentType,
                method: "GET",
            });

            if (response.status == 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};


export const getEmailHelpDesk = async () => {
    const response = await fetch("/uiapi/rest/users/$?_get_helpdesk_email=true", {
        credentials: "same-origin",
        headers: headerNoContentType,
        method: "GET",
    });

    if (response.status === 200) { return await response.json(); }
    if (response.status == 401) { // the status should be changed!!!
        window.location.href = '/app/signout?resignin';
    }
    return null;
};

export const getWelcomePage = async () => {
    const response = await fetch("/uiapi/rest/users/$?_get_welcome_page=true", {
        credentials: "same-origin",
        headers: headerNoContentType,
        method: "GET",
    });
    if (response.status == 401) { // the status should be changed!!!
        window.location.href = '/app/signout?resignin';
    }
    return await response.json();
};
